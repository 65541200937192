import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { developerService } from '../../../services/developer.service';
import { IPaymentPlan } from '../appUsers/types';
import { UserDetails } from '../appUsers/userDetails/userDetails.comp';
import { UserList } from '../appUsers/userList/userList.comp';
import { DeveloperContext } from '../developer.context';

export const AppUsers = () => {
  const { activeApp, } = useContext(DeveloperContext);
  const { userId } = useParams<any>();
  const [plans, setPlans] = useState<IPaymentPlan[]>([]);

  async function getPlans() {
    try {
      const { data } = await developerService.getAppPlans(activeApp.appId);
      setPlans(data);
    } catch (e) {
    }
  }

  useEffect(() => {
    if (!activeApp.appId) {
      return;
    }

    getPlans();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeApp.appId]);

  return (
    <>
      {userId ?
        <UserDetails plans={plans} />
        :
        <UserList />
      }
    </>
  );
};
