export const denormalizeFieldType: { [key: string]: string } = {
	text: 'Text',
	email: 'Email',
	password: 'Password',
	'rich-text': 'Rich Text',
	number: 'Number',
	enumeration: 'Enumeration',
	date: 'Date',
	boolean: 'Boolean',
	json: 'JSON',
	uid: 'UID',
	array: 'Array',
	url: 'URL',
	relation: 'Relation',
	media: 'Media',
};

export function camelize(str: string) {
	const newStr = str
		.trim()
		.replace(/[^\w\s]/gi, '') // removes special characters
		.replace(/[-_\s]+(.)?/g, (_, c) => (c ? c.toUpperCase() : '')); // uppercases first letter after each space
	return newStr.charAt(0).toLowerCase() + newStr.slice(1); // lowercases first letter
}
