import { HttpService } from "@commonninja/commonninja-styleguide-react";

export interface IPluginListingNew {
  name: string;
  slug: string;
  buttonText: string;
  teaser: string;
  description: string;
  serviceName: string;
  componentType: string;
  developerId: string;
  supportLink: string;
  logo: string;
  whiteLogo: string;
  hero: string;
  developerName: string;
  developerLink: string;
  price: number;
  order: number;
  tags: string[];
  categories: string[];
  status: string;
  created: string;
  updated: string; //'2022-02-01T07:42:51.442Z';
  promotionImage: string;
  catalogImage: string;
  links: { platformName: string; url: string }[];
}

class AppListService extends HttpService {
  public apps: IPluginListingNew[] = [];

  constructor() {
    super();
    this.get();
  }

  async get(): Promise<IPluginListingNew[]> {
    if (this.apps.length) {
      return this.apps;
    }

    try {
      const result = await fetch("https://www.commoninja.com/nindo/api/apps");
      const json = await result.json();

      if (json.success) {
        this.apps = json.data.docs;
      }
    } catch (e) {
      console.error("Could not get apps list", e);
    }

    return this.apps;
  }
}

export const appList = new AppListService();
