import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { DeveloperContext } from '../developer.context';

import logo from '../../../assets/logo.png';
import './appMenu.scss';
import { AppSubmenu } from './appSubmenu/appSubmenu.comp';
import { AppMenuSection } from './appMenuSection.comp';
import { AppDeveloperMenu } from './appDeveloperMenu/appDeveloperMenu.comp';
import {
  faBook,
  faCommentDots,
  faThLarge,
  faUserEdit,
} from '@fortawesome/free-solid-svg-icons';

interface IAppMenuProps {
  isMenuOpen?: boolean;
  setMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const AppMenu = ({ isMenuOpen, setMenuOpen }: IAppMenuProps) => {
  const { activeApp } = useContext(DeveloperContext);

  function createAppMenuItem(
    { label, url }: { label: string; url?: string; },
    index: number
  ) {
    const slug = label.toLowerCase().replace(' ', '-');
    return {
      url: url || `${`/developer/apps/${activeApp.appId}/`}${slug}`,
      label,
    };
  }

  const appMenuSections = [
    { items: [{ label: 'Dashboard' }] },
    {
      title: 'Build Your App',
      items: [
        { label: 'Integrations' },
        { label: 'Authentication' },
        { label: 'Permissions' },
        { label: 'Webhooks' },
        { label: 'Payments' },
      ],
    },
    {
      title: 'Manage Your App',
      items: [
        // { label: 'Database' },
        { label: 'Users' },
        { label: 'App Info' },
      ],
    },
  ];

  return (
    <aside className={`developer-app-menu ${isMenuOpen ? 'menu-shown' : ''}`}>
      <div className="nav-content">
        <figure className="logo">
          <Link to={'/developer/apps'} className="flex-wrapper">
            <img src={logo} width="200" alt="Common Ninja" />
            <p>Developer Platform</p>
          </Link>
        </figure>
        <AppSubmenu />
        <nav>
          <div className="navigation-wrapper">
            {appMenuSections.map((section, index) => (
              <AppMenuSection
                title={section.title}
                key={index}
                items={section.items.map(createAppMenuItem)}
                setMenuOpen={setMenuOpen}
              />
            ))}
          </div>
          <AppDeveloperMenu
            inMenu
            menuLinks={[
              {
                name: 'My Apps',
                url: `/developer/apps`,
                icon: faThLarge,
              },
              {
                name: 'Developer Info',
                url: `/developer/account`,
                icon: faUserEdit,
              },
              {
                name: 'Documentation',
                url: `https://docs.commoninja.com/`,
                target: '_blank',
                icon: faBook,
              },
              {
                name: 'Support',
                url: 'https://help.commoninja.com/',
                target: '_blank',
                icon: faCommentDots,
              },
            ]}
          />
        </nav>
      </div>
    </aside>
  );
};
