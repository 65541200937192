/* eslint-disable no-undef */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { error, removeAll } from 'react-notification-system-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useResizeDetector } from 'react-resize-detector';
import { 
  Loader,
  NinjaSkeletonTheme,
  eventService,
  IUser,
  ICycle,
  IPlan,
  IPricingModel,
  useQuery,
  NinjaSkeleton,
} from '@commonninja/commonninja-styleguide-react';
import { H2 } from '@commonninja/commonninja-styleguide-react/lib/esm/components/internal';

import LocationHelper from '../../helpers/countries';
import LocalEventService from '../../services/event.service';
// import { sendConversionEventToGA } from '../../helpers/helpers';
import { appList, IPluginListingNew } from '../../services/appList.service';

import bluesnapLogo from '../../assets/bluesnap-logo.png';

import './checkout.scss';

declare let bluesnap: any;
declare let paypal: any;

let isSubmitting = false;
const Script = require('react-load-script');
const { REACT_APP_BLUESNAP_API_URL, REACT_APP_PAYPAL_CLIENT_ID } = process.env;
const bluesnapFieldStyle = {
  input: {
    padding: '10px',
    color: '#1c3144',
    'font-weight': '300',
    'font-size': '14px',
    'background-color': 'none',
    'border-color': 'rgba(28, 49, 68, 0.5)',
    'border-width': '1px',
    'border-style': 'solid',
    'font-family': '"Reboto", sans-serif',
    'border-radius': '6px',
    'position': 'relative',
    'width': '93%'
  },
  ':focus': {
    'background-color': 'rgba(255, 255, 255, 0.7)'
  },
  '#month': {
    width: '60px !important',
  },
  '#year': {
    width: '60px !important',
  },
  '#cvv': {
    width: '130px !important',
    'text-align': 'center'
  },
  '#exp': {
    width: '130px !important',
    'text-align': 'center'
  },
  span: {
    left: '18px',
    position: 'relative'
  }
};

interface IBluesnapFormState {
  name: string;
  state: string;
  country: string;
  address: string;
  city: string;
  zip: string;
  companyName: string;
  '3DS': true;
}

function getErrorText(errorCode: string | number) {
  switch (errorCode) {
    case '001':
      return 'Please enter a valid card number';
    case '002':
      return 'Please enter the CVV/CVC of your card';
    case '003':
      return 'Please enter your card\'s expiration date';
    case '22013': 
      return 'Card type is not supported by merchant'; 
    case '400':
      return 'Session expired please refresh page to continue';
    case '403': 
    case '404':
    case '500':
      return 'Internal server error please try again later';
    default: 
      return '';
  }
}

function handleMessageEvent(e: any) {
  if (!e.data) {
    return;
  }

  try {
    const eventData = typeof e.data === 'string' ? JSON.parse(e.data) : e.data;

    switch (eventData.type) {
      case 'COMMONNINJA_DASHBOARD_PATH_CHANGE':
        window.location.href = eventData.url;
        break;
      default:
        return;
    }
  } catch (e) {}
}

const ChangePlan = ({ 
  activePlan, 
  activeCycle, 
  onChange 
}: {
  activePlan: IPlan;
  activeCycle: ICycle;
  onChange: () => void;
}) => {
  const discountPrice = (activeCycle.billingCycle * activePlan.pricing) * activeCycle.discount / 100;

  return (
    <div className="change-plan-checkout">
      <p>
        Please note that you'll be charged now for <strong>${(activePlan.pricing * activeCycle.billingCycle - discountPrice).toFixed(2)}</strong>.<br />
        <span>* No refunds for previous payments.</span>
      </p>
      <div className="buttons-wrapper center">
        <button className="checkout-button" onClick={() => onChange()}>Switch Plan</button>
      </div>
    </div>
  );
}

const PaymentMethods = ({ 
  bluesnapScriptLoaded, 
  onSubmit, 
  submitting,
}: {
  bluesnapScriptLoaded: boolean;
  onSubmit: (e: any, formState: IBluesnapFormState) => void;
  submitting: boolean;
}) => {
  const [formState, setFormState] = useState<IBluesnapFormState>({
    name: '',
    state: '',
    country: 'US',
    address: '',
    city: '',
    zip: '',
    companyName: '',
    '3DS': true // https://developers.bluesnap.com/docs/3-d-secure-for-api#section-3-d-secure-in-hosted-payment-fields
  });
  const formElm = useRef() as any;
  const firstInput = useRef() as any;

  function renderCreditCards() {
    return (
      <div className="credit-cards">
        <img alt="AmericanExpress" title="AmericanExpress" src="https://files.readme.io/97e7acc-Amex.png" />
        {/* <img alt="CarteBleau" title="CarteBleau" src="https://files.readme.io/5da1081-cb.png"/> */}
        <img alt="DinersClub" title="DinersClub" src="https://files.readme.io/8c73810-Diners_Club.png"/>
        <img alt="Discover" title="Discover" src="https://files.readme.io/caea86d-Discover.png"/>
        <img alt="JCB" title="JCB" src="https://files.readme.io/e076aed-JCB.png"/>
        <img alt="MaestroUK" title="MaestroUK" src="https://files.readme.io/daeabbd-Maestro.png"/>
        <img alt="MasterCard" title="MasterCard" src="https://files.readme.io/5b7b3de-Mastercard.png"/>
        {/* <img alt="Solo" title="Solo" src="https://sandbox.bluesnap.com/services/hosted-payment-fields/cc-types/solo.png" /> */}
        <img alt="Visa" title="Visa" src="https://files.readme.io/9018c4f-Visa.png" />
      </div>
    );
  }

  function renderBlueSnapForm() {
    return (
      <>
        <form onSubmit={(e) => onSubmit(e, formState)} ref={formElm}>
          <div className="form">
            {
              submitting && 
              <div className="processing">
                <Loader>
                  <p>Processing...</p>
                </Loader>
              </div>
            }
            <div className="form-row">
              <label>Cardholder Name</label>
              <div className="form-elm">
                <input 
                  type="text" 
                  placeholder="Full Name"
                  defaultValue={formState.name} 
                  onChange={(e) => setFormState({ 
                    ...formState,
                    name: e.target.value
                  })} 
                  required
                  ref={firstInput}
                />
              </div>
            </div>
            <div className="form-row card-number">
              <label>Card Number</label>
              <div className="form-elm hosted">
                <div data-bluesnap="ccn"></div>
              </div>
            </div>
            <div className="form-row half">
              <label>Exp. (MM/YY)</label>
              <div className="form-elm hosted">
                <div data-bluesnap="exp"></div>
              </div>
            </div>
            <div className="form-row half">
              <label>CVV (3 digits)</label>
              <div className="form-elm hosted">
                <div data-bluesnap="cvv"></div>
              </div>
            </div>
            <h3>Billing</h3>
            <div className="form-row">
              <label>Company Name</label>
              <div className="form-elm">
                <input 
                  type="text" 
                  placeholder="Your Name / Company Name"
                  defaultValue={formState.companyName} 
                  onChange={(e) => setFormState({ 
                    ...formState,
                    companyName: e.target.value
                  })} 
                />
              </div>
            </div>
            <div className="form-row half">
              <label>Country</label>
              <div className="form-elm">
                <select 
                  defaultValue={formState.country} 
                  onChange={(e) => setFormState({ 
                    ...formState,
                    country: e.target.value
                  })}
                  required
                >
                  {
                    LocationHelper.countries.map((country) => (
                      <option key={`country_${country.code}`} value={country.code}>{country.name}</option>
                    ))
                  }
                </select>
              </div>
            </div>
            <div className="form-row half">
              <label>State</label>
              <div className="form-elm">
                <input 
                  required
                  placeholder="State / Region"
                  type="text" 
                  defaultValue={formState.state} 
                  onChange={(e) => setFormState({ 
                    ...formState,
                    state: e.target.value
                  })} 
                />
              </div>
            </div>
            <div className="form-row half">
              <label>City</label>
              <div className="form-elm">
                <input 
                  required
                  placeholder="City"
                  type="text" 
                  defaultValue={formState.city} 
                  onChange={(e) => setFormState({ 
                    ...formState,
                    city: e.target.value
                  })} 
                />
              </div>
            </div>
            <div className="form-row half">
              <label>Zip</label>
              <div className="form-elm">
                <input 
                  required
                  placeholder="Zip / Postal Code"
                  className="small"
                  type="text" 
                  defaultValue={formState.zip} 
                  onChange={(e) => setFormState({ 
                    ...formState,
                    zip: e.target.value
                  })} 
                />
              </div>
            </div>
            <div className="form-row">
              <label>Address</label>
              <div className="form-elm">
                <input 
                  required
                  placeholder="Billing Street Address"
                  type="text" 
                  defaultValue={formState.address} 
                  onChange={(e) => setFormState({ 
                    ...formState,
                    address: e.target.value
                  })} 
                />
              </div>
            </div>
            <div className="buttons-wrapper center">
              <button className="checkout-button" data-bluesnap="submitButton" type="submit" disabled={submitting}>Complete My Order</button>
            </div>
          </div>
        </form>
        <p className="bluesnap-disclaimer">
          <img src={bluesnapLogo} alt="BlueSnap Logo" />
          <span>Common Ninja uses the service of BlueSnap Inc. An online reseller for providing secure payment.</span>
        </p> 
      </>
    );
  }

  useEffect(() => {
    if (bluesnapScriptLoaded && firstInput.current) {
      firstInput.current.focus();
    }
    // eslint-disable-next-line
  }, [bluesnapScriptLoaded]);

  return (
    <div className="payment-methods">
      <div className="paypal">
        <h3>Pay with PayPal</h3>
        <div className="center paypal-form">
          <div id="paypal-button-container"></div>
        </div>
      </div>
      <div className="credit">
        <h3>Pay with Credit Card</h3>
        {renderCreditCards()}
        {
          bluesnapScriptLoaded &&
          renderBlueSnapForm()
        }
      </div>
    </div>
  );
}

const renderLoading = ({ returnCallback }: { returnCallback: () => void }) => {
  return (
    <NinjaSkeletonTheme leadColor="#f7f7f9">
      <div className="content-wrapper">
        <div className="payment-methods">
          <div className="paypal">
            <h3><NinjaSkeleton width="50%" /></h3>
            <div className="paypal-form">
              <NinjaSkeleton width="70%" />
            </div>
          </div>
        </div>
        <div className="pricing-calc">
          <hgroup>
            <h3>
              <NinjaSkeleton width="50%" />
            </h3>
            <h5><NinjaSkeleton width="30%" /></h5>
          </hgroup>
          <div className="billing-row">
            <span><NinjaSkeleton width="40%" /></span>
            <span><NinjaSkeleton width="30%" /></span>
          </div>
          <div className="billing-row">
            <span><NinjaSkeleton width="40%" /></span>
            <span><NinjaSkeleton width="30%" /></span>
          </div>
          <div className="billing-row">
            <span><NinjaSkeleton width="40%" /></span>
            <span className="discount"><NinjaSkeleton width="30%" /></span>
          </div>
          <div className="billing-row total">
            <span><NinjaSkeleton width="50%" /></span>
            <span>
              <NinjaSkeleton width="30%" />
              <i><NinjaSkeleton width="30%" /></i>
            </span>
          </div>
        </div>
      </div>
    </NinjaSkeletonTheme>
  );
}

const renderPricingModelPlugin = (pricingModel: IPricingModel, list: IPluginListingNew[]) => {
  if (!pricingModel) {
    return '';
  }

  const listing = list.filter((p) => p.status === 'published').filter((l) => l.serviceName === pricingModel.services[0])[0];

  if (!listing) {
    return pricingModel.name;
  }
  
  return listing.name;
}

export const Checkout = ({
  checkoutMode,
  user,
  embedMode,
  websiteMode,
  pricingModel,
  activePlan,
  activeCycle,
  activeSubscription,
  returnCallback,
}: {
  checkoutMode: 'change' | 'create';
  user: IUser;
  embedMode: boolean;
  websiteMode: boolean;
  pricingModel: IPricingModel;
  activePlan: IPlan;
  activeCycle: ICycle;
  activeSubscription: any;
  returnCallback: () => void;
}) => {
  const [loading, setLoading] = useState(checkoutMode !== 'change');
  const [bluesnapScriptLoaded, setBluesnapScriptLoaded] = useState(false);
  const [payPalScriptLoaded, setPayPalScriptLoaded] = useState(false);
  const [apps, setApps] = useState<IPluginListingNew[]>([]);
  const [token, setToken] = useState('');
  const [postCheckout, setPostCheckout] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const { params: { serviceName, vendor } } = useRouteMatch() as any;
  const dispatch = useDispatch();
  const history = useHistory();
  const query = useQuery();
  const onResize = useCallback(() => {
    const elm = document.querySelector('#main-content');
		if (!elm) {
			return;
		}

		const { height } = elm.getBoundingClientRect();

		window.parent.postMessage({
      type: 'COMMONNINJA_DASHBOARD_IFRAME_UPDATE',
      height
    }, '*');
  }, []);
  const { ref } = useResizeDetector({
    refreshMode: 'debounce',
    onResize
  });
  const discountPrice = (activeCycle.billingCycle * activePlan.pricing) * activeCycle.discount / 100;

  async function getFormToken() {
    let localError = null;

    try {
      const req = await fetch('/api/bluesnap/getToken');
      const tokenRes = await req.json();

      if (tokenRes.success) {
        const { token } = tokenRes.data;
        setToken(token);
      } else {
        localError = tokenRes.data[0] ? tokenRes.data[0].msg : 'Could not load checkout page';
      }
    } catch (e) {
      localError = 'Could not load plan';
    }

    if (localError) {
      dispatch(error({
        message: localError,
        autoDismiss: 5,
        position: 'tc'
      }));
    }

    setLoading(false);
  }

  function onBluesnapScriptLoaded() {
    const bsObj: any = {
      token,
      '3DS': true,
      onFieldEventHandler: {
        onFocus: function() {},
        onBlur: function() {},
        onError: (tagId: any, errorCode: number | string, errorDescription: string) => {
          const err = getErrorText(errorCode) || errorDescription;
          if (err && isSubmitting) {
            dispatch(removeAll());
            dispatch(error({
              message: err,
              autoDismiss: 5,
              position: 'tc'
            }));
          }
          setSubmitting(false);
        },
        onType: function() {},
        onValid: function() {},
      },
      style: bluesnapFieldStyle,
      ccnPlaceHolder: 'Credit Card Number',
      cvvPlaceHolder: 'CVV',
      expPlaceHolder: 'MM/YY',
      // expDropDownSelector: false,
    };

    // eslint-disable-next-line no-undef
    bluesnap.hostedPaymentFieldsCreate(bsObj);
  }

  function onPayPalScriptLoaded() {
    const planIds = activePlan.planIds?.[activeCycle.period];

    // eslint-disable-next-line no-undef
    paypal.Buttons({
      createSubscription: function(data: any, actions: any) {
        return actions.subscription.create({
          plan_id: planIds?.paypalApi
        });
      },
      onApprove: function(data: any, actions: any) {
        createSubscription('paypalApi', { subscriptionId: data.subscriptionID || data.subscriptionId });
      }
    }).render('#paypal-button-container');
  }

  async function changeSubscriptionPlan() {
    let localError = null;
    const merchantType = activeSubscription.source === 'paypal' ? 'paypalApi' : 'bluesnap';

    // Send event for tracking
    LocalEventService.send('PRICING_PLAN_CHANGE');

    setLoading(true);

    try {
      const planIds = activePlan.planIds?.[activeCycle.period];
      let body = {
        newSubscriptionTypeId: planIds?.commonninja,
        newPlanId: planIds?.[merchantType],
      };

      const req = await fetch(`/api/${activeSubscription.source}${vendor ? '/v/' + vendor : ''}/changePlan/${activeSubscription.subscriptionId}?${query.toString()}&serviceName=${serviceName}`, {
        method: 'post',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
      });
      const result = await req.json();
      
      if (result.success) {
        setPostCheckout(true);
        return;
      } else {
        localError = result.message;
      }
    } catch (e) {
      localError = 'Could not change subscription plan';
    }

    if (localError) {
      dispatch(error({
        message: localError,
        autoDismiss: 5,
        position: 'bc'
      }));
    }

    setLoading(false);
  }

  async function createSubscription(merchantType = 'bluesnap', data: any, cb?: Function) {
    let localError = null;
    // const discountPrice = (activeCycle.billingCycle * activePlan.pricing) * activeCycle.discount / 100;
    // const finalPrice: number = parseFloat((activePlan.pricing * activeCycle.billingCycle - discountPrice).toFixed(2));

    // Send event for tracking
    LocalEventService.send('PRICING_PLAN_SUBMIT');

    eventService.reportMixpanelEvent(
      `Click subscribe with ${merchantType === 'paypalApi' ? 'paypal' : merchantType}`, 
      { 
        serviceName, 
        authenticated: user.isAuthenticated, 
        embedMode: embedMode 
      }
    );

    try {
      const planIds = activePlan.planIds?.[activeCycle.period];
      let body = {
        ...data,
        subscriptionTypeId: planIds?.commonninja,
        planId: (planIds as any)[merchantType],
      };

      const req = await fetch(`/api/${merchantType === 'paypalApi' ? 'paypal' : merchantType}${vendor ? '/v/' + vendor : ''}/subscription?${query.toString()}&serviceName=${serviceName}`, {
        method: 'post',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
      });
      const result = await req.json();

      if (result.success) {
        // Send conversion events
        // sendConversionEventToGA(finalPrice, serviceName);

        eventService.reportMixpanelEvent('Subscription creation success', { 
          serviceName, 
          authenticated: user.isAuthenticated, 
          embedMode 
        });

        setPostCheckout(true);
        return;
      } else {
        localError = result.message;
      }
    } catch (e) {
      localError = 'Could not create subscription';
    }

    if (localError) {
      dispatch(error({
        message: localError,
        autoDismiss: 5,
        position: 'bc'
      }));
    }

    if (cb) {
      cb();
    }
  }

  function onSubmit(e: any, formState: IBluesnapFormState) {
    e.preventDefault();

    if (submitting) {
      return;
    }

    const stateLocation = (LocationHelper as any).states[(formState.state || '').toLowerCase().trim()] || '';

    if ((formState.country === 'US' || formState.country === 'CA') && !stateLocation) {
      dispatch(error({
        message: 'State field is invalid.',
        autoDismiss: 5,
        position: 'bc'
      }));
      return;
    }

    setSubmitting(true);
    
    const threeDSecureObj = {
      currency: 'USD',
      amount: parseFloat((activePlan.pricing * activeCycle.billingCycle - discountPrice).toFixed(2))
    };

    // eslint-disable-next-line no-undef
    bluesnap.hostedPaymentFieldsSubmitData(function(callback: any) {
      const errorArray = callback.error;
      if (errorArray) {
        for (let i in errorArray) {
          const error = errorArray[i].errorDescription;
          dispatch(error({
            message: error,
            autoDismiss: 5,
            position: 'bc'
          }));
        }
        setSubmitting(false);
        return;
      }
      const authResult = callback?.threeDSecure ? callback?.threeDSecure?.authResult : null;
      if (authResult === 'AUTHENTICATION_FAILED') {
        console.error('The card on the transaction could not be authenticated.');
      }
      // const threeDSecureReferenceId = callback?.threeDSecure.threeDSecureReferenceId;

      // console.log('authResult', callback?.threeDSecure);
      
      createSubscription('bluesnap', {
        ...formState,
        state: stateLocation,
        token,
      }, () => {
        setSubmitting(false);
      });
    }, threeDSecureObj);
  }

  function renderCheckoutSummary() {
    return (
      <div className="pricing-calc">
        <hgroup>
          <h3>
            Billing Summary
          </h3>
          <h5>{activeCycle.description}</h5>
        </hgroup>
        <div className="billing-row">
          <span>Monthly Price</span>
          <strong>${activePlan.pricing}</strong>
        </div>
        <div className="billing-row">
          <span>x {activeCycle.billingCycle} Month{activeCycle.billingCycle > 1 ? 's' : ''}</span>
          <strong>${activePlan.pricing * activeCycle.billingCycle}</strong>
        </div>
        <div className="billing-row">
          <span>Discount</span>
          <strong className="discount">- ${discountPrice.toFixed(2)} {activeCycle.discount ? `(${activeCycle.discount.toFixed(2)}%)` : ''}</strong>
        </div>
        <div className="billing-row total">
          <span>Total</span>
          <strong>
            ${(activePlan.pricing * activeCycle.billingCycle - discountPrice).toFixed(2)}
            <i>every billing cycle</i>
          </strong>
        </div>
      </div>
    );
  }

  function renderPaymentScripts() {
    return (
      <>
        <Script
          url={`${REACT_APP_BLUESNAP_API_URL}/web-sdk/4/bluesnap.js`}
          onLoad={() => setBluesnapScriptLoaded(true)}
        />
        <Script
          url={`https://www.paypal.com/sdk/js?client-id=${REACT_APP_PAYPAL_CLIENT_ID}&vault=true&currency=USD`}
          onLoad={() => setPayPalScriptLoaded(true)}
        />
      </>
    );
  }

  async function loadApps() {
    setApps(await appList.get());
  }

  useEffect(() => {
    isSubmitting = submitting;
    // eslint-disable-next-line
  }, [submitting]);

  useEffect(() => {
    if (bluesnapScriptLoaded) {
      onBluesnapScriptLoaded();
    }
    // eslint-disable-next-line
  }, [bluesnapScriptLoaded]);

  useEffect(() => {
    if (payPalScriptLoaded) {
      onPayPalScriptLoaded();
    }
    // eslint-disable-next-line
  }, [payPalScriptLoaded]);
  
  useEffect(() => {
    if (postCheckout) {
      window.setTimeout(() => {
        history.push(`/user${embedMode ? '/embed' : ''}/dashboard`);
      }, 10000);
    }
    // eslint-disable-next-line
  }, [postCheckout]);

  useEffect(() => {
    loadApps();

    if (checkoutMode !== 'change') {
      getFormToken();
    }

    window.addEventListener('message', handleMessageEvent);

    return () => {
      window.removeEventListener('message', handleMessageEvent);
    }
    // eslint-disable-next-line
  }, []);

  if (postCheckout) {
    return (
      <section className={`checkout post-checkout`} ref={ref}>
        <header>
          <hgroup className="page-titles center">
            <H2>👍 Great Success!</H2>
          </hgroup>
        </header>
        <div className="checkout-content">
          <p className="center">
            The <strong className="service-name">{renderPricingModelPlugin(pricingModel, apps)}</strong> app has been upgraded successfully.
          </p>
          <p className="center">
            Within a few seconds you'll be redirected to the dashboard <br />where you may start working with the app's premium features.
          </p>
          <p className="center">
            Note that it might take a couple of minutes to process the new subscription.
          </p>
        </div>
      </section>
    );
  }

  return (
    <>
      <section className={`checkout`} ref={ref}>
        <div className="wrapper">
          <header>
            <hgroup className="page-titles">
              <h2>Checkout</h2>
              <h4>
                {renderPricingModelPlugin(pricingModel, apps)} - {activePlan.name} Plan
                <button className="back-btn" onClick={returnCallback}>
                  (See Other Plans)
                </button>
              </h4>
            </hgroup>
          </header>
          {
            loading ? 
            renderLoading({ returnCallback }) :
            <>
              {checkoutMode !== 'change' && renderPaymentScripts()}
              <div className="content-wrapper">
                {
                  checkoutMode === 'change' ? 
                  <ChangePlan 
                    activePlan={activePlan} 
                    activeCycle={activeCycle} 
                    onChange={changeSubscriptionPlan}
                  /> :
                  <PaymentMethods 
                    bluesnapScriptLoaded={bluesnapScriptLoaded} 
                    onSubmit={onSubmit} 
                    submitting={submitting} 
                  />
                }
                {renderCheckoutSummary()}
              </div>
            </>
          }
        </div>
      </section>
    </>
  );
}