import { IPaymentPlan } from './types';

export function getPlanName(plans: IPaymentPlan[], planId: string) {
  return plans.find((plan) => plan.planId === planId)?.name || 'Not found';
}

export function getPlanPaymentPeriod(
  plans: IPaymentPlan[],
  planId: string,
  platformSubscriptionId: string,
) {
  const plan = plans.find((plan) => plan.planId === planId);

  return (
    (plan as any)?.pricingVariants.find(
      (variant: any) => variant.id === platformSubscriptionId,
    )?.pricePeriod || plan?.pricingVariants[0].pricePeriod
  );
}

export function getSubscriptionMRR(
  plans: IPaymentPlan[],
  planId: string,
  planPricingVariantId: string,
) {
  const plan = plans.find((plan) => plan.planId === planId);

  const variant =
    (plan as any)?.pricingVariants.find(
      (variant: any) => variant.id === planPricingVariantId,
    ) || plan?.pricingVariants[0];

  if (!variant) {
    return 0;
  }

  return (
    ((variant?.priceCount || 0) / (variant?.pricePeriod === 'year' ? 12 : 1)).toFixed(2)
  );
}
