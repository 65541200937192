import React, { useState, useContext } from 'react';
import { toast } from 'react-toastify';

import { DeveloperContext } from '../developer.context';
import { developerService } from '../../../services/developer.service';
import { DeveloperCopier } from '../newDashboardComponents/developerCopier.comp';
import { DeveloperInput } from '../newDashboardComponents/developerInput.comp';
import { useHandleChange } from '../newDashboardComponents/hooks/useHandleChange';

export const AppAuth = () => {
  const { activeApp, setActiveApp } = useContext(DeveloperContext);
  const [formState, setFormState] = useState<any>({
    authCallback: activeApp.endpoints.authCallback,
    authErrorCallback: activeApp.endpoints.authErrorCallback,
  });

  const [handleChange] = useHandleChange(setFormState);

  async function updateApp(e: any) {
    e.preventDefault();
    try {
      const data = {
        endpoints: {
          ...activeApp.endpoints,
          authCallback: formState.authCallback.trim(),
          authErrorCallback: formState.authErrorCallback.trim(),
        },
      };
      const { success } = await toast.promise(
        developerService.updateDeveloperApp(activeApp.appId, data),
        {
          pending: 'Saving changes...',
          success: 'Great success! 👌',
          error: 'Could not save changes 😕',
        }
      );

      if (success) {
        setActiveApp({
          ...activeApp,
          ...data,
        });
      }
    } catch (e) {
      toast.error((e as Error).message);
    }
  }

  return (
    <form onSubmit={updateApp}>
      <header className="flex-wrapper align-end">
        <hgroup>
          <h2>Authentication</h2>
          <p>
            Provide a URL that your users will be redirected to after
            installation.
          </p>
        </hgroup>
        <button className="button primary-button">Save</button>
      </header>
      <section className="app-section">
        <div className="app-section-body">
          <DeveloperCopier
            label="Connect URL"
            value={`https://integrations.commoninja.com/integrations/${activeApp.appId}/connect`}
          />
          <DeveloperInput
            name="authCallback"
            label="Redirect URL"
            title="Redirect URL"
            placeholder="Enter Redirect URL..."
            value={formState.authCallback}
            handleChange={handleChange}
            required
          />
          <DeveloperInput
            name="authErrorCallback"
            label="Error Redirect URL"
            title="Error Redirect URL"
            placeholder="Enter Redirect URL..."
            value={formState.authErrorCallback}
            handleChange={handleChange}
          />
        </div>
      </section>
    </form>
  );
};
