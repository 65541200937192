import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { useHandleChange } from '../../../../../../developer/newDashboardComponents/hooks/useHandleChange';

import { IPlanVariant } from '../../../../../developer.types';
import { DeveloperInput } from '../../../../../newDashboardComponents/developerInput.comp';

type TPlanPriceEditorProps = {
  priceVariant: IPlanVariant;
  currency: string;
  onChange: (priceVariant: IPlanVariant) => void;
  onRemove: () => void;
  editMode: boolean;
};

export const PlanPriceEditor = ({
  priceVariant,
  currency,
  onChange,
  onRemove,
  editMode,
}: TPlanPriceEditorProps) => {
  const [localPrice, setLocalPrice] = useState({ ...priceVariant });

  const [handleChange] = useHandleChange(setLocalPrice);

  function getPriceName(type: string) {
    switch (type) {
      case 'month':
        return 'Monthly';
      case 'year':
        return 'Annually';
      case 'one-time':
        return 'One-Time';
      default:
        return '';
    }
  }

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (!localPrice.priceCount || localPrice.priceCount < 0.01) {
      toast.error(`Price must be equal or greater than 0.01 ${currency}`);
      return;
    }
    if (!localPrice.name) {
      toast.error(`Please add a name`);
      return;
    }
    if (typeof localPrice.priceCount === 'string') {
      localPrice.priceCount = parseFloat(
        String(localPrice.priceCount).replace(/,/g, '') || '0',
      );
    }
    onChange({ ...localPrice, name: getPriceName(localPrice.pricePeriod) });
  };

  const remove = (e: any) => {
    e.preventDefault();
    onRemove();
  };

  return (
    <form onSubmit={handleSubmit}>
      <DeveloperInput
        name="priceCount"
        label="Price"
        required
        value={localPrice.priceCount}
        type="number"
        handleChange={(e: any) => {
          setLocalPrice({
            ...localPrice,
            priceCount: e.target.value,
          });
        }}
        autoFocus
      />

      <DeveloperInput
        label="Billing Period"
        required
        name="pricePeriod"
        select={[
          {
            name: 'one-time',
            displayName: 'One Time',
          },
          {
            name: 'month',
            displayName: 'Monthly',
          },
          {
            name: 'year',
            displayName: 'Annually',
          },
        ]}
        value={localPrice.pricePeriod}
        handleChange={handleChange}
      />

      <div className="buttons-wrapper center">
        {editMode && (
          <button onClick={remove} className="button link-button">
            Remove Permanently
          </button>
        )}
        <button className="button primary-button">Save</button>
      </div>
    </form>
  );
};
