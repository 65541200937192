import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronLeft,
  faCheckCircle,
  faTimesCircle,
  faEllipsisH,
  faCircle,
  faQuestionCircle,
  faExclamationTriangle,
  faEdit,
  faRedo,
  faSpinner,
  faChevronDown,
} from '@fortawesome/free-solid-svg-icons';

interface IDeveloperIcon {
  name: string;
  size?: string;
  customProps?: any;
  label?: string;
  spacing?: number;
  className?: string;
}
interface IIconProps {
  [key: string]: {
    icon: any;
    [key: string]: any;
  };
}
const iconProps: IIconProps = {
  back: {
    icon: faChevronLeft,
  },
  sent: {
    icon: faCheckCircle,
    color: '#20bf55',
  },
  failed: {
    icon: faTimesCircle,
    color: '#ec526a',
  },
  pending: {
    icon: faEllipsisH,
    mask: faCircle,
    color: '#fcbd3a',
    transform: 'shrink-5',
  },
  unknown: {
    icon: faQuestionCircle,
    color: '#c0c8d2',
  },
  warning: {
    icon: faExclamationTriangle,
    color: 'rgba(0, 0, 0, 0.62)',
  },
  resend: {
    icon: faRedo,
  },
  loading: {
    icon: faSpinner,
    spin: true,
  },
  edit: {
    icon: faEdit,
  },
  options: {
    icon: faEllipsisH,
  },
  loadmore: {
    icon: faChevronDown,
  },
};
export const DeveloperIcon = ({
  name = 'unknown',
  size = '1x',
  customProps = {},
  label,
  spacing = 10,
  className = '',
}: IDeveloperIcon) => {
  const icon = iconProps[name] || iconProps['unknown'];
  return (
    <div className={`flex-wrapper align-center ${className}`}>
      <FontAwesomeIcon size={size} {...icon} {...customProps} />
      {label && <div style={{ paddingLeft: `${spacing}px` }}>{label}</div>}
    </div>
  );
};
