import React from "react";
import { Popup } from "@commonninja/commonninja-styleguide-react";
import { FieldIcons } from "../../../common/fieldIcons/fieldIcons.comp";
import { denormalizeFieldType } from "../../helpers";

interface IFieldTypesPopup {
	showPopup: boolean;
	exclude?: string;
	title?: string;
	onSelectFieldType: (type: string) => void;
	setShowPopup: React.Dispatch<React.SetStateAction<boolean>>;
}

const fieldTypes = [
	{ type: "text", desc: "Any text, such as title, description, etc." },
	{ type: "email", desc: "Email field with validations format" },
	{ type: "rich-text", desc: "A rich text editor with formatting options" },
	{ type: "password", desc: "Password field with encryption" },
	{ type: "number", desc: "Any number such as integers, decimals, etc." },
	{ type: "enumeration", desc: "List of values" },
	{ type: "date", desc: "A date picker" },
	// { type: 'Media', desc: 'Files like images, videos, etc' },
	// { type: 'relation', desc: 'Refers to a Collection Type' },
	{ type: "boolean", desc: "True or false" },
	{ type: "json", desc: "Data in JSON format" },
	{ type: "uid", desc: "Unique identifier" },
	{ type: "url", desc: "URL field with validations format" },
	{ type: "array", desc: "An array of any of the available field types" },
];

export const FieldTypesPopup = ({
	showPopup,
	setShowPopup,
	onSelectFieldType,
	exclude = "",
	title = "Select a filed",
}: IFieldTypesPopup) => {
	return (
		<Popup
			show={showPopup}
			closeCallback={() => setShowPopup(false)}
			className="developer-page popup-warn collection-popup field-types-popup"
		>
			<header>
				<h3>{title}</h3>
			</header>
			<div className="fields-container">
				{fieldTypes.map((field: typeof fieldTypes[0]) => {
					if (exclude === field.type) return <></>;
					return (
						<div
							key={`field-${field.type}`}
							onClick={() => onSelectFieldType(field.type)}
							className="field"
						>
							<FieldIcons type={field.type} />
							<div className="content">
								<h4>{denormalizeFieldType[field.type]}</h4>
								<p>{field.desc}</p>
							</div>
						</div>
					);
				})}
			</div>
		</Popup>
	);
};
