import { HttpService } from '@commonninja/commonninja-styleguide-react';

const baseUrl = process.env.REACT_APP_SERVICE_URL || '';

class AuthService extends HttpService {
	login(data: any = {}, captchaToken: string) {
		return this.makeRequest(`${baseUrl}/api/user/login`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({ ...data, captchaToken }),
		});
	}

	signup(data: any = {}, captchaToken: string) {
		return this.makeRequest(`${baseUrl}/api/user/register`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({ ...data, captchaToken }),
		});
	}

	forgotPassword(data: any = {}, captchaToken: string) {
		return this.makeRequest(`${baseUrl}/api/user/forgotPassword`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({ ...data, captchaToken }),
		});
	}

	resetPassword(data: any = {}, captchaToken: string, token: string) {
		return this.makeRequest(`${baseUrl}/api/user/resetPassword`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({ ...data, captchaToken, token }),
		});
	}

	emailConfirmation(token: string) {
		return this.makeRequest(`${baseUrl}/api/user/emailConfirmation/${token}`, {
			method: 'POST',
		});
	}

	invitationApproval(
		token: string,
		invitationType: 'project' | 'component' = 'component'
	) {
		const url =
			invitationType === 'component'
				? `${baseUrl}/api/user/invitationApproval/${token}`
				: `${baseUrl}/api/project/invitationApproval/${token}`;
		return this.makeRequest(url, {
			method: 'POST',
		});
	}

	verifyMFA(data: any = {}) {
		return this.makeRequest(`${baseUrl}/api/mfa/verify`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify(data),
		});
	}
}

export const authService = new AuthService();
