import React from 'react';

import './checkbox.scss';

interface ICheckboxProps extends React.HTMLAttributes<HTMLInputElement> {
  label?: string;
  checked?: boolean;
}

export const Checkbox = (props: ICheckboxProps) => {
  return (
    <div className="checkbox-wrapper">
      <input type="checkbox"  {...props} />
      {props.label && <label>{props.label}</label>}
    </div>
  );
};
