import React, { ChangeEvent, useRef } from "react";
import { FaUpload } from "react-icons/fa";
import cn from "classnames";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";

export type FileType = "image" | "video" | "audio" | "pdf" | "csv" | "";

const FileTypes = new Map<FileType, string>();
FileTypes.set("image", "image/*");
FileTypes.set("csv", "text/csv");
FileTypes.set("pdf", "application/pdf");
FileTypes.set("audio", "audio/*");
FileTypes.set("video", "video/*");
FileTypes.set("", "");

export interface IFileUploadSig {
  success: boolean;
  message?: string;
  url?: string;
}

export interface IFileUploadProps extends React.HTMLAttributes<HTMLDivElement> {
  successCallback: (url: string) => void;
  upload?: (file: File) => Promise<IFileUploadSig> | IFileUploadSig;
  acceptType?: FileType;
  disabled?: boolean;
  uploadStartCallback?: () => void;
  errorCallback?: (message: string) => void;
  disabledCallback?: () => void;
  sizeLimitInMB?: number;
}

const serviceUrl = process.env.REACT_APP_NINDO_SERVICE_URL || "";

export const CNFileUpload = (props: IFileUploadProps) => {
  const query = new URLSearchParams(window.location.search);
  const { projectId } = useParams<any>();
  const queryParams: string = [
    `multi=true`,
    `projectId=${projectId}`,
    `assetType=${props.acceptType || ""}`,
    query.toString(),
  ].join("&");
  let defaultUploadUrl = `${serviceUrl}/api/v1/asset?${queryParams}`;

  async function upload(file: File): Promise<IFileUploadSig> {
    try {
      const data = new FormData();
      data.append("files[]", file);

      const req: any = await fetch(defaultUploadUrl, {
        method: "post",
        body: data,
      });
      const result = await req.json();

      if (result.success) {
        const { url } = result.data;

        return {
          success: true,
          url,
        };
      }

      return {
        success: false,
        message: result.message,
      };
    } catch (e) {
      return {
        success: false,
        message: "Could not upload file. Please contact the support team.",
      };
    }
  }

  return <FileUpload {...props} upload={upload} />;
};

export const FileUpload = (props: IFileUploadProps) => {
  const {
    children,
    acceptType = "",
    upload,
    errorCallback,
    uploadStartCallback,
    successCallback,
    sizeLimitInMB = 5,
    disabled,
    disabledCallback,
  } = props;
  const accept = FileTypes.get(acceptType) as string;
  const inputRef = useRef<HTMLInputElement>(null);

  function defaultErrorCallback(errorMessage: string) {
    toast.dismiss();
    toast.error(errorMessage);
  }

  function defaultUploadStartCallback() {
    toast.dismiss();
    toast.loading("Uploading file...");
  }

  function triggerClick() {
    if (!inputRef.current) {
      return;
    }

    if (disabled) {
      disabledCallback?.();
      return;
    }

    inputRef.current.click();
  }

  async function fileSelected(e: ChangeEvent<HTMLInputElement>) {
    const target = e.target as HTMLInputElement;
    const file: File = (target.files as FileList)[0];

    if (!file) {
      return;
    }

    try {
      if (acceptType === "image") {
        if (file.type.indexOf("image/") < 0) {
          throw new Error("Only png, jpg, and gif formats are allowed.");
        }
      } else {
        if (accept && !file.type.includes(accept.replace("*", ""))) {
          throw new Error("Unsupported file type.");
        }
      }

      // File size in mb
      const sizeInMB = file.size / 1000 / 1000;
      if (sizeLimitInMB && sizeInMB > sizeLimitInMB) {
        throw new Error(
          `File is too big. Size limitation is ${sizeLimitInMB}mb`
        );
      }

      const data = new FormData();
      data.append("files[]", file);

      (uploadStartCallback || defaultUploadStartCallback)();

      const {
        success,
        message = "",
        url = "",
      } = (await upload?.(file)) || {
        success: false,
        message: "Missing upload function prop.",
      };

      toast.dismiss();

      if (success) {
        if (url) {
          successCallback(url);
          return;
        }
        throw new Error("Missing URL in upload callback.");
      }

      throw new Error(message);
    } catch (e) {
      toast.dismiss();
      (errorCallback || defaultErrorCallback)(
        (e as Error).message ||
          "Could not upload file. Please contact the support team."
      );
    }
  }

  return (
    <div className={cn("file-upload", props.className)} onClick={triggerClick}>
      <input
        type="file"
        name="file"
        style={{ display: "none" }}
        ref={inputRef}
        onChange={fileSelected}
        accept={accept}
      />
      {children || <FaUpload title="Upload file" />}
    </div>
  );
};
