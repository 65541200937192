import React from 'react';
import { AuthLayout } from './authLayout.comp';
import { Feedback } from './feedback.comp';

export const FeedbackPage = () => {
    return (
        <AuthLayout teaser="Oh no! We are sad to see you leave!" feedbackMode={true}>
            <Feedback />
        </AuthLayout>
    )
}
