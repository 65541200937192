import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  Loader,
  error,
  success,
} from "@commonninja/commonninja-styleguide-react";
import PasswordStrengthBar from "react-password-strength-bar";
import "./password.scss";

import Embed from "../embed/embed.comp";

class Password extends Embed {
  formElm: null | HTMLFormElement;
  firstInput: HTMLInputElement | null = null;
  ReactPasswordStrength: any = null;

  constructor(props: any) {
    super(props);

    this.formElm = null;
    this.state = {
      loading: false,
      form: {
        password: "",
        passwordAgain: "",
      },
    };
  }

  submit = async (e: any) => {
    e.preventDefault();

    const { form } = this.state as any;

    if (form.password !== form.passwordAgain) {
      this.props.dispatch(
        error({
          message: "Passwords do not match",
          autoDismiss: 5,
          position: "tc",
        })
      );
      return;
    }

    this.setState({ loading: true });

    let localError = null;

    try {
      const update = await fetch("/api/user/changePassword", {
        body: JSON.stringify(form),
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
      });

      const updateRes = await update.json();

      if (updateRes.success) {
        this.props.dispatch(
          success({
            message: "Your password has been updated.",
            autoDismiss: 3.5,
            position: "tc",
          })
        );

        this.setState({
          form: {
            password: "",
            passwordAgain: "",
          },
        });
      } else {
        localError = updateRes.message || "Could not update password";
      }
    } catch (e) {
      localError = "Could not update password";
    }

    if (localError) {
      this.props.dispatch(
        error({
          message: localError,
          autoDismiss: 5,
          position: "tc",
        })
      );
    }

    this.setState({ loading: false });
  };

  render() {
    const { form, loading } = this.state as any;

    return loading ? (
      <Loader>
        <p>Loading...</p>
      </Loader>
    ) : (
      <div className="content-wrapper">
        <form onSubmit={this.submit} ref={(el) => (this.formElm = el)}>
          <div className="input-container">
            <label>New Password</label>
            <input
              type="password"
              placeholder="Enter a password"
              minLength={5}
              required
              value={this.state.form.password}
              onChange={(e) => {
                this.setState({
                  form: {
                    ...form,
                    password: e.target.value,
                  },
                });
              }}
              autoComplete={"off"}
            />
            <PasswordStrengthBar
              password={this.state.form.password}
              minLength={5}
              // onChangeScore={(nextScore) => {
              //   console.log("nextScore", nextScore);
              // }}
            />
          </div>
          <div className="input-container">
            <label>Confirm Password</label>
            <div className="form-elm">
              <input
                type="password"
                defaultValue={form.passwordAgain}
                placeholder="Enter a password"
                onChange={(e) =>
                  this.setState({
                    form: {
                      ...form,
                      passwordAgain: e.target.value,
                    },
                  })
                }
              />
            </div>
          </div>
          <div className="buttons-wrapper">
            <button className="button primary-button" type="submit">
              Change Password
            </button>
          </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = (state: any) => ({
  user: state.user,
});

export default connect(mapStateToProps)(withRouter(Password as any));
