import React, { useEffect, useState } from 'react';
import ReactDatePicker, { DateObject } from 'react-multi-date-picker';
import { FaRegCalendar } from 'react-icons/fa';

import './datePicker.scss';

type TDateFormat =
  | 'DD/MM/YYYY'
  | 'DD-MM-YYYY'
  | 'MM/DD/YYYY'
  | 'MM-DD-YYYY'
  | 'YYYY/MM/DD'
  | 'YYYY-MM-DD'
  | 'YYYY/DD/MM'
  | 'YYYY-DD-MM'
  | 'MM/YYYY';

interface IDatePickerProps {
  type?: 'range' | 'single';
  format?: TDateFormat;
  value?: string;
  onChange?: (selectedDates: DateObject[]) => void;
}

export const DatePicker = ({
  type = 'single',
  format = 'DD-MM-YYYY',
  onChange,
  value,
}: IDatePickerProps) => {
  const [date, setDate] = useState<any>();
  const [portalTarget, setPortalTaget] = useState<HTMLDivElement>();

  function handleChange(event: any) {
    setDate(event);

    if (onChange) {
      onChange(event);
    }
  }

  useEffect(() => {
    const portalDiv = document.createElement('div');

    /**
     * This ID is optional and has been added
     * to better recognize it in the DOM tree.
     */
    portalDiv.id = 'stupid-date-picker';

    document.body.appendChild(portalDiv);

    setPortalTaget(portalDiv);

    return () => {
      document.body.removeChild(portalDiv);
    };
  }, []);

  useEffect(() => {
    if (type === 'range') {
      setDate([]);
    }
    if (value) {
      setDate(new Date(value));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);

  return (
    <div className="date-picker-wrapper">
      <ReactDatePicker
        format={format}
        id="date-picker"
        range={type === 'range' ? true : false}
        shadow={false}
        value={date}
        onChange={handleChange}
        showOtherDays={true}
        portal={true}
        portalTarget={portalTarget}
        placeholder="Select date"
      />
      <FaRegCalendar className="calender-icon" />
    </div>
  );
};
