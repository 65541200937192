import React from 'react';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUndoAlt } from '@fortawesome/free-solid-svg-icons';

import {
  AssetType,
  FileUpload,
} from '@commonninja/commonninja-styleguide-react';

export const ImageUpload = ({
  value,
  successCallback,
}: {
  value?: string;
  successCallback: (url: string) => void;
}) => {
  return (
    <div className="image-upload">
      <div
        className="image"
        style={{
          backgroundImage: value ? `url(${value})` : '',
        }}
      ></div>
      <FileUpload
        acceptType={AssetType.IMAGE}
        enabled={true}
        uploadApiUrl="/api/user/file"
        sizeLimitInMB={3}
        postUploadCallback={(url: string) => {
          toast.dismiss();
          successCallback(url);
        }}
        uploadStartCallback={() => {
          toast.dismiss();
          toast.loading('Uploading icon...');
        }}
        errorCallback={(err: string) => {
          toast.dismiss();
          toast.error(err);
        }}
        className={`image-upload-button ${value ? '' : 'no-image'}`}
      />
      {value && (
        <button
          className="reset-botton"
          title="Restore to Default"
          onClick={() => successCallback('')}
        >
          <FontAwesomeIcon icon={faUndoAlt} />
        </button>
      )}
    </div>
  );
};
