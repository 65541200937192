import { HttpService } from '@commonninja/commonninja-styleguide-react';

class EventService extends HttpService {

  async send(eventType: string) {
    try {
      if (!eventType) {
        throw new Error('Event type not defined');
      }
      await this.makeRequest(`/api/funnelEvent?type=${eventType}`, null, false);
    } catch (e) {}
  }

}

export default new EventService();