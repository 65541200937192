import React, { useContext, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Popup } from '@commonninja/commonninja-styleguide-react';
import { DeveloperContext } from '../../developer.context';
import { toast } from 'react-toastify';
import { developerService } from '../../../../services/developer.service';

import './collections.scss';

export const Collections = () => {
    const { activeApp } = useContext(DeveloperContext);
    const [openPopup, setOpenPopup] = useState<boolean>(false);
    const [newCollectionName, setNewCollectionName] = useState<string>('');
    const [collections, setCollections] = useState<string[]>([]);
    const history = useHistory();

    async function createCollection(e: any) {
        e.preventDefault();
        if (!newCollectionName) return toast.warning('Missing collection name');
        const regex = /^[0-9A-Za-z\s-]+$/;
        const isValid = regex.test(newCollectionName);
        if (!isValid) return toast.warning(`Please remove special characters`);

        try {
            const { data } = await developerService.getCollections(activeApp.appId);
            if (data.includes(newCollectionName)) return toast.warning(`Collection ${newCollectionName} already exists.`);
            const values = await Promise.all([
                await developerService.createCollection(activeApp.appId, newCollectionName),
                await developerService.createCollectionSchema(activeApp.appId, newCollectionName)
            ]);
            const [{ data: newCollection }] = values;
            setCollections([...collections, newCollection]);
            setOpenPopup(false);
            toast.success(`Collection ${newCollectionName} created successfully.`);
            history.push(`/developer/apps/${activeApp.appId}/database/${newCollectionName}`);
        } catch (e) {
            toast.error((e as Error).message || 'Oops, something went wrong');
        }

        setNewCollectionName('');
    };

    async function getCollections() {
        const { data } = await developerService.getCollections(activeApp.appId);
        setCollections(data);
    }

    useEffect(() => {
        if (!activeApp.appId) {
            return;
        }
        getCollections();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeApp.appId]);

    return (
        <>
            <hgroup>
                <h2>Collections</h2>
                <p>
                    Create and manage data collections for your app
                </p>
            </hgroup>
            <div className="collections-container">
                {(collections.length > 0 && collections.map((collection) => (
                    <div key={collection} className="collection-card">
                        <div className="top-content">
                            <h3>{collection}</h3>
                        </div>
                        <Link
                            to={`/developer/apps/${activeApp.appId}/database/collection/${collection}`}
                            className="button secondary-button"
                        >
                            View Collection
                        </Link>
                    </div>
                )))}
                <div
                    className="app-card-add collection-card"
                    onClick={() => setOpenPopup(true)}
                >
                    <FontAwesomeIcon icon={faPlus} />
                    <span>Create Collection</span>
                </div>
            </div>
            <Popup
                show={openPopup}
                closeCallback={() => setOpenPopup(false)}
                className="developer-page popup-warn collection-popup"
            >
                <header>
                    <h3>New collection</h3>
                </header>
                <form onSubmit={createCollection}>
                    <div className="input-container">
                        <label htmlFor="name">Collection Name</label>
                        <div>
                            <input
                                type="text"
                                name="name"
                                title="Collection Name"
                                placeholder='Enter Name...'
                                value={newCollectionName}
                                onChange={(e) => setNewCollectionName(e.target.value.toLocaleLowerCase().replaceAll(' ', '-'))}
                            />
                        </div>
                    </div>
                    <div className="popup-warn-buttons">
                        <button className="button primary-button" >
                            Create
                        </button>
                    </div>
                </form>
            </Popup>
        </>
    );
};
