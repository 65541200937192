import React, { useEffect, useState } from "react";
import { H2 } from "@commonninja/commonninja-styleguide-react/lib/esm/components/internal";

import Profile from "../profile/profile.comp";
import Password from "../password/password.comp";
import { APIAccess } from '../apiAccess/apiAccess.comp';
import { Sidebar } from "../sidebar/sidebar.comp";

import "./account.scss";

export const Account = () => {
  const [activeTab, setActiveTab] = useState("profile");

  function changeActiveTab(tab: string, e: any) {
    e.preventDefault();
    setActiveTab(tab);
  }

  function renderBody() {
    if (activeTab === "api") {
      return <APIAccess />;
    }

    if (activeTab === 'password') {
      return <Password />;
    }

    return <Profile />;
  }

  useEffect(() => {
    document.title = `Manage Account | Common Ninja`;
  }, []);

  return (
    <>
      <Sidebar />
      <section className={`account main-section ${activeTab}`}>
        <header>
          <hgroup className="page-titles">
            <H2>Account</H2>
          </hgroup>
        </header>
        <nav className="tabs-wrapper">
          <ul className="tabs">
            <li
              className={`tab-trigger ${
                activeTab === "profile" ? "active" : ""
              }`}
              onClick={(e) => changeActiveTab("profile", e)}
            >
              Profile
            </li>
            <li
              className={`tab-trigger ${activeTab === "api" ? "active" : ""}`}
              onClick={(e) => changeActiveTab("api", e)}
            >
              API Access
            </li>
            <li
              className={`tab-trigger ${
                activeTab === "password" ? "active" : ""
              }`}
              onClick={(e) => changeActiveTab("password", e)}
            >
              Change Password
            </li>
          </ul>
        </nav>
        {renderBody()}
      </section>
    </>
  );
};
