import React, { useCallback, useEffect, useRef, useState } from 'react';
import { HiOutlineArrowSmUp } from 'react-icons/hi';
import { FaTrash } from 'react-icons/fa';
import { FiEdit } from 'react-icons/fi';
import { BiDuplicate } from 'react-icons/bi';
import { BsPlusLg } from 'react-icons/bs';
import { TbRefresh } from 'react-icons/tb';
import { IoFilterOutline } from 'react-icons/io5';
import { Pagination } from '../pagination/pagination.comp';
import { FieldIcons } from '../fieldIcons/fieldIcons.comp';
import { Checkbox } from '../checkbox/checkbox.comp';
import { DLoader } from '../../loader/loader.comp';
import { CopyText } from '../copyText/copyText.comp';
import { DeveloperInput } from '../../newDashboardComponents/developerInput.comp';

import './dataTable.scss';

interface IHeader {
  title: string;
  key?: string;
  type?: 'string' | 'number' | 'date' | 'img';
}

interface IDataTableProps {
  className?: string;
  disableSearch?: boolean;
  disablePagination?: boolean;
  showRefresh?: boolean;
  loading?: boolean;
  defaultSearchKey?: string;
  showPageSizeSelect?: boolean;
  actionIdKey?: string;
  headers: IHeader[];
  rows: object[]; //TODO add types
  title?: string;
  subTitle?: string;
  onDelete?: (id: string) => void;
  onDuplicate?: (id: string) => void;
  onEdit?: (id: string) => void;
  onCreate?: () => void;
  customActions?: {
    onClick: (id: string) => void;
    icon: React.ReactNode;
    title: string;
  }[];
  buttonText?: string;
  totalPages: number;
  currentPage: number;
  noItemsIcon?: () => void;
  noItemsText?: string;
  rowBehavior?: 'copy-on-click' | 'edit';
  getItems: ({
    page,
    limit,
    query,
    sort,
  }: {
    page: number;
    limit: number;
    query: any;
    sort: any;
  }) => void;
  paginationParams?: {
    query?: any;
    sort?: any;
    page?: number;
    limit?: number;
  };
}

export const DataTable = ({
  className = '',
  headers,
  rows,
  title,
  subTitle,
  defaultSearchKey,
  showRefresh = true,
  loading,
  disableSearch = false,
  showPageSizeSelect = false,
  onDelete,
  onCreate,
  onDuplicate,
  disablePagination = false,
  buttonText = '',
  onEdit,
  getItems,
  totalPages,
  currentPage,
  actionIdKey = '_id',
  noItemsIcon,
  noItemsText,
  rowBehavior,
  customActions = [],
  paginationParams,
}: IDataTableProps) => {
  const [data, setData] = useState(rows);
  const [filteredFields, setFilteredFields] = useState<{
    [key: string]: boolean;
  }>(
    headers.reduce((acc: any, header: IHeader) => {
      acc[header?.key || ''] = true;
      return acc;
    }, {}),
  );
  const [textFilter, setTextFilter] = useState('');
  const [tableHeaders, setTableHeaders] = useState(headers);
  const [filterModalOpen, setFilterModalOpen] = useState(false);
  const [refreshActive, setRefreshActive] = useState(false);
  const [selectedSearchField, setSelectedSearchField] = useState(
    defaultSearchKey || (headers[0]?.key || ''),
  );
  const [filterState, setFilterState] = useState<{
    page: number;
    limit: number;
    query: any;
    sort: any;
  }>({
    page: paginationParams?.page || 1,
    limit: paginationParams?.limit || 10,
    query: paginationParams?.query || {},
    sort: paginationParams?.sort || {},
  });
  const filterModalRef = useRef<any>();
  const tableHasActions =
    customActions.length || onCreate || onEdit || onDuplicate || onDelete;

  function sort(key: string) {
    if (disablePagination) return;
    let value: any;
    if (!filterState['sort'][key]) value = 1;
    if (filterState['sort'][key] === 1) value = -1;
    if (filterState['sort'][key] === -1) value = 1;
    const sort = { [key]: value };
    setFilterState({ ...filterState, sort });
  }

  function debounce(func: (...args: any) => void, wait: number = 300) {
    let timeout: any;
    return function executedFunction(...args: any) {
      const later = () => {
        clearTimeout(timeout);
        func(...args);
      };
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
    };
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onSearch = useCallback(
    debounce((value: string, field: string) => {
      if (!textFilter) return;
      if (!value) {
        setFilterState({ ...filterState, query: {} });
        return;
      }
      let query: any = { [field]: { $regex: value, $options: 'i' } };
      if (field === 'actionIdKey' || field === actionIdKey) {
        query = { [actionIdKey]: value };
      }

      setFilterState({ ...filterState, query });
    }),
    [filterState, textFilter],
  );

  function paginate(pageNumber: number) {
    setFilterState({ ...filterState, page: pageNumber });
  }

  function refresh() {
    setRefreshActive(true);
    getItems(filterState);

    setTimeout(() => {
      setRefreshActive(false);
    }, 100);
  }

  function onOpenFilterModal() {
    setFilterModalOpen(!filterModalOpen);
  }

  function onRowClick(rowId: string) {
    if (rowBehavior === 'edit' && onEdit) {
      onEdit(rowId);
    }
  }

  const handleClickOutside = (event: Event) => {
    if (
      filterModalRef.current &&
      !filterModalRef.current.contains(event.target as Node)
    ) {
      setFilterModalOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  useEffect(() => {
    setData(rows);
    setTableHeaders(headers);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rows]);

  useEffect(() => {
    // eslint-disable-next-line array-callback-return
    setTableHeaders(
      headers.filter((header: any) => {
        if (filteredFields[header.key as any]) {
          return true;
        }
        return false;
      }),
    );
    setData(
      rows.map((item: any) => {
        return Object.keys(item).reduce((acc: any, key: any) => {
          if (filteredFields[key]) {
            acc[key] = item[key];
          }
          return acc;
        }, {});
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredFields]);

  useEffect(() => {
    getItems(filterState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterState]);

  return (
    <div className={`data-table-card ${className}`}>
      {!disablePagination && headers.length > 5 && (
        <div className="filter-modal-container">
          <IoFilterOutline
            onClick={onOpenFilterModal}
            className="filter-icon"
          />
          <div
            ref={filterModalRef}
            className={`filter-modal ${filterModalOpen ? 'opened' : ''}`}
          >
            {headers.map((header: IHeader) => (
              <div key={`filter-${header.title}`} className="row">
                <Checkbox
                  defaultChecked={
                    filteredFields[header.key as keyof IHeader] || true
                  }
                  onChange={(e: any) =>
                    setFilteredFields({
                      ...filteredFields,
                      [header.key as any]: e.target.checked,
                    })
                  }
                />
                <p>{header.title}</p>
              </div>
            ))}
          </div>
        </div>
      )}
      <div className={`data-table-wrapper ${className}`}>
        <div className="table-top">
          <div className="title-wrapper">
            <h3>{title}</h3>
            <p>{subTitle}</p>
          </div>
          <div className="filter-wrapper">
            {!disableSearch && (
              <>
                {showRefresh &&
                  <TbRefresh onClick={refresh} className={`refresh-btn ${refreshActive ? 'active' : ''}`} />
                }
                <div className="search-form">
                  <form onSubmit={(e: any) => { e.preventDefault(); onSearch(textFilter, selectedSearchField); }}>
                    <DeveloperInput
                      name="searchTerm"
                      placeholder="Search..."
                      title="Search"
                      type="text"
                      value={textFilter}
                      handleChange={(e: any) => {
                        setTextFilter(e.target.value);
                        onSearch(e.target.value, selectedSearchField);
                      }}
                    />
                  </form>
                  <DeveloperInput
                    name="searchType"
                    title="Search by type"
                    placeholder="Search by type..."
                    value={selectedSearchField}
                    handleChange={(e: any) => {
                      setSelectedSearchField(e.target.value);
                      onSearch(textFilter, e.target.value);
                    }}
                    select={headers
                      .map((header: any) => {

                        return {
                          name: header.key,
                          displayName: header.title,
                        };
                      })
                      .filter(
                        (header) =>
                          header.name !== '_created' &&
                          header.name !== '_updated',
                      )}
                  />
                </div>
              </>
            )}
            {onCreate && (
              <button onClick={onCreate} className="button primary-button">
                <BsPlusLg />
                {buttonText}
              </button>
            )}
          </div>
        </div>
        <div className={`inner-table-wrapper `}>
          <table className={`data-table ${!rowBehavior ? 'default' : ''}`}>
            <thead>
              <tr>
                {tableHeaders.map((header: IHeader, idx: number) => (
                  <th key={`th-${header.title}-${idx}`}>
                    <p onClick={() => sort(header.key || '')}>
                      {header.title}
                      <HiOutlineArrowSmUp
                        className={`arrow ${filterState['sort'][header.key as any] === 1 && 'asc'
                          } ${filterState['sort'][header.key as any] === -1 &&
                          'desc'
                          } `}
                      />
                    </p>
                  </th>
                ))}
                {tableHasActions && <th>Actions</th>}
              </tr>
            </thead>
            <tbody>
              {data &&
                data.length > 0 &&
                !loading &&
                data.map((row: any, idx: number) => {
                  // Filter fields that are not part of the headers list
                  const cells = Object.keys(filteredFields).map(
                    (field) => filteredFields[field] && field,
                  );
                  if (Object.entries(row).length === 0) return <tr></tr>;
                  return (
                    <tr
                      key={`row-${idx}`}
                      onClick={(e: any) => {
                        e.stopPropagation();
                        onRowClick(row?.[actionIdKey] || '' || row?.name || '');
                      }}
                    >
                      {cells.map((cell: any) => {
                        if (typeof row[cell] === 'object')
                          return (
                            <td className="icon-td">
                              {FieldIcons({ type: 'json' })}
                            </td>
                          );
                        if (row[cell] === true || row[cell] === false)
                          return (
                            <td key={`cell-${row?.[actionIdKey] || row.name}-${cell}`}>
                              {String(row[cell])}
                            </td>
                          );
                        // eslint-disable-next-line array-callback-return
                        if (!cell) return;
                        return (
                          <td key={`cell-${row?.[actionIdKey] || row.name}-${cell}`}>
                            <p
                              className={`${cell === 'status' ? `status ${row[cell]}` : ''
                                }`}
                            >
                              {cell === 'type' && (
                                <FieldIcons type={row[cell]} />
                              )}
                              {cell === 'previewImage' ?
                                <img src={row[cell]} alt="app preview" />
                                :
                                <>
                                  {rowBehavior === 'copy-on-click' ? (
                                    <CopyText showIcon={false} text={row[cell]} />
                                  ) : (
                                    row[cell]
                                  )}
                                </>
                              }
                            </p>
                          </td>
                        );
                      })}
                      {tableHasActions && (
                        <td className="actions-container">
                          {onEdit && (
                            <FiEdit
                              title="edit"
                              className="icon edit"
                              onClick={(e: any) => {
                                e.stopPropagation();
                                onEdit(
                                  (row?.[actionIdKey] as string) || (row.name as string),
                                );
                              }}
                            />
                          )}
                          {onDuplicate && (
                            <BiDuplicate
                              title="duplicate"
                              className="icon duplicate"
                              onClick={(e: any) => {
                                e.stopPropagation();
                                onDuplicate(row?.[actionIdKey] as string);
                              }}
                            />
                          )}
                          {onDelete && (
                            <FaTrash
                              title="delete"
                              className="icon"
                              onClick={(e: any) => {
                                e.stopPropagation();
                                onDelete(row?.[actionIdKey] || (row.name as string));
                              }}
                            />
                          )}
                          {customActions.length > 0 &&
                            customActions.map((action) => (
                              <>
                                <span
                                  className="icon"
                                  title={action?.title || 'action'}
                                  onClick={(e: any) => {
                                    e.stopPropagation();
                                    action.onClick(row?.[actionIdKey] || '');
                                  }}
                                >
                                  {action.icon}
                                </span>
                              </>
                            ))}
                        </td>
                      )}
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
        {loading && (
          <div className="loading-wrapper">
            <DLoader />
          </div>
        )}
        {data.length === 0 && !loading && (
          <div className="no-items-container">
            {noItemsIcon ? noItemsIcon() : renderLogo()}
            <p>{noItemsText ? noItemsText : 'Sorry, no items found'}</p>
          </div>
        )}
        <div className="bottom-wrapper">
          {showPageSizeSelect && (
            <select
              onChange={(value: any) =>
                setFilterState({ ...filterState, limit: value.target.value })
              }
              value={filterState.limit}
            >
              <option value={5}>5</option>
              <option value={10}>10</option>
              <option value={20}>20</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
            </select>
          )}
          {!disablePagination && (
            <Pagination
              className="data-pagination"
              paginate={paginate}
              currentPage={currentPage}
              pages={totalPages}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export function renderLogo() {
  return (
    <svg
      width="137"
      height="65"
      viewBox="0 0 137 65"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="32.4697"
        y="28.4886"
        width="102.623"
        height="35.8219"
        transform="rotate(-3.3288 32.4697 28.4886)"
        fill="#16171B"
      />
      <path d="M20.8041 0L36.534 31.4598L0 11.6706L20.8041 0Z" fill="#16171B" />
      <path
        d="M21.5923 40.7753L34.4544 28.752H21.5923V40.7753Z"
        fill="#16171B"
      />
      <path
        d="M109.584 34.8632L94.6112 47.3406L112.129 46.4421"
        stroke="white"
        strokeWidth="2.8513"
        strokeLinecap="round"
      />
      <path
        d="M57.3408 38.844L74.0938 48.3931L57.8733 49.2251"
        stroke="white"
        strokeWidth="2.8513"
        strokeLinecap="round"
      />
    </svg>
  );
}
