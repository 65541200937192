import React, { useContext, useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { DeveloperContext } from "../../developer.context";
import { useParams } from "react-router-dom";
import { DLoader } from "../../loader/loader.comp";
import { toast } from "react-toastify";
import { StatsCard } from "../../common/statsCard/statsCard.comp";
import { InfoCard } from "../../common/infoCard/infoCard.comp";
import { DangerZone } from "../../common/dangerZone/dangerZone.comp";
import { Card } from "../../common/card/card.comp";
import { WarnPopup } from "../../common/warnPopup/warnPopup.comp";
import { DataTable } from "../../common/dataTable";
import { MdDelete, MdOutlineCancel } from "react-icons/md";
import { FaEdit } from "react-icons/fa";
import { RiRefund2Line } from "react-icons/ri";
import { TbFileExport } from "react-icons/tb";
import { developerService } from "../../../../services/developer.service";
import { CreateNotePopup } from "./createNotePopup/createNotePopup.comp";
import { UpdateUserPopup } from "./updateUserPopup/updateUserPopup.comp";
import {
  IAppNote,
  IAppUser,
  IAppUserCharge,
  IAppUserSubscription,
  IPaymentPlan,
  IUsersPaginationResponse,
} from "../types";
import {
  getPlanName,
  getPlanPaymentPeriod,
  getSubscriptionMRR,
} from "../helpers";
import { UpdateSubscriptionPopup } from "./updateSubscriptionPopup/updateSubscriptionPopup.comp";
import { Checkbox } from "../../common/checkbox/checkbox.comp";
import { BsCheckCircleFill } from "react-icons/bs";
import { DeveloperInput } from "../../newDashboardComponents/developerInput.comp";

import "./userDetails.scss";

export const UserDetails = ({ plans }: { plans: IPaymentPlan[] }) => {
  const [loading, setLoading] = useState(true);
  const [chargesLoading, setChargesLoading] = useState(false);
  const [subscriptionsLoading, setSubscriptionsLoading] = useState(false);
  const [openNotePopup, setOpenNotePopup] = useState(false);
  const [openUserPopup, setOpenUserPopup] = useState(false);
  const [openSubsciptionPopup, setOpenSubsciptionPopup] = useState(false);
  const [openSubsciptionCancelPopup, setOpenSubsciptionCancelPopup] =
    useState(false);
  const [openChargeRefundPopup, setOpenChargeRefundPopup] = useState(false);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [refundCancelUser, setRefundCancelUser] = useState<boolean>(false);
  const [selectedNote, setSelectedNote] = useState<IAppNote>();
  const [userDetails, setUserDetails] = useState<IAppUser>();
  const [userCharges, setUserCharges] =
    useState<IUsersPaginationResponse<IAppUserCharge>>();
  const [userSubscriptions, setUserSubscriptions] =
    useState<IUsersPaginationResponse<IAppUserSubscription>>();
  const [selectedSubscription, setSelectedSubscription] =
    useState<IAppUserSubscription>();
  const [selectedCharge, setSelectedCharge] = useState<IAppUserCharge>();
  const [totalStats, setTotalStats] = useState<{
    totalCharges: number;
    mrr: number;
  }>({ totalCharges: 0, mrr: 0 });
  const [selectedRefundMethod, setSelectedRefundMethod] = useState<
    "full" | "partial"
  >("full");
  const [refundAmount, setRefundAmount] = useState<number>(0);

  const { activeApp } = useContext(DeveloperContext);
  const { userId } = useParams<any>();
  const history = useHistory();

  async function onRefund() {
    try {
      await developerService.refundAppUser(
        activeApp.appId,
        userId,
        selectedCharge?._id || "",
        {
          platform: selectedCharge?.platform || "",
          cancelUserSubscription: refundCancelUser,
          refundAmount:
            selectedRefundMethod === "partial" ? refundAmount || 0 : undefined,
        }
      );
      getUserCharges({ page: 1, query: {}, sort: { created: -1 } });
      setOpenChargeRefundPopup(false);
      setSelectedCharge(undefined);
      setRefundAmount(0);
      toast.success(
        "A refund is now in process and might take a couple of days to affect."
      );
    } catch (e) {
      toast.error((e as Error)?.message || "Oops, something went wrong");
    }
  }

  async function selectCharge(id: string) {
    const charge = (userCharges?.docs || []).find(
      (charge: any) => charge._id === id
    );
    setSelectedCharge(charge);
    setOpenChargeRefundPopup(true);
  }

  function onInvoice(id: string) {
    const charge = (userCharges?.docs || []).find(
      (charge: any) => charge._id === id
    );
    window.open(
      charge?.platformReceiptUrl || "",
      "_blank",
      "noopener,noreferrer"
    );
  }

  function selectSubscription(id: string, mode: "edit" | "cancel") {
    const subscription = (userSubscriptions?.docs || []).find(
      (subscriptions: any) => subscriptions._id === id
    );
    setSelectedSubscription(subscription);

    if (mode === "edit") {
      setOpenSubsciptionPopup(true);
    }
    if (mode === "cancel") {
      setOpenSubsciptionCancelPopup(true);
    }
  }

  async function onDeleteNote(noteId: string) {
    try {
      await developerService.deleteAppUserNote(activeApp.appId, userId, noteId);
      getUserDetails();
    } catch (e) {
      toast.error((e as Error)?.message || "Oops, something went wrong");
    }
  }

  async function onAddNote(newNote: IAppNote) {
    if (!newNote.title) return toast.warning("Missing note title");
    const newNoteCopy: { title: string; description: string; id?: string } = {
      ...newNote,
    };
    delete newNoteCopy["id"];
    try {
      const { data } = await developerService.createAppUserNote(
        activeApp.appId,
        userId,
        newNoteCopy
      );
      setUserDetails(data);
      setOpenNotePopup(false);
    } catch (e) {
      toast.error((e as Error)?.message || "Oops, something went wrong");
    }
  }

  async function onEditSubscription(newPlan: {
    planId: string;
    planPricingVariantId: string;
  }) {
    try {
      await developerService.updateAppUserSubscription(
        activeApp.appId,
        userId,
        selectedSubscription?._id || "",
        {
          ...newPlan,
          platform: selectedSubscription?.platform || "",
        }
      );
      setSelectedSubscription(undefined);
      getUserSubscriptions({ page: 1, limit: 5, query: {}, sort: {} });
      toast.success(
        "The update is now in process and might take a few minutes to affect."
      );
      setOpenSubsciptionPopup(false);
    } catch (e) {
      toast.error((e as Error)?.message || "Oops, something went wrong");
    }
  }

  async function cancelSubscription() {
    try {
      await developerService.cancelAppUserSubscription(
        activeApp.appId,
        userId,
        selectedSubscription?._id || "",
        selectedSubscription?.platform || ""
      );
      setSelectedSubscription(undefined);
      getUserSubscriptions({
        page: 1,
        limit: 5,
        query: {},
        sort: { created: -1 },
      });
      toast.success(
        "The update is now in process and might take a few minutes to affect."
      );
      setOpenSubsciptionCancelPopup(false);
    } catch (e) {
      toast.error((e as Error)?.message || "Oops, something went wrong");
    }
  }

  async function onEditNote(note: IAppNote) {
    if (!note.title) return toast.warning("Missing note title");
    try {
      const { data } = await developerService.updateAppUserNote(
        activeApp.appId,
        userId,
        note
      );
      setUserDetails(data);
      setOpenNotePopup(false);
    } catch (e) {
      toast.error((e as Error)?.message || "Oops, something went wrong");
    }
  }

  async function onSelectNote(noteId: string) {
    const note = (userDetails?.notes || []).find(
      (note: IAppNote) => note.id === noteId
    );
    setSelectedNote(note);
    setOpenNotePopup(true);
  }

  async function onEditUserData(user: {
    accountName: string;
    accountEmail: string;
  }) {
    if (!user.accountName) return toast.warning("Missing user name");
    try {
      const { data } = await developerService.updateAppUserDetails(
        activeApp.appId,
        userId,
        user
      );
      setUserDetails(data);
      toast.success("User details updated successfully");
      setOpenUserPopup(false);
    } catch (e) {
      toast.error((e as Error)?.message || "Oops, something went wrong");
    }
  }

  async function deleteUserData() {
    setLoading(true);
    try {
      await developerService.deleteAppUserDetails(activeApp.appId, userId);
      history.push(`/developer/apps/${activeApp.appId}/users`);
      toast.success("User deleted");
    } catch (e) {
      toast.error((e as Error)?.message || "Oops, something went wrong");
    }
    setLoading(false);
  }

  async function getUserDetails() {
    try {
      const { data } = await developerService.getAppUser(
        activeApp.appId,
        userId
      );
      setUserDetails(data);
    } catch (e) {
      toast.error((e as Error)?.message || "Oops, something went wrong");
    }

    setTimeout(() => {
      setLoading(false);
    }, 600);
  }

  async function getUserCharges({
    page,
    query,
    sort,
  }: {
    page: number;
    query: any;
    sort: any;
  }) {
    if (!activeApp?.appId) return;
    setChargesLoading(true);
    try {
      const formattedQuery = encodeURIComponent(JSON.stringify(query));
      const formattedSort = encodeURIComponent(JSON.stringify(sort));
      const { data } = await developerService.getAppUserCharges(
        activeApp.appId,
        userId,
        { page, limit: 5, query: formattedQuery, sort: formattedSort }
      );
      const { data: stats } = await developerService.getAppUserStats(
        activeApp.appId,
        userId
      );
      setTotalStats(stats);
      setUserCharges(data);
    } catch (e) {
      toast.error((e as Error)?.message || "Oops, something went wrong");
    }

    setTimeout(() => {
      setChargesLoading(false);
    }, 600);
  }

  async function getUserSubscriptions({
    page,
    limit,
    query,
    sort,
  }: {
    page: number;
    limit: number;
    query: any;
    sort: any;
  }) {
    if (!activeApp?.appId) return;
    setSubscriptionsLoading(true);
    try {
      const formattedQuery = encodeURIComponent(JSON.stringify(query));
      const formattedSort = encodeURIComponent(JSON.stringify(sort));
      const { data } = await developerService.getAppUserSubscription(
        activeApp.appId,
        userId,
        { page, limit, query: formattedQuery, sort: formattedSort }
      );
      setUserSubscriptions(data);
    } catch (e) {
      toast.error((e as Error)?.message || "Oops, something went wrong");
    }

    setTimeout(() => {
      setSubscriptionsLoading(false);
    }, 600);
  }

  useEffect(() => {
    if (!activeApp.appId) {
      return;
    }
    getUserDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeApp.appId]);

  if (loading)
    return (
      <>
        <DLoader />
      </>
    );

  return (
    <>
      <div className="app-editor-page user-details">
        <Link
          to={`/developer/apps/${activeApp.appId}/users`}
          className="app-editor-page-back"
        >
          <i className="chevron-left"></i>
          <span>Back to Users</span>
        </Link>
        <header className="flex-wrapper">
          <hgroup className="subtitle">
            <h2>{userDetails?.accountName || ""}</h2>
            {/* <div>Active</div> */}
          </hgroup>
        </header>
        <div className="number-stats-container">
          <StatsCard
            data={[
              {
                title: "Signed up ",
                value: new Date(
                  userDetails?.created || ""
                ).toLocaleDateString(),
              },
            ]}
            className="card column"
            icon="members"
          />
          <StatsCard
            data={[
              {
                title: "Current MRR ",
                value: `${(totalStats?.mrr || 0).toFixed(2)} ${
                  selectedCharge?.currency?.toUpperCase() || "USD"
                }`,
              },
            ]}
            className="card column"
            icon="exchange"
          />
          <StatsCard
            data={[
              {
                title: "Total charges ",
                value: `${(totalStats?.totalCharges || 0).toFixed(2)} ${
                  selectedCharge?.currency?.toUpperCase() || "USD"
                }`,
              },
            ]}
            className="card column"
            icon="coins"
          />
        </div>
        <InfoCard
          title="User info"
          mail={userDetails?.accountEmail || ""}
          leftSide={[
            { title: "Name", text: userDetails?.accountName || "" },
            { title: "Email", text: userDetails?.accountEmail || "" },
            {
              title: "Plan",
              text: getPlanName(plans, userDetails?.planId || ""),
            },
          ]}
          rightSide={[
            { title: "Platform", text: userDetails?.platform || "" },
            {
              title: "Platform User ID",
              text: userDetails?.platformUserId || "",
            },
            // { title: 'Shop URL', text: '' },
          ]}
          openPopup={setOpenUserPopup}
        />
        <div className="advanced-user-info">
          <div className="tables-container">
            <DataTable
              className="user-info-table"
              currentPage={userSubscriptions?.page || 1}
              totalPages={userSubscriptions?.pages || 1}
              getItems={getUserSubscriptions}
              onEdit={(id) => selectSubscription(id, "edit")}
              headers={[
                { title: "Plan", key: "plan", type: "string" },
                { title: "Interval", key: "interval", type: "string" },
                { title: "MRR", key: "mrr", type: "string" },
                { title: "Created", key: "created", type: "string" },
                { title: "Status", key: "status", type: "string" },
              ]}
              rows={
                userSubscriptions?.docs.map((item: any) => ({
                  ...item,
                  created: new Date(item.created).toLocaleDateString(),
                  plan: getPlanName(plans, item?.planId || ""),
                  mrr: `$${getSubscriptionMRR(
                    plans,
                    item?.planId || "",
                    item?.planPricingVariantId || ""
                  )}`,
                  interval: getPlanPaymentPeriod(
                    plans,
                    item?.planId || "",
                    item?.platformSubscriptionId || ""
                  ),
                })) || []
              }
              disableSearch={true}
              loading={subscriptionsLoading}
              customActions={[
                {
                  onClick: (id) => selectSubscription(id, "cancel"),
                  icon: <MdOutlineCancel className="cancel-icon" />,
                  title: "Cancel",
                },
              ]}
              paginationParams={{ sort: { created: -1 }, limit: 5 }}
              title="Subscriptions"
            />
            <DataTable
              className="user-info-table"
              currentPage={userCharges?.page || 1}
              totalPages={userCharges?.pages || 1}
              getItems={getUserCharges}
              headers={[
                { title: "Amount", key: "amount", type: "string" },
                { title: "Date", key: "created", type: "string" },
                { title: "Plan", key: "plan", type: "string" },
                { title: "Status", key: "status", type: "string" },
              ]}
              rows={
                userCharges?.docs.map((item: any) => ({
                  ...item,
                  amount: `$${Number(item?.amount || 0).toFixed(2)}`,
                  created: new Date(item.created).toLocaleDateString(),
                  plan: getPlanName(plans, item?.planId || ""),
                })) || []
              }
              disableSearch={true}
              loading={chargesLoading}
              customActions={[
                {
                  onClick: onInvoice,
                  icon: <TbFileExport className="icon invoice" />,
                  title: "invoice",
                },
                {
                  onClick: selectCharge,
                  icon: <RiRefund2Line className="icon refund" />,
                  title: "refund",
                },
              ]}
              paginationParams={{ sort: { created: -1 }, limit: 5 }}
              title="Charges"
            />
          </div>
          {/* <Card className='timeline-container'>
                        <h2>Timeline</h2>
                        <div className="event-wrapper">
                            <div className="event">
                                <div className="title-wrapper">
                                    <span className='event-icon'>
                                        {renderChargeEventIcon()}
                                    </span>
                                    <h2>Event title</h2>
                                </div>
                                20/20/2020
                            </div>
                            <div className="event">
                                <div className="title-wrapper">
                                    <span className='event-icon'>
                                        {renderChangeEventIcon()}
                                    </span>
                                    <h2>Event title</h2>
                                </div>
                                20/20/2020
                            </div>
                            <div className="event">
                                <div className="title-wrapper">
                                    <span className='event-icon last'>
                                        {renderChargeEventIcon()}
                                    </span>
                                    <h2>Event title</h2>
                                </div>
                                20/20/2020
                            </div>
                        </div>
                    </Card> */}
        </div>
        <Card className="notes-section">
          <h3>Notes</h3>
          <div className="notes-container">
            {(userDetails?.notes || []).length > 0 &&
              (userDetails?.notes || []).map((note: any) => (
                <div key={`note-${note.id}`} className="note">
                  <h3>{note.title}</h3>
                  <p>{note.description}</p>
                  <div className="actions">
                    <MdDelete
                      onClick={() => onDeleteNote(note.id)}
                      className="icon"
                      title="delete"
                    />
                    <FaEdit
                      onClick={() => onSelectNote(note.id)}
                      className="icon edit"
                      title="edit"
                    />
                  </div>
                </div>
              ))}
            <div
              className="add-item-card"
              onClick={() => setOpenNotePopup(true)}
            >
              + Add note
            </div>
          </div>
        </Card>
        <DangerZone
          buttonText="Delete User"
          text="Caution! This will delete the user and all the related data will be lost. This action cannot be undone."
          onButtonClick={setShowDeletePopup}
        />
      </div>
      <WarnPopup
        showPopup={showDeletePopup}
        setShowPopup={setShowDeletePopup}
        title="Are you sure you want to delete this user?"
        text={`Warning! Deleting this user will permanently remove all associated data.`}
        onConfirm={deleteUserData}
      />
      <WarnPopup
        showPopup={openSubsciptionCancelPopup}
        setShowPopup={setOpenSubsciptionCancelPopup}
        buttonText="Confirm"
        title="Are you sure you want to cancel this subscription?"
        text={`Cancelling this subscription will disable the associated features for your user.`}
        onConfirm={cancelSubscription}
      />
      <WarnPopup
        showPopup={openChargeRefundPopup}
        setShowPopup={setOpenChargeRefundPopup}
        buttonText="Confirm"
        title="Are you sure you want to refund this transaction?"
        text={``}
        onConfirm={onRefund}
        className="cancel-subscription-popup"
      >
        <div className="refund-content">
          <div className="box-select-container">
            <div
              onClick={() => setSelectedRefundMethod("full")}
              className={`box ${
                selectedRefundMethod === "full" ? "active" : ""
              }`}
            >
              {selectedRefundMethod === "full" && (
                <BsCheckCircleFill className="checked" />
              )}
              <h4>Full Refund</h4>
              <p className="suffix">
                {selectedCharge?.amount || 0}{" "}
                {selectedCharge?.currency?.toUpperCase() || "USD"}
              </p>
            </div>
            <div
              onClick={() => setSelectedRefundMethod("partial")}
              className={`box ${
                selectedRefundMethod === "partial" ? "active" : ""
              }`}
            >
              {selectedRefundMethod === "partial" && (
                <BsCheckCircleFill className="checked" />
              )}
              <h4>Partial Refund</h4>
              <div className="number-container">
                <DeveloperInput
                  className="number-select"
                  type="number"
                  name="minLength"
                  value={refundAmount}
                  disabled={selectedRefundMethod === "full"}
                  handleChange={(e: any) =>
                    setRefundAmount(Number(e.target.value))
                  }
                />
                <p className="suffix flex-end">
                  {selectedCharge?.currency?.toUpperCase() || " USD"}
                </p>
              </div>
            </div>
          </div>
          <div className="checkbox-wrapper">
            <Checkbox
              checked={refundCancelUser}
              onChange={(e: any) => setRefundCancelUser(e.target.checked)}
            />
            <p
              className="cancel-subscription-text"
              onClick={() => setRefundCancelUser(!refundCancelUser)}
            >
              Cancel plan after refund
            </p>
          </div>
        </div>
      </WarnPopup>
      <CreateNotePopup
        onAddNote={onAddNote}
        openPopup={openNotePopup}
        setOpenPopup={setOpenNotePopup}
        selectedNote={selectedNote}
        setSelectedNote={setSelectedNote}
        onEditNote={onEditNote}
      />
      <UpdateUserPopup
        openPopup={openUserPopup}
        setOpenPopup={setOpenUserPopup}
        onEditUser={onEditUserData}
        user={userDetails}
      />
      <UpdateSubscriptionPopup
        openPopup={openSubsciptionPopup}
        setOpenPopup={setOpenSubsciptionPopup}
        onEditSubscription={onEditSubscription}
        subscription={selectedSubscription}
        setSubscription={setSelectedSubscription}
        plans={plans}
      />
    </>
  );
};

// function renderChargeEventIcon() {
//     return (
//         <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
//             <path d="M14 11.7143H18C18.5521 11.7143 19 11.2984 19 10.7857C19 10.2728 18.5521 9.85714 18 9.85714H16V8.92857C16 8.41568 15.5521 8 15 8C14.4477 8 14 8.41568 14 8.92857V9.85714C12.3456 9.85714 11 11.1068 11 12.6429C11 14.1789 12.3456 15.4286 14 15.4286H15.9997C16.5513 15.4286 17 15.845 17 16.3571C17 16.8693 16.5539 17.286 15.9997 17.286C15.9995 17.286 11.9997 17.2857 12 17.2857C11.4477 17.2857 11 17.7014 11 18.214C10.9997 18.7269 11.4474 19.1426 11.9997 19.1429H14V20.0714C14 20.5841 14.4477 21 15 21C15.5521 21 16 20.5841 16 20.0714V19.1431C17.6578 19.1431 19 17.8975 19 16.3571C19 14.8211 17.6539 13.5714 15.9997 13.5714H14C13.4484 13.5714 13 13.1548 13 12.6429C13 12.1307 13.4484 11.7143 14 11.7143Z" fill="#30BB57" />
//             <circle cx="15" cy="15" r="13.5" stroke="#30BB57" strokeWidth="3" />
//         </svg>
//     );
// }

// function renderChangeEventIcon() {
//     return (
//         <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
//             <path d="M9 17L15 12L21 17" stroke="#B879EA" strokeWidth="3" strokeLinecap="round" />
//             <circle cx="15" cy="15" r="13.5" stroke="#B879EA" strokeWidth="3" />
//         </svg>
//     );
// };
