import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import moment from 'moment';
// @ts-ignore
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import { Loader, error } from '@commonninja/commonninja-styleguide-react';
import { H2 } from '@commonninja/commonninja-styleguide-react/lib/esm/components/internal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';

import Highcharts from 'highcharts';
import ReactHighcharts from 'react-highcharts';

import AnalyticsService from '../../services/analytics.service';

import './analytics.scss';

Highcharts.setOptions({
  lang: {
    thousandsSep: ','
  }
});

const dateFormat = 'MM-DD-YYYY';
const minDate = moment('08-01-2019', dateFormat).toDate();
const now: Date = new Date();

const getEventSubTypeAsDesc = (subtype: string): string => {
  switch (subtype) {
    case 'link':
      return 'Link clicked';
    case 'button':
      return 'Button clicked';
    case 'vote':
      return 'Vote button clicked';
    case 'vote-popup':
      return 'Vote popup opened';
    case 'game-popup':
      return 'Game details popup opened';
    case 'vote-completed':
      return 'Voting completed';
    case 'participant-popup':
      return 'Participant details popup opened';
    case 'tab':
      return 'Tab clicked';
    case 'zoom':
      return 'Zoom button clicked';
    case 'share':
      return 'Share button clicked';
    case 'full-screen':
      return 'Full screen button clicked';
    case 'series-hover':
      return 'Series mouse hovered';
    case 'conversion':
      return 'Payment button clicked';
    case 'vote-signup':
      return 'Signups to vote';
    case 'participant-signup':
      return 'Signups to bracket';
    case 'question':
      return 'Questions opened';
  }

  return subtype;
}

const basicBarChartConfig = {
  title: {
    text: null
  },
  chart: {
    backgroundColor: 'transparent',
    type: 'column'
  },
  credits: {
    enabled: false
  },
  legend: {
    align: 'right',
    x: -30,
    verticalAlign: 'top',
    y: 0,
    floating: true,
    borderColor: '#CCC',
    borderWidth: 0,
    shadow: false,
    enabled: false,
  },
  colors: ['#4881FD', '#03D8A9'],
  xAxis: {
    categories: []
  },
  series: []
};

export const Analytics = (props: any) => {
  const dispatch = useDispatch();
  const { componentId } = useParams() as any;
  // const user = useSelector((state: any) => state.user);
  const [loading, setLoading] = useState<boolean>(false);
  const [analyticsData, setAnalyticsData] = useState<any>(null);
  const [range, setRange] = useState<{ startDate: Date, endDate: Date }>({
    startDate: moment().subtract(7, 'days').toDate(),
    endDate: new Date(),
  });

  async function loadData() {
    if (!componentId) {
      return;
    }

    setLoading(true);
    setAnalyticsData(null);
    
    const { startDate, endDate } = range;
    let localError = null;

    try {
      const nextAnalyticsData = {
        client: null,
        engagement: null
      };
      const requests = [
        AnalyticsService.getClientEvents(componentId, moment(startDate).format(dateFormat), moment(endDate).format(dateFormat)).then((res) => {
          if (res.success) {
            nextAnalyticsData.client = res.data;
          } else {
            localError = res.message || 'Could not load plugin analytics';
          }
        }),
        AnalyticsService.getEngagementEvents(componentId, moment(startDate).format(dateFormat), moment(endDate).format(dateFormat)).then((res) => {
          if (res.success) {
            nextAnalyticsData.engagement = res.data;
          } else {
            localError = res.message || 'Could not load plugin analytics';
          }
        }),
      ];

      await Promise.all(requests);

      if (nextAnalyticsData.client) {
        setAnalyticsData(nextAnalyticsData);
      }
    } catch (e) {
      localError = 'Could not load plugin analytics';
    }

    if (localError) {
      dispatch(error({
        message: localError,
        autoDismiss: 5,
        position: 'tc'
      }));
    }

    setLoading(false);
  }

  function handleSelect(dates: any) {
    const [start, end] = dates;

    setRange({
      startDate: start,
      endDate: end
    });
  }

  function renderComponentAnalytics() {
    const { data, breakdown } = analyticsData?.engagement;
    const eventsChartData = Object.assign({}, basicBarChartConfig, {
      colors: ['#FF4572', '#FBAB68'],
      xAxis: { categories: data.timestamp.map((d: any) => {
        const momentDate = moment(parseInt(d.date), 'x');
        if (breakdown === 'month') {
          return momentDate.format('MMM YYYY');  
        } else if (breakdown === 'week') {
          return `${moment(parseInt(d.date), 'x').subtract(7, 'days').format('Do, MMM YYYY')} - ${momentDate.format('Do, MMM YYYY')}`;
        }
        return momentDate.format('dddd Do, MMM YYYY');
      })},
      series: [{ name: 'Events', data: data.timestamp.map((d: any) => d.count) }],
      tooltip: {
        headerFormat: '<b>{point.x}</b><br/>',
        pointFormat: '{series.name}: {point.y}<br/>', // Total: {point.stackTotal}
        valueDecimals: 0,
        shared: true
      },
      plotOptions: {
        column: {
          stacking: 'normal',
          dataLabels: {
            enabled: false,
          }
        }
      },
      yAxis: {
        min: 0,
        title: {
          text: '# of Events'
        },
      },
    });

    return (
      <>
        <section>
          <h2 className="title">- Engagement Events -</h2>
          <div className="boxes">
            <div className="box">
              <h4>{(data.summary.all || 0).toLocaleString()}</h4>
              <p>
                All Events
                <span className="tooltip-wrapper">
                  <span className="tooltip-toggler">?</span>
                  <span className="local-tooltip">Total number of engagement events.</span>
                </span>
              </p>
            </div>
            <div className="box">
              <h4>{(data.summary.conversions || 0).toLocaleString()}</h4>
              <p>
                Conversions
                <span className="tooltip-wrapper">
                  <span className="tooltip-toggler">?</span>
                  <span className="local-tooltip">A conversion is being count when a user completes signup, completes voting, clicks a link or a conversion button.</span>
                </span>
              </p>
            </div>
            <div className="box">
              <h4>{(data.summary.interactions || 0).toLocaleString()}</h4>
              <p>
                Interactions
                <span className="tooltip-wrapper">
                  <span className="tooltip-toggler">?</span>
                  <span className="local-tooltip">An interaction is being count everytime a user interacts with your plugin: open popups, hovering a series, clicking a share button or a non-conversion button.</span>
                </span>
              </p>
            </div>
          </div>
        </section>
        <div className="flex-wrapper">
          <section>
            <h2 className="title">- Events Breakdown -</h2>
            <div className="table-wrapper">
              {
                (data.events.length === 0) ?
                <p className="center">No data.</p> :
                <table>
                  <thead>
                    <tr>
                      <th className="center" style={{ width: '100px' }}>#</th>
                      <th>Action</th>
                      <th className="center" style={{ width: '200px' }}># of Events</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      data.events.sort((a: any, b: any) => b.count - a.count).map((eventObj: any, idx: number) => (
                        <tr key={`host_${idx}`}>
                          <td className="center">{idx + 1}</td>
                          <td>{getEventSubTypeAsDesc(eventObj.subtype)}</td>
                          <td className="center">{eventObj.count.toLocaleString()}</td>
                        </tr>
                      ))
                    }
                  </tbody>
                </table>          
              }
            </div>
          </section>
          <section>
            <h2 className="title">- Events by Date -</h2>
            <div className="chart">
              {
                (data.timestamp.length === 0) ?
                <p className="center">No data.</p> :
                <ReactHighcharts config={eventsChartData} />
              }
            </div>
          </section>
        </div>
      </>
    );
  }

  function renderAnalytics() {
    const { data, breakdown } = analyticsData?.client;

    const eventsChartData = Object.assign({}, basicBarChartConfig, {
      xAxis: { categories: data.timestamp.map((d: any) => {
        const momentDate = moment(parseInt(d.date), 'x');
        if (breakdown === 'month') {
          return momentDate.format('MMM YYYY');  
        } else if (breakdown === 'week') {
          return `${moment(parseInt(d.date), 'x').subtract(7, 'days').format('Do, MMM YYYY')} - ${momentDate.format('Do, MMM YYYY')}`;
        }
        return momentDate.format('dddd Do, MMM YYYY');
      })},
      series: [{ name: 'Events', data: data.timestamp.map((d: any) => d.count) }],
      tooltip: {
        headerFormat: '<b>{point.x}</b><br/>',
        pointFormat: '{series.name}: {point.y}<br/>', // Total: {point.stackTotal}
        valueDecimals: 0,
        shared: true
      },
      plotOptions: {
        column: {
          stacking: 'normal',
          dataLabels: {
            enabled: false,
          }
        }
      },
      yAxis: {
        min: 0,
        title: {
          text: '# of Events'
        },
      },
    });

    return (
      <div className="analytics">
        <section>
          <h2 className="title">- Installations & Pageviews -</h2>
          <div className="boxes">
            <div className="box">
              <h4>{data.eventType.install.toLocaleString()}</h4>
              <p>
                Installs
                <span className="tooltip-wrapper">
                  <span className="tooltip-toggler">?</span>
                  <span className="local-tooltip">Number of times Common Ninja's code was loaded into a web page successfully.</span>
                </span>
              </p>
            </div>
            <div className="box">
              <h4>{data.eventType.impression.toLocaleString()}</h4>
              <p>
                Impressions
                <span className="tooltip-wrapper">
                  <span className="tooltip-toggler">?</span>
                  <span className="local-tooltip">Number of times the plugin has been completely loaded and ready to be used.</span>
                </span>
              </p>
            </div>
            <div className="box">
              <h4>{data.eventType.view.toLocaleString()}</h4>
              <p>
                Pageviews
                <span className="tooltip-wrapper">
                  <span className="tooltip-toggler">?</span>
                  <span className="local-tooltip">Number of times the plugin has been viewed by a user in a webpage.</span>
                </span>
              </p>
            </div>
          </div>
        </section>

        <div className="flex-wrapper">
          <section>
            <h2 className="title">- Top Hosts -</h2>
            <div className="table-wrapper">
              {
                (data.host.length === 0) ?
                <p className="center">No data.</p> :
                <table>
                  <thead>
                    <tr>
                      <th className="center" style={{ width: '100px' }}>#</th>
                      <th>Host</th>
                      <th className="center" style={{ width: '200px' }}># of Events</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      data.host.sort((a: any, b: any) => b.count - a.count).map((hostObj: any, idx: number) => (
                        <tr key={`host_${idx}`}>
                          <td className="center">{idx + 1}</td>
                          <td>{hostObj.host}</td>
                          <td className="center">{hostObj.count.toLocaleString()}</td>
                        </tr>
                      ))
                    }
                  </tbody>
                </table>          
              }
            </div>
          </section>
          <section>
            <h2 className="title">- Impressions by Date -</h2>
            <div className="chart">
              {
                (data.timestamp.length === 0) ?
                <p className="center">No data.</p> :
                <ReactHighcharts config={eventsChartData} />
              }
            </div>
          </section>
        </div>

        {renderComponentAnalytics()}
      </div>
    );
  }
  
  function renderContent() {
    if (!componentId) {
      return <div className="app-error center">{props.embedMode ? `Analytics will be available once you hit "Save".` : 'Widget ID is empty.'}</div>
    }

    if (loading) {
      return (
        <Loader>
          <p>Loading...</p>
        </Loader>
      );
    }

    if (!analyticsData) {
      return <div className="app-error center">Could not load analytics.</div>;
    }

    return (
      <div className="content-wrapper">
        {renderAnalytics()}
      </div>
    );
  }

  useEffect(() => {
    loadData();
    // eslint-disable-next-line
  }, [range]);

  useEffect(() => {
    document.title = `Analytics | Common Ninja`;
  }, []);

  return (
    <>
      <section className="analytics-wrapper main-section">
        {
          !props.embedMode &&
          <a className="back-button" href="/user/dashboard" target="_self">
            <FontAwesomeIcon icon={faChevronLeft} />
            <span>Back</span>
          </a>
        }
        <header>
          <hgroup className="page-titles">
            <H2>Analytics <span>Beta</span></H2>
            {
              analyticsData?.client && analyticsData.client.name &&
              <h3>For <strong>{analyticsData.client.name}</strong></h3>
            }
          </hgroup>
          {
            componentId &&
            <div className="date-picker-wrapper">
              <h3>
                Select Dates: 
              </h3>
              <DateRangePicker
                onChange={handleSelect}
                value={[range.startDate, range.endDate]}
                maxDate={now}
                minDate={minDate}
              />
            </div>
          }
        </header>
        {renderContent()}
      </section>
    </>
  );
}