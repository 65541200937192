import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { IHttpResult, Loader } from '@commonninja/commonninja-styleguide-react';
import { H3 } from '@commonninja/commonninja-styleguide-react/lib/esm/components/internal';

import { IAuthProps } from './auth.types';
import { AuthLayout } from './authLayout.comp';
import { authService } from './auth.service';

export const Confirmation = ({ embedMode }: IAuthProps) => {
  const [postSubmission, setPostSubmission] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const { token }: any = useParams();

  async function confirm() {
    setError('');

    try {
      const result: IHttpResult = await authService.emailConfirmation(token);

      // Throw error if needed
      if (!result.success) {
        throw new Error(result.message);
      }

      setPostSubmission(true);

      window.setTimeout(() => {
        window.location.href = '/user/dashboard';
      }, 5000);
    } catch(e) {
      setError(e.message);
    }
  }
  
  function renderPostSubmissionMessage() {
    return (
      <>
        <H3>Great Success!</H3>
        <p className="body-1 post-message">Sweet! Your email address has been confirmed.</p>
        <p className="body-1">Hold tight, <em>redirecting to dashboard</em>...</p>
      </>
    );
  }

  function renderForm() {
    return (
      <>
        <H3>Confirming Email</H3>
        {
          !error ?
          <Loader innerColor="#D3D3D4" /> :
          <p className="body-1 post-message center error">{error}</p>
        }
        <div className="buttons-wrapper center">
          <a className="primary" href="/login">Back to Login Page</a>
        </div>
      </>
    );
  }

  useEffect(() => {
    document.title = `Confirm your email | Common Ninja`;
    confirm();
    // eslint-disable-next-line
  }, []);

  return (
    <AuthLayout embedMode={embedMode}>
      {
        postSubmission ?
        renderPostSubmissionMessage() : 
        renderForm()
      }
    </AuthLayout>
  );
}