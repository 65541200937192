import { Popup } from '@commonninja/commonninja-styleguide-react';
import React, { useEffect, useState } from 'react';
import { IAppUser } from '../../types';

interface IUpdateUserPopupProps {
    openPopup: boolean;
    setOpenPopup: React.Dispatch<React.SetStateAction<boolean>>;
    user?: IAppUser;
    onEditUser: (user: { accountName: string, accountEmail: string; }) => void;
}

export const UpdateUserPopup = ({ openPopup, setOpenPopup, onEditUser, user }: IUpdateUserPopupProps) => {
    const [userState, setUserState] = useState({
        accountName: '',
        accountEmail: ''
    });

    function onSubmit(e: any) {
        e.preventDefault();
        onEditUser(userState);
    }

    useEffect(() => {
        const miniUser = { accountName: user?.accountName || '', accountEmail: user?.accountEmail || '' };
        setUserState(miniUser);
    }, [user]);

    return (
        <Popup
            show={openPopup}
            closeCallback={() => { setOpenPopup(false); }}
            className="developer-page popup-warn collection-popup"
        >
            <header>
                <h3>Edit user</h3>
            </header>
            <form onSubmit={onSubmit}>
                <div className="input-container">
                    <label htmlFor="name">User Name</label>
                    <div>
                        <input
                            type="text"
                            name="name"
                            title="User Name"
                            placeholder='User Name...'
                            value={userState.accountName}
                            onChange={(e) => setUserState({ ...userState, accountName: e.target.value })}
                        />
                    </div>
                    <label htmlFor="email">Email</label>
                    <div>
                        <input
                            type="text"
                            name="email"
                            title="Note Email"
                            placeholder='User Email...'
                            value={userState.accountEmail}
                            onChange={(e) => setUserState({ ...userState, accountEmail: e.target.value })}
                        />
                    </div>
                </div>
                <div className="popup-warn-buttons">
                    <button className="button primary-button" >
                        Confirm
                    </button>
                </div>
            </form>
        </Popup>
    );
};
