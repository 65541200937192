import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  faBook,
  faCommentDots,
  faThLarge,
  faUserEdit,
} from '@fortawesome/free-solid-svg-icons';

import { developerService } from '../../../services/developer.service';
import { AppAuth } from '../app/auth.comp';
import { AppDashboardView } from '../app/dashboardView.comp';
import { AppInfo } from '../app/info.comp';
import { AppDatabase } from '../app/database.comp';
import { AppUsers } from '../app/users.comp';
import { AppIntegrations } from '../app/integrations.comp';
import { AppPermissions } from '../app/permissions.comp';
import { AppWebhooks } from '../appWebhooks/appWebhooks.comp';
import { AppMenu } from '../appMenu/appMenu.comp';
import { DeveloperContext } from '../developer.context';
import { AppPayments } from '../appPayments/appPayments.comp';
import { DLoader } from '../loader/loader.comp';
import { ReactDimmer } from 'react-dimmer';
import { AppDeveloperMenu } from '../appMenu/appDeveloperMenu/appDeveloperMenu.comp';
import { IoMenuOutline } from 'react-icons/io5';

import './appDashboard.scss';

export const AppDashboard = () => {
  const { activeApp, setActiveApp, loading, setLoading, setIntegrations } =
    useContext(DeveloperContext);
  const { appId, page } = useParams() as any;
  const [menuOpen, setMenuOpen] = useState(false);

  async function loadApp() {
    try {
      setLoading(true);
      const { data } = await developerService.getDeveloperApp(appId);
      setActiveApp(data);
      setLoading(false);
    } catch (e) {
      toast.error((e as Error).message);
    }
  }
  async function loadAppIntegrations() {
    try {
      const { data } = await developerService.getAppIntegrations(appId);
      setIntegrations(data);
    } catch (e) {
      toast.error((e as Error).message);
    }
  }

  function renderPage() {
    if (page === 'integrations') {
      return <AppIntegrations />;
    }

    if (page === 'webhooks') {
      return <AppWebhooks />;
    }

    if (page === 'authentication') {
      return <AppAuth />;
    }

    if (page === 'app-info') {
      return <AppInfo />;
    }

    if (page === 'permissions') {
      return <AppPermissions />;
    }

    if (page === 'payments') {
      return <AppPayments />;
    }

    if (page === 'users') {
      return <AppUsers />;
    }

    if (page === 'database') {
      return <AppDatabase />;
    }

    // if (page === 'developer-info') {
    //   return <DeveloperAccount />;
    // }

    return <AppDashboardView />;
  }

  useEffect(() => {
    if (!activeApp.appId) {
      loadApp();
    }
    loadAppIntegrations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="app-dashboard flex-wrapper no-wrap">
      {loading ? (
        <DLoader />
      ) : (
        <>
          <AppMenu isMenuOpen={menuOpen} setMenuOpen={setMenuOpen} />
          <main className={`${page}-page`}>
            <div
              title="Open menu"
              onClick={() => setMenuOpen(true)}
              className={`developer-menu-hamburger ${menuOpen ? 'hide' : ''}`}
            >
              <IoMenuOutline className="icon" />
            </div>
            {renderPage()}
            {(page === 'developer-apps' || page === 'account') && (
              <AppDeveloperMenu
                menuLinks={[
                  {
                    name: 'My Apps',
                    url: `/developer/apps`,
                    icon: faThLarge,
                  },
                  {
                    name: 'Developer Info',
                    url: `/developer/account`,
                    icon: faUserEdit,
                  },
                  {
                    name: 'Documentation',
                    url: `https://docs.commoninja.com/`,
                    target: '_blank',
                    icon: faBook,
                  },
                  {
                    name: 'Support',
                    url: 'https://help.commoninja.com/',
                    target: '_blank',
                    icon: faCommentDots,
                  },
                ]}
              />
            )}
          </main>
        </>
      )}
      <ReactDimmer
        zIndex={20}
        opacity={0.2}
        blur={1.4}
        isOpen={menuOpen}
        exitDimmer={setMenuOpen}
      />
    </div>
  );
};
