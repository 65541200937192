import React, { useState } from "react";
import { Popup } from "@commonninja/commonninja-styleguide-react";
import { v4 } from "uuid";

import { SortableAppCards } from "../../../common/sortableAppCards.comp";
import { PlanPriceEditor } from "./planPriceEditor/planPriceEditor";
import { IPlanVariant } from "../../../../developer.types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import NumberFormat from "react-number-format";
import { IPaymentPlanPeriod } from "@commonninja/types";

import "./planPrices.scss";

interface TPlanPricesProps {
  isFree: boolean;
  prices: IPlanVariant[];
  currency: string;
  onChange: (prices: IPlanVariant[]) => void;
  isProviderConnected: boolean;
}

export const PlanPrices = ({
  isFree,
  prices,
  currency,
  onChange,
  isProviderConnected,
}: TPlanPricesProps) => {
  const emptyPrice = () => ({
    id: v4(),
    name: "Monthly",
    priceCount: 0,
    pricePeriod: "month",
    pricePeriodCount: 1,
  });
  const [editorOpened, setEditorOpened] = useState(false);
  const [editorPrice, setEditorPrice] = useState(emptyPrice());
  const [editorPriceIndex, setEditorPriceIndex] = useState(-1);
  const [warningOpened, setWarningOpened] = useState(false);
  const handleEditorClose = () => {
    setEditorOpened(false);
    setEditorPriceIndex(-1);
    setEditorPrice(emptyPrice());
  };
  const handleEditorOpen = (price: IPlanVariant, index: number) => {
    if (isProviderConnected) return;
    setEditorPrice(price);
    setEditorPriceIndex(index);
    setEditorOpened(true);
  };
  const handlePriceSave = (price: IPlanVariant) => {
    const newPrices = [...prices];
    newPrices[editorPriceIndex] = price;
    onChange(newPrices);
    handleEditorClose();
  };
  const handlePriceDelete = (id: string, { confirmed = false }) => {
    const idx = prices.findIndex((p) => p.id === id);
    if (!confirmed) {
      setEditorPrice(prices[idx]);
      setWarningOpened(true);
      return;
    }

    const newPrices = [...prices];
    newPrices.splice(idx, 1);
    onChange(newPrices);
    setWarningOpened(false);
  };
  const onSortEnd = ({ oldIndex, newIndex }: any) => {
    const newPrices = [...prices];
    newPrices.splice(newIndex, 0, newPrices.splice(oldIndex, 1)[0]);
    onChange(newPrices);
  };
  const getPeriodName = (pricePeriod: IPaymentPlanPeriod): string => {
    if (pricePeriod === "day") {
      return "Billed Daily";
    }

    if (pricePeriod === "week") {
      return "Billed Weekly";
    }

    if (pricePeriod === "month") {
      return "Billed Monthly";
    }

    if (pricePeriod === "year") {
      return "Billed Annually";
    }

    if (pricePeriod === "one-time") {
      return "Billed Once (One Time)";
    }

    return "Period";
  };

  return (
    <div className="app-section plan-prices">
      <div className="app-section-header">
        <h4>Prices</h4>
      </div>
      <div className="app-section-body">
        {isFree && prices.length === 0 && <div>Free plan.</div>}
        <SortableAppCards
          onSortEnd={onSortEnd}
          buttonText={"Add New Price"}
          disabled={isFree}
          // disabled={isFree || isProviderConnected}
          onRemove={(index, e) => {
            handlePriceDelete(prices[index].id, e);
          }}
          onAdd={() =>
            handleEditorOpen(emptyPrice() as IPlanVariant, prices.length)
          }
          items={prices.map((price, index) => ({
            idx: index,
            onClick: () => handleEditorOpen(price, index),
            copyValue: price.id,
            header: (
              <NumberFormat
                displayType="text"
                value={price.priceCount}
                thousandSeparator
                prefix={`${currency} `}
                decimalScale={2}
              />
            ) as any,
            content: "",
            footer: price.priceCount
              ? getPeriodName(price.pricePeriod)
              : "No billing",
          }))}
        />
      </div>
      <Popup
        show={editorOpened}
        closeCallback={handleEditorClose}
        className="developer-page"
      >
        <h2>Price Editor</h2>
        <section className="popup-form">
          <PlanPriceEditor
            priceVariant={editorPrice as IPlanVariant}
            currency={currency}
            onChange={handlePriceSave}
            onRemove={() =>
              handlePriceDelete(editorPrice.id, { confirmed: false })
            }
            editMode={editorPriceIndex !== prices.length}
          />
        </section>
      </Popup>
      <Popup
        show={warningOpened}
        closeCallback={() => setWarningOpened(false)}
        className="developer-page popup-warn"
      >
        <header>
          <FontAwesomeIcon
            icon={faExclamationCircle}
            color="#fabf5e"
            size="2x"
          />
          <h3>Are you sure you want to delete this price?</h3>
        </header>
        <p className="popup-warn-text">
          This action cannot be undone. If you delete this price, it will be
          removed from the plan.
        </p>
        <div className="popup-warn-buttons">
          <button
            className="button secondary-button"
            onClick={() => setWarningOpened(false)}
          >
            Cancel
          </button>
          <button
            className="button primary-button"
            onClick={() => {
              setWarningOpened(false);
              handlePriceDelete(editorPrice.id, { confirmed: true });
            }}
          >
            Delete
          </button>
        </div>
      </Popup>
    </div>
  );
};
