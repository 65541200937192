import React from "react";
import { IAppPlan } from "../../developer/developer.types";

export const CycleSelector = ({
  serviceName,
  plans,
  activeVariantId,
  setActiveVariantId,
}: {
  serviceName: string;
  plans: IAppPlan[];
  activeVariantId: number;
  setActiveVariantId: (id: number) => void;
}) => {
  const firstPlanVariants = plans.find((p) => !p.isFree)?.pricingVariants || [];
  const haveMultipleCycles = firstPlanVariants.length > 1;

  function renderArrow() {
    return (
      <svg className="arrow" xmlns="http://www.w3.org/2000/svg" width="23" height="33" viewBox="0 0 23 33" fill="none">
        <path d="M0.539813 29.2134C2.2454 29.3831 3.95296 29.5411 5.65776 29.725C11.9896 30.4074 15.3934 28.5566 16.935 23.5834C18.6096 18.1819 15.9213 10.5647 10.816 7.81468C11.069 9.74378 11.4275 11.2367 11.4042 12.7233C11.3883 13.7831 10.8585 14.8355 10.5588 15.892C9.36044 15.4569 7.4758 15.3512 7.08126 14.53C5.32644 10.8778 3.91946 7.04622 2.6274 3.1987C2.48568 2.77555 3.8638 1.25996 4.58595 1.22423C9.54447 0.976176 14.5186 0.880946 19.4806 1.01521C20.362 1.03955 21.2085 2.36815 22.0711 3.09451C21.0951 3.63694 20.1577 4.52804 19.1342 4.64113C17.514 4.82015 15.8351 4.47888 13.8794 4.67637C19.9596 9.32508 22.953 15.3905 21.6417 22.6697C21.117 25.5808 18.7929 28.5993 16.4786 30.6591C12.5368 34.1651 3.08062 33.5114 0.539813 29.2134Z" fill="url(#paint0_linear_5793_5117)" fill-opacity="0.6" />
        <defs>
          <linearGradient id="paint0_linear_5793_5117" x1="21.5342" y1="-1" x2="7.58391" y2="36.4886" gradientUnits="userSpaceOnUse">
            <stop stop-color="#4C4FFF" stop-opacity="0.4" />
            <stop offset="1" stop-color="#4C4FFF" />
          </linearGradient>
        </defs>
      </svg>
    );
  }

  if (!haveMultipleCycles) {
    return <></>;
  }

  return (
    <div className="new-select-cycle-wrapper center">
      <div className="select-cycle">
        <div className="cycle-selector">
          <button
            className={`${activeVariantId === 0 ? "active" : ""}`}
            onClick={() => setActiveVariantId(0)}
          >
            {firstPlanVariants[0].name}
            </button>
          <button
            className={`${activeVariantId === 1 ? "active" : ""}`}
            onClick={() => setActiveVariantId(1)}
          >
            {firstPlanVariants[1].name}
            </button>
        </div>
        <span className="slider-save">
          Save{" "}
          {serviceName !== "bundles" && serviceName !== "bracketsninja"
            ? 20
            : 30}
          %{renderArrow()}
          {' '}with the annual plan
        </span>
        {/* <div className="cycle-selector">
          <h4
            className={`${activeVariantId === 0 ? "active" : ""}`}
            onClick={() => setActiveVariantId(0)}
          >
            {firstPlanVariants[0].name}
          </h4>
          <label className="switch">
            <input
              type="checkbox"
              onChange={(e) => setActiveVariantId(e.target.checked ? 0 : 1)}
              checked={activeVariantId === 0 ? true : false}
            />
            <span className="slider"></span>
          </label>
          <h4
            className={`${activeVariantId === 1 ? "active" : ""}`}
            onClick={() => setActiveVariantId(1)}
          >
            {firstPlanVariants[1].name}
          </h4>
        </div> */}
      </div>
    </div>
  );
};
