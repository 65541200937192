import React, { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { developerService } from '../../../../services/developer.service';
import { integrationsFieldsCompleted } from '../../appIntegration/appIntegration.comp';
import { DeveloperContext } from '../../developer.context';
import { IAppIntegration } from '../../developer.types';
import { DashboardStep } from './dashboardStep.comp';

export const DashboardSteps = () => {
  const { activeApp, integrations, integrationPlatforms, setIntegrations } =
    useContext(DeveloperContext);
  const [doneSteps, setDoneSteps] = useState<{ [key: string]: boolean }>({
    auth: !!activeApp.endpoints.authCallback,
    integrations: (integrations || []).some((integration) =>
      integrationsFieldsCompleted(
        integrationPlatforms.find((p) => p.name === integration.platform)
          ?.fields,
        integration.platformAppKeys
      )
    ),
    webhooks: false, // TODO: Get webhooks count
    docs: false,
  });

  const dashboardSteps = [
    {
      completed: doneSteps.auth,
      title: 'Set up authentication',
      description: `Provide a URL that your users will be redirected to after a successful installation.`,
      url: `/developer/apps/${activeApp.appId}/authentication`,
      linkText: 'Set up Auth',
    },
    {
      completed: doneSteps.intergrations,
      title: 'Add integrations',
      description: `Integrate your app with the most popular e-commerce platforms.`,
      url: `/developer/apps/${activeApp.appId}/integrations`,
      linkText: 'Add Integrations',
    },
    {
      completed: doneSteps.webhooks,
      title: 'Set up webhooks',
      description:
        'Provide a URL and get notified on events happening in your app.',
      url: `/developer/apps/${activeApp.appId}/webhooks`,
      linkText: 'Set up Webhooks',
    },
    {
      completed: doneSteps.docs,
      title: 'Read the docs',
      description: `Learn how to build your app with Common Ninja.`,
      url: 'https://docs.commoninja.com',
      linkText: 'Read the Docs',
      openInAnotherTab: true,
    },
    {
      completed: doneSteps.community,
      title: 'Join the community!',
      description: `Join our growing community of amazing developers who build e-commerce apps.`,
      url: 'https://discord.com/invite/cxqUTbvMNd',
      linkText: 'Join the Community',
      openInAnotherTab: true,
    },
  ];

  async function loadAppIntegrations() {
    try {
      const { data } = await developerService.getAppIntegrations(
        activeApp.appId
      );
      setIntegrations(data);
      setDoneSteps((prevState) => ({
        ...prevState,
        integrations: ((data as IAppIntegration[]) || []).some((integration) =>
          integrationsFieldsCompleted(
            integrationPlatforms.find((p) => p.name === integration.platform)
              ?.fields,
            integration.platformAppKeys
          )
        ),
      }));
    } catch (e) {
      toast.error((e as Error).message);
    }
  }

  useEffect(() => {
    if (activeApp?.appId) {
      loadAppIntegrations();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <section className="app-section dashboard-card steps-section">
      <div className="app-section-header separator-light flex-wrapper">
        <h4>How to Start</h4>
      </div>
      <div className="app-section-body">
        <ul>
          {dashboardSteps.map((step, index) => (
            <DashboardStep key={index} {...step} />
          ))}
        </ul>
      </div>
    </section>
  );
};
