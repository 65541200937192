import React, { useState, useEffect, useContext } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Popup, Table } from '@commonninja/commonninja-styleguide-react';
import { toast } from 'react-toastify';
import { FiEdit } from 'react-icons/fi';
import { FaTrash } from 'react-icons/fa';
import { DLoader } from '../loader/loader.comp';
import { DeveloperContext } from '../developer.context';
import { IAppWebhook } from '../developer.types';
import { developerService } from '../../../services/developer.service';
import { WebhookMessages } from './webhookMessages/webhookMessages.comp';
import { WebhookEditor } from './webhookEditor/webhookEditor.comp';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ActiveButton } from '../appPayments/common/activeButton.comp';
import {
  // faEdit,
  faExclamationCircle,
  // faTrash,
} from '@fortawesome/free-solid-svg-icons';

import './appWebhooks.scss';

export const AppWebhooks = () => {
  const { webhookId } = useParams<{ webhookId: string; }>();
  const { activeApp } = useContext(DeveloperContext);
  const [webhookToDelete, setWebhookToDelete] = useState<IAppWebhook>();
  const history = useHistory();
  const [webhooks, setWebhooks] = useState<IAppWebhook[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [editorWebhook, setEditorWebhook] = useState<Partial<IAppWebhook>>({
    url: '',
  });
  const [popupOpened, setPopupOpened] = useState<boolean>(false);

  function closeEditor() {
    setEditorWebhook({ url: '' });
    setPopupOpened(false);
    getWebhooksData();
  }
  function renderWebhookButtons(webhook: IAppWebhook) {
    return (
      <>
        <ActiveButton
          size={22.5}
          isActive={webhook.status === 'active'}
          onClick={() => toggleWebhookStatus(webhook)}
        />
        <i
          className="webhook-button"
          onClick={() => {
            history.push(
              `/developer/apps/${activeApp.appId}/webhooks/webhook/${webhook.id}`
            );
          }}
        >
          {/* <FontAwesomeIcon icon={faEdit} size="sm" /> */}
          <FiEdit fontSize='17px' />
        </i>
        <i
          className="webhook-button"
          onClick={() => setWebhookToDelete(webhook)}
        >
          {/* <FontAwesomeIcon icon={faTrash} color="#D3D3D3" size="sm" /> */}
          <FaTrash fontSize='16px' />
        </i>
      </>
    );
  }

  async function deleteWebhook() {
    try {
      if (!webhookToDelete) {
        throw new Error('No webhook to delete');
      }
      const { success } = await toast.promise(
        developerService.deleteAppWebhook(activeApp?.appId, webhookToDelete.id),
        {
          pending: 'Deleting app...',
          success: 'Great success! 👌',
          error: 'Could not delete app 😕',
        }
      );
      if (success) {
        getWebhooksData();
      }
    } catch (e) {
      toast.error((e as Error).message);
    }
    setWebhookToDelete(undefined);
  }
  async function toggleWebhookStatus(webhook: IAppWebhook) {
    try {
      const localWebhook = JSON.parse(JSON.stringify(webhook));
      localWebhook.status =
        webhook?.status === 'active' ? 'inactive' : 'active';
      const { success } = await developerService.updateAppWebhook(
        activeApp?.appId,
        webhook.id,
        localWebhook
      );
      if (success) {
        toast.success('Webhook updated successfully');
        setWebhooks((prevState) => {
          const newState = [...prevState];
          const index = newState.findIndex((w) => w.id === webhook.id);
          newState[index] = localWebhook;
          return newState;
        });
      }
    } catch (e) {
      toast.error('Could not update webhook 😕');
    }
  }
  async function getWebhooksData() {
    setLoading(true);
    try {
      setError('');
      const { data } = await developerService.getAppWebhooks(activeApp?.appId);
      setWebhooks(data);
    } catch (e) {
      setError((e as Error).message);
    }
    setLoading(false);
  }

  useEffect(() => {
    if (activeApp?.appId) {
      getWebhooksData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeApp?.appId]);

  if (activeApp?.appId && webhookId) {
    return (
      <WebhookMessages
        webhook={webhooks.find((webhook) => webhook.id === webhookId)}
        getWebhooks={getWebhooksData}
      />
    );
  }
  if (loading) {
    return <DLoader />;
  }
  if (error) {
    return <div className="error center">{error}</div>;
  }
  return (
    <div className="app-content-wrapper app-webhooks">
      <header className="flex-wrapper align-end">
        <hgroup>
          <h2>Webhooks</h2>
          <p>Provide a URL and get notified on events happening in your app.</p>
        </hgroup>
        <button
          className="button primary-button"
          onClick={(e) => {
            e.preventDefault();
            setPopupOpened(true);
          }}
        >
          + Add Webhook
        </button>
      </header>
      {!webhooks?.length ? (
        <div className="center">You don't have any webhooks yet.</div>
      ) : (
        <div className="app-content-table app-section app-section-body">
          <Table
            headers={['Webhook', 'buttons']}
            rows={webhooks.map((webhook: IAppWebhook) => ({
              cells: [
                {
                  content: webhook.url,
                  className: 'clickable',
                  onClick: () => {
                    history.push(`/developer/apps/${activeApp.appId}/webhooks/webhook/${webhook.id}`);
                  }
                },
                {
                  content: renderWebhookButtons(webhook),
                  className: 'row-buttons',
                },
              ],
            }))}
          />
        </div>
      )}
      <Popup
        closeCallback={() => setPopupOpened(false)}
        show={popupOpened}
        className="developer-page"
      >
        <WebhookEditor webhook={editorWebhook} closeEditor={closeEditor} />
      </Popup>
      <Popup
        closeCallback={() => setWebhookToDelete(undefined)}
        show={!!webhookToDelete}
        className="developer-page popup-warn"
      >
        <header>
          <FontAwesomeIcon
            icon={faExclamationCircle}
            color="#fabf5e"
            size="2x"
          />
          <h3>Are you sure you want to delete this webhook?</h3>
        </header>
        <p className="popup-warn-text">
          This action cannot be undone. This will delete the webhook from your
          app and any messages that were sent to it.
        </p>
        <div className="popup-warn-buttons">
          <div
            className="button secondary-button"
            onClick={() => setWebhookToDelete(undefined)}
          >
            Cancel
          </div>
          <div className="button primary-button" onClick={deleteWebhook}>
            Delete
          </div>
        </div>
      </Popup>
    </div>
  );
};
