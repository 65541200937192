import React, { useState, useEffect, useContext } from 'react';
import { DeveloperContext } from '../../developer.context';
import { developerService } from '../../../../services/developer.service';

const statsCardProps = {
  users: {
    title: 'Users',
    suffix: ' Total',
  },
  subscriptions: {
    title: 'Active Subscriptions',
    suffix: ' Subscriptions',
  },
  revenue: {
    title: 'Revenue',
    prefix: '$',
    suffix: ' Overall',
  },
};

export interface IDashboardStatsCard {
  title: string;
  value: number;
  prefix?: string;
  suffix?: string;
  iconClassName: string;
}

export const DashboardStatsCards = () => {
  const [stats, setStats] = useState<IDashboardStatsCard[]>();
  const { activeApp } = useContext(DeveloperContext);

  async function getDashboardStats() {
    try {
      if (!activeApp?.appId) {
        return;
      }
      const { data } = await developerService.getDashboardData(
        activeApp.appId,
        'total'
      );
      let statsData = [];
      for (const key in data) {
        if (statsCardProps.hasOwnProperty(key)) {
          statsData.push({
            ...statsCardProps[key as keyof typeof statsCardProps],
            value: data[key],
            iconClassName: key,
          });
        }
      }
      setStats(statsData);
    } catch (e) {
      console.error(e);
    }
  }
  useEffect(() => {
    if (activeApp?.appId) {
      getDashboardStats();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeApp]);

  if (!stats || stats?.every((stat) => stat.value === 0)) {
    return <></>;
  }

  return (
    <div className="flex-wrapper dashboard-cards-spread">
      {stats &&
        stats.map((stat, index: number) => (
          <section className="app-section dashboard-card" key={index}>
            <div className="app-section-body dashboard-stats-card flex-wrapper">
              <div
                className={`icon ${stat.iconClassName}`}
                title={stat.title}
              ></div>
              <div>
                <div className="title">{stat.title}</div>
                <div className="value">
                  {stat.prefix ? stat.prefix : ''}
                  {stat.value.toLocaleString('en-US')}
                  {stat.suffix ? stat.suffix : ''}
                </div>
              </div>
            </div>
          </section>
        ))}
    </div>
  );
};
