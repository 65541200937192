import React, { useRef, useState, useEffect } from 'react';
import { H3 } from '@commonninja/commonninja-styleguide-react/lib/esm/components/internal';

import { IAuthProps } from '../../auth/auth.types';
import { AuthLayout } from '../../auth/authLayout.comp';
import { AuthForm } from '../../auth/authForm.comp';
import { TermsOfService } from '../terms/terms';
import { FormRow } from '@commonninja/commonninja-styleguide-react';
import { developerService } from '../../../services/developer.service';

export const DeveloperSignup = ({ embedMode }: IAuthProps) => {
  const firstInput: any = useRef();
  const [postSubmission, setPostSubmission] = useState<boolean>(false);
  const [formState, setFormState] = useState<any>({
    checked: false,
  });

  function formFieldChanged(value: boolean) {
    setFormState({
      checked: value
    });
  }

  function renderPostSubmissionMessage() {
    return (
      <>
        <H3>👍 You're good to go!</H3>
        <p className="body-1 post-message">
          Redirecting you to the dashboard...
        </p>
      </>
    );
  }

  function renderForm() {
    return (
      <>
        <H3>Join the Common Ninja Developer Platform</H3>
        <TermsOfService />
        <AuthForm
          name="developer-signup"
          mainButtonText="Apply Now!"
          embedMode={embedMode}
          authRequest={(captchaToken) => developerService.developerSignup(captchaToken)}
          firstInput={firstInput}
          successCallback={() => setPostSubmission(true)}
          className="developer-signup-form"
          buttonDisabled={!formState.checked}
        >
          <FormRow className="read-terms">
            <input 
              type="checkbox" 
              required={true}
              id="read-terms"
              checked={formState.checked}
              onChange={(e) => formFieldChanged(e.target.checked)}
            />
            <span 
              className="body-1" 
              onClick={() => {
                formFieldChanged(!formState.checked);
              }}
            >I read and approve Common Ninja Developer Platform terms of service.</span>
          </FormRow>
        </AuthForm>
      </>
    );
  }

  useEffect(() => {
    if (postSubmission) {
      window.setTimeout(() => {
        window.location.href = '/developer/apps';
      }, 2000);
    }
  }, [postSubmission]);

  useEffect(() => {
    document.title = `Developer Signup | Common Ninja`;
  }, []);
  
  return (
    <AuthLayout 
      embedMode={embedMode}
      isDeveloper={true}
      teaser=' '
    >
      {
        postSubmission ?
        renderPostSubmissionMessage() : 
        renderForm()
      }
    </AuthLayout>
  );
}