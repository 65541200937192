const defaultChartOptions: Highcharts.Options = {
  chart: {
    height: 250,
    style: {
      fontFamily: '"lato", sans-serif',
      fontSize: '12px',
    },
  },
  title: {
    text: '',
  },
  colors: ['#6881E9', '#C2D6FB'],
  plotOptions: {
    areaspline: {
      fillOpacity: 0,
      marker: {
        enabled: false,
      },
      tooltip: {
        headerFormat: '',
        pointFormat:
          '<text style="font-family: lato; font-size: 12px; line-height: 1.5;"><strong>{series.name}:</strong> {point.y}</text>',
      },
      softThreshold: true,
      lineWidth: 3,
      lineColor: '#6881e9',
      threshold: null,
    },
    column: {
      pointWidth: 18,
      tooltip: {
        pointFormat:
          '<div style="font-family: lato; font-size: 12px; line-height: 1.5;"><span style="color: {point.color}; margin-right: 3px;">●</span><strong>{series.name}</strong><br />{point.y}<div>',
        valueSuffix: '',
      },
    },
  },
  xAxis: {
    tickWidth: 0,
    labels: {
      style: {
        fontSize: '12px',
        fontFamily: 'lato',
        color: '#0C0F21',
      },
    },
  },

  yAxis: {
    min: 0,
    allowDecimals: false,
    title: undefined,
    labels: {
      style: {
        fontSize: '12px',
        fontFamily: 'lato',
        color: '#0C0F21',
      },
    },
  },
  legend: {
    enabled: false,
  },

  credits: {
    enabled: false,
  },
  tooltip: {
    pointFormat: '{point.y}',
    valueSuffix: '',
    followPointer: true,
    followTouchMove: true,
    hideDelay: 0,
    useHTML: true,
    headerFormat: '',
  },
};

export { defaultChartOptions };
