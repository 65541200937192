import React, { useEffect, useState, useContext } from 'react';
import { DeveloperContext } from '../../developer.context';
import { DashboardMeter, IDashboardMeterProps } from './dashboardMeter.comp';
import { developerService } from '../../../../services/developer.service';

interface IDashboardMeters {
  requestSlugUrl: string;
  metersProps: any;
  className?: string;
}
export const DashboardMeters = ({
  requestSlugUrl,
  metersProps,
  className,
}: IDashboardMeters) => {
  const { activeApp } = useContext(DeveloperContext);
  const [meters, setMeters] = useState<IDashboardMeterProps[]>([]);

  async function fetchMetersData() {
    if (!activeApp?.appId) {
      return;
    }
    const { data } = await developerService.getDashboardData(
      activeApp.appId,
      requestSlugUrl
    );
    let metersData = [];
    for (const key in data) {
      if (metersProps.hasOwnProperty(key)) {
        let value = data[key];
        if (key === 'storage') {
          value = (value / 1024 / 1024 / 1024).toFixed(2).replace('.00', '');
        }
        metersData.push({ ...metersProps[key], value });
      }
    }
    setMeters(metersData);
  }

  useEffect(() => {
    if (activeApp) {
      fetchMetersData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeApp]);

  return (
    <div className={`flex-wrapper ${className}-meters`}>
      {meters.map((meterData: IDashboardMeterProps, index: number) => (
        <DashboardMeter {...meterData} key={index} />
      ))}
    </div>
  );
};
