import React, { useState } from "react";
import { BiSearch } from "react-icons/bi";
import { BsFileEarmarkArrowUp } from "react-icons/bs";
import { Button } from "@commonninja/commonninja-styleguide-react";

import { FileType } from "../asset.types";

import "./mediaManagerToolbar.scss";
import { DeveloperInput } from "../../../developer/newDashboardComponents/developerInput.comp";
import { CNFileUpload } from "../cnFileUpload.comp";

export interface IMediaManagerToolbarProps {
  onChange?: (query?: string, reload?: boolean) => void;
  showActions?: boolean;
  uploadIsAvailable?: boolean;
  showSearch?: boolean;
  onSearch?: (query: string) => void;
  acceptType?: FileType;
  sizeLimitInMB?: number;
  uploadDisabledCallback?: () => void;
}

export const MediaManagerToolbar = ({
  onChange,
  acceptType,
  uploadIsAvailable = false,
  sizeLimitInMB = 5,
  showActions,
  showSearch,
  uploadDisabledCallback,
}: IMediaManagerToolbarProps) => {
  const [query, setQuery] = useState("");

  return (
    <div className="media-manager-toolbar">
      <div className="actions">
        {showActions && (
          <CNFileUpload
            successCallback={() => onChange?.("", true)}
            acceptType={acceptType}
            disabled={!uploadIsAvailable}
            disabledCallback={
              uploadDisabledCallback ||
              (() => {
                // updatePremiumMessage(
                //   `Your current premium plan doesn't support media uploads.`
                // );
              })
            }
            sizeLimitInMB={sizeLimitInMB}
          >
            <Button className="secondary-button">
              <BsFileEarmarkArrowUp />
              Upload File
            </Button>
          </CNFileUpload>
        )}
      </div>
      <div className="search">
        <DeveloperInput
          disabled={!showSearch}
          value={query}
          handleChange={(e) => setQuery(e.target.value)}
          placeholder="Enter text..."
          onKeyDown={(e) => {
            if (e.keyCode === 13 || e.key === "Enter") {
              onChange?.(query);
            }
          }}
          name="search"
        />
        <BiSearch />
      </div>
    </div>
  );
};
