import React, { ReactElement } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { Popup } from "@commonninja/commonninja-styleguide-react";

interface IWarnPopup {
  setShowPopup: React.Dispatch<React.SetStateAction<boolean>>;
  onConfirm: () => void;
  showPopup: boolean;
  loading?: boolean;
  title: string;
  text: ReactElement | string;
  buttonText?: string;
  children?: React.ReactNode;
  className?: string;
}

export const WarnPopup = ({
  showPopup,
  setShowPopup,
  title,
  text,
  onConfirm,
  buttonText,
  children,
  className = "",
  loading = false,
}: IWarnPopup) => {
  return (
    <Popup
      show={showPopup}
      closeCallback={() => setShowPopup(false)}
      className={`developer-page popup-warn ${className}`}
    >
      <header>
        <FontAwesomeIcon icon={faExclamationCircle} color="#fabf5e" size="2x" />
        <h3>{title}</h3>
      </header>
      <p className="popup-warn-text">{text}</p>
      {children}
      <div className="popup-warn-buttons">
        <div
          className="button secondary-button"
          onClick={() => setShowPopup(false)}
        >
          Cancel
        </div>
        <button
          className={`button primary-button ${loading ? "loading" : ""}`}
          onClick={onConfirm}
        >
          {buttonText
            ? `${loading ? "Loading..." : buttonText}`
            : `${loading ? "Loading..." : "Remove"}`}
        </button>
      </div>
    </Popup>
  );
};
