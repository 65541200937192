import { Tabs } from "@commonninja/commonninja-styleguide-react";
import React, { useContext, useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { DeveloperContext } from "../../developer.context";
import { Documents } from "../documents/documents.comp";
import { Fields } from "../fields/fields.comp";
import { Settings } from "../settings/settings.comp";
import { developerService } from "../../../../services/developer.service";
import { ICollectionSchemaAttribute } from "../types";
import { CopyText } from "../../common/copyText/copyText.comp";

import "./collectionDetails.scss";

export const CollectionDetails = ({
	collectionName,
}: {
	collectionName: string;
}) => {
	const { activeApp } = useContext(DeveloperContext);
	const [collectionSchemaAttributes, setCollectionSchemaAttributes] = useState<
		ICollectionSchemaAttribute[]
	>([]);
	const [prevCollectionName, setPrevCollectionName] = useState(
		collectionName || ""
	);
	const { collectionId, subPage } = useParams<any>();

	const history = useHistory();

	async function getOrCreateCollectionSchema() {
		try {
			const {
				data: { attributes },
			} = await developerService.getCollectionSchema(
				activeApp.appId,
				prevCollectionName
			);
			setCollectionSchemaAttributes(attributes);
		} catch (e) {
			const {
				data: { attributes },
			} = await developerService.createCollectionSchema(
				activeApp.appId,
				prevCollectionName
			);
			setCollectionSchemaAttributes(attributes);
		}
	}

	async function checkCollectionExist() {
		const { data } = await developerService.getCollections(activeApp.appId);
		if (!data.includes(prevCollectionName))
			return history.push(`/developer/apps/${activeApp.appId}/database`);
	}

	function renderCollectionTab(activeTabId: string) {
		if (activeTabId === "settings")
			return (
				<Settings
					collectionName={prevCollectionName}
					setCollectionName={setPrevCollectionName}
				/>
			);
		if (activeTabId === "fields")
			return (
				<Fields
					fields={collectionSchemaAttributes}
					setFields={setCollectionSchemaAttributes}
					collectionName={prevCollectionName}
				/>
			);
		return (
			<Documents
				fields={collectionSchemaAttributes}
				collectionName={prevCollectionName}
			/>
		);
	}

	useEffect(() => {
		if (!activeApp.appId) {
			return;
		}
		checkCollectionExist();
		getOrCreateCollectionSchema();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [activeApp.appId]);

	return (
		<>
			<div className="app-editor-page collection-details">
				<Link
					to={`/developer/apps/${activeApp.appId}/database`}
					className="app-editor-page-back"
				>
					<i className="chevron-left"></i>
					<span>Back to Collections</span>
				</Link>
				<header className="flex-wrapper">
					<hgroup>
						<h2>{prevCollectionName}</h2>
						<div className="subtitle">
							<h4>API Endpoint</h4>
							<CopyText
								className="copy-container"
								text={`https://api.commoninja.com/integrations/api/v1/db/collections/${prevCollectionName}`}
							/>
						</div>
					</hgroup>
				</header>
				<Tabs
					items={[
						{
							id: "documents",
							name: "Documents",
						},
						{
							id: "fields",
							name: "Fields",
						},
						{
							id: "settings",
							name: "Settings",
						},
					]}
					onChange={(tab) =>
						history.push(
							`/developer/apps/${activeApp.appId}/database/collection/${collectionId}/${tab.id}`
						)
					}
					activeTabId={subPage}
					resolveTabComp={(activeTabId) => {
						return renderCollectionTab(activeTabId as string);
					}}
				/>
			</div>
		</>
	);
};
