import React, { CSSProperties, useRef, useState } from 'react';

import './copyInput.scss';

type CopyInputProps = {
	value: string;
	inputType?: 'text' | 'password' | 'hidden';
	className?: string;
	postMessageText?: string;
	postMessageClassName?: string;
	postMessageTimeout?: number;
	children?: any;
	buttonStyles?: CSSProperties;
};

export const CopyInput = (props: CopyInputProps) => {
	const {
		value,
		postMessageText,
		className,
		postMessageClassName,
		postMessageTimeout,
		children,
		inputType,
		buttonStyles,
	} = props;
	const [postMessage, setPostMessage] = useState<string>('');
	const inputRef = useRef<any>(null);

	function buttonClicked(e: any, fromButton: boolean = false) {
		e.preventDefault();

		if (!inputRef || !inputRef.current) {
			return;
		}

		inputRef.current.select();

		if (!fromButton) {
			return;
		}

		navigator.clipboard.writeText(inputRef.current.value).then(() => {
			setPostMessage(postMessageText || 'Copied');
			window?.setTimeout(() => {
				setPostMessage('');
			}, postMessageTimeout || 2000);
		}, (err) => {
		});
	}

	return (
		<div className={`copy-input-wrapper ${className ? className : ''}`}>
			<input
				ref={inputRef}
				type={inputType || 'text'}
				value={value}
				readOnly
				onClick={buttonClicked}
			/>
			<button
				className="copy-btn"
				title="Copy to Clipboard"
				onClick={(e) => buttonClicked(e, true)}
				style={buttonStyles}
			>
				{children || <span>Copy</span>}
			</button>
			{postMessage && (
				<div
					className={`copy-input-post-message ${postMessageClassName ? postMessageClassName : ''
						}`}
				>
					{postMessageText || 'Copied'}
				</div>
			)}
		</div>
	);
};
