import React, { useEffect, useState, useContext } from 'react';
import { toast } from 'react-toastify';
import NumberFormat from 'react-number-format';
import { Table } from '@commonninja/commonninja-styleguide-react';
import { DeveloperContext } from '../../../../developer.context';
import { IAppPlan, IAppPlanFeature } from '../../../../developer.types';
import { developerService } from '../../../../../../services/developer.service';
import './planFeatures.scss';
import { DeveloperInput } from '../../../../newDashboardComponents/developerInput.comp';

type TPlanFeaturesProps = {
  planFeatures: IAppPlan['features'];
  setPlanFeatures: (features: IAppPlan['features']) => void;
};

export const PlanFeatures = ({
  planFeatures = {},
  setPlanFeatures,
}: TPlanFeaturesProps) => {
  const [appFeatures, setAppFeatures] = useState<IAppPlanFeature[]>([]);
  const {
    activeApp: { appId },
  } = useContext(DeveloperContext);

  useEffect(() => {
    async function fetchFeatures() {
      try {
        const {
          data: { features },
        } = await developerService.getAppFeatures(appId);
        setAppFeatures(features);
      } catch (error) {
        toast.error(
          'There was a problem getting the app features: ' +
            (error as Error).message
        );
      }
    }
    if (appId) {
      fetchFeatures();
    }
  }, [appId]);

  function handleChange(e: any) {
    const newFeatures = { ...planFeatures };
    newFeatures[e.target.name] = e.target.value;
    setPlanFeatures(newFeatures);
  }

  function handleEditorFeatureAddRemove(feature: IAppPlanFeature) {
    const newFeatures = { ...planFeatures };
    isCustomFeature(feature.name)
      ? delete newFeatures[feature.name]
      : (newFeatures[feature.name] = feature.value);
    setPlanFeatures(newFeatures);
  }
  function renderFeature(feature: IAppPlanFeature) {
    switch (feature.type) {
      case 'string':
        return (
          <input
            type="text"
            name={feature.name}
            {...(isCustomFeature(feature.name)
              ? {
                  onChange: handleChange,
                  value: planFeatures[feature.name],
                }
              : { disabled: true, value: feature.value?.toString() })}
            placeholder={'Add ' + feature.name}
            className="payments-input"
          />
        );
      case 'number':
        const value = planFeatures[feature.name] || feature.value;
        return (
          <>
            <NumberFormat
              displayType="input"
              thousandSeparator={true}
              name={feature.name}
              {...(isCustomFeature(feature.name)
                ? {
                    value: planFeatures[feature.name],
                    onValueChange: (values) => {
                      const e = {
                        target: { value: parseInt(values.value), name: feature.name },
                      } as {
                        target: { value: number; name: string };
                      };
                      handleChange(e as any);
                    },
                    onBlur: (e: React.BaseSyntheticEvent<FocusEvent>) => {
                      if (!e.target.value) {
                        handleChange({
                          target: { value: 0, name: feature.name },
                        } as any);
                      }
                    },
                    className: 'number-input',
                  }
                : {
                    value: planFeatures[feature.name]
                      ? planFeatures[feature.name]
                      : feature.value,
                    disabled: true,
                    className: 'number-input disabled',
                  })}
            />
            <div className="buttons-container">
              <i
                title="increase"
                onClick={() =>
                  handleChange({
                    target: {
                      name: feature.name,
                      value: Number(value) + 1,
                    },
                  })
                }
              ></i>
              <i
                title="decrease"
                className={Number(value) === 0 ? 'disabled' : ''}
                onClick={() =>
                  handleChange({
                    target: {
                      name: feature.name,
                      value: Number(value) - 1,
                    },
                  })
                }
              ></i>
            </div>
          </>
        );
      case 'boolean':
        return (
          <DeveloperInput
            type="checkbox"
            name={feature.name}
            title={feature.name}
            value={
              isCustomFeature(feature.name)
                ? planFeatures[feature.name]
                : feature.value
            }
            handleChange={handleChange}
            disabled={!isCustomFeature(feature.name)}
          />
        );

      default:
        return <></>;
    }
  }

  function isCustomFeature(featureName: string) {
    return planFeatures.hasOwnProperty(featureName);
  }

  return (
    <div className="app-section-body plan-features">
      <Table
        headers={['Feature', 'Use default value', 'Value']}
        rows={appFeatures.map((feature) => ({
          cells: [
            { content: feature.name },
            {
              content: (
                <DeveloperInput
                  name={feature.name}
                  title="Feature Name"
                  type="checkbox"
                  value={!isCustomFeature(feature.name) as any}
                  handleChange={() => handleEditorFeatureAddRemove(feature)}
                />
              ),
            },
            {
              content: (
                <div className="input-container">{renderFeature(feature)}</div>
              ),
            },
          ],
        }))}
      />
    </div>
  );
};
