import React, { useEffect, useState, useContext } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { developerService } from '../../../../services/developer.service';
import { DashboardChart } from './dashboardChart/dashboardChart.comp';
import { DeveloperContext } from '../../developer.context';
import { defaultChartOptions } from './dashboardChart/dashboardChartDefault';
import { DashboardMeter } from './dashboardMeter.comp';
import {
  getDateRange,
  capitalize,
  formatDates,
} from './dashboardChart/helpers';

interface IDashboardChartSectionProps {
  extractUrls: string[];
  title: string;
  className: string;
  bodyClassName?: string;
  options: Highcharts.Options;
  showMeters?: boolean;
}

export const DashboardChartSection = ({
  extractUrls,
  title,
  className,
  bodyClassName,
  options,
  showMeters = false,
}: IDashboardChartSectionProps) => {
  const [combinedOptions, setCombinedOptions] =
    useState<Highcharts.Options>(defaultChartOptions);
  const [viewOptions, setViewOptions] = useState<{
    groupBy: 'month' | 'day';
    to: Dayjs;
    from: Dayjs;
    selected: 'month' | 'half-year' | 'annual';
  }>(() => {
    const to = dayjs();
    const from = to.subtract(1, 'month');
    return {
      groupBy: 'day',
      from,
      to,
      selected: 'month',
    };
  });
  const [metersData, setMetersData] = useState<{
    maxValue: number;
    values: { [key: string]: number };
  }>({
    maxValue: 0,
    values: {},
  });
  const { activeApp } = useContext(DeveloperContext);

  async function getChartData() {
    const data = await developerService.getChartData(
      activeApp.appId,
      extractUrls,
      viewOptions,
    );
    const dateRange = getDateRange(viewOptions);

    let metersData = { maxValue: 0, values: {} as any };
    const normalizedChartData = data.map(({ name, data }: any) => {
      let platformName = 'No data to display';
      if (name === 'revenue') {
        const values = dateRange.map((date) => {
          let value = {
            amount: 0,
            user: {
              platform: '',
            },
          };
          data.revenue.forEach((item: any) => {
            const isSame = dayjs(item.created).isSame(
              date,
              viewOptions.groupBy,
            );
            if (isSame) {
              value.user.platform = item.user.platform;
              value.amount += item.amount;
            }
          });

          if (value) {
            platformName =
              value.user.platform === 'nindo'
                ? 'Common Ninja'
                : capitalize(value.user.platform || '');
            metersData.maxValue += value.amount;

            if (platformName) {
              metersData.values[platformName] =
                metersData.values[platformName] + value.amount || value.amount;
            }
          }

          return value ? parseFloat(value.amount.toFixed(2)) : 0;
        });

        return { name: 'Revenue', data: values };
      }

      const values = dateRange.map((date: Dayjs) => {
        const value = data.find((item: any) => {
          let itemDate =
            viewOptions.groupBy === 'month'
              ? item._id.year + '-' + item._id.month
              : item._id.year + '-' + item._id.month + '-' + item._id.day;
          return dayjs(itemDate).isSame(date, viewOptions.groupBy);
        });
        return value?.count || 0;
      });

      return {
        name: capitalize(name.split('/')[0]),
        data: values ? values : 0,
      };
    });
    if (showMeters) {
      setMetersData(metersData);
    }
    const newOptions = JSON.parse(JSON.stringify(options));
    newOptions.series = normalizedChartData;
    newOptions.xAxis = {
      ...newOptions.xAxis,
      categories: formatDates(dateRange, viewOptions),
    };
    newOptions.plotOptions = {
      ...newOptions?.plotOptions,
      column: {
        ...newOptions?.plotOptions?.column,
        pointWidth: viewOptions.groupBy === 'month' ? 18 : 8,
      },
    };
    setCombinedOptions(Object.assign({}, defaultChartOptions, newOptions));
  }

  function changeViewState({
    months,
    groupBy,
    selected,
  }: {
    months: number;
    groupBy: 'month' | 'day';
    selected: 'month' | 'half-year' | 'annual';
  }) {
    const now = dayjs();
    const from = now.subtract(months, 'month');
    setViewOptions({
      groupBy,
      from,
      to: now,
      selected,
    });
  }

  useEffect(() => {
    if (activeApp?.appId) {
      getChartData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeApp, viewOptions]);

  if (!combinedOptions.series?.length) {
    return <></>;
  }

  return (
    <section className={`app-section dashboard-card ${className}`}>
      <div className="app-section-header app-section-header-buttons flex-wrapper">
        <h4>{title}</h4>
        <div className="dashboard-buttons flex-wrapper">
          <div
            onClick={() =>
              changeViewState({
                months: 12,
                groupBy: 'month',
                selected: 'annual',
              })
            }
            className={`button dashboard-button ${
              viewOptions.selected === 'annual' ? 'active' : 'grey'
            }`}
          >
            1 Year
          </div>
          <div
            onClick={() =>
              changeViewState({
                months: 6,
                groupBy: 'month',
                selected: 'half-year',
              })
            }
            className={`button dashboard-button ${
              viewOptions.selected === 'half-year' ? 'active' : 'grey'
            }`}
          >
            6 Months
          </div>
          <div
            onClick={() =>
              changeViewState({ months: 1, groupBy: 'day', selected: 'month' })
            }
            className={`button dashboard-button ${
              viewOptions.selected === 'month' ? 'active' : 'grey'
            }`}
          >
            1 Month
          </div>
        </div>
      </div>
      <div className={`app-section-body ${bodyClassName ? bodyClassName : ''}`}>
        {showMeters && (
          <div className="revenue-chart">
            <div className="revenue-chart-header flex-wrapper">
              <div>All Platforms</div>
              <div>{'$' + metersData.maxValue.toLocaleString('en-US')}</div>
            </div>
            <div className="revenue-chart-meters flex-wrapper">
              {metersData.values &&
                Object.keys(metersData.values).map((platform) => (
                  <DashboardMeter
                    key={platform}
                    title={platform}
                    value={metersData.values[platform]}
                    showMaxValue={false}
                    maxValue={metersData.maxValue}
                    formatValues={false}
                    prefix="$"
                  />
                ))}
            </div>
          </div>
        )}
        <DashboardChart options={combinedOptions} />
      </div>
    </section>
  );
};
