import { BsThreeDots } from "react-icons/bs";
import { MdDelete } from "react-icons/md";
import { HiPencil } from "react-icons/hi";
import {
  FaFile,
  FaFileAudio,
  FaFileCsv,
  FaFileImage,
  FaFilePdf,
  FaFileVideo,
} from "react-icons/fa";
import cn from "classnames";
import { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";

import { IAssetFigure } from "../../asset.types";
import { AssetService } from "../../asset.service";
import { Button, Popup } from "@commonninja/commonninja-styleguide-react";
import React from "react";
import { DeveloperInput } from "../../../../developer/newDashboardComponents/developerInput.comp";
import { DeveloperWarning } from "../../../../developer/newDashboardComponents/developerWarning.comp";

import "./mediaManagerFilesFigure.scss";

interface IMediaManagerFilesFigureProps {
  item: IAssetFigure;
  selected: boolean;
  onClick: (item?: IAssetFigure) => void;
  showActions?: boolean;
}

export const MediaManagerFilesFigure = ({
  item,
  onClick,
  selected,
  showActions,
}: IMediaManagerFilesFigureProps) => {
  const [popoverOpened, setPopoverOpened] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [localAssetName, setLocalAssetName] = useState<string>(item.name || "");
  const inputRef = useRef<HTMLInputElement>(null);
  const assetService = new AssetService();
  const ref: any = useRef();

  async function onDelete() {
    // Set active asset to null
    onClick(undefined);

    // Close confirmation popup
    setShowConfirmation(false);

    try {
      await assetService.delete(item.guid || "");

      // Reload list
      const reloader: HTMLDivElement | null = document.querySelector(
        "#media-manager-filer-reload"
      );

      if (reloader) {
        reloader.click();
      }
    } catch (e) {
      toast.dismiss();
      toast.error((e as Error).message);
    }
  }

  function toggleEditMode(e: any) {
    e.stopPropagation();
    setPopoverOpened(false);
    setEditMode(!editMode);
  }

  function submitNameChange() {
    if (localAssetName !== item.name) {
      assetService.update(item.guid as string, localAssetName);
    }
    setEditMode(false);
  }

  function renderCaption() {
    if (!editMode) {
      return localAssetName || "Untitled";
    }

    return (
      <form onSubmit={submitNameChange}>
        <DeveloperInput
          value={localAssetName}
          handleChange={(e) => setLocalAssetName(e.target.value)}
          onKeyDown={(e) => {
            if (e.keyCode === 13 || e.key === "Enter") {
              submitNameChange();
            }
          }}
          onBlur={submitNameChange}
          name="asset-name"
        />
      </form>
    );
  }

  function renderAssetIcon() {
    switch (item.assetType) {
      case "image": {
        if (item.url) {
          return <img src={item.thumbnail} alt={item.name} />;
        }
        return <FaFileImage />;
      }
      case "pdf":
        return <FaFilePdf />;
      case "video":
        return <FaFileVideo />;
      case "csv":
        return <FaFileCsv />;
      case "audio":
        return <FaFileAudio />;
      default:
        return <FaFile />;
    }
  }

  useEffect(() => {
    if (editMode) {
      setTimeout(() => {
        if (inputRef.current) {
          inputRef.current.querySelector("input")?.focus();
        }
      }, 50);
    }
  }, [editMode]);

  useEffect(() => {
    const handleClickOutside = (event: Event) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        setPopoverOpened(false);
      }
    };

    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  return (
    <>
      <div
        key={`media-${item.guid || Date.now()}`}
        onClick={() => onClick(item)}
        className={cn("styled-media-manager-files-figure", {
          selected,
        })}
        title={item.name || "Untitled"}
        ref={ref}
      >
        <div className="figure-media">{renderAssetIcon()}</div>
        <figcaption ref={inputRef}>{renderCaption()}</figcaption>
        {showActions && (
          <div
            className={cn("media-manager-popover", {
              opened: popoverOpened,
            })}
            // onOpenChange={(open: boolean) => setPopoverOpened(open)}
          >
            <span
              className="popover-trigger"
              onClick={() => {
                setPopoverOpened(!popoverOpened);
              }}
            >
              <BsThreeDots />
            </span>
            {popoverOpened && (
              <div className="styled-media-manager-files-figure-actions">
                <Button color="transparent" onClick={toggleEditMode}>
                  <HiPencil />
                  Rename
                </Button>
                <Button
                  color="transparent"
                  className="delete"
                  onClick={(e) => {
                    e.stopPropagation();
                    setPopoverOpened(false);
                    setShowConfirmation(true);
                  }}
                >
                  <MdDelete />
                  Delete
                </Button>
              </div>
            )}
          </div>
        )}
      </div>
      <Popup
        closeCallback={() => setShowConfirmation(false)}
        show={showConfirmation}
        className="popup-dashboard popup-warn"
      >
        <DeveloperWarning
          title={"Delete Asset"}
          message={`Are you sure you want to delete "${item.name}"?`}
          confirm={{ label: "Delete", onClick: onDelete }}
          cancel={{
            label: "Cancel",
            onClick: () => setShowConfirmation(false),
          }}
        />
      </Popup>
    </>
  );
};
