import React, { Suspense, useState } from 'react';
import NumberFormat from 'react-number-format';
import { useHandleChange } from '../../../newDashboardComponents/hooks/useHandleChange';
import { IAppPlanFeature } from '../../../developer.types';
import { ActiveButton } from '../../common/activeButton.comp';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { DeveloperInput } from '../../../newDashboardComponents/developerInput.comp';
import { capitalizeFirstLetter } from '../../../../../helpers/helpers';

import './featureEditor.scss';

interface IProps {
  feature: IAppPlanFeature;
  onChange: (feature: IAppPlanFeature) => void;
  onRemove: () => void;
  nameReadonly: boolean;
}

const JSONEditor = React.lazy(() => import('../../common/jsonEditor.comp'));

export const FeatureEditor: React.FC<IProps> = ({
  feature,
  onChange,
  onRemove,
  nameReadonly,
}) => {
  const [isSaving, setIsSaving] = useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');
  const [localFeature, setLocalFeature] = useState<IAppPlanFeature>({
    ...feature,
  });
  const { displayName, name, type, value } = localFeature;
  const [handleChange] = useHandleChange(setLocalFeature);
  const handleError = (message: string) => {
    setErrorMessage(message);
    setIsSaving(false);
  };
  const camelize = (str: string) => {
    const newStr = str
      .trim()
      .replace(/[^\w\s]/gi, '') // removes special characters
      .replace(/[-_\s]+(.)?/g, (_, c) => (c ? c.toUpperCase() : '')); // uppercases first letter after each space
    return newStr.charAt(0).toLowerCase() + newStr.slice(1); // lowercases first letter
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsSaving(true);
    try {
      if (displayName.trim().length === 0) {
        throw new Error('Display name is required');
      }
      if (name.trim().length === 0) {
        throw new Error('Name is required');
      }
      if (name.trim().length > 50) {
        throw new Error('Name must be less than 50 characters');
      }
      if (!name.match(/^[a-z][a-zA-Z0-9]+$/g)) {
        throw new Error('Name must be camel case');
      }
      onChange(localFeature);
      setLocalFeature({} as IAppPlanFeature);
      setErrorMessage('');
      setIsSaving(false);
    } catch (error) {
      handleError((error as any).message);
    }
  };
  const nameInputProps = nameReadonly
    ? { defaultValue: name ? name : '', disabled: true }
    : {
        value: name ? name : '',
        onChange: handleChange,
        placeholder: 'Add a name in camel case',
        autoComplete: 'off',
      };

  const displayNameInputProps = nameReadonly
    ? { onChange: handleChange }
    : {
        onChange: (e: any) => {
          setLocalFeature({
            ...localFeature,
            name: camelize(e.target.value),
            displayName: e.target.value,
          });
        },
      };
  function getDefaultFeatureValue(type: string) {
    switch (type) {
      case 'boolean':
        return false;
      case 'number':
        return 0;
      case 'string':
        return '';
      default:
        return null;
    }
  }
  function renderFeatureValueInput() {
    switch (type) {
      case 'boolean':
        return (
          <ActiveButton
            size={22.5}
            isActive={value === true}
            onClick={() => {
              setLocalFeature({ ...localFeature, value: !value });
            }}
          />
        );
      case 'number':
        return (
          <>
            <NumberFormat
              className="number-input"
              thousandsGroupStyle="thousand"
              value={value?.toString()}
              displayType="input"
              type="text"
              id="value"
              thousandSeparator={true}
              autoComplete="off"
              allowNegative={false}
              onBlur={(e: React.BaseSyntheticEvent<FocusEvent>) => {
                if (!e.target.value) {
                  setLocalFeature({ ...localFeature, value: 0 });
                }
              }}
              onValueChange={(values: any) => {
                setLocalFeature({
                  ...localFeature,
                  value: values.floatValue,
                });
              }}
            />
            <div className="buttons-container">
              <i
                title="increase"
                onClick={() =>
                  setLocalFeature({ ...localFeature, value: Number(value) + 1 })
                }
              ></i>
              <i
                title="decrease"
                className={!value || value === '0' ? 'disabled' : ''}
                onClick={() =>
                  setLocalFeature({ ...localFeature, value: Number(value) - 1 })
                }
              ></i>
            </div>
          </>
        );
      case 'string':
        return (
          <input
            type="text"
            id="value"
            value={value?.toString()}
            name="value"
            required
            onFocus={() => setErrorMessage('')}
            onChange={handleChange}
            placeholder="Add a value"
            autoComplete="off"
          />
        );
      default:
        return <></>;
    }
  }

  return (
    <form onSubmit={handleSubmit} className="app-section-body feature-editor">
      <div className="input-container">
        <label htmlFor="displayName">
          Display Name <span className="required">*</span>
        </label>
        <input
          type="text"
          id="displayName"
          name="displayName"
          autoComplete="off"
          autoFocus
          placeholder="Add a display name"
          onFocus={() => setErrorMessage('')}
          value={displayName}
          {...displayNameInputProps}
        />
      </div>
      <div className="input-container">
        <label htmlFor="name">Name</label>
        <input
          id="name"
          name="name"
          autoComplete="off"
          type="text"
          {...nameInputProps}
          onFocus={() => setErrorMessage('')}
        />
      </div>
      {/* <DeveloperInput
        label="Description"
        name="description"
        placeholder="Add a description"
        textarea
        value={description}
        handleChange={handleChange}
      /> */}
      <div className="inputs-horizontal">
        <DeveloperInput
          label="Choose Type"
          name="type"
          required
          value={type}
          title="Type"
          select={['boolean', 'number', 'string'].map((name) => ({
            name,
            displayName: capitalizeFirstLetter(name),
          }))}
          handleChange={(e) => {
            setLocalFeature({
              ...localFeature,
              type: e.target.value as any,
              value: getDefaultFeatureValue(e.target.value),
            });
          }}
        />
        <div className="input-container">
          <label htmlFor="value">
            Default Value <span className="required">*</span>
          </label>
          {renderFeatureValueInput()}
        </div>
      </div>
      <div className={`input-container`}>
        <label>Metadata</label>
        <Suspense fallback={<div>Loading...</div>}>
          <JSONEditor
            value={feature.meta}
            onChange={(j) =>
              handleChange({
                target: {
                  name: 'meta',
                  value: j,
                },
              })
            }
          />
        </Suspense>
      </div>
      <div className="buttons-wrapper center">
        {nameReadonly && (
          <button className="button link-button" onClick={onRemove}>
            Permanently Remove
          </button>
        )}
        <button
          className="button primary-button"
          type="submit"
          disabled={isSaving}
        >
          {isSaving
            ? 'Saving...'
            : nameReadonly
            ? 'Update Feature'
            : 'Add Feature'}
        </button>
      </div>
      {errorMessage && (
        <span className="editor-error">
          <FontAwesomeIcon icon={faExclamationTriangle} />
          {errorMessage}
        </span>
      )}
    </form>
  );
};
