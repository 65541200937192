import React, { useContext } from 'react';
import { IPluginListingNew } from '../../services/appList.service';
import { ProjectContext } from '../projectDashboard/projectDashboard.context';
import { Sidebar } from '../sidebar/sidebar.comp';

import './newApps.scss';

export const NewApps = () => {
  const { apps } = useContext(ProjectContext);
  const pluginList = apps.filter((app) => app.status === 'published');

  const filteredPlugins = pluginList
    .map((plugin: IPluginListingNew) => ({
      ...plugin,
      tags: plugin.tags.map(
        (tag) => tag?.split?.('-')?.[0]?.toLowerCase() || [],
      ),
    }))
    .filter((p) => p.tags.includes('new!'));

  return (
    <>
      <Sidebar />
      <section className="new-apps main-section">
        <div className="inner">
          <header>
            <hgroup className="page-titles">
              <h2>What's New</h2>
            </hgroup>
          </header>
          <div className="cards">
            {(filteredPlugins || []).length > 0 &&
              filteredPlugins.map((p) => (
                <div className="card" key={`new-apps-${p.componentType}`}>
                  <div className="img-container">
                    <img src={p.promotionImage} alt={p.name} />
                  </div>
                  <div className="details">
                    <h3>{p.name}</h3>
                    <p>{p.teaser}</p>
                    <a
                      href={`/${p.slug}/editor`}
                      className="button primary-button"
                    >
                      Try Now!
                    </a>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </section>
    </>
  );
};
