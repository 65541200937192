import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { User } from '@commonninja/commonninja-styleguide-react/lib/esm/components/internal';
import {
  loggedIn,
} from '@commonninja/commonninja-styleguide-react/lib/esm/actions';

import commonNinjaPlaceholder from '../../../../assets/commoninja-placeholder.png';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import './appDeveloperMenu.scss';

interface IDeveloperMenu {
  inMenu?: boolean;
  className?: string;
  menuLinks: {
    name: string;
    url: string;
    icon: IconProp;
    target?: '_blank' | '_self' | '_top';
  }[];
  failedToAuthenticateCallback?: () => void;
}

export function AppDeveloperMenu({
  menuLinks,
  inMenu = false,
  className = '',
  failedToAuthenticateCallback,
}: IDeveloperMenu) {
  const baseUrl = process.env.REACT_APP_SERVICE_URL || '';
  const { user } = useSelector((state: any) => ({ user: state.user }));
  const dispatch = useDispatch();

  const handleLogin = (user: any) => {
    dispatch(loggedIn(user));
  };
  const handleLogout = () => {
    window.location.href = `${baseUrl}/logout`;
    // dispatch(logout());
    // window.setTimeout(() => {
    //   dispatch(logout());
    // }, 500);
  };

  return (
    <div className={`developer-menu ${inMenu ? 'in-menu' : ''} ${className}`}>
      <div className="developer-menu-content">
        <User
          user={{
            ...user,
            thumbnail: user.thumbnail || commonNinjaPlaceholder,
          }}
          userMenuLinks={menuLinks}
          postLoginCallback={handleLogin}
          postLogoutCallback={handleLogout}
          failedToAuthenticateCallback={failedToAuthenticateCallback}
        />
      </div>
    </div>
  );
}
