import { Popup } from '@commonninja/commonninja-styleguide-react';
import React, { useContext, useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { DeveloperContext } from '../../developer.context';

import './appSubmenu.scss';
import { NewAppPopup } from './newAppPopup.comp';

export function AppSubmenu() {
  const [submenuOpen, setSubmenuOpen] = useState(false);
  const [newAppPopupOpen, setNewAppPopupOpen] = useState(false);
  const { activeApp, setActiveApp, apps } = useContext(DeveloperContext);

  const submenuRef = useRef<HTMLDivElement>(null);
  function clickedOutside(event: any) {
    if (submenuRef.current && !submenuRef.current.contains(event.target)) {
      setSubmenuOpen(false);
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', clickedOutside);
    return () => {
      document.removeEventListener('mousedown', clickedOutside);
    };
  }, []);

  return (
    <section
      className={`app-submenu ${submenuOpen ? 'open' : ''}`}
      ref={submenuRef}
    >
      <div
        className="submenu-toggler"
        onClick={() => setSubmenuOpen(!submenuOpen)}
      >
        <div className="selected-app">
          <h3>{activeApp.name}</h3>
        </div>
        <div className="submenu-icon-wrapper">
          <i className="submenu-icon"></i>
        </div>
      </div>
      {submenuOpen && (
        <ul className="submenu-list">
          {apps
            // .filter((app) => app.appId !== activeApp.appId)
            .map((app) => (
              <li
                className={`submenu-item ${
                  app.appId === activeApp.appId ? 'selected' : ''
                }`}
                key={app.appId}
              >
                <Link
                  to={`/developer/apps/${app.appId}`}
                  onClick={() => setActiveApp(app)}
                >
                  {app.name}
                </Link>
              </li>
            ))}

          <li className="submenu-add-item">
            <span
              onClick={() => {
                setNewAppPopupOpen(true);
                setSubmenuOpen(false);
              }}
            >
              New app
            </span>
          </li>
        </ul>
      )}
      <Popup
        closeCallback={() => setNewAppPopupOpen(false)}
        show={newAppPopupOpen}
        className="developer-page app-name-popup"
      >
        <NewAppPopup />
      </Popup>
    </section>
  );
}
