import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { appList, IPluginListingNew } from '../../services/appList.service';
import { IProject } from '../projects/projects.comp';
import { projectService } from './projectDashboard.service';

const emptyProject = (): IProject => ({
  name: '',
  projectId: '',
  slug: '',
  userId: '',
  userProjectRole: '',
  users: [],
  totalComponents: 0,
});

// const emptyPlan = (): IPlan => ({
//   appId: '',
//   currency: '',
//   description: '',
//   features: {},
//   isActive: false,
//   isFree: true,
//   meta: {},
//   name: 'Free',
//   planId: '',
//   pricingVariants: [],
//   trialCount: 0,
//   trialPeriod: 'day',
// });

const emptyPlanFeatures = (): TPlanFeatures => ({
  websites: 1,
  collaborators: 0,
});

type TPlanFeatures = {
  websites: number;
  collaborators: number;
  [key: string]: any;
};

interface IProjectDashboardContext {
  apps: IPluginListingNew[];
  setApps: () => void;
  getApps: () => Promise<void>;
  projects: IProject[];
  setProjects: (nextProjects: IProject[]) => void;
  activeProject: IProject;
  setActiveProject: (app: null | IProject) => void;
  loading: boolean;
  setLoading: (loading: boolean) => void;
  hasSharedWidgets: boolean;
  setHasSharedWidgets: (hasSharedWidgets: boolean) => void;
  userPlanFeatures: TPlanFeatures;
  setUserPlanFeatures: (nextPlanFeatures?: TPlanFeatures) => void;
  getUserPlanFeatures: () => Promise<void>;
}

export const ProjectContext = React.createContext<IProjectDashboardContext>({
  apps: [],
  setApps: () => {},
  getApps: async () => {},
  projects: [],
  hasSharedWidgets: false,
  setHasSharedWidgets: () => {},
  setProjects: () => {},
  loading: false,
  setLoading: () => {},
  activeProject: emptyProject(),
  setActiveProject: () => {},
  userPlanFeatures: emptyPlanFeatures(),
  setUserPlanFeatures: () => {},
  getUserPlanFeatures: async () => {},
});

export const ProjectDashboardContextProvider = (props: any) => {
  const setProjects = (nextProjects: IProject[]) => {
    setState((prevState) => ({
      ...prevState,
      projects: nextProjects,
    }));
  };

  const setHasSharedWidgets = (nextHasSharedWidgets: boolean) => {
    setState((prevState) => ({
      ...prevState,
      hasSharedWidgets: nextHasSharedWidgets,
    }));
  };

  const setActiveProject = (project: null | IProject) => {
    setState((prevState) => ({
      ...prevState,
      activeProject: project || emptyProject(),
    }));
  };

  const setLoading = (nextLoading: boolean) => {
    setState((prevState) => ({ ...prevState, loading: nextLoading }));
  };

  const setApps = (nextApps: IPluginListingNew[] = []) => {
    setState((prevState) => ({
      ...prevState,
      apps: nextApps,
    }));
  };

  const getApps = async () => {
    try {
      const pluginList = await appList.get();
      setApps(pluginList);
    } catch (e) {
      toast.dismiss();
      toast.error((e as Error).message);
    }
  };

  const setUserPlanFeatures = (nextUserPlanFeatures?: TPlanFeatures) => {
    setState((prevState) => ({
      ...prevState,
      userPlanFeatures: nextUserPlanFeatures || emptyPlanFeatures(),
    }));
  };

  const getUserPlanFeatures = async () => {
    try {
      const data = await projectService.getPlanFeatures('bundles');

      setUserPlanFeatures({
        ...data,
        collaborators: data.collaborators || 1,
        websites: data.websites || 1,
      });
    } catch (e) {
      toast.dismiss();
      toast.error((e as Error).message);
    }
  };

  const initState: IProjectDashboardContext = {
    apps: [],
    setApps,
    getApps,
    projects: [],
    setProjects,
    hasSharedWidgets: false,
    setHasSharedWidgets,
    loading: false,
    setLoading,
    activeProject: emptyProject(),
    setActiveProject,
    userPlanFeatures: emptyPlanFeatures(),
    setUserPlanFeatures,
    getUserPlanFeatures,
  };

  const [state, setState] = useState(initState);

  return (
    <ProjectContext.Provider value={state}>
      {props.children}
    </ProjectContext.Provider>
  );
};
