const nindoApiUrl = process.env.REACT_APP_NINDO_SERVICE_URL || '';

class ProjectService {
  public cache: {
    [key: string]: {
      [key: string]: any;
    };
  } = {};

  public async getPlanFeatures(serviceName: string, projectId: string = '', cached?: boolean) {
    if (cached && this.cache[serviceName]) {
      return this.cache[serviceName];
    }

    if (!serviceName) {
      throw new Error('Service name is missing.');
    }

    const planFeaturesReq = await fetch(
      `${nindoApiUrl}/api/v1/user/planFeatures/?serviceName=${serviceName}&projectId=${projectId}`,
      {
        credentials: 'include',
      }
    );

    const json = await planFeaturesReq.json();

    const { success, message, data } = json;

    if (!success) {
      throw new Error(message);
    }

    this.cache[serviceName] = data || {};

    return data;
  }

  public getViewsLimit(serviceName: string): number {
    const key = 'numberOfViewsPerMonth';
    const minimum = 300;
    const serviceLimit = this.cache[serviceName]?.[key] || minimum;
    const bundlesLimit = this.cache.bundles?.[key];

    if (typeof bundlesLimit === 'number' && bundlesLimit > serviceLimit) {
      return bundlesLimit;
    }

    return serviceLimit;
  }

  public getPlanName(serviceName: string): string {
    const key = 'planName';
    const defaultPlan = 'Free';
    const servicePlan = this.cache[serviceName]?.[key] || defaultPlan;
    const bundlesPlan = this.cache.bundles?.[key] || defaultPlan;

    if (bundlesPlan !== 'Free') {
      return bundlesPlan;
    }

    return servicePlan;
  }
}

export const projectService = new ProjectService();
