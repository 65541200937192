import React, { useEffect } from 'react';

export const PostLogin = () => {
  useEffect(() => {
    // Post message to opener if it's an iframe
    window.opener.postMessage({
      type: 'COMMONNINJA_POST_SOCIAL_LOGIN',
      isNew: window.location.search.includes('isNew=true')
    }, '*');

    window.close();
  }, []);

  return (
    <></>
  );
}