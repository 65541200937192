import { useRef } from 'react';

type CopyFn = (e: any) => Promise<boolean>; // Return success

function useCopyToClipboard(duration = 1000): [CopyFn] {
  const timeoutRef = useRef<number>();

  function removeClassAndClearTimeout(e: any) {
    e.target.classList.remove('copied');
    window?.clearTimeout(timeoutRef.current);
    timeoutRef.current = undefined;
  }

  const copy: CopyFn = async (e) => {
    e.stopPropagation();
    if (!navigator?.clipboard) {
      console.warn('Clipboard not supported');
      return false;
    }

    try {
      if (e.target?.classList?.contains('copied') || timeoutRef.current) {
        return true;
      }
      if (e?.target) {
        await navigator.clipboard.writeText(e.target.innerText);
        e.target?.classList?.add('copied');
        timeoutRef.current = window?.setTimeout(
          removeClassAndClearTimeout,
          duration,
          e
        );
        return true;
      }
      return false;
    } catch (error) {
      console.warn('Copy failed', error);
      return false;
    }
  };

  return [copy];
}

export default useCopyToClipboard;
