import React, { useEffect, useRef } from "react";
import { Loader } from "@commonninja/commonninja-styleguide-react";
// import { error, success } from "react-notification-system-redux";

import { DeveloperInput } from "../developer/newDashboardComponents/developerInput.comp";

import "./apiAccess.scss";

export const APIAccess = () => {
  const [loading, setLoading] = React.useState(true);
  const [apiKey, setApiKey] = React.useState("");
  const ref: any = useRef();

  async function getAPIKey() {
    setLoading(true);
    setApiKey("");

    try {
    } catch (e) {}
    
    setLoading(false);
  }

  useEffect(() => {
    getAPIKey();
  }, []);

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <div className="api-access content-wrapper">
        <div className="inner-wrapper">
          {apiKey ? (
            <DeveloperInput
              name="apiKey"
              type="text"
              label="API Key"
              title="API Key"
              value={"asdasd"}
              readOnly
              props={{
                ref,
                onFocus: () => {
                  if (ref.current) {
                    ref.current.select();
                  }
                },
              }}
            />
          ) : (
            <>
              <p>Your account currently does not have an associated API key.</p>
              <p>
                Discover more about the Common Ninja API and consider applying
                for our Developer Program via our{" "}
                <a
                  href="https://www.commoninja.com/platform/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  official documentation pages
                </a>
                .
              </p>
            </>
          )}
        </div>
      </div>
    </>
  );
};
