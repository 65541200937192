import Highcharts from 'highcharts';
import React, { useEffect, useRef, useState } from 'react';
import { v4 as uuid } from 'uuid';

interface IDashboardChartProps {
  options: Highcharts.Options;
}
export const DashboardChart = ({ options }: IDashboardChartProps) => {
  const chartRef = useRef<HTMLDivElement>(null);
  const [chartId] = useState(uuid());
  const [chart, setChart] = useState<Highcharts.ChartObject>();
  const debounceTimer = useRef<any>(null);
  const [isResizing, setIsResizing] = useState(false);

  function createChart() {
    if (chartId) {
      const newChart = Highcharts.chart(chartId, options);
      setChart(newChart);
    }
  }

  function updateChart() {
    if (chartRef.current) {
      chart?.setSize(
        chartRef.current?.clientWidth,
        chartRef.current?.clientHeight
      );
      setIsResizing(false);
    }
  }

  function debounceResize() {
    if (debounceTimer.current) {
      clearTimeout(debounceTimer.current);
    }
    setIsResizing(true);
    debounceTimer.current = setTimeout(updateChart, 100);
  }

  useEffect(() => {
    createChart();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options]);

  useEffect(() => {
    if (window) {
      window.addEventListener('resize', debounceResize);
    }
    return () => {
      window.removeEventListener('resize', debounceResize);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div
      style={{ display: isResizing ? 'none' : 'block' }}
      className="dashboard-chart"
      id={chartId}
      ref={chartRef}
    />
  );
};
