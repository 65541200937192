import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { error } from "react-notification-system-redux";

import { SkeletonTable } from "../skeletonTable/skeletonTable.comp";
import Embed from "../embed/embed.comp";

import "./payments.scss";

const nindoApiUrl = process.env.REACT_APP_NINDO_SERVICE_URL || "";

class Payments extends Embed {
  constructor(props: any) {
    super(props);

    this.state = {
      loading: false,
      items: [],
    };
  }

  componentDidMount() {
    this.loadItems();
    this.handleEmbedIfNeeded(true);
  }

  loadItems = async () => {
    this.setState({
      loading: true,
      items: [],
    });

    let localError = null;

    try {
      const req = await fetch("/api/payment");
      const itemsRes = await req.json();

      if (itemsRes.success) {
        const { docs } = itemsRes.data;
        
        const platformReq = await fetch(
          `${nindoApiUrl}/nindo/api/getUserPayments`,
          { credentials: "include" }
        );
        const platformRes = await platformReq.json();

        const unifiedPayments: any[] = [
          ...docs,
          ...(platformRes?.data || []),
        ];
        
        this.setState({
          items: unifiedPayments,
        });
      } else {
        localError = itemsRes.data[0]
          ? itemsRes.data[0].msg
          : "Could not load payments";
      }
    } catch (e) {
      localError = "Could not load payments";
    }

    if (localError) {
      this.props.dispatch(
        error({
          message: localError,
          autoDismiss: 5,
          position: "tc",
        })
      );
    }

    this.setState({ loading: false });
  };

  render() {
    const { items, loading } = this.state as any;

    return loading ? (
      <SkeletonTable rows={10} cols={6} />
    ) : (
      <React.Fragment>
        {!items.length ? (
          <p className="center">Cannot find payments history.</p>
        ) : (
          <div className="content-wrapper">
            <div className="data-table-wrapper">
              <table className="data-table">
                <thead>
                  <tr>
                    <th>Subscription ID</th>
                    <th>Transaction ID</th>
                    <th>Plan Name</th>
                    <th>Payment Amount</th>
                    <th>Payment Date</th>
                    <th>Invoice</th>
                  </tr>
                </thead>
                <tbody>
                  {items.map((item: any) => (
                    <tr
                      className="payment"
                      key={`payment_${item.transactionId}_${item.subscriptionId}`}
                    >
                      <td>{item.subscriptionId}</td>
                      <td>{item.transactionId}</td>
                      <td>{item.planName}</td>
                      <td>${item.amount}</td>
                      <td>{item.created}</td>
                      <td className="center">
                        {item.invoiceUrl ? (
                          <a
                            href={item.invoiceUrl}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Download
                          </a>
                        ) : (
                          <span>Not available.</span>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: any) => ({
  user: state.user,
});

export default connect(mapStateToProps)(withRouter(Payments as any));
