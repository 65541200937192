import React, { useRef, useState, useEffect } from 'react';
import { FormRow } from '@commonninja/commonninja-styleguide-react';
import { H3 } from '@commonninja/commonninja-styleguide-react/lib/esm/components/internal';

import { IAuthProps } from './auth.types';
import { AuthLayout } from './authLayout.comp';
import { AuthForm } from './authForm.comp';
import { authService } from './auth.service';
import { useParams } from 'react-router-dom';

export const ResetPassword = ({ embedMode }: IAuthProps) => {
  const firstInput: any = useRef();
  const { token }: any = useParams();
  const [postSubmission, setPostSubmission] = useState<boolean>(false);
  const [formState, setFormState] = useState<any>({
    password: '',
    token
  });

  function formFieldChanged(value: string = '') {
    setFormState({
      ...formState,
      password: value
    });
  }

  function renderPostSubmissionMessage() {
    return (
      <>
        <H3>Great Success!</H3>
        <p className="body-1 post-message">
          Your password has been successfully reset. Next step - login to your account with the new password.
        </p>
        <div className="buttons-wrapper center">
          <a className="primary" href={`${embedMode ? '/embed' : ''}/login`}>Back to Login Page</a>
        </div>
      </>
    );
  }

  function renderForm() {
    return (
      <>
        <H3>Reset Password</H3>
        <p className="body-1">Enter a new password</p>
        <AuthForm
          name="reset"
          mainButtonText="Save"
          embedMode={embedMode}
          authRequest={(captchaToken) => authService.resetPassword(formState, captchaToken, token)}
          firstInput={firstInput}
          successCallback={() => setPostSubmission(true)}
        >
          <FormRow flow="column">
            <input 
              type="password" 
              ref={firstInput} 
              value={formState.password} 
              onChange={(e) => formFieldChanged(e.target.value)} 
              minLength={4}
              maxLength={60}
              required
              placeholder='Enter a new password'
            />
          </FormRow>
        </AuthForm>
      </>
    );
  }

  useEffect(() => {
    document.title = `Reset Password | Common Ninja`;
  }, []);
  
  return (
    <AuthLayout embedMode={embedMode}>
      {
        postSubmission ?
        renderPostSubmissionMessage() : 
        renderForm()
      }
    </AuthLayout>
  );
}