import React, { useContext, useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { DeveloperInput } from "../developer/newDashboardComponents/developerInput.comp";
import { SkeletonComponent } from "../component/component.comp";
import defaultImage from "../../assets/defualt-project-thumb.png";
import AvatarGroup from "@atlaskit/avatar-group";
import { Sidebar } from "../sidebar/sidebar.comp";
import {
  IUser,
  Popup,
} from "@commonninja/commonninja-styleguide-react";
import { FaChevronRight, FaPlus } from "react-icons/fa";
import { CommonNinjaWidget } from 'commonninja-react';
import logo from "../../assets/logo-face.svg";
import { ProjectContext } from "../projectDashboard/projectDashboard.context";
import { toast } from "react-toastify";
import { ProjectUpgradePopup } from "../projectUpgradePopup/projectUpgradePopup.comp";

import "./projects.scss";

export interface IProject {
  projectId: string;
  userId: string;
  name: string;
  slug: string;
  domains?: string[];
  users: IUser[];
  description?: string;
  thumbnail?: string;
  userProjectRole: "reseller" | "owner" | "admin" | "editor" | "viewer" | "";
  _id?: string;
  totalComponents: number;
}

export const Projects = ({
  type = "personal",
}: {
  type?: "shared" | "personal";
}) => {
  const {
    projects,
    setProjects,
    loading,
    setLoading,
    setHasSharedWidgets,
    userPlanFeatures,
  } = useContext(ProjectContext);
  const [oldComponentsExist, setOldComponentsExist] = useState(true);
  const [openPopup, setOpenPopup] = useState(false);
  const [upgradePopupOpen, setUpgradePopupOpen] = useState(false);
  const [totalUncategorizedComponents, setTotalUncategorizedComponents] =
    useState(0);
  const [newProjectName, setNewProjectName] = useState("");
  const [searchInput, setSearchInput] = useState("");
  const history = useHistory();
  const { pathname } = useLocation();
  const isSharedProjects = (pathname || "")?.includes("shared-projects");

  const renderLoader = () => (
    <div className="components-wrapper loading">
      {Array.from(new Array(9)).map((c, idx) => (
        <SkeletonComponent key={`comp_skel_${idx}`} />
      ))}
    </div>
  );

  const onSearch = (e: any) => {
    e.preventDefault();
    getProjects(searchInput);
  };

  const onSubmit = async (e: any) => {
    e.preventDefault();
    setLoading(true);
    try {
      if (projects.length >= userPlanFeatures.websites) {
        setUpgradePopupOpen(true);
        setOpenPopup(false);
        setLoading(false);
        return;
      }

      const req = await fetch(`/api/project`, {
        method: "POST",
        body: JSON.stringify({ name: newProjectName }),
      });
      const { success, message, data } = await req.json();

      if (!success) {
        throw new Error(message);
      }

      history.push(`/user/dashboard/projects/${data.projectId}/widgets`);
      setProjects([...projects, data]);
      setOpenPopup(false);
    } catch (e) {
      toast.dismiss();
      toast.error((e as Error).message);
    }
    setLoading(false);
  };

  const getProjects = async (search?: string) => {
    setLoading(true);
    try {
      const req = await fetch(
        `/api/project${type === "shared" ? `/shared` : ""}${
          search ? `?search=${search}` : ""
        }`
      );
      const { data } = await req.json();
      setProjects(data?.docs || []);
    } catch (e) {
      toast.dismiss();
      toast.error((e as Error).message);
    }
    setLoading(false);
  };

  const checkIfUncategorizedComponentsExist = async () => {
    try {
      const req = await fetch(`/api/project/uncategorized/component?limit=1`);
      const { data } = await req.json();
      if ((data?.docs || []).length > 0) {
        setTotalUncategorizedComponents(data?.total || 0);
        setOldComponentsExist(true);
      } else {
        setOldComponentsExist(false);
      }
    } catch (e) {
      setOldComponentsExist(false);
    }
  };

  const checkIfSharedWidgetsExist = async () => {
    try {
      const req = await fetch(`/api/component/shared`);
      const { data } = await req.json();

      if ((data?.docs || []).length > 0) {
        setHasSharedWidgets(true);
      } else {
        setHasSharedWidgets(false);
      }
    } catch (e) {
      setHasSharedWidgets(false);
    }
  };

  useEffect(() => {
    getProjects();
    checkIfUncategorizedComponentsExist();
    checkIfSharedWidgetsExist();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);

  const cardGridNormalSize =
    (oldComponentsExist && !projects.length) ||
    (!oldComponentsExist && projects.length === 1) ||
    (projects.length === 1 && isSharedProjects);

  return (
    <>
      <Sidebar />
      <section className="projects main-section">
        <div className="inner">
          <header>
            <hgroup className="page-titles">
              <h2>{type === "personal" ? "My" : "Shared"} Projects</h2>
            </hgroup>
            {(projects || []).length > 4 && (
              <form className="search-components" onSubmit={onSearch}>
                <DeveloperInput
                  name="searchType"
                  title="Search by name"
                  placeholder="Search by name..."
                  value={searchInput}
                  handleChange={(e) => setSearchInput(e.target.value)}
                />
              </form>
            )}
          </header>
          {loading ? (
            renderLoader()
          ) : (
            <div
              className={`projects-wrapper ${
                cardGridNormalSize ? "less-than-2" : ""
              }`}
            >
              {oldComponentsExist && type !== "shared" && (
                <div className="project">
                  <div className="top">
                    <img
                      onClick={() =>
                        history.push(
                          `/user/dashboard/projects/uncategorized/widgets`
                        )
                      }
                      src={defaultImage}
                      alt="uncategorized project cover"
                      className="thumbnail"
                    />
                    <div className="details">
                      <div className="title-wrapper">
                        <h3
                          onClick={() =>
                            history.push(
                              `/user/dashboard/projects/uncategorized/widgets`
                            )
                          }
                        >
                          {" "}
                          Uncategorized Widgets
                        </h3>
                        <p>{totalUncategorizedComponents || 0} widgets</p>
                      </div>
                    </div>
                  </div>
                  <Link
                    className="manage-project button transparent"
                    to={`/user/dashboard/projects/uncategorized/widgets`}
                  >
                    Manage Project
                    <FaChevronRight />
                  </Link>
                </div>
              )}
              {projects.length > 0 &&
                projects.map((project: IProject) => (
                  <div className="project" key={project.projectId}>
                    <div className="top">
                      <img
                        onClick={() =>
                          history.push(
                            `/user/dashboard/projects/${project.projectId}/widgets`
                          )
                        }
                        className="thumbnail"
                        src={project.thumbnail || defaultImage}
                        alt={project.name}
                      />
                      <div className="details">
                        <div className="title-wrapper">
                          <h3
                            onClick={() =>
                              history.push(
                                `/user/dashboard/projects/${project.projectId}/widgets`
                              )
                            }
                          >
                            {project.name}
                          </h3>
                          <p>{project?.totalComponents || 0} widgets</p>
                        </div>
                        <div className="avatar-group-wrapper">
                          <AvatarGroup
                            appearance="stack"
                            maxCount={4}
                            size="medium"
                            data={
                              project?.users?.map((user) => ({
                                name: user?.fullName || "",
                                src: user?.thumbnail || "",
                              })) || []
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <Link
                      className="manage-project button transparent"
                      to={`/user/dashboard/projects/${project.projectId}/widgets`}
                    >
                      Manage Project
                      <FaChevronRight />
                    </Link>
                  </div>
                ))}
              {type === "personal" && (
                <div
                  className="project create"
                  onClick={() => setOpenPopup(true)}
                >
                  <button className="button">
                    <FaPlus size={18} />
                  </button>
                </div>
              )}
            </div>
          )}
        </div>
        {type === "shared" && !projects.length && (
          <div className="no-results center">
            <img src={logo} alt="common ninja icon" />
            <p>
              {searchInput
                ? `No results found for ${searchInput}`
                : `It looks like you don't have any shared projects yet.`}
            </p>
          </div>
        )}
        <div className="cn-announcements">
          <CommonNinjaWidget widgetId="1ba73502-56e4-48da-8388-012a8f6e47ee" />
        </div>
      </section>
      <Popup
        show={openPopup}
        closeCallback={() => {
          setOpenPopup(false);
          setNewProjectName("");
        }}
        className="developer-page popup-warn collection-popup"
      >
        <header>
          <h3>New Project</h3>
        </header>
        <form onSubmit={onSubmit}>
          <div className="input-container">
            <label htmlFor="name">Project Name</label>
            <div>
              <input
                type="text"
                name="name"
                title="Project Name"
                placeholder="Project Name..."
                value={newProjectName}
                onChange={(e) => setNewProjectName(e.target.value)}
              />
            </div>
          </div>
          <div className="popup-warn-buttons">
            <button className="button primary-button">Create Project</button>
          </div>
        </form>
      </Popup>
      <ProjectUpgradePopup
        title="Upgrade your plan now and get more projects!"
        text="By upgrading your current plan you’ll be able to add more projects to your account, aditionally you’ll be getting more benefits such as more websites and collaborators."
        buttonText="Upgrade Now!"
        type="project"
        setShowPopup={setUpgradePopupOpen}
        showPopup={upgradePopupOpen}
      />
    </>
  );
};
