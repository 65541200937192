import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { H3 } from '@commonninja/commonninja-styleguide-react/lib/esm/components/internal';
import { IHttpResult, Loader } from '@commonninja/commonninja-styleguide-react';
import { AuthLayout } from './authLayout.comp';
import { authService } from './auth.service';

interface IInvitationConfirmation {
  invitationType?: 'project' | 'component';
  embedMode?: boolean;
}

export const InvitationConfirmation = ({ embedMode, invitationType = 'component' }: IInvitationConfirmation) => {
  const [postSubmission, setPostSubmission] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const { token } = useParams() as any;

  async function confirm() {
    setError('');

    try {
      const result: IHttpResult = await authService.invitationApproval(token, invitationType);

      // Throw error if needed
      if (!result.success) {
        throw new Error(result.message);
      }

      setPostSubmission(true);

      window.setTimeout(() => {
        if (invitationType === 'project') {
          window.location.href = '/shared-projects';
        } else {
          window.location.href = '/shared-widgets';
        }
      }, 5000);
    } catch (e) {
      setError((e as Error).message);
    }
  }

  function renderPostSubmissionMessage() {
    return (
      <>
        <H3>Great Success!</H3>
        <p className="body-1 post-message">Sweet! Your invitation to collaborate has been confirmed.</p>
        <p className="body-1">Hold tight, <em>redirecting to dashboard</em>...</p>
      </>
    );
  }

  function renderForm() {
    return (
      <>
        <H3>Confirming Invitation</H3>
        {
          !error ?
            <Loader innerColor="#D3D3D4" /> :
            <p className="body-1 post-message center error">{error}</p>
        }
        <div className="buttons-wrapper center">
          <a className="primary" href="/login">Back to Login Page</a>
        </div>
      </>
    );
  }

  useEffect(() => {
    document.title = `Accept Invitation | Common Ninja`;
    confirm();
    // eslint-disable-next-line
  }, []);

  return (
    <AuthLayout embedMode={embedMode}>
      {
        postSubmission ?
          renderPostSubmissionMessage() :
          renderForm()
      }
    </AuthLayout>
  );
};