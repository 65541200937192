import { HttpService } from "@commonninja/commonninja-styleguide-react";

const serviceUrl = process.env.REACT_APP_NINDO_SERVICE_URL || '';

export class AssetService extends HttpService {
  public baseApiUrl: string = `${serviceUrl}/api/v1`;
  public platform: string | undefined;

  constructor(platform?: string) {
    super();
    this.platform = platform;
  }

  private getUrlExtension() {
    return this.platform ? "/" + this.platform : "";
  }

  async get({
    q = "",
    page = 1,
    limit = 12,
    assetType = "",
    projectId = "",
  }: {
    q?: string;
    page?: number;
    limit?: number;
    assetType?: string;
    projectId?: string;
  }) {
    return await this.makeRequest(
      `${
        this.baseApiUrl
      }${this.getUrlExtension()}/asset?q=${q}&projectId=${projectId}&assetType=${assetType}&limit=${limit}&page=${page}&${
        this.queryParams
      }`
    );
  }

  public async update(assetId: string, name: string) {
    return await this.makeRequest(
      `${this.baseApiUrl}${this.getUrlExtension()}/asset/${assetId}?${
        this.queryParams
      }`,
      {
        method: "put",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ name }),
      }
    );
  }

  public async delete(assetId: string) {
    return await this.makeRequest(
      `${this.baseApiUrl}${this.getUrlExtension()}/asset/${assetId}?${
        this.queryParams
      }`,
      {
        method: "delete",
      }
    );
  }
}
