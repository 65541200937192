import React, { useState } from 'react';
import priceTag from '../../assets/price-tag.svg';
import speedMeter from '../../assets/speedometer.svg';
import browser from '../../assets/browser.svg';
import clipboard from '../../assets/clipboards.svg';
import forbidden from '../../assets/forbidden.svg';
import feedbackService from '../../services/feedback.service';
import { toast } from 'react-toastify';
import { saveAs } from 'file-saver';

import starIcon1 from '../../assets/star-icon-1.png';
import starIcon2 from '../../assets/star-icon-2.png';
import starIcon3 from '../../assets/star-icon-3.png';
import starIcon4 from '../../assets/star-icon-4.png';
import starIcon5 from '../../assets/star-icon-5.png';
import starIcon6 from '../../assets/star-icon-6.png';

interface IFeedbackProps {
  subscriptionTypeId?: string;
  subscriptionId?: string;
}

export const Feedback = ({
  subscriptionTypeId,
  subscriptionId,
}: IFeedbackProps) => {
  const [reason, setReason] = useState('');
  const [userAnswer, setUserAnswer] = useState('');
  const [iconsVisible, setIconsVisible] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  // const finalSubscriptionId = subscriptionId ? subscriptionId : new URLSearchParams(window.location.search).get('subscriptionId');
  // const finalSubscriptionTypeId = subscriptionTypeId ? subscriptionTypeId : new URLSearchParams(window.location.search).get('subscriptionTypeId');
  const token = new URLSearchParams(window.location.search).get('token');

  async function submitFeedback() {
    try {
      if (!token) {
        toast.error('Oops, something went wrong. Please try again.');
        return;
      }
      toast.dismiss();
      toast.info('Sending feedback...');
      const data = await feedbackService.postFeedback(
        reason,
        userAnswer,
        token,
      );
      toast.dismiss();
      if (data.success) {
        setIconsVisible(true);
        setIsSubmitted(true);
        setReason('');
        setUserAnswer('');
      } else {
        throw new Error(data.message);
      }
    } catch (e) {
      toast.dismiss();
      toast.error((e as Error).message);
    }
  }

  function downloadImage() {
    saveAs(
      'https://website-assets.commoninja.com/distribution/1645966229882_shuriken_4_1.png',
      'ninja-star-1.png',
    );
    saveAs(
      'https://website-assets.commoninja.com/distribution/1645966385738_shuriken_3_1.png',
      'ninja-star-2.png',
    );
    saveAs(
      'https://website-assets.commoninja.com/distribution/1645966459017_shuriken_2_1.png',
      'ninja-star-3.png',
    );
    saveAs(
      'https://website-assets.commoninja.com/distribution/1645966490235_shuriken_1_1.png',
      'ninja-star-4.png',
    );
    saveAs(
      'https://website-assets.commoninja.com/distribution/1645966513711_star_1.png',
      'ninja-star-5.png',
    );
    saveAs(
      'https://website-assets.commoninja.com/distribution/1645966530164_shuriken_5.png',
      'ninja-star-6.png',
    );
  }

  function getReasonButtons() {
    return (
      <div className="reasons-cards-container">
        <div
          onClick={() => setReason('price')}
          className={`card ${reason === 'price' ? 'active' : ''}`}
        >
          <h4>Price</h4>
          <img src={priceTag} alt="price tag" />
        </div>
        <div
          onClick={() => setReason('hard-to-use')}
          className={`card ${reason === 'hard-to-use' ? 'active' : ''}`}
        >
          <h4>Difficulty of use</h4>
          <img src={speedMeter} alt="speedometer" />
        </div>
        <div
          onClick={() => setReason('missing-functionality')}
          className={`card ${
            reason === 'missing-functionality' ? 'active' : ''
          }`}
        >
          <h4>Missing Functionality</h4>
          <img src={browser} alt="browser" />
        </div>
        <div
          onClick={() => setReason('dont-need')}
          className={`card ${reason === 'dont-need' ? 'active' : ''}`}
        >
          <h4>I don’t need it anymore</h4>
          <img src={forbidden} alt="forbidden" />
        </div>
        <div
          onClick={() => setReason('other')}
          className={`card ${reason === 'other' ? 'active' : ''}`}
        >
          <h4>Other</h4>
          <img src={clipboard} alt="clipboard" />
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="feedback-container">
        {!isSubmitted ? (
          <>
            <h3>Help Us Improve</h3>
            <p>
              Would you kindly indicate the reason for cancelling your
              subscription?
            </p>
            {getReasonButtons()}
            {reason && (
              <div className="form-wrapper">
                <p>If you don’t mind sharing - what was the problem?</p>
                <textarea
                  value={userAnswer}
                  onChange={(e) => setUserAnswer(e.target.value)}
                  cols={70}
                  rows={3}
                  minLength={4}
                  required
                />
              </div>
            )}
            <button onClick={submitFeedback} className="submit-btn">
              Submit Your Feedback
            </button>
          </>
        ) : (
          <>
            <h3 className="submited">Thank You For Your Feedback!</h3>
          </>
        )}
        {(iconsVisible || reason) && (
          <>
            <p className="sad-text">
              While we are truly sad to see you go, we’d like you to accept this
              lovely set of ninja icons for free!
            </p>
            <div className="icons-container">
              <img src={starIcon1} alt="star icon 1" className="icon" />
              <img src={starIcon2} alt="star icon 2" className="icon" />
              <img src={starIcon3} alt="star icon 3" className="icon" />
              <img src={starIcon4} alt="star icon 4" className="icon" />
              <img src={starIcon5} alt="star icon 5" className="icon" />
              <img src={starIcon6} alt="star icon 6" className="icon" />
            </div>
            <button className="download-icons-btn" onClick={downloadImage}>
              Download Icons
            </button>
          </>
        )}
      </div>
    </>
  );
};
