import React from "react";
import "./customPlan.scss";

export const CustomPlan = () => {
  return (
    <div className="custom-plan">
      Our plans don't fit your size?{" "}
      <a
        href="https://commoninja.site/custom-plan"
        target="_blank"
        rel="noreferrer"
      >
        Contact us for a tailor-made one
      </a>
      .
    </div>
  );
};
