import { useEffect, useState } from "react";
import cn from "classnames";
import { IAsset, IAssetFigure } from "../asset.types";
import {
  IMediaManagerToolbarProps,
  MediaManagerToolbar,
} from "../mediaManagerToolbar/mediaManagerToolbar.comp";
import { MediaManagerFilesFigure } from "./mediaManagerFilesFigure/mediaManagerFilesFigure.comp";
import React from "react";
import { Button, Loader } from "@commonninja/commonninja-styleguide-react";

import "./mediaManagerFiles.scss";

interface IMediaManagerFilesProps {
  onSelect?: (media?: IAsset) => void;
  getFilesPromise: (
    q?: string,
    page?: number,
    limit?: number
  ) => Promise<{
    success: boolean;
    message?: string;
    data: {
      items: IAssetFigure[];
      total: number;
    };
  }>;
  toolbarProps: IMediaManagerToolbarProps;
  showActions?: boolean;
}

export const MediaManagerFiles = ({
  toolbarProps,
  getFilesPromise,
  showActions,
}: IMediaManagerFilesProps) => {
  const [query, setQuery] = useState("");
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState<IAssetFigure[]>([]);
  const [paginate, setPaginate] = useState({
    page: 1,
    limit: 25,
    total: 0,
    done: false,
  });

  async function get(reset: boolean = false) {
    if (loading) {
      return;
    }

    if (reset) {
      setItems([]);
    }

    setLoading(true);

    try {
      const page = reset ? 1 : paginate.page;
      const result = await getFilesPromise(query, page, paginate.limit);

      if (!result.success) {
        throw new Error(result.message);
      }

      if (!result.data?.items.length) {
        setPaginate({
          ...paginate,
          done: true,
        });
        setLoading(false);
        return;
      }

      // Set next page
      setPaginate({
        ...paginate,
        page: page + 1,
        total: result.data.total,
        done: result.data.items.length < paginate.limit,
      });

      setItems(
        reset ? [...result.data.items] : [...items, ...result.data.items]
      );
    } catch (e) {}

    setLoading(false);
  }

  function select(item?: IAsset) {
    return;
    // setSelected(item?.guid === selected?.guid ? undefined : item);
  }

  function reload() {
    get(true);
  }

  useEffect(() => {
    reload();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  useEffect(() => {
    get();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <MediaManagerToolbar
        {...toolbarProps}
        onChange={(q = "", localReload: boolean = false) => {
          setQuery(q);

          if (localReload && q === query) {
            reload();
          }
        }}
      />
      <div className="media-manager-content">
        <div className="styled-media-manager-files">
          {items.length > 0 && (
            <>
              <p className="styled-media-manager-total">
                Showing <strong>{paginate.total.toLocaleString()}</strong>{" "}
                result{paginate.total !== 1 ? "s" : ""}.
              </p>
              <div className="media-manager-content-items">
                {items.map((item) => (
                  <MediaManagerFilesFigure
                    key={`media-${item.guid}`}
                    onClick={select}
                    // selected={item.guid === selected?.guid}
                    selected={false}
                    item={item}
                    showActions={showActions}
                  />
                ))}
              </div>
            </>
          )}
          {items.length === 0 && !loading && (
            <p style={{ padding: "40px 20px" }}>
              Could not find any media files.
            </p>
          )}
          {loading && (
            <Loader
              innerColor={"#6881e9"}
              outerColor="#6881e9"
              className={cn({
                "load-more": !paginate.done && items.length > 0,
              })}
            />
          )}
          {!loading && !paginate.done && items.length > 0 && (
            <Button
              style={{ margin: "0 auto 20px" }}
              className="secondary-button"
              onClick={() => get()}
            >
              Load More
            </Button>
          )}
        </div>
        <div
          id="media-manager-filer-reload"
          style={{ display: "none" }}
          onClick={reload}
        ></div>
      </div>
    </>
  );
};
