import React, { useEffect, useState } from "react";
import { FaCheckCircle } from "react-icons/fa";
import cn from "classnames";
import { Button, Popup } from "@commonninja/commonninja-styleguide-react";

import { IPluginListingNew } from "../../../services/appList.service";
import { WidgetCatalog } from "../../widgetCatalog/widgetCatalog.comp";

import "./miniBundlesPopup.scss";

export const MiniBundlesPopup = ({
  popupOpened,
  setPopupOpened,
  initialSelectedApps,
  limit,
  apps,
  onSelect,
  moveToNextPlan,
  allowLimitPass = false,
}: {
  popupOpened: boolean;
  setPopupOpened: (opened: boolean) => void;
  limit: number;
  initialSelectedApps: string[];
  apps: IPluginListingNew[];
  onSelect: (apps: string[]) => void;
  moveToNextPlan?: () => void;
  allowLimitPass?: boolean;
}) => {
  const [selectedApps, setSelectedApps] = useState<string[]>(
    initialSelectedApps ?? []
  );

  function renderError() {
    if (moveToNextPlan) {
      return (
        <>
          The bundle you chose supports {limit} apps, upgrade to get more!{" "}
          <Button onClick={() => moveToNextPlan()}>Click to upgrade</Button>
        </>
      );
    }
    return (
      <span>
        The bundle you chose supports {limit} apps. Need more? Check out our
        {` `}
        <strong>All-in-One plan</strong>.
      </span>
    );
  }

  useEffect(() => {
    setSelectedApps(initialSelectedApps);
  }, [initialSelectedApps]);

  return (
    <Popup
      show={popupOpened}
      closeCallback={() => setPopupOpened(false)}
      className="mini-bundles-app-selection"
    >
      <h2>
        Create your bundle
        <span>
          <b
            className={cn({
              error: selectedApps.length > limit,
            })}
          >
            {selectedApps.length}
          </b>{" "}
          / {limit} apps selected
        </span>
      </h2>
      {selectedApps.length > limit && (
        <p className="error-message">{renderError()}</p>
      )}
      <WidgetCatalog
        standalone
        apps={apps}
        onAppClick={(plugin) => {
          if (selectedApps.includes(plugin.serviceName)) {
            setSelectedApps(
              selectedApps.filter((app) => app !== plugin.serviceName)
            );
          } else {
            if (!allowLimitPass && selectedApps.length >= limit) {
              return;
            }
            setSelectedApps([...selectedApps, plugin.serviceName]);
          }
        }}
        appClassGenerator={(plugin) => {
          return selectedApps.includes(plugin.serviceName) ? "selected" : "";
        }}
        additionalAppElement={(plugin) => (
          <>
            <FaCheckCircle />
            <div className="select-overlay">
              Click to{" "}
              {selectedApps.includes(plugin.serviceName) ? "Remove" : "Select"}
            </div>
          </>
        )}
        additionalFilters={[
          {
            name: `Selected (${selectedApps.length})`,
            filter: (plugin) => {
              return selectedApps.includes(plugin.serviceName);
            },
          },
        ]}
      />
      <footer className="mini-bundles-app-selection-footer">
        <Button
          onClick={() => {
            setPopupOpened(false);
          }}
          color="transparent"
        >
          Cancel
        </Button>
        <Button
          onClick={() => {
            onSelect(selectedApps);
            setPopupOpened(false);
          }}
        >
          Select
        </Button>
      </footer>
    </Popup>
  );
};
