import React, { useContext, useEffect } from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import { toast } from 'react-toastify';

import { AppDashboard } from './appDashboard/appDashboard.comp';
import { DeveloperApps } from './developerApps/developerApps.comp';
import {
  DeveloperContext,
  DeveloperContextProvider,
} from './developer.context';
import { DeveloperAccount } from './developerAccount/developerAccount.comp';
import { developerService } from '../../services/developer.service';

import 'react-toastify/dist/ReactToastify.css';
import './developer.scss';

const DeveloperDashboardWrapper = () => {
  const { path } = useRouteMatch();
  const {
    developer,
    setDeveloper,
    setIntegrationPlatforms,
    setLoading,
    setApps,
  } = useContext(DeveloperContext);

  async function loadDeveloper() {
    try {
      setLoading(true);
      const response = await developerService.getDeveloper();
      setDeveloper(response.data);
      const { data: integrations } =
        await developerService.getAppIntegrationPlatforms();
      setIntegrationPlatforms(integrations);
      setLoading(false);
    } catch (e) {
      toast.error((e as Error).message);
    }
  }

  async function loadApps() {
    try {
      const { data } = await developerService.getDeveloperApps();
      setApps(data);
    } catch (e) {
      toast.error((e as Error).message);
    }
  }

  useEffect(() => {
    loadDeveloper();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (developer) {
      loadApps();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Switch>
        <Route exact path={`${path}/account`}>
          <DeveloperAccount />
        </Route>
        <Route exact path={`${path}/apps`}>
          <DeveloperApps />
        </Route>
        <Route exact path={`${path}/apps/:appId/:page/:subPage`}>
          <AppDashboard />
        </Route>
        <Route path={`${path}/apps/:appId/:page/user/:userId`}>
          <AppDashboard />
        </Route>
        <Route exact path={`${path}/apps/:appId/:page/collection/:collectionId/:subPage`}>
          <AppDashboard />
        </Route>
        <Route path={`${path}/apps/:appId/:page/collection/:collectionId`}>
          <AppDashboard />
        </Route>
        <Route path={`${path}/apps/:appId/:page/plan/:planId`}>
          <AppDashboard />
        </Route>
        <Route path={`${path}/apps/:appId/:page/webhook/:webhookId`}>
          <AppDashboard />
        </Route>
        <Route path={`${path}/apps/:appId/:page`}>
          <AppDashboard />
        </Route>
        <Redirect
          from={`${path}/apps/:appId`}
          to={`${path}/apps/:appId/dashboard`}
        />
      </Switch>
    </>
  );
};

export const DeveloperDashboard = () => {
  useEffect(() => {
    document.title = `Developer Dashboard | Common Ninja`;
  }, []);

  return (
    <DeveloperContextProvider>
      <DeveloperDashboardWrapper />
    </DeveloperContextProvider>
  );
};
