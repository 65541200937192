import React from "react";
import { IAppPlan } from "../../developer/developer.types";

export const Price = ({
  plan,
  activeVariantId,
}: // disableFloating,
{
  plan: IAppPlan;
  activeVariantId: number;
  disableFloating?: boolean;
}) => {
  const activeVariant = plan.pricingVariants[activeVariantId] || {};
  const otherVariant = plan.pricingVariants[activeVariantId === 0 ? 1 : 0];

  function getFloatingNumber(num: number) {
    if (!String(num).includes(".")) {
      return num;
    }
    return num.toFixed(2);
  }

  if (!activeVariant?.priceCount) {
    return <>${getFloatingNumber(activeVariant.priceCount || 0)}</>;
  }

  if (
    !otherVariant?.priceCount ||
    activeVariant.pricePeriod !== "year" ||
    activeVariant.priceCount < otherVariant.priceCount
  ) {
    return (
      <>
        ${getFloatingNumber(activeVariant.priceCount || 0)}
        <span className="period">/mo</span>
      </>
    );
  }

  let monthlyCost: any = activeVariant.priceCount / 12;

  if (monthlyCost > 0) {
    monthlyCost = getFloatingNumber(monthlyCost);
  }

  return (
    <>
      ${monthlyCost || 0}
      <span className="period">/mo</span>
    </>
  );
};
