import React from 'react';
import NumberFormat from 'react-number-format';
import { ActiveButton } from '../appPayments/common/activeButton.comp';

export const DeveloperInput = ({
  name,
  value,
  title,
  handleChange,
  label,
  type = 'text',
  placeholder = '',
  autoComplete = false,
  required = false,
  autoFocus = false,
  disabled = false,
  props,
  className,
  select,
  textarea,
  numberMaxLength,
  numberMinLength,
  numberAllowNegative = false,
  readOnly = false,
  onKeyDown,
  onBlur,
}: {
  name: string; // kebab-case
  value: string | number;
  title?: string; // human-readable name
  handleChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  label?: string;
  type?: string;
  placeholder?: string;
  autoComplete?: boolean;
  required?: boolean;
  autoFocus?: boolean;
  disabled?: boolean;
  props?: any;
  className?: string;
  select?: { name: string; displayName?: string; }[];
  textarea?: boolean;
  numberMaxLength?: number;
  numberMinLength?: number;
  numberAllowNegative?: boolean;
  readOnly?: boolean;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
}) => {
  const inputProps = {
    id: name,
    name,
    title: title || label || name,
    value,
    readOnly: readOnly,
    onChange: handleChange,
    label,
    type,
    placeholder,
    autoComplete: autoComplete
      ? 'on'
      : type === 'password'
        ? 'new-password'
        : 'off',
    required,
    autoFocus,
    disabled,
    onKeyDown,
    onBlur,
    ...props,
  };
  // if (!title && !label) {
  //   console.warn(
  //     `Element has no title attribute: ${name}, please set one. Label may be used as fallback but has not been set yet.`
  //   );
  // }

  function renderInput() {
    if (textarea || type === 'textarea') {
      return <textarea {...inputProps} />;
    }

    if (type === 'checkbox') {
      return (
        <ActiveButton
          isActive={!!value}
          size={22.5}
          onClick={() => {
            if (handleChange) {
              handleChange({ target: { value: !value, name } } as any);
            }
          }}
          isDisabled={disabled}
        />
      );
    }

    if (type === 'number') {
      return (
        <>
          <NumberFormat
            {...inputProps}
            className="number-input"
            value={value}
            disabled={disabled}
            displayType={'input'}
            allowNegative={numberAllowNegative}
            maxLength={numberMaxLength ? numberMaxLength : undefined}
            minLength={numberMinLength ? numberMinLength : undefined}
            type="text" // 'text' | 'password' | 'tel' | undefined
            thousandSeparator={true}
            allowEmptyFormatting={false}
            allowLeadingZeros={false}
            onValueChange={(values) => {
              const e = {
                target: {
                  value: parseFloat(values.value?.replace(/,/g, '') || '0'),
                  name,
                },
              } as {
                target: { value: number; name: string; };
              };
              if (handleChange) {
                handleChange(e as any);
              }
            }}
          />
          {!disabled && (
            <div className="buttons-container">
              <i
                title="increase"
                onClick={(e) => {
                  e.stopPropagation();
                  if (handleChange) {
                    handleChange({
                      target: { name, value: parseInt(value.toString()) + 1 },
                    } as any);
                  }
                }}
              ></i>
              <i
                title="decrease"
                className={
                  !numberAllowNegative && (!value || value === '0')
                    ? 'disabled'
                    : ''
                }
                onClick={(e) => {
                  e.stopPropagation();
                  if (handleChange) {
                    handleChange({
                      target: { name, value: parseInt(value.toString()) - 1 },
                    } as any);
                  }
                }}
              ></i>
            </div>
          )}
        </>
      );
    }

    if (select) {
      return (
        <select {...inputProps}>
          {select.map((option) => (
            <option key={option.name} value={option.name} >
              {option.displayName ? option.displayName : option.name}
            </option>
          ))}
        </select>
      );
    }

    return <input {...inputProps} />;
  }

  return (
    <div className={`input-container ${className ? className : ''}`}>
      {label && (
        <label htmlFor={name}>
          {label}
          {required && <span> *</span>}
        </label>
      )}
      {renderInput()}
    </div>
  );
};
