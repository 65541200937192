import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { error, success } from 'react-notification-system-redux';
import {
  Loader,
  FormRow,
  Popup,
} from '@commonninja/commonninja-styleguide-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';

import './permissions.scss';

const PermissionsComp = ({ ownerDetails, component }: any) => {
  const [dataState, setDataState] = useState({ type: 'idle', message: '' });
  const [permissions, setPermissions] = useState({
    docs: [],
    page: 1,
    total: 0,
    pages: 0,
    limit: 100,
  });
  const [popupOpened, setPopupOpened] = useState(false);
  const [formData, setFormData] = useState({
    state: 'init',
    email: '',
    role: 'viewer',
  });
  const dispatch = useDispatch();

  async function getPermissions() {
    setDataState({ type: 'loading', message: '' });

    try {
      const req = await fetch(`/api/v1/plugin/${component.guid}/permission`);
      const res = await req.json();

      if (!res.success) {
        throw new Error(res.message);
      }

      setPermissions(res.data);
      setDataState({ type: 'idle', message: '' });
    } catch (e) {
      setDataState({ type: 'error', message: (e as Error).message });
    }
  }

  async function deletePermission(permissionId: string) {
    const approval = window.confirm(
      'Are you sure you want to delete this invitation?'
    );
    if (!approval) {
      return;
    }

    try {
      await fetch(
        `/api/v1/plugin/${component.guid}/permission/${permissionId}`,
        {
          method: 'delete',
        }
      );
      await fetch('/api/component/deleteInvite', {
        method: 'post',
        body: JSON.stringify({
          permissionId,
        }),
      });

      getPermissions();

      dispatch(
        success({
          title: 'Invitation has been deleted',
          autoDismiss: 3,
          position: 'tc',
        })
      );
    } catch (e) {
      dispatch(
        error({
          title: 'Could not delete invitation',
          message: (e as Error).message,
          autoDismiss: 5,
          position: 'tc',
        })
      );
    }
  }

  async function sendInvite(email: string, role: string, e: any) {
    if (e) {
      e.preventDefault();
    }

    setFormData({ ...formData, state: 'loading' });

    try {
      const permissionReq = await fetch(
        `/api/v1/plugin/${component.guid}/permission`,
        {
          method: 'post',
          body: JSON.stringify({
            componentId: component.guid,
            userDetails: {
              email,
              name: '',
            },
            role,
            userId: email, // Temporary save user id as email until the email approval
          }),
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      const permissionRes = await permissionReq.json();

      if (!permissionRes.success) {
        throw new Error(permissionRes.message);
      }

      const inviteData = {
        permissionId: permissionRes.data.permissionId,
        email,
        componentName: component.name,
        componentThumb: component.previewImage,
      };

      await fetch('/api/component/invite', {
        method: 'post',
        body: JSON.stringify(inviteData),
        headers: {
          'Content-Type': 'application/json',
        },
      });

      setFormData({ email: '', role: 'viewer', state: 'init' });
      setPopupOpened(false);
      getPermissions();

      dispatch(
        success({
          title: 'Invitation has been successfully sent!',
          autoDismiss: 3,
          position: 'tc',
        })
      );
    } catch (e) {
      dispatch(
        error({
          title: 'Could not send invitation',
          message: (e as Error).message,
          autoDismiss: 5,
          position: 'tc',
        })
      );

      setFormData({ ...formData, state: 'init' });
    }
  }

  useEffect(() => {
    getPermissions();
    // eslint-disable-next-line
  }, []);

  function renderFormContent() {
    if (formData.state === 'loading') {
      return <Loader />;
    }

    return (
      <form onSubmit={(e) => sendInvite(formData.email, formData.role, e)}>
        <FormRow>
          <label>Email Address</label>
          <input
            type="email"
            value={formData.email}
            onChange={(e) =>
              setFormData({ ...formData, email: e.target.value })
            }
            required
          />
        </FormRow>
        <FormRow>
          <label>Role</label>
          <select
            defaultValue={formData.role}
            onChange={(e) =>
              setFormData({
                ...formData,
                role: e.target.value,
              })
            }
            className="medium"
            required
          >
            <option value="viewer">Viewer</option>
            <option value="analyst">Analyst</option>
            <option value="editor">Editor</option>
          </select>
        </FormRow>
        <div className="buttons-wrapper center">
          <button
            className="btn green"
            type="submit"
            disabled={!formData.email}
          >
            Send Invite
          </button>
        </div>
      </form>
    );
  }

  function renderContent() {
    if (dataState.type === 'loading') {
      return (
        <Loader>
          <p className="center">Loading...</p>
        </Loader>
      );
    }

    if (dataState.type === 'error') {
      return <p className="center">{dataState.message || 'Error'}</p>;
    }

    return (
      <table>
        <thead>
          <tr>
            <th>Collaborators</th>
            <th>Role</th>
            <th className="actions">
              <button
                className="btn green"
                type="submit"
                onClick={() => setPopupOpened(true)}
              >
                + Add Collaborator
              </button>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{ownerDetails.name}</td>
            <td className="role">{ownerDetails.role}</td>
            <td></td>
          </tr>
          {permissions.docs.map((permission: any) => (
            <tr key={permission.guid}>
              <td>
                {permission.userDetails.name || permission.userDetails.email}
                {permission.status === 'pending' && (
                  <span className="status"> (pending approval)</span>
                )}
              </td>
              <td className="role">{permission.role}</td>
              <td className="actions">
                <button
                  className="btn action"
                  onClick={() => {
                    deletePermission(permission.guid);
                  }}
                >
                  <FontAwesomeIcon icon={faTrashAlt} />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  }

  return (
    <div className="permissions-manager">
      {renderContent()}
      <Popup
        closeCallback={() => setPopupOpened(false)}
        show={popupOpened}
        className="invite-popup"
      >
        <h2>Invite to Collaborate</h2>
        <div className="content">{renderFormContent()}</div>
      </Popup>
    </div>
  );
};

export default PermissionsComp;
