import React from 'react';
import { Popup } from '@commonninja/commonninja-styleguide-react';
import upgradeProject from '../../assets/upgrade-project.png';
import upgradeProjectCollab from '../../assets/upgrade-project-collab.png';

import './projectUpgradePopup.scss';

export interface IProjectUpgradePopup {
  setShowPopup: React.Dispatch<React.SetStateAction<boolean>>;
  showPopup: boolean;
  loading?: boolean;
  title: string;
  text: string;
  buttonText?: string;
  children?: React.ReactNode;
  className?: string;
  type?: 'project' | 'collab';
}

export const ProjectUpgradePopup = ({ setShowPopup, showPopup, text, title, buttonText, className = '', loading, type = 'project' }: IProjectUpgradePopup) => {

  return (
    <>
      <Popup
        show={showPopup}
        closeCallback={() => setShowPopup(false)}
        className={`developer-page popup-warn project-upgrade ${className}`}
      >
        <img src={type === 'project' ? upgradeProject : upgradeProjectCollab} alt={'Upgrade project'} />
        <header>
          <h3>{title}</h3>
        </header>
        <p className="popup-warn-text">
          {text}
        </p>
        <div className="popup-warn-buttons">
          <button
            className={`button primary-button ${loading ? 'loading' : ''}`}
            // onClick={(() => { setShowPopup(false); setPremiumIsOpen(true); })}
            onClick={(() => {
              setShowPopup(false);
              (
                document.querySelector('button.upgrade') as HTMLDivElement
              )?.click();
            })}
          >
            {renderUpgradeIcon()}
            {buttonText ? `${loading ? 'Loading...' : buttonText}` : `${loading ? 'Loading...' : 'Remove'}`}
          </button>
        </div>
      </Popup>
    </>
  );
};

function renderUpgradeIcon() {
  return (
    <svg
      width="18"
      height="15"
      viewBox="0 0 18 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.7532 2.4929C17.5406 2.3208 17.2406 2.28453 16.9891 2.4006L13.1414 4.1766L9.53118 0.229867C9.39766 0.0838668 9.20384 0 9.00001 0C8.79617 0 8.60239 0.0838668 8.46883 0.229867L4.85857 4.17657L1.01093 2.40057C0.759453 2.28453 0.45943 2.32077 0.246734 2.49287C0.034039 2.66497 -0.0508985 2.94023 0.0304179 3.1939L2.70229 11.5272C2.79233 11.8081 3.06546 12 3.37501 12H14.625C14.9345 12 15.2077 11.8081 15.2977 11.5273L17.9696 3.19393C18.0509 2.94027 17.966 2.665 17.7532 2.4929Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.25 14C3.25 13.5858 3.58579 13.25 4 13.25H14C14.4142 13.25 14.75 13.5858 14.75 14C14.75 14.4142 14.4142 14.75 14 14.75H4C3.58579 14.75 3.25 14.4142 3.25 14Z"
        fill="white"
      />
    </svg>
  );
}