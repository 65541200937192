import React, { useState, useContext } from 'react';
import { toast } from 'react-toastify';

import { DeveloperContext } from '../../developer.context';
import { developerService } from '../../../../services/developer.service';
import { IAppWebhook } from '../../developer.types';
import { DeveloperIcon } from '../common/developerIcon.comp';

interface IWebhookPopupProps {
  webhook: Partial<IAppWebhook>;
  closeEditor: () => void;
}

export const WebhookEditor = ({
  webhook = { url: '' },
  closeEditor,
}: IWebhookPopupProps) => {
  const [formState, setFormState] = useState(webhook);
  const [error, setError] = useState('');
  const { activeApp } = useContext(DeveloperContext);
  const { appId } = activeApp;

  function updateFormField(propName: string, value: any) {
    setFormState((prevState: any) => ({ ...prevState, [propName]: value }));
  }

  function validateForm() {
    const validationErrors = [
      {
        rule: !formState.url?.startsWith('http://'),
        message: 'Invalid URL, must start with https://',
      },
      {
        rule: formState.url?.startsWith('https://'),
        message: 'Invalid URL, must start with https://',
      },
    ];
    return validationErrors.filter((v) => !v.rule)[0]?.message || '';
  }

  async function saveWebhook(e: any) {
    e.preventDefault();
    try {
      const error = validateForm();
      if (error) {
        throw new Error(error);
      }
      const { success } = await toast.promise(
        webhook.id
          ? developerService.updateAppWebhook(appId, webhook.id, formState)
          : developerService.createAppWebhook(appId, formState),
        {
          pending: 'Saving changes...',
          success: 'Great success! 👌',
          error: 'Could not save changes 😕',
        }
      );

      if (success) {
        setFormState({ url: '' });
        closeEditor();
      }
    } catch (e) {
      toast.error((e as Error).message);
    }
  }

  return (
    <>
      <h2>{webhook.id ? 'Edit' : 'Add'} Webhook</h2>
      <section>
        <form onSubmit={saveWebhook}>
          <div className="form-row column">
            <label>
              Webhooks URL <span className="required">*</span>
            </label>
            <input
              type="text"
              value={formState.url}
              onChange={(e) => {
                e.target.value === 'w'
                  ? updateFormField('url', 'https://w')
                  : updateFormField('url', e.target.value);
                e.target.value.length > 8
                  ? setError(validateForm())
                  : setError('');
              }}
              autoFocus
              placeholder="https://example.com"
            />
          </div>
          {error && (
            <div className="form-row">
              <DeveloperIcon
                name="warning"
                label={error}
                className="editor-error"
              />
            </div>
          )}
          <div className="buttons-wrapper center">
            <button className="button primary-button">
              {webhook.id ? 'Save' : 'Add'} Webhook
            </button>
          </div>
        </form>
      </section>
    </>
  );
};
