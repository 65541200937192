import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';

interface IConfirmOrCancel {
  label: string;
  onClick: () => void;
}
interface IDeveloperWarning {
  title: string;
  message: string;
  confirm: IConfirmOrCancel;
  cancel?: IConfirmOrCancel;
}

export const DeveloperWarning = ({
  title = 'Are you sure you want to do this?',
  message = 'This action cannot be undone.',
  confirm = {
    label: 'Confirm',
    onClick: () => {},
  },
  cancel,
}: IDeveloperWarning) => {
  return (
    <>
      <header>
        <FontAwesomeIcon
          icon={faExclamationCircle}
          color="rgb(250, 191, 94)" // #FABF56
          size="2x"
        />
        <h3>{title}</h3>
      </header>
      <p className="popup-warn-text">{message}</p>
      <div className="popup-warn-buttons">
        {cancel && (
          <div className="button secondary-button" onClick={cancel.onClick}>
            {cancel.label}
          </div>
        )}
        <div className="button primary-button" onClick={confirm.onClick}>
          {confirm.label}
        </div>
      </div>
    </>
  );
};
