import React from "react";
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from "react-sortable-hoc";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FaTrash } from "react-icons/fa";
import { FiEdit } from "react-icons/fi";
import { CopyInput } from '../../copyInput/copyInput.comp';
import { MdContentCopy } from 'react-icons/md';

interface ISortableAppCard {
  onClick: (e?: any) => void;
  onRemove?: (e?: any) => void;
  copyValue?: string;
  header: string;
  content: any;
  footer: any;
  idx: number;
}

interface ISortableAppCards {
  items: ISortableAppCard[];
  onAdd: (e?: any) => void;
  onRemove?: (idx: number, e?: any) => void;
  onSortEnd?: (e?: any) => void;
  customListProps?: any;
  buttonText?: string;
  disabled?: boolean;
}

export const SortableAppCards = ({
  onSortEnd,
  onAdd,
  // onRemove,
  customListProps = {},
  items,
  buttonText = "Add an item",
  disabled = false,
}: ISortableAppCards) => {
  const sortableListProps = {
    axis: "xy",
    useDragHandle: true,
    pressDelay: 120,
    onSortStart: () => (document.body.style.cursor = "grabbing"),
    onSortEnd: (indexes: any) => {
      onSortEnd?.(indexes);
      document.body.style.cursor = "default";
    },
    ...customListProps,
  };

  const SortableGrip = SortableHandle(() => (
    <div className="app-card-handle"></div>
  ));

  const SortableItem = SortableElement(
    ({
      header,
      content,
      footer,
      onClick,
      onRemove,
      copyValue,
    }: ISortableAppCard) => {
      return (
        <div className="app-card app-card-item">
          <div className="app-card-header">
            {!disabled && onSortEnd && items.length > 1 && <SortableGrip />}
            <h5>{header}</h5>
            <div className="app-card-buttons">
              {copyValue && (
                <CopyInput
                  inputType="hidden"
                  value={copyValue}
                  buttonStyles={{
                    padding: 0,
                    marginRight: 5,
                    marginTop: 0,
                  }}
                >
                  <MdContentCopy className="copy-icon" />
                </CopyInput>
              )}
              {!disabled && (
                <i className="app-card-button" title="Edit">
                  <FiEdit onClick={onClick} fontSize="17px" />
                </i>
              )}
              {onRemove && (
                <i className="app-card-button" title="Delete">
                  <FaTrash onClick={onRemove} fontSize="16px" />
                </i>
              )}
            </div>
          </div>
          <div className="app-card-content">{content}</div>
          <div className="app-card-footer">{footer}</div>
        </div>
      );
    }
  );

  const SortableList = SortableContainer(() => {
    return (
      <div
        className={`app-cards ${disabled ? "disabled" : ""}`}
        tabIndex={disabled ? -1 : 0}
      >
        {items.map((item, index) => (
          <SortableItem {...item} idx={index} index={index} key={index} />
        ))}
        {!disabled && (
          <div
            className="app-card app-card-add"
            onClick={onAdd}
            onKeyPress={onAdd}
          >
            <FontAwesomeIcon icon={faPlus} />
            <span>{buttonText}</span>
          </div>
        )}
      </div>
    );
  });

  return <SortableList {...sortableListProps} />;
};
