import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useRouteMatch } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useResizeDetector } from "react-resize-detector";
import {
  plansService,
  loggedIn,
  HttpService,
  IUserSubscription,
} from "@commonninja/commonninja-styleguide-react";
import {
  CycleSelectorLoader,
  PricingTableLoader,
} from "@commonninja/commonninja-styleguide-react/lib/esm/components/internal";
import { OldPricing } from "./oldPricing.comp";
import { NewPricing } from "./newPricing.comp";

import "./pricing.scss";

export const Pricing = ({ embedMode, websiteMode }: any) => {
  const dispatch = useDispatch();
  let {
    params: { serviceName, vendor },
  } = useRouteMatch() as any;
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const mode = searchParams.get("mode");
  const isBundle =
    typeof window !== "undefined" &&
    window.location.href.includes("bundle=true");
  if (isBundle) {
    serviceName = "bundles";
  }
  const [hasOldSubscriptions, setHasOldSubscriptions] =
    useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const onResize = useCallback(() => {
    const elm = document.querySelector("#main-content");
    if (!elm) {
      return;
    }

    const { height, width } = elm.getBoundingClientRect();

    window.parent.postMessage(
      {
        type: "COMMONNINJA_DASHBOARD_IFRAME_UPDATE",
        height,
        width,
      },
      "*"
    );
  }, []);
  const { ref } = useResizeDetector({
    refreshMode: "debounce",
    onResize,
  });

  async function getOldUserSubscriptions() {
    setLoading(true);
    setHasOldSubscriptions(false);

    try {
      const client = new HttpService();
      const result = await client.makeRequest(
        `/api/subscription${vendor ? "/v/" + vendor : ""}/${serviceName}?${
          client.queryParams
        }`
      );
      const subscriptionData: IUserSubscription = result?.data?.docs?.[0];
      if (subscriptionData) {
        setHasOldSubscriptions(true);
      }
    } catch (e) {}

    setLoading(false);
  }

  function renderBody() {
    if (loading) {
      return (
        <div className="pricing-loader">
          <CycleSelectorLoader />
          <PricingTableLoader />
        </div>
      );
    }

    if (vendor !== "shopify" && hasOldSubscriptions) {
      return <OldPricing embedMode={embedMode} websiteMode={websiteMode} />;
    }

    return <NewPricing embedMode={embedMode} websiteMode={websiteMode} />;
  }

  useEffect(() => {
    // Report event
    plansService.reportFunnelEvent("PRICING_PLAN_VISIT");

    if (vendor !== "shopify" && serviceName !== "bundles") {
      // Check which pricing plans to render - old or new
      getOldUserSubscriptions();
    } else {
      setLoading(false);
    }

    if (vendor) {
      dispatch(
        loggedIn({
          isAuthenticated: true,
        })
      );
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div
      className={`pricing-page ${
        !hasOldSubscriptions ? `new-pricing ${mode || ""}` : "old-pricing"
      } ${!!vendor ? "vendor-vode" : ""} ${websiteMode ? "website-mode" : ""}`}
      ref={ref}
    >
      {renderBody()}
    </div>
  );
};
