import React, { useContext } from 'react';
import { DeveloperContext } from '../developer.context';
import {
  DashboardCopier,
  DashboardStatsCards,
  DashboardMeters,
  DashboardChartSection,
  DashboardSteps,
} from './dashboardViewComponents';

const meterProps = {
  installations: {
    title: 'Number of Stores',
    maxValue: 500,
  },
  storage: {
    title: 'Storage Used',
    maxValue: 5,
    suffix: 'GB',
  },
  requests: {
    title: 'Requests',
    maxValue: 5000,
  },
};

export const AppDashboardView = () => {
  const { activeApp } = useContext(DeveloperContext);

  return (
    <>
      <div className="dashboard-topbar">
        <div className="inner">
          <div className="topbar-preface">
            <div className="intro">Hi {'Ninja'},</div>
            <div className="title">
              <h2 className="name">
                <span>{activeApp.name}</span>
                <span className={`tier ${activeApp.status}`}>
                  {activeApp.status}
                </span>
              </h2>
            </div>
          </div>
        </div>
        <div className="topbar-details">
          <div className="topbar-details-copiers">
            <DashboardCopier title="App ID" value={activeApp.appId} />
            <DashboardCopier
              title="App Secret"
              hidden
              value={activeApp.secretKey}
            />
          </div>
          <DashboardMeters
            requestSlugUrl="usage"
            className="topbar-details"
            metersProps={meterProps}
          />
        </div>
      </div>
      <DashboardMeters requestSlugUrl={''} metersProps={''} />
      <div className="dashboard-cards">
        <DashboardStatsCards />
        <DashboardChartSection
          extractUrls={['revenue']}
          options={{
            chart: { type: 'areaspline', height: 250 },
            tooltip: { valuePrefix: '$' },
          }}
          title={'Revenue'}
          className="revenue-section"
          bodyClassName="flex-wrapper"
          showMeters={true}
        />
        <div className="dashboard-cards-spread flex-wrapper">
          <DashboardChartSection
            extractUrls={['installations/stats']}
            title={'Installations'}
            className="users-section"
            options={{
              chart: { type: 'areaspline', height: 250 },
            }}
          />
          <DashboardChartSection
            extractUrls={['subscriptions/stats']}
            title={'Subscriptions'}
            className="users-section"
            options={{
              chart: { type: 'areaspline', height: 250 },
            }}
          />
        </div>
        <DashboardSteps />
        {/* <section className="app-section dashboard-card support-section">
          <div className="app-section-header seperator-light flex-wrapper">
            <div>
              <h4>Support</h4> <p>We're here for you</p>
            </div>
            <button className="button dashboard-button grey">
              What do you need help with?
            </button>
          </div>
          <div className="app-section-body">
            {reactStringReplace(
              `We're here for you`.repeat(9),
              `We're here for you`,
              (match, index) => (
                <a className="support-result" href="/" key={index}>
                  {match}
                </a>
              )
            )}
            <a
              className="button support-email"
              href="mailto:support@commoninja.com"
            >
              Still need help? Send us an email
            </a>
          </div>
        </section> */}
      </div>
    </>
  );
};
