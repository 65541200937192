import { Tabs } from '@commonninja/commonninja-styleguide-react';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { AppIntegration } from '../appIntegration/appIntegration.comp';
import { AppIntegrationForm } from '../appIntegration/appIntegrationForm.comp';
import { DeveloperContext } from '../developer.context';
import { IAppIntegration, IIntegrationPlatform } from '../developer.types';

export const AppIntegrations = () => {
  const { integrations, integrationPlatforms, activeApp } = useContext(DeveloperContext);
  const [integrationsObj, setLocalIntegrations] = useState<{
    [key: string]: IAppIntegration;
  }>({});
  const [selectedIntegration, setSelectedIntegration] = useState<{
    integrationData: IAppIntegration;
    platform: IIntegrationPlatform;
  }>();
  const history = useHistory();
  const { subPage } = useParams<any>();

  function renderIntegrations(tag: string) {
    const integrationsList = integrationPlatforms.filter((i) =>
      i.tags.includes(tag)
    );

    return integrationsList.length > 0 ? (
      <div className="cards-container">
        {integrationsList
          .sort((a, b) => (b.order || 0) - (a.order || 0))
          .map((platform) => (
            <AppIntegration
              key={`platform-${platform.name}`}
              platform={platform}
              integrationData={integrationsObj[platform.name]}
              selectIntegration={() =>
                setSelectedIntegration({
                  integrationData: integrationsObj[platform.name],
                  platform,
                })
              }
            />
          ))}
      </div>
    ) : (
      <p className="center">Coming soon!</p>
    );
  }

  useEffect(() => {

    if (integrations.length > 0) {
      const integrationsObject: { [key: string]: IAppIntegration; } = {};
      integrations.forEach((integration: IAppIntegration) => {
        if (integration.platform) {
          integrationsObject[integration.platform] = integration;
        }
      });
      setLocalIntegrations((prevState) => ({
        ...prevState,
        ...integrationsObject,
      }));
      setLocalIntegrations((prevState) => ({
        ...prevState,
        ...integrationsObject,
      }));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [integrations]);

  useEffect(() => {
    if (!activeApp.appId) {
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeApp.appId]);

  if (!activeApp.appId) {
    return <></>;
  }

  if (selectedIntegration) {
    return (
      <AppIntegrationForm
        {...selectedIntegration}
        backPageCallback={() => setSelectedIntegration(undefined)}
      />
    );
  }

  return (
    <>
      <header className="flex-wrapper">
        <hgroup>
          <h2>Integrations</h2>
          <p>
            Connect your app to e-commerce platforms, payments solutions, and
            storage providers.
          </p>
        </hgroup>
      </header>
      <Tabs
        items={[
          {
            id: 'e-commerce',
            name: 'E-Commerce',
          },
          {
            id: 'payments',
            name: 'Payments',
          },
          {
            id: 'storage',
            name: 'Storage',
          },
        ]}
        onChange={(tab) => history.push(`/developer/apps/${activeApp.appId}/integrations/${tab.id}`)}
        activeTabId={subPage}
        resolveTabComp={(activeTabId) => {
          return renderIntegrations(activeTabId as string);
        }}
      />
    </>
  );
};
