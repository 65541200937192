import React, { useState, useRef, useEffect, CSSProperties } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface IOptionItem {
  name: string;
  icon?: any;
  onClick: () => void;
  className?: string;
}
interface IOptionsMenu {
  options: IOptionItem[];
  icon: any;
  style?: CSSProperties;
}
export const OptionsMenu = ({ options, icon, style = {} }: IOptionsMenu) => {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  const handleHideDropdown = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      setIsOpen(false);
    }
  };
  const handleClickOutside = (event: Event) => {
    if (ref.current && !ref.current.contains(event.target as Node)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handleHideDropdown, true);
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('keydown', handleHideDropdown, true);
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  return (
    <div
      className="options-menu"
      onClick={(e) => e.stopPropagation()}
      ref={ref}
    >
      <div className="options-menu-button" onClick={() => setIsOpen(!isOpen)}>
        <FontAwesomeIcon icon={icon} />
      </div>
      {isOpen && (
        <div className="options-menu-list" style={style}>
          {options.map((item, i) => (
            <div
              key={i}
              onClick={item.onClick}
              className={`options-menu-item ${item.className}`}
            >
              {item.icon && item.icon}
              {item.name}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
