import React from 'react';

// array is used here becuase it needs to check in that order
const abbreviationFormats = [
  { abbreviation: 'B', count: 1000000000 },
  { abbreviation: 'M', count: 1000000 },
  { abbreviation: 'K', count: 1000 },
];

export interface IDashboardMeterProps {
  title: string;
  value: number;
  maxValue: number;
  suffix?: string;
  prefix?: string;
  formatValues?: boolean;
  showMaxValue?: boolean;
}
export const DashboardMeter = ({
  title,
  value,
  maxValue,
  suffix = '',
  prefix = '',
  formatValues = true,
  showMaxValue = true,
}: IDashboardMeterProps) => {

  function abbreviateNumber(num: number) {
    if (!formatValues) {
      return prefix + num.toLocaleString('en-US') + suffix;
    }

    for (const format of abbreviationFormats) {
      if (num >= format.count) {
        num = parseFloat((num / format.count).toFixed(1));
        return `${prefix ? ' ' + prefix : ''}${num}${format.abbreviation}${
          suffix ? ' ' + suffix : ''
        }`;
      }
    }
    return `${num}${suffix ? suffix : ''}`;
  }

  return (
    <div className="meter">
      <div className="title">{title}</div>
      <div className="meter-indicator">
        <span
          className="meter-indicator-value"
          style={{ width: `${(value / maxValue) * 100}%` }}
        ></span>
      </div>
      <div className="meter-label">
        <span className="meter-label-value">{abbreviateNumber(value)}</span>
        {showMaxValue && (
          <span className="meter-label-max-value">
            / {abbreviateNumber(maxValue)}
          </span>
        )}
      </div>
    </div>
  );
};
