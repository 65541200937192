import {
  eventService,
  HttpService,
  Loader,
  useQuery,
} from "@commonninja/commonninja-styleguide-react";
import {
  CycleSelectorLoader,
  PricingTableLoader,
} from "@commonninja/commonninja-styleguide-react/lib/esm/components/internal";
import { IUser } from "@commonninja/types";
import React, { useEffect, useState } from "react";
import { useRouteMatch } from "react-router-dom";
import { appList, IPluginListingNew } from "../../services/appList.service";
import { IAppPlan, IAppPlanFeature } from "../developer/developer.types";
import { MiniBundles } from "./miniBundles/miniBundles.comp";

import { CycleSelector } from "./cycleSelector/cycleSelector.comp";
import { CurrentSubscription } from "./currentSubscription/currentSubscription.comp";
import { PricingTable } from "./pricingTable/pricingTable.comp";
import { FeaturesComparison } from "./featuresComparison/featuresComparison.comp";
import { IUserSubscription } from "../subscriptions/subscriptions.comp";

import "./newPricing.scss";

const nindoApiUrl = process.env.REACT_APP_NINDO_SERVICE_URL || "";

export interface IPricingData {
  plans: IAppPlan[];
  features: IAppPlanFeature[];
  userDetails: IUser;
}

export const NewPricing = ({ embedMode, websiteMode }: any) => {
  const [userSubscriptions, setUserSubscriptions] = useState<
    IUserSubscription[]
  >([]);
  const [pricingData, setPricingData] = useState<IPricingData>({
    plans: [],
    features: [],
    userDetails: {} as IUser,
  });
  const [miniBundlesPricingData, setMiniBundlesPricingData] =
    useState<IPricingData>({
      plans: [],
      features: [],
      userDetails: {} as IUser,
    });
  const { plans, userDetails } = pricingData;
  const [loading, setLoading] = useState<boolean>(true);
  const [loadedOnce, setLoadedOnce] = useState<boolean>(true);
  const [subscriptionsLoadedOnce, setSubscriptionsLoadedOnce] =
    useState<boolean>(false);
  const [checkoutLoading, setCheckoutLoading] = useState<boolean>(false);
  const [apps, setApps] = useState<IPluginListingNew[]>([]);
  let {
    params: { serviceName, vendor },
  } = useRouteMatch() as any;
  const query = useQuery();
  const isBundle =
    typeof window !== "undefined" &&
    window.location.href.includes("bundle=true");
  if (isBundle) {
    serviceName = "bundles";
  }
  const [numberOfWidgets, setNumberOfWidgets] = useState<number>(5);
  const [activeVariantId, setActiveVariantId] = useState<number>(0);
  const [activeServiceName, setActiveServiceName] =
    useState<string>(serviceName);
  const activeApp = apps.find((a) => a.serviceName === activeServiceName);
  const hasNonWidgetsBundlePlan =
    vendor ||
    serviceName === "bracketsninja" ||
    userSubscriptions.some((s) => {
      return (
        (s.status === "active" || s.status === "trial") &&
        !s.plan.services.includes("widgetsbundle")
      );
    });

  async function loadUserSubscriptions() {
    try {
      const platformReq = await fetch(
        `${nindoApiUrl}/nindo/api/getUserSubscriptions`,
        { credentials: "include" }
      );
      const platformRes = await platformReq.json();
      const paddleSubscriptions = platformRes?.data || [];

      const promises = paddleSubscriptions
        .filter(
          (s: IUserSubscription) =>
            s.status === "active" || s.status === "trial"
        )
        .map(async (paddleSubscription: IUserSubscription) => {
          const localServiceName = paddleSubscription.plan.services[0];
          if (localServiceName === "minibundles") {
            const res = await fetch(`${nindoApiUrl}/nindo/api/getMiniBundle`, {
              credentials: "include",
            });
            const json = await res.json();

            if (json.success && json.data) {
              paddleSubscription.miniBundleDetails = json.data;
            }
          }

          return paddleSubscription;
        });

      await Promise.all(promises);

      setUserSubscriptions(paddleSubscriptions);
    } catch (e) {}

    setSubscriptionsLoadedOnce(true);
  }

  async function loadAppPlans(serviceNameToLoad: string = activeServiceName) {
    const client = new HttpService();
    const baseUrl = `${nindoApiUrl}/nindo/api/getPaymentPlans`;

    const apps = await appList.get();
    setApps(apps);

    if (serviceNameToLoad === "bundles") {
      return await (
        await fetch(`${baseUrl}/bundle?${client.queryParams}`, {
          credentials: "include",
        })
      ).json();
    }

    if (serviceNameToLoad === "widgetsbundle") {
      return await (
        await fetch(`${baseUrl}/widgets_bundle?${client.queryParams}`, {
          credentials: "include",
        })
      ).json();
    }

    if (serviceNameToLoad === "minibundles") {
      return await (
        await fetch(`${baseUrl}/mini_bundles?${client.queryParams}`, {
          credentials: "include",
        })
      ).json();
    }

    const componentType = apps.filter(
      (a) => a.serviceName === serviceNameToLoad
    )?.[0]?.componentType;

    if (!componentType) {
      throw new Error("Plugin type not found.");
    }

    return await (
      await fetch(`${baseUrl}/${componentType}?${client.queryParams}`, {
        credentials: "include",
      })
    ).json();
  }

  async function loadPricingPlans(
    serviceNameToLoad: string = activeServiceName
  ) {
    setLoading(true);

    try {
      const result = await loadAppPlans(serviceNameToLoad);
      const data = (result?.data || {
        plans: [],
        features: [],
        userDetails: {},
      }) as IPricingData;
      if (data) {
        let platformHasSpecificPlans = false;

        data.plans.forEach((plan) => {
          if (platformHasSpecificPlans) {
            return;
          }

          const supportedPlatforms = plan.meta?.supportedPlatforms || [];

          if (vendor && supportedPlatforms.includes(vendor)) {
            platformHasSpecificPlans = true;
          }
        });

        data.plans = data.plans.filter((plan) => {
          // Filter plans that meant for specific platform
          const supportedPlatforms = plan.meta?.supportedPlatforms || [];
          if (platformHasSpecificPlans) {
            if (supportedPlatforms.includes(vendor)) {
              return true;
            }
            return false;
          }

          if (
            supportedPlatforms.length === 0 ||
            supportedPlatforms.includes("all")
          ) {
            return true;
          }

          if (supportedPlatforms.length > 0) {
            if (vendor && supportedPlatforms.includes(vendor)) {
              return true;
            }

            if (!vendor && supportedPlatforms.includes("nindo")) {
              return true;
            }
          }

          return false;
        });

        data.plans = data.plans
          .sort((a, b) => (a.isFree ? -1 : 1))
          .sort((a, b) => (a.order || 0) - (b.order || 0));

        const firstPlanVariants =
          data.plans.find((p) => !p.isFree)?.pricingVariants || [];
        const haveMultipleCycles = firstPlanVariants.length > 1;

        // Set default active variant to annual
        if (
          haveMultipleCycles &&
          (activeServiceName === serviceNameToLoad ||
            serviceNameToLoad === "widgetsbundle")
        ) {
          let nextActiveVariantId = 0;
          if (
            data.userDetails.plan?.subscription?.status === "active" &&
            data.userDetails.plan?.planId
          ) {
            // Try to find the index of the active variant
            nextActiveVariantId =
              data.plans
                .find((p) => p.planId === data.userDetails.plan?.planId)
                ?.pricingVariants?.findIndex(
                  (v) =>
                    v.id ===
                    data.userDetails.plan?.subscription?.planPricingVariantId
                ) || 0;
          } else {
            // nextActiveVariantId = firstPlanVariants.length - 1;
            // nextActiveVariantId = 0; // Monthly as default for testing
            nextActiveVariantId = 1; // Annually as default for testing
          }
          setActiveVariantId(nextActiveVariantId);
        }

        const finalData = {
          ...data,
          features: data.features.filter((feat) => {
            const { hidden = false, supportedPlatforms = [] } =
              feat?.meta || {};

            if (hidden === true) {
              return false;
            }

            if (
              supportedPlatforms.length === 0 ||
              supportedPlatforms.includes("all")
            ) {
              return true;
            }

            if (supportedPlatforms.length > 0) {
              if (vendor && supportedPlatforms.includes(vendor)) {
                return true;
              }

              if (!vendor && supportedPlatforms.includes("nindo")) {
                return true;
              }
            }

            return false;
          }),
        };
        if (serviceNameToLoad === "minibundles") {
          setMiniBundlesPricingData(finalData);
        } else {
          if (serviceNameToLoad === "widgetsbundle") {
            // For widgets based pricing
            const { planPricingVariantId } =
              data?.userDetails.plan?.subscription || {};
            const statusNumberOfWidgets =
              data.plans.find((p) =>
                p.pricingVariants.find((v) => v.id === planPricingVariantId)
              )?.features?.numberOfInstances ||
              parseInt(query.get("widgets") || "0") ||
              0;

            if (statusNumberOfWidgets) {
              setNumberOfWidgets(statusNumberOfWidgets);
            }
          }
          setPricingData(finalData);
        }
        setLoadedOnce(true);
      }
    } catch (e) {
      console.error("Cannot load pricing", serviceNameToLoad, e);
    }

    setLoading(false);
  }

  function renderMainTitle(): string {
    if (activeServiceName === "widgetsbundle" || !hasNonWidgetsBundlePlan) {
      return "Pricing";
    }

    if (activeServiceName === "bundles") {
      return "All-in-One Bundle";
    }

    if (activeServiceName === "minibundles") {
      return "Create Your Own Bundle";
    }

    return activeApp?.name || "1 App";
  }

  function onLoading(loading: boolean, type?: "checkout" | "update") {
    if (type === "checkout") {
      setCheckoutLoading(loading);
    } else {
      setLoading(loading);
    }
  }

  function getWidgetsBundlePricingData(): IPricingData {
    const pricingDataByNumberOfWidgets: IPricingData = {
      features: [
        pricingData.features[0],
        {
          name: "allApps",
          description: "",
          displayName: "Access 180+ widget types",
          meta: {
            group: "Core Features",
          },
          id: "allApps",
          type: "boolean",
          value: false,
        },
        {
          name: "numberOfInstances",
          description:
            "The number of widget instances that can be created. Each instance is a unique widget. For example, if you have a widget on your website and you want to create a new one, regardless of its type, you will need to create a new instance.",
          displayName: "Widget Instances",
          meta: {
            group: "Core Features",
          },
          id: "numberOfInstances",
          type: "number",
          value: 1,
        },
        ...pricingData.features.slice(1),
      ],
      userDetails: pricingData.userDetails,
      plans: pricingData.plans
        .filter((plan) => {
          const planMeta = plan.meta || {};
          const planWidgets = plan?.features?.numberOfInstances || 0;

          if (
            plan.isFree ||
            (planMeta.core && planWidgets === numberOfWidgets)
          ) {
            return true;
          }

          return false;
        })
        .map((plan) => {
          return {
            ...plan,
            name: plan.meta?.planName || plan.name,
          };
        }),
    };
    return pricingDataByNumberOfWidgets;
  }

  function renderWidgetsBundlePlans() {
    const localPricingData: IPricingData = getWidgetsBundlePricingData();
    const featureSections: { name: string; features: IAppPlanFeature[] }[] = [];
    localPricingData.features.forEach((feature) => {
      const featureSection = featureSections.find((fs) => {
        return fs.name === feature.meta?.group;
      });

      // if (feature.meta?.includedApps?.length > 0) {
      //   feature.description = feature.meta?.includedApps
      //     .map((a: string) => {
      //       const appName =
      //         apps.find((app) => app.serviceName === a)?.name || a;
      //       return `- ${appName}`;
      //     })
      //     .join("<br />");
      // }

      if (!featureSection) {
        featureSections.push({
          name: feature.meta?.group || "",
          features: [feature],
        });
        return;
      }

      featureSection.features.push(feature);
    });

    // const { planPricingVariantId } =
    //   userDetails.plan?.subscription || {};
    // const statusNumberOfWidgets =
    //   plans.find((p) =>
    //     p.pricingVariants.find((v) => v.id === planPricingVariantId)
    //   )?.features?.numberOfInstances || 0;

    function renderCheckboxOption(num: number) {
      return (
        <label
          key={`checkbox-${num}`}
          className={`checkbox-option ${
            num === numberOfWidgets ? "active" : ""
          }`}
        >
          <input
            type="checkbox"
            checked={num === numberOfWidgets}
            onChange={() => {
              if (num === 0) {
                // Custom plan request
                window.open(
                  "https://www.commoninja.com/contact-form/lp/787c08f9-f4e7-4bc5-8906-3fbe99032d65"
                );
                return;
              }

              setNumberOfWidgets(num);
            }}
          />
          <span>{num ? num.toLocaleString() : "Custom Plan"}</span>
        </label>
      );
    }

    return (
      <>
        <CycleSelector
          activeVariantId={activeVariantId}
          setActiveVariantId={setActiveVariantId}
          plans={localPricingData.plans}
          serviceName={"widgetsbundle"}
        />
        <div className="number-of-widgets-selector-wrapper">
          {/* {(status === "active" || status === "trial") && (
            <div className="number-of-widgets-selector-status">
              Your current plan includes {statusNumberOfWidgets} widgets
            </div>
          )} */}
          {/* <div className="number-of-widgets-selector">
            <span>How many widgets do you need?</span>
            <span className="number-of-widgets-select">
              <select
                value={numberOfWidgets}
                onChange={(e) => {
                  const value = parseInt(e.target.value);

                  if (value === 0) {
                    // Custom plan request
                    window.open(
                      "https://www.commoninja.com/contact-form/lp/787c08f9-f4e7-4bc5-8906-3fbe99032d65"
                    );
                    return;
                  }

                  setNumberOfWidgets(value);
                }}
              >
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="5">5</option>
                <option value="10">10</option>
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="250">250</option>
                <option value="1000">1,000</option>
                <option value="0">Custom Plan</option>
              </select>
            </span>
          </div> */}
          <div className="number-of-widgets-checkbox-selector">
            <span className="number-of-widgets-checkbox-title">
              How many widgets do you need?
            </span>
            <span className="number-of-widgets-checkbox-select">
              {renderCheckboxOption(1)}
              {renderCheckboxOption(2)}
              {renderCheckboxOption(3)}
              {renderCheckboxOption(5)}
              {renderCheckboxOption(10)}
              {renderCheckboxOption(25)}
              {renderCheckboxOption(50)}
              {renderCheckboxOption(100)}
              {renderCheckboxOption(250)}
              {renderCheckboxOption(1000)}
              {renderCheckboxOption(0)}
            </span>
          </div>
        </div>
        <PricingTable
          activeVariantId={activeVariantId}
          apps={apps}
          embedMode={embedMode}
          onLoading={onLoading}
          pricingData={localPricingData}
          extendedPricingData={pricingData}
          serviceName={"widgetsbundle"}
          websiteMode={websiteMode}
          showOnlyDiffs
          showDecorations={false}
        />
        <FeaturesComparison
          activeVariantId={activeVariantId}
          apps={apps}
          embedMode={embedMode}
          onLoading={onLoading}
          pricingData={localPricingData}
          serviceName={"widgetsbundle"}
          websiteMode={websiteMode}
          featureSections={featureSections}
        />
      </>
    );
  }

  function renderLoading() {
    return (
      <div className="mini-bundles-wrapper">
        <div className="mini-bundles-plans">
          <div style={{ textAlign: "center", color: "#fff" }}>Loading...</div>
        </div>
      </div>
    );
  }

  function renderServicePlans() {
    // Website / Nindo
    if (!vendor && serviceName !== "bracketsninja") {
      if (!pricingData.plans.length) {
        return renderLoading();
      }

      if (!hasNonWidgetsBundlePlan) {
        return renderWidgetsBundlePlans();
      }

      // Old subscriptions with bundles
      if (!miniBundlesPricingData.plans.length) {
        return renderLoading();
      }

      return (
        <MiniBundles
          activeVariantId={activeVariantId}
          apps={apps}
          embedMode={embedMode}
          onLoading={onLoading}
          pricingData={pricingData}
          serviceName={activeServiceName}
          websiteMode={websiteMode}
          miniBundlesPricingData={miniBundlesPricingData}
          setActiveServiceName={setActiveServiceName}
          setActiveVariantId={setActiveVariantId}
          userSubscriptions={userSubscriptions}
          setUserSubscriptions={setUserSubscriptions}
        />
      );
    }

    return (
      <>
        <CycleSelector
          activeVariantId={activeVariantId}
          setActiveVariantId={setActiveVariantId}
          plans={plans}
          serviceName={activeServiceName}
        />
        <PricingTable
          activeVariantId={activeVariantId}
          apps={apps}
          embedMode={embedMode}
          onLoading={onLoading}
          pricingData={pricingData}
          serviceName={activeServiceName}
          websiteMode={websiteMode}
        />
        <FeaturesComparison
          activeVariantId={activeVariantId}
          apps={apps}
          embedMode={embedMode}
          onLoading={onLoading}
          pricingData={pricingData}
          serviceName={activeServiceName}
          websiteMode={websiteMode}
        />
      </>
    );
  }

  function renderBody(): any {
    // if (loading && (vendor || serviceName === "bracketsninja" || !loadedOnce)) {
    if (loading || !subscriptionsLoadedOnce) {
      return (
        <>
          <CycleSelectorLoader />
          <PricingTableLoader />
        </>
      );
    }

    if (!plans.length && !loadedOnce) {
      return (
        <p className="message center">
          Cannot load plans. Please contact support.
        </p>
      );
    }

    if (checkoutLoading) {
      return (
        <div className="message center checkout-redirect">
          <Loader
            innerColor={!hasNonWidgetsBundlePlan ? "#4C4FFF" : "#fff"}
            outerColor={!hasNonWidgetsBundlePlan ? "#4C4FFF" : "#fff"}
          />
          <p>Redirecting to checkout...</p>
        </div>
      );
    }

    return (
      <>
        {loading ? (
          <div style={{ marginBottom: 70 }}>
            <CycleSelectorLoader />
          </div>
        ) : (
          <>
            <h1 className="app-name-title center">{renderMainTitle()}</h1>
            <h3 className="app-guarantee center">
              3-Day Free Trial - Flexible Plans, Cancel Anytime!
            </h3>
            {hasNonWidgetsBundlePlan && (
              <CurrentSubscription userDetails={userDetails} />
            )}
          </>
        )}
        {renderServicePlans()}
      </>
    );
  }

  useEffect(() => {
    if (
      activeServiceName !== "minibundles" &&
      activeServiceName !== "widgetsbundle" &&
      hasNonWidgetsBundlePlan
    ) {
      loadPricingPlans();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeServiceName]);

  useEffect(() => {
    if (vendor || !subscriptionsLoadedOnce) {
      return;
    }

    if (!hasNonWidgetsBundlePlan) {
      loadPricingPlans("widgetsbundle");
    } else {
      loadPricingPlans();

      if (!miniBundlesPricingData.plans.length) {
        loadPricingPlans("minibundles");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userSubscriptions, subscriptionsLoadedOnce]);

  useEffect(() => {
    loadUserSubscriptions();

    eventService.reportMixpanelEvent(`Enter Pricing`, {
      authenticated: !!userDetails?.name,
      platform: vendor || "nindo",
      serviceName,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className={`new-pricing-wrapper ${
        !hasNonWidgetsBundlePlan ? "widgets-based" : ""
      } ${activeServiceName}`}
    >
      <div className="pricing-loader">{renderBody()}</div>
    </div>
  );
};
