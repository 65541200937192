import React, { useContext, useEffect, useRef, useState } from "react";
import queryString, { ParsedQuery } from "query-string";
import {
  Popup,
  NinjaIcon,
  // eventService,
  NinjaSkeleton,
} from "@commonninja/commonninja-styleguide-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisH, faPlus } from "@fortawesome/free-solid-svg-icons";
import { TbBrandGoogleAnalytics } from "react-icons/tb";
import { IoArrowRedoOutline } from "react-icons/io5";
import { MdDelete } from "react-icons/md";
import {
  AiFillExclamationCircle,
  AiOutlineEdit,
  AiOutlineEye,
} from "react-icons/ai";
import { BiDuplicate } from "react-icons/bi";
import { DeveloperInput } from "../../developer/newDashboardComponents/developerInput.comp";
import { SkeletonComponent } from "../../component/component.comp";
import PermissionsComp from "../../permissions/permissions.comp";
import { getActionUrl } from "../../../helpers/helpers";
import { OptionsMenu } from "../../developer/appWebhooks/common/optionsMenu.comp";
import { DeveloperCopier } from "../../developer/newDashboardComponents/developerCopier.comp";
import { DeveloperWarning } from "../../developer/newDashboardComponents/developerWarning.comp";
import { IPluginListingNew } from "../../../services/appList.service";
import defaultImage from "../../../assets/placeholder.png";
import { Pagination } from "../../developer/common/pagination/pagination.comp";
import { Sidebar } from "../../sidebar/sidebar.comp";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import { IProject } from "../../projects/projects.comp";
import logo from "../../../assets/no-widgets.svg";
import { toast } from "react-toastify";
import { ProjectContext } from "../../projectDashboard/projectDashboard.context";
import { ProjectPermissionRoleLevel } from "../project.helpers";
import { projectService } from "../../projectDashboard/projectDashboard.service";

import "./widgets.scss";

interface IComponent {
  created: string;
  creationSource: string;
  galleryId: string;
  guid: string;
  name: string;
  permissions: { name: string; role: string; user: string }[];
  previewImage: string;
  privacy: string;
  status: string;
  type: string;
  updated: string;
  views?: number;
}

export const Widgets = (props: any) => {
  const { activeProject, apps } = useContext(ProjectContext);
  const qs: ParsedQuery = queryString.parse(props.location?.search);
  const history = useHistory();
  const { projectId } = useParams<any>();
  const [moveToProjects, setMoveToProjects] = useState<IProject[]>([]);
  const filteredApps = apps.filter(
    ({ status }: IPluginListingNew) => status === "published"
  );
  const { search } = useLocation();
  const currentPage =
    (search || "")?.split("&page=")?.[1]?.split("&")?.[0] || "1";

  const [state, setState] = useState({
    loading: false,
    menuOpen: false,
    components: [],
    page: qs.page || "1",
    totalPages: 0,
    totalComponents: 0,
    viewsLoading: false,
    limit: qs.limit || "12",
    searchTerm: qs.search || "",
    searchType: qs.type || "",
    searchOwnership: qs.ownership || "my",
    activeModal: null,
    activeComponent: null,
    discoverList: filteredApps.sort(() => Math.random() - 0.5).slice(0, 6),
    moveToProjectId: "",
  });
  const actionsRef = useRef<any>();

  function hasAccess(minRole: IProject["userProjectRole"]) {
    const minLevel: number = (ProjectPermissionRoleLevel as any)[minRole] || 1;
    const userLevel =
      (ProjectPermissionRoleLevel as any)[activeProject.userProjectRole] || 1;
    return userLevel >= minLevel;
  }

  async function loadViewsLimit(components: IComponent[]) {
    setState((prevState) => ({
      ...prevState,
      viewsLoading: true,
    }));

    try {
      const calledService: string[] = [];

      await Promise.all(
        components.map(async ({ type }) => {
          const serviceName = apps.find(
            (app) => app.componentType === type
          )?.serviceName;

          if (!serviceName || calledService.includes(serviceName)) {
            return true;
          }

          calledService.push(serviceName);

          const partOfBundle =
            projectService.cache.bundles?.adsRemoval && // Indicates that it's premium
            projectService.cache.bundles?.[serviceName];

          if (partOfBundle) {
            return true;
          }

          return await projectService.getPlanFeatures(serviceName, projectId, true);
        })
      );
    } catch (e) {}

    setState((prevState) => ({
      ...prevState,
      viewsLoading: false,
    }));
  }

  const loadComponents = async (
    currentPage: string = "1",
    searchType: string = ""
  ) => {
    const { searchTerm } = state;

    setState((prevState) => ({
      ...prevState,
      loading: true,
    }));

    if (
      Number(currentPage) > Number(state?.totalPages || 1) ||
      Number(currentPage) <= 0
    ) {
      loadComponents();
      return;
    }

    try {
      let req;
      if (props?.type === "shared-widgets") {
        history.push(
          `/user${
            props.embedMode ? "/embed" : ""
          }/dashboard/shared-widgets?search=${searchTerm}&type=${searchType}&page=${currentPage}`
        );
        req = await fetch(
          `/api/component/shared?limit=10&page=${currentPage}&search=${searchTerm}&type=${
            searchType || ""
          }`
        );
      } else {
        history.push(
          `/user${
            props.embedMode ? "/embed" : ""
          }/dashboard/projects/${projectId}/widgets?search=${searchTerm}&type=${searchType}&page=${currentPage}`
        );
        req = await fetch(
          `/api/project/${projectId}/component?limit=10&page=${currentPage}&search=${searchTerm}&type=${
            searchType || ""
          }`
        );
      }
      const {
        data: { docs, pages, total, page },
      } = await req.json();

      setState((prevState) => ({
        ...prevState,
        searchType: searchType || "",
        components: docs,
        totalPages: pages,
        page: String(page),
        totalComponents: total,
      }));
    } catch (e) {
      toast.dismiss();
      toast.error((e as Error).message || "Could not load plugin list");
    }

    setState((prevState) => ({ ...prevState, loading: false }));
  };

  const onSubmit = (e?: any, searchType?: string) => {
    if (e) {
      e.preventDefault();
    }

    if (props.type === "shared-widgets") {
      history.push(
        `/user${
          props.embedMode ? "/embed" : ""
        }/dashboard/shared-widgets?search=${state.searchTerm}&type=${
          searchType || ""
        }&ownership=${state.searchOwnership}`
      );
    } else {
      history.push(
        `/user${
          props.embedMode ? "/embed" : ""
        }/dashboard/projects/${projectId}/widgets?search=${
          state.searchTerm
        }&type=${searchType || ""}&ownership=${state.searchOwnership}`
      );
    }
    setState({
      ...state,
      page: String(1),
      totalPages: 0,
      totalComponents: 0,
    });
    loadComponents("1", searchType || "");
  };

  const openModal = (data: any, type: any) => {
    setState((prevState) => ({
      ...prevState,
      activeComponent: data,
      activeModal: type,
    }));
  };

  const getScript = () => {
    const { activeComponent } = state;

    if (!activeComponent) {
      return "";
    }

    return `<script src="https://cdn.commoninja.com/sdk/latest/commonninja.js" defer></script>\n<div class="commonninja_component pid-${
      (activeComponent as IComponent).guid
    }"></div>`;
  };

  const deleteComponent = async () => {
    const { activeComponent } = state;

    if (!activeComponent) {
      return;
    }

    const actionUrl = getActionUrl("delete", activeComponent, apps, projectId);

    if (!actionUrl) {
      return;
    }

    setState((prevState) => ({
      ...prevState,
      activeComponent: null,
      activeModal: null,
      loading: true,
    }));

    let localError = null;

    try {
      const req = await fetch(actionUrl, {
        method: "delete",
        credentials: "include",
      });
      const componentsRes = await req.json();

      if (componentsRes.success) {
        loadComponents();
        return;
      }

      localError = componentsRes.message || "Could not delete plugin";
    } catch (e) {
      localError = "Could not delete plugin";
    }

    if (localError) {
      toast.dismiss();
      toast.error(localError);
    }

    setState((prevState) => ({ ...prevState, loading: false }));
  };

  const duplicateComponent = async () => {
    const { activeComponent } = state;

    if (!activeComponent) {
      return;
    }

    const actionUrl = getActionUrl(
      "duplicate",
      activeComponent,
      apps,
      projectId
    );

    if (!actionUrl) {
      return;
    }

    setState((prevState) => ({
      ...prevState,
      activeComponent: null,
      activeModal: null,
      loading: true,
    }));

    let localError = null;

    try {
      const req = await fetch(actionUrl, {
        method: "post",
        credentials: "include",
      });
      const componentsRes = await req.json();

      if (componentsRes.success) {
        loadComponents();
        return;
      }

      localError = componentsRes.message || "Could not duplicate plugin";
    } catch (e) {
      localError = "Could not duplicate plugin";
    }

    if (localError) {
      toast.dismiss();
      toast.error(localError);
    }

    setState((prevState) => ({ ...prevState, loading: false }));
  };

  const onMoveComponent = async () => {
    if (!state.moveToProjectId) return;

    const { activeComponent } = state;

    if (!activeComponent) {
      return;
    }

    try {
      const actionUrl = getActionUrl(
        "update",
        activeComponent,
        apps,
        projectId
      );

      if (!actionUrl) {
        return;
      }

      const req = await fetch(actionUrl, {
        method: "PUT",
        body: JSON.stringify({ projectId: state.moveToProjectId }),
        headers: {
          "Content-Type": "application/json",
        },
      });
      const result = await req.json();

      if (!result.success) {
        throw new Error(result.message);
      }

      loadComponents();
    } catch (e) {
      toast.dismiss();
      toast.error((e as Error).message);
    }
    setState((prevState) => ({
      ...prevState,
      activeModal: null,
      moveToProjectId: "",
    }));
  };

  const onChangeComponentName = async () => {
    const { activeComponent } = state;

    if (!activeComponent) {
      return;
    }

    try {
      const actionUrl = getActionUrl(
        "update",
        activeComponent,
        apps,
        projectId
      );

      if (!actionUrl) {
        return;
      }

      const req = await fetch(actionUrl, {
        method: "PUT",
        body: JSON.stringify({ name: (activeComponent as any).name }),
        headers: {
          "Content-Type": "application/json",
        },
      });
      const result = await req.json();

      if (!result.success) {
        throw new Error(result.message);
      }

      loadComponents();
      getProjects();
      setState((prevState) => ({ ...prevState, activeModal: null }));
    } catch (e) {
      toast.dismiss();
      toast.error((e as Error).message);
    }
  };

  const handleSearchChange = (e: any) => {
    if (e.target.name === "searchTerm") {
      setState((prevState) => ({
        ...prevState,
        [e.target.name]: e.target.value,
      }));
      return;
    }
    setState((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
    onSubmit(e, e.target.value);
  };

  const renderLoader = () => (
    <div className="components-wrapper loading">
      {Array.from(new Array(9)).map((c, idx) => (
        <SkeletonComponent key={`comp_skel_${idx}`} />
      ))}
    </div>
  );

  const getLimitValueStr = (num: number, unlimitedMode: boolean = false): string => {
    if (num < 1000) {
      return `${num}`;
    }
    if (unlimitedMode && num > 10000000) {
			// If number is bigger than 10,000,000
			return 'Unlimited';
		}
    let si = [
      { v: 1e3, s: "K" },
      { v: 1e6, s: "M" },
      { v: 1e9, s: "B" },
      { v: 1e12, s: "T" },
      { v: 1e15, s: "P" },
      { v: 1e18, s: "E" },
    ];
    let index;
    for (index = si.length - 1; index > 0; index--) {
      if (num >= si[index].v) {
        break;
      }
    }
    return (
      (num / si[index].v).toFixed(2).replace(/\.0+$|(\.[0-9]*[1-9])0+$/, "$1") +
      si[index].s
    );
  };

  const renderPlan = (component: IComponent) => {
    const serviceName =
      apps.find((app) => app.componentType === component.type)?.serviceName ||
      "bundles";

    return projectService.getPlanName(serviceName);
  };

  const renderViews = (component: IComponent) => {
    if (state.viewsLoading) {
      return <NinjaSkeleton count={1} width={"70%"} />;
    }

    const serviceName =
      apps.find((app) => app.componentType === component.type)?.serviceName ||
      "bundles";
    const limit = projectService.getViewsLimit(serviceName);
    const needsUpgrade = (component.views || 0) >= limit;

    return (
      <>
        <p title={`${component.views || 0}`}>
          <span className={needsUpgrade ? "red" : ""}>
            {getLimitValueStr(component.views || 0)}
          </span>
          /{getLimitValueStr(limit, true)}
        </p>
        {needsUpgrade && (
          <div
            className="upgrade-text"
            onClick={() => {
              (
                document.querySelector("button.upgrade") as HTMLDivElement
              )?.click();
            }}
          >
            <AiFillExclamationCircle fontSize={16} />
            Please Upgrade
          </div>
        )}
      </>
    );
  };

  const getProjects = async (search?: string) => {
    try {
      const req = await fetch(
        `/api/project${search ? `?search=${search}` : ""}`
      );
      const { data } = await req.json();
      setMoveToProjects(data?.docs || []);
    } catch (e) {}
  };

  useEffect(() => {
    loadViewsLimit(state.components);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.components, apps, activeProject]);

  useEffect(() => {
    document.title = `Project Widgets | Common Ninja`;
    loadComponents(currentPage);
    getProjects();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    page,
    totalPages,
    components,
    loading,
    searchTerm,
    searchType,
    totalComponents,
    activeComponent,
    activeModal,
  } = state;

  return (
    <React.Fragment>
      {props.type && props.type === "shared-widgets" ? (
        <Sidebar variant="projects" />
      ) : (
        <Sidebar variant="widgets" />
      )}
      <section className="widgets main-section">
        <div className="inner">
          <header>
            <hgroup className="page-titles">
              <h2>
                {props?.type === "shared-widgets"
                  ? "Shared Widgets"
                  : "Widgets"}
              </h2>
            </hgroup>
            <form className="search-components" onSubmit={onSubmit}>
              <DeveloperInput
                name="searchTerm"
                placeholder="Search by name..."
                title="Search"
                type="text"
                value={searchTerm as string}
                handleChange={handleSearchChange}
              />
              <DeveloperInput
                name="searchType"
                title="Search by type"
                placeholder="Search by type..."
                value={searchType as string}
                handleChange={handleSearchChange}
                select={[
                  { name: "", displayName: "— All Widgets —" },
                  ...apps
                    .filter((p: IPluginListingNew) => p.status === "published")
                    .sort((a, b) => {
                      if (a.name < b.name) {
                        return -1;
                      }
                      if (a.name > b.name) {
                        return 1;
                      }
                      return 0;
                    })
                    .map((p: IPluginListingNew) => ({
                      name: p.componentType.toLowerCase(),
                      displayName: p.name,
                    })),
                ]}
              />
            </form>
          </header>
          {loading ? (
            renderLoader()
          ) : (
            <React.Fragment>
              {totalComponents <= 0 ? (
                <div className="no-results center">
                  <img src={logo} alt="common ninja logo" />
                  <p>It looks like you don't have any widgets yet.</p>
                  {!searchType && (
                    <Link className="button primary-button" to="catalog">
                      Explore Widgets
                    </Link>
                  )}
                  {searchType && (
                    <div className="buttons-wrapper">
                      <a
                        className="button primary-button"
                        href={getActionUrl(
                          "edit",
                          {
                            type: searchType,
                          },
                          apps,
                          projectId
                        )}
                        target="_parent"
                        rel="noopener noreferrer"
                      >
                        + Create a new{" "}
                        {
                          apps.find(
                            ({ componentType }: IPluginListingNew) =>
                              componentType === searchType
                          )?.name
                        }{" "}
                        now!
                      </a>
                    </div>
                  )}
                </div>
              ) : (
                <div className="components-wrapper">
                  <div className="top">
                    <span>Photo</span>
                    <span>Name</span>
                    <span>App</span>
                    <span>Last updated</span>
                    <span>Monthly Pageviews</span>
                    <span>Actions</span>
                  </div>
                  <div className="components">
                    {components.map((component: IComponent) => (
                      <div className="component" key={component.guid}>
                        <a
                          href={
                            hasAccess("editor")
                              ? getActionUrl("edit", component, apps)
                              : "#"
                          }
                          onClick={(e) => {
                            if (!hasAccess("editor")) {
                              e.preventDefault();
                            }
                          }}
                        >
                          <img
                            src={component.previewImage || defaultImage}
                            alt={component.name}
                          />
                        </a>
                        <p>{component.name}</p>
                        <p className="app-name">
                          {apps.find(
                            (app) => app.componentType === component.type
                          )?.name || "App"}
                          <span className="plan-name">
                            {renderPlan(component)}
                          </span>
                        </p>
                        <p>{component.updated}</p>
                        <div className="views">{renderViews(component)}</div>
                        <div ref={actionsRef} className="actions">
                          {hasAccess("editor") && (
                            <a
                              className="button secondary-button"
                              href={getActionUrl("edit", component, apps)}
                            >
                              Edit
                            </a>
                          )}
                          <button
                            className="button primary-button"
                            onClick={() => openModal(component, "code")}
                          >
                            Add to site
                          </button>
                          <OptionsMenu
                            options={[
                              ...(hasAccess("editor")
                                ? [
                                    {
                                      name: "Duplicate",
                                      className: "duplicate",
                                      icon: (
                                        <BiDuplicate
                                          className="icon"
                                          fontSize={16}
                                        />
                                      ),
                                      onClick: () =>
                                        openModal(component, "duplicate"),
                                    },
                                  ]
                                : []),
                              ...(moveToProjects.length >= 1 &&
                              hasAccess("owner")
                                ? [
                                    {
                                      name: "Move to",
                                      className: "transfer",
                                      icon: (
                                        <IoArrowRedoOutline
                                          className="icon"
                                          fontSize={16}
                                        />
                                      ),
                                      onClick: () =>
                                        openModal(component, "moveTo"),
                                    },
                                  ]
                                : []),
                              ...(hasAccess("admin")
                                ? [
                                    {
                                      name: "Rename",
                                      className: "rename",
                                      icon: (
                                        <AiOutlineEdit
                                          className="icon"
                                          fontSize={16}
                                        />
                                      ),
                                      onClick: () =>
                                        openModal(component, "componentName"),
                                    },
                                  ]
                                : []),
                              {
                                name: "Analytics",
                                className: "analytics",
                                icon: (
                                  <TbBrandGoogleAnalytics
                                    className="icon"
                                    fontSize={16}
                                  />
                                ),
                                onClick: () => {
                                  window.location.href = getActionUrl(
                                    "analytics",
                                    component,
                                    apps
                                  );
                                },
                              },
                              {
                                name: "Live Preview",
                                className: "view",
                                icon: (
                                  <AiOutlineEye
                                    className="icon"
                                    fontSize={16}
                                  />
                                ),
                                onClick: () =>
                                  window.open(
                                    getActionUrl("view", component, apps),
                                    "_blank"
                                  ),
                              },
                              ...(hasAccess("admin")
                                ? [
                                    {
                                      name: "Delete",
                                      className: "delete",
                                      icon: (
                                        <MdDelete
                                          className="icon"
                                          fontSize={16}
                                        />
                                      ),
                                      onClick: () =>
                                        openModal(component, "delete"),
                                    },
                                  ]
                                : []),
                            ]}
                            icon={faEllipsisH}
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                  <Pagination
                    paginate={(page) => {
                      loadComponents(String(page));
                    }}
                    pages={Number(totalPages)}
                    currentPage={Number(page)}
                  />
                </div>
              )}
            </React.Fragment>
          )}
        </div>
        <div className="discover-plugins">
          <h3>Discover more widgets</h3>
          <div className="plugin-listing-wrapper">
            {state.discoverList.map((plugin: IPluginListingNew) => (
              <div
                key={`plugin_${plugin.componentType}`}
                className="plugin-listing"
              >
                <div className="plugin-listing-image">
                  {plugin.promotionImage.startsWith("https") ? (
                    <img
                      src={plugin.promotionImage}
                      alt={plugin.name}
                      width={"100%"}
                    />
                  ) : (
                    <NinjaIcon type={plugin.slug as any} />
                  )}
                </div>
                <div className="plugin-meta">
                  <h2>{plugin.name}</h2>
                  <p>{plugin.teaser}</p>
                </div>
                <div className="plugin-listing-actions">
                  <a
                    href={`${
                      hasAccess("admin")
                        ? `/${plugin.slug}/editor?projectId=${
                            projectId !== "uncategorized" ? projectId : ""
                          }`
                        : "#"
                    }`}
                    className="action"
                    onClick={(e) => {
                      if (!hasAccess("admin")) {
                        e.preventDefault();
                        toast.warn(
                          `Sorry, you can't create a new widget, please contact the project owner.`
                        );
                      }
                      // eventService.reportMixpanelEvent(
                      //   `widgets ${plugin.buttonText}`,
                      //   { authenticated: true }
                      // );
                    }}
                  >
                    <FontAwesomeIcon icon={faPlus} />
                    <span>{plugin.buttonText}</span>
                  </a>
                </div>
              </div>
            ))}
          </div>
        </div>
        {activeComponent && activeModal && (
          <React.Fragment>
            <Popup
              closeCallback={() =>
                setState((prevState) => ({
                  ...prevState,
                  activeComponent: null,
                  activeModal: null,
                }))
              }
              show={activeModal === "code"}
              className="developer-page popup-warn collection-popup popup-dashboard popup"
            >
              <header>
                <h3>Installation Code</h3>
              </header>
              <div className="content">
                {/* <article> */}
                <h4>
                  Place code wherever you want the plugin to appear on your
                  website (HTML editor, theme, template, etc).
                </h4>
                <DeveloperCopier type="textarea" value={getScript()} />
                <div className="horizontal">
                  <DeveloperCopier
                    label="Widget ID"
                    value={(activeComponent as IComponent).guid}
                  />
                </div>
              </div>
            </Popup>
            <Popup
              closeCallback={() =>
                setState((prevState) => ({
                  ...prevState,
                  activeComponent: null,
                  activeModal: null,
                  moveToProjectId: "",
                }))
              }
              show={activeModal === "moveTo"}
              className="developer-page popup-warn collection-popup "
              style={{ maxWidth: "400px" }}
            >
              <header>
                <h3>Move Widget</h3>
              </header>
              <div className="content">
                <div className="input-container">
                  <label htmlFor="project">Move to:</label>
                  <select
                    name="project"
                    value={state?.moveToProjectId}
                    onChange={(e: any) =>
                      setState((prevState) => ({
                        ...state,
                        moveToProjectId: e.target.value,
                      }))
                    }
                  >
                    <option value={""}>-Select project-</option>
                    {moveToProjects
                      .filter((p) => p.projectId !== projectId)
                      .map((project) => (
                        <option
                          key={project.projectId}
                          value={project.projectId}
                        >
                          {project.name}
                        </option>
                      ))}
                  </select>
                </div>
                <div className="popup-warn-buttons">
                  <button
                    className="button primary-button"
                    onClick={() => onMoveComponent()}
                  >
                    Move
                  </button>
                </div>
              </div>
            </Popup>
            <Popup
              closeCallback={() =>
                setState((prevState) => ({
                  ...prevState,
                  activeComponent: null,
                  activeModal: null,
                  moveToProjectId: "",
                }))
              }
              show={activeModal === "componentName"}
              className="developer-page popup-warn collection-popup "
              style={{ maxWidth: "400px" }}
            >
              <header>
                <h3>Change Name</h3>
              </header>
              <div className="content">
                <div className="input-container">
                  <label htmlFor="project">Name:</label>
                  <input
                    type="text"
                    value={(activeComponent as IComponent).name}
                    onChange={(e) =>
                      setState((prevState) => ({
                        ...prevState,
                        activeComponent: {
                          ...(activeComponent as any),
                          name: e.target.value,
                        },
                      }))
                    }
                  />
                </div>
                <div className="popup-warn-buttons">
                  <button
                    className="button primary-button"
                    onClick={() => onChangeComponentName()}
                  >
                    Save
                  </button>
                </div>
              </div>
            </Popup>
            <Popup
              closeCallback={() =>
                setState((prevState) => ({
                  ...state,
                  activeComponent: null,
                  activeModal: null,
                }))
              }
              show={activeModal === "duplicate"}
              className="developer-page popup-warn collection-popup "
            >
              <header>
                <h3>Duplicate Plugin</h3>
              </header>
              <div className="content">
                Are you sure you want to duplicate the plugin "
                <strong>{(activeComponent as IComponent).name}</strong>"?
                <div className="popup-warn-buttons">
                  <button
                    className="button secondary-button"
                    onClick={() =>
                      setState((prevState) => ({
                        ...prevState,
                        activeComponent: null,
                        activeModal: null,
                      }))
                    }
                  >
                    Close
                  </button>
                  <button
                    className="button primary-button"
                    onClick={duplicateComponent}
                  >
                    Duplicate
                  </button>
                </div>
              </div>
            </Popup>

            <Popup
              closeCallback={() =>
                setState((prevState) => ({
                  ...prevState,
                  activeComponent: null,
                  activeModal: null,
                }))
              }
              show={activeModal === "delete"}
              className="popup-dashboard popup-warn"
            >
              <DeveloperWarning
                title={`Are you completely sure you want to delete ${
                  (activeComponent as IComponent).name
                }?`}
                message="Note that action is irreversible. You will lose all data associated with plugin."
                confirm={{ label: "Delete", onClick: deleteComponent }}
                cancel={{
                  label: "Cancel",
                  onClick: () =>
                    setState((prevState) => ({
                      ...prevState,
                      activeComponent: null,
                      activeModal: null,
                    })),
                }}
              />
            </Popup>
            <Popup
              closeCallback={() =>
                setState((prevState) => ({
                  ...prevState,
                  activeComponent: null,
                  activeModal: null,
                }))
              }
              show={activeModal === "permissions"}
              className="permissions-popup popup-dashboard developer-page popup-warn collection-popup popup-dashboard"
            >
              <header>
                <h3>Plugin Permissions</h3>
              </header>
              <div className="content">
                <p>
                  Manage permissions for{" "}
                  <strong>
                    {(activeComponent as IComponent).name || "User"}
                  </strong>
                  .
                </p>
                {activeModal === "permissions" && (
                  <PermissionsComp
                    ownerDetails={
                      (activeComponent as IComponent).permissions[0]
                    }
                    component={activeComponent}
                    notificationSystem={props.notificationSystem}
                  />
                )}
              </div>
            </Popup>
          </React.Fragment>
        )}
      </section>
    </React.Fragment>
  );
};
