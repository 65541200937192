import React from 'react';

type TActiveButtonProps = {
  isActive: boolean;
  onClick: (name?: any) => void;
  isDisabled?: boolean;
  tabIndex?: number;
  size?: number;
  keys?: string[];
  customStyle?: React.CSSProperties;
};

export const ActiveButton = ({
  isActive,
  onClick,
  isDisabled = false,
  tabIndex = 0,
  size = 25,
  keys = ['Enter', ' '],
  customStyle = {},
}: TActiveButtonProps) => {
  return (
    <span
      style={{ ...customStyle }}
      className={`active-button ${isDisabled ? 'disabled' : ''}`}
    >
      <span
        {...(isDisabled && { tabIndex: -1 })}
        {...(!isDisabled && {
          tabIndex,
          onClick,
          onKeyDown: (e) => {
            if (keys.includes(e.key)) {
              e.preventDefault();
              onClick();
            }
          },
        })}
        style={{ width: size * 2 + 'px' }}
        className={`switch ${isActive ? 'active' : ''}`}
      >
        <div
          className="slider"
          style={{ height: size + 'px', width: size + 'px' }}
        ></div>
      </span>
    </span>
  );
};
