import React, { useState } from 'react';
import { H2 } from '@commonninja/commonninja-styleguide-react/lib/esm/components/internal';

import Payments from '../payments/payments.comp';
import { Subscriptions } from '../subscriptions/subscriptions.comp';

import './billing.scss';
import { Sidebar } from '../sidebar/sidebar.comp';

export const Billing = () => {
  const [activeTab, setActiveTab] = useState('subscriptions');

  return (
    <>
      <Sidebar />
      <section className={`billing main-section ${activeTab}`}>
        <header>
          <hgroup className="page-titles">
            <H2>Billing</H2>
          </hgroup>
        </header>
        <nav className="tabs-wrapper">
          <ul className="tabs">
            <li
              className={`tab-trigger ${activeTab === 'subscriptions' ? 'active' : ''
                }`}
              onClick={(e) => setActiveTab('subscriptions')}
            >
              Subscriptions
            </li>
            <li
              className={`tab-trigger ${activeTab === 'payments' ? 'active' : ''
                }`}
              onClick={(e) => setActiveTab('payments')}
            >
              Payments History
            </li>
          </ul>
        </nav>
        {activeTab === 'subscriptions' ? <Subscriptions /> : <Payments />}
      </section>
    </>
  );
};