import { Popup } from '@commonninja/commonninja-styleguide-react';
import React, { useEffect, useState, useContext } from 'react';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';

import { IAppPlanFeature } from '../../developer.types';
import { DeveloperContext } from '../../developer.context';
import { developerService } from '../../../../services/developer.service';
import { FeatureEditor } from './featureEditor/featureEditor.comp';
import { SortableAppCards } from '../common/sortableAppCards.comp';
import { DLoader } from '../../loader/loader.comp';

import './features.scss';

export const Features = () => {
  const emptyFeature: IAppPlanFeature = {
    id: '',
    name: '',
    displayName: '',
    description: '',
    type: 'number',
    value: 0,
    meta: {},
  };
  const {
    activeApp: { appId },
  } = useContext(DeveloperContext);
  const [features, setFeatures] = useState<IAppPlanFeature[]>([]);
  const [editorOpened, setEditorOpened] = useState(false);
  const [loading, setLoading] = useState(true);
  const [editorFeature, setEditorFeature] = useState(emptyFeature);
  const [editorFeatureIndex, setEditorFeatureIndex] = useState(-1);
  const [removeFeatureWarning, setRemoveFeatureWarning] = useState(false);
  const editMode = editorFeatureIndex !== features.length;

  const handleEditorClose = () => {
    setEditorOpened(false);
    setEditorFeatureIndex(-1);
    setEditorFeature(emptyFeature);
  };
  const handleEditorOpen = (feature: IAppPlanFeature, index: number) => {
    setEditorOpened(true);
    setEditorFeature(feature);
    setEditorFeatureIndex(index);
  };
  const handleNewFeature = (feature: IAppPlanFeature) => {
    const newFeatures = [...features, feature];
    updateFeaturesInDatabase(newFeatures);
    setFeatures(newFeatures);
  };
  const handleRemoveFeature = (feature: IAppPlanFeature, { confirmed = false }) => {
    const idx = features.findIndex((f) => f.name === feature.name);
    if (!confirmed) {
      setRemoveFeatureWarning(true);
      setEditorFeature(feature);
      return;
    }
    const newFeatures = [...features];
    newFeatures.splice(idx, 1);
    updateFeaturesInDatabase(newFeatures);
    setFeatures(newFeatures);
    setRemoveFeatureWarning(false);
  };
  const updateFeaturesInDatabase = (features: IAppPlanFeature[]) => {
    if (new Set(features.map((f) => f.name)).size !== features.length) {
      throw new Error('Feature names must be unique');
    }
    try {
      developerService.updateAppFeatures(appId, features);
      handleEditorClose();
    } catch (error) {
      console.error(error);
    }
  };
  const handleFeatureChange = (feature: IAppPlanFeature) => {
    const newFeatures = [...features];
    newFeatures[editorFeatureIndex] = feature;
    updateFeaturesInDatabase(newFeatures);
    setFeatures(newFeatures);
    handleEditorClose();
  };
  const onSortEnd = ({ oldIndex, newIndex }: any) => {
    const newFeatures = [...features];
    newFeatures.splice(newIndex, 0, newFeatures.splice(oldIndex, 1)[0]);
    updateFeaturesInDatabase(newFeatures);
    setFeatures(newFeatures);
  };

  useEffect(() => {
    const fetchFeatures = async () => {
      try {
        const {
          data: { features },
        } = await developerService.getAppFeatures(appId);
        setFeatures(features);
        setLoading(false);
      } catch (error) {
        toast.error(
          'There was a problem getting the plan info: ' +
          (error as Error).message,
        );
        setLoading(false);
      }
    };
    fetchFeatures();
  }, [appId]);

  return (
    <div className="features">
      {loading ? (
        <DLoader />
      ) : (
        <>
          <div className="features-container app-content-wrapper">
            <SortableAppCards
              onSortEnd={onSortEnd}
              buttonText="Add a Feature"
              onAdd={() => handleEditorOpen(emptyFeature, features.length)}
              items={features.map((feature, index) => {
                return {
                  onClick: () => handleEditorOpen(feature, index),
                  onRemove: () => handleRemoveFeature(feature, { confirmed: false }),
                  header: feature.name,
                  content: feature.type,
                  footer: 'default value: ' + feature.value,
                  idx: index,
                };
              })}
            />
          </div>
          <Popup
            show={editorOpened}
            closeCallback={handleEditorClose}
            className="developer-page app-section"
          >
            <h2 className="separator-light">
              {editMode ? 'Edit' : 'Add'} Feature
            </h2>
            <FeatureEditor
              feature={editorFeature}
              onChange={editMode ? handleFeatureChange : handleNewFeature}
              nameReadonly={editMode}
              onRemove={() =>
                handleRemoveFeature(editorFeature, { confirmed: false })
              }
            />
          </Popup>
          <Popup
            show={removeFeatureWarning}
            closeCallback={() => setRemoveFeatureWarning(false)}
            className="developer-page popup-warn"
          >
            <header>
              <FontAwesomeIcon
                icon={faExclamationCircle}
                color="#fabf5e"
                size="2x"
              />
              <h3>Are you sure you want to remove this feature?</h3>
            </header>
            <p className="popup-warn-text">
              This will remove the feature from all of the app plans. This is
              irreversible.
            </p>
            <div className="popup-warn-buttons">
              <div
                className="button secondary-button"
                onClick={() => setRemoveFeatureWarning(false)}
              >
                Cancel
              </div>
              <div
                className="button primary-button"
                onClick={() =>
                  handleRemoveFeature(editorFeature, { confirmed: true })
                }
              >
                Remove
              </div>
            </div>
          </Popup>
        </>
      )}
    </div>
  );
};
