import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { developerService } from '../../../../services/developer.service';
import { DeveloperContext } from '../../developer.context';

export function NewAppPopup() {
  const [localName, setLocalName] = useState<string>('');
  const { setActiveApp, apps, setApps } = useContext(DeveloperContext);
  const history = useHistory();
  async function createApp(e: any) {
    e.preventDefault();
    try {
      toast.loading('Creating app...');
      const { data } = await developerService.createDeveloperApp({
        name: localName || '',
      });
      toast.dismiss();
      setActiveApp(data);
      setApps([...apps, data]);
      history.push(`/developer/apps/${data.appId}`);
    } catch (e) {
      toast.error((e as Error).message);
    }
  }
  return (
    <>
      <h2>App Name</h2>
      <section>
        <p>What would be the name of your app?</p>
        <form onSubmit={createApp}>
          <div className="form-row">
            <input
              type={'text'}
              value={localName}
              onChange={(e) => setLocalName(e.target.value)}
              placeholder="Enter app name..."
              required
              minLength={3}
              maxLength={50}
              autoFocus
            />
          </div>
          <div className="buttons-wrapper center">
            <button className="button primary-button" type="submit">
              Create App
            </button>
          </div>
        </form>
      </section>
    </>
  );
}
