import React, { Suspense, useContext, useEffect, useState } from "react";
import { Popup, Tabs } from "@commonninja/commonninja-styleguide-react";
// import { BsCheckCircleFill } from 'react-icons/bs';
import { Checkbox } from "../../../common/checkbox/checkbox.comp";
import { DeveloperInput } from "../../../newDashboardComponents/developerInput.comp";
import {
	ICollectionSchemaAttribute,
	TCollectionSchemaAttribute,
} from "../../types";
import { FieldTypesPopup } from "../fieldTypesPopup/fieldTypesPopup.comp";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import { developerService } from "../../../../../services/developer.service";
import { DeveloperContext } from "../../../developer.context";
import JSONEditor from "../../../appPayments/common/jsonEditor.comp";
import { DatePicker } from "../../../common/datePicker/datePicker.comp";
import dayjs from "dayjs";
import { camelize } from "../../helpers";

interface IFieldTypesPopup {
	type: string;
	selectedField?: string;
	collectionName: string;
	showPopup: boolean;
	loading: boolean;
	setShowPopup: React.Dispatch<React.SetStateAction<boolean>>;
	setSelectedField: React.Dispatch<React.SetStateAction<string>>;
	onCreate: (newField: ICollectionSchemaAttribute) => void;
}

const emptyField = (): ICollectionSchemaAttribute => ({
	id: "",
	name: "",
	displayName: "",
	defaultValue: "",
	type: "",
	subtype: null,
	validation: {
		required: false,
		indexable: false,
		unique: false,
		private: false,
		array: false,
		maxLength: null,
		minLength: null,
		enum: [],
	},
});

export const FieldCreationPopup = ({
	showPopup,
	setShowPopup,
	type,
	onCreate,
	collectionName,
	selectedField,
	setSelectedField,
	loading,
}: IFieldTypesPopup) => {
	const { activeApp } = useContext(DeveloperContext);
	const [openArrayFieldPopup, setOpenArrayFieldPopup] = useState(false);
	const [arrayFieldType, setArrayFieldType] = useState("");
	const [numberCheckboxes, setNumberCheckboxes] = useState({
		maxLength: false,
		minLength: false,
	});
	const [newField, setNewField] = useState<ICollectionSchemaAttribute>(
		emptyField()
	);

	function createField() {
		const fieldCopy: ICollectionSchemaAttribute = JSON.parse(
			JSON.stringify(newField)
		);

		fieldCopy.id = fieldCopy?.id || uuidv4();
		fieldCopy.type = type as TCollectionSchemaAttribute;

		if (fieldCopy.type === "array") {
			if (!arrayFieldType) return toast.warning("Missing array type field");
			fieldCopy.subtype = arrayFieldType as TCollectionSchemaAttribute;
			fieldCopy.validation.array = true;
		}

		if (!fieldCopy.name || !fieldCopy.displayName)
			return toast.warning("Missing required fields");

		onCreate(fieldCopy);
		setNewField({
			id: "",
			name: "",
			displayName: "",
			defaultValue: "",
			type: "",
			subtype: null,
			validation: {
				required: false,
				indexable: false,
				unique: false,
				private: false,
				array: false,
				maxLength: null,
				minLength: null,
				enum: [],
			},
		});
	}

	function onHandleNumberCheckbox(keyName: "maxLength" | "minLength") {
		if (numberCheckboxes[keyName]) {
			setNewField({
				...newField,
				validation: { ...newField.validation, [keyName]: null },
			});
		}
		setNumberCheckboxes({
			...numberCheckboxes,
			[keyName]: !numberCheckboxes[keyName],
		});
	}

	function onEditNumberFields(e: any, keyName: "maxLength" | "minLength") {
		const fieldCopy = JSON.parse(JSON.stringify(newField));
		fieldCopy["validation"][keyName] = numberCheckboxes[keyName]
			? Number(e.target.value)
			: null;
		setNewField(fieldCopy);
	}

	function onEditField(e: any, keyName: string) {
		const fieldCopy = JSON.parse(JSON.stringify(newField));
		fieldCopy[keyName] = e.target.value;
		if (keyName === "displayName") {
			fieldCopy["name"] = camelize(e.target.value);
		}
		if (keyName === "name") {
			fieldCopy["name"] = camelize(e.target.value);
		}
		setNewField(fieldCopy);
	}

	function onEditJSONFiled(json: object, keyName: string) {
		setNewField({ ...newField, [keyName]: json });
	}

	function onEditDateField(e: any, keyName: string) {
		const date = new Date(e).toLocaleDateString();
		const formattedDate = dayjs(date).format("MM/DD/YYYY");
		setNewField({ ...newField, [keyName]: formattedDate });
	}

	function onEditFieldValidation(e: any, keyName: string) {
		const fieldCopy = JSON.parse(JSON.stringify(newField));
		fieldCopy["validation"][keyName] = e.target.checked;
		setNewField(fieldCopy);
	}

	function renderTab(activeTab: string) {
		if (activeTab === "advanced") return renderAdvancedTab();
		return renderBasicTab();
	}

	async function getSelectedField(selectedFieldName: string) {
		try {
			const { data: schema } = await developerService.getCollectionSchema(
				activeApp.appId,
				collectionName
			);
			const selectedField = schema.attributes.find(
				(field: any) => field.name === selectedFieldName
			);
			setNewField(selectedField);
		} catch (e) {
			toast.error("Oops, something went wrong");
		}
	}

	useEffect(() => {
		if (selectedField) {
			getSelectedField(selectedField);
		} else {
			setNewField(emptyField());
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedField]);

	function renderCorrectInputType(field: ICollectionSchemaAttribute) {
		if (
			type === "text" ||
			type === "password" ||
			type === "email" ||
			type === "uid" ||
			type === "url"
		) {
			return (
				<input
					type="text"
					name="defaultValue"
					title="Default Value"
					placeholder="Enter Default Value..."
					value={newField.defaultValue}
					onChange={(e) => onEditField(e, "defaultValue")}
				/>
			);
		}

		if (type === "number") {
			return (
				<DeveloperInput
					className="number-select"
					type="number"
					name="number"
					value={newField.defaultValue}
					handleChange={(e) => onEditField(e, "defaultValue")}
					numberMaxLength={field.validation?.maxLength || undefined}
					numberMinLength={field.validation?.minLength || undefined}
				/>
			);
		}

		if (type === "boolean") {
			return (
				<DeveloperInput
					type="checkbox"
					name="boolean"
					value={newField.defaultValue || false}
					handleChange={(e) => onEditField(e, "defaultValue")}
				/>
			);
		}

		if (type === "json") {
			return (
				<Suspense fallback={<div>Loading...</div>}>
					<JSONEditor
						value={newField.defaultValue || {}}
						onChange={(j) => onEditJSONFiled(j, "defaultValue")}
					/>
				</Suspense>
			);
		}

		if (type === "date") {
			return (
				<DatePicker
					type="single"
					value={newField.defaultValue}
					onChange={(e) => onEditDateField(e, "defaultValue")}
					format="MM/DD/YYYY"
				/>
			);
		}

		if (type === "enumeration") {
			return (
				<Suspense fallback={<div>Loading...</div>}>
					<JSONEditor
						value={newField.defaultValue || []}
						onChange={(j) => onEditJSONFiled(j, "defaultValue")}
					/>
				</Suspense>
			);
		}

		if (type === "rich-text") {
			return (
				<textarea
					value={newField.defaultValue}
					cols={30}
					rows={10}
					onChange={(e) => onEditField(e, "defaultValue")}
				/>
			);
		}

		// if (type === 'relation') { }
	}

	function renderBasicTab() {
		return (
			<div className="input-container">
				<label htmlFor="name">Display Name</label>
				<div>
					<input
						type="text"
						name="name"
						title="Field Display Name"
						placeholder="Enter Display Name..."
						value={newField.displayName}
						onChange={(e) => onEditField(e, "displayName")}
					/>
				</div>
				<label htmlFor="name">Name</label>
				<div>
					<input
						type="text"
						name="name"
						title="Field Name"
						placeholder="Enter Name..."
						value={newField.name}
						onChange={(e) => onEditField(e, "name")}
					/>
				</div>
				{type === "array" && (
					<>
						<label htmlFor="name">Array Type</label>
						<div>
							<input
								type="text"
								className="input-select"
								name="name"
								title="Array Type"
								placeholder="Click to add array type..."
								value={arrayFieldType}
								onClick={() => setOpenArrayFieldPopup(true)}
							/>
						</div>
						<FieldTypesPopup
							title="Select array type"
							exclude="array"
							showPopup={openArrayFieldPopup}
							setShowPopup={setOpenArrayFieldPopup}
							onSelectFieldType={(type) => {
								setArrayFieldType(type);
								setOpenArrayFieldPopup(false);
							}}
						/>
					</>
				)}
				{/* <label htmlFor="type">Type</label>
                <div className="box-select-container">
                    <div className="box">
                        <BsCheckCircleFill className='checked' />
                        <h4>Use Common Ninja</h4>
                        <p>place holder and text bluh bluh jonathan this one’s for ya.</p>
                    </div>
                    <div className="box active">
                        <BsCheckCircleFill className='checked' />
                        <h4>Use Common Ninja</h4>
                        <p>place holder and text bluh bluh jonathan this one’s for ya.</p>
                    </div>
                </div> */}
			</div>
		);
	}

	function renderAdvancedTab() {
		return (
			<div className="input-container">
				{type !== "array" && (
					<label htmlFor="defaultValue">Default Value</label>
				)}
				{renderCorrectInputType(newField)}
				<label htmlFor="settings">Settings</label>
				<div className="settings-container">
					<div className="checkbox-field">
						<div className="title-wrapper">
							<Checkbox
								defaultChecked={newField.validation.required}
								onChange={(e) => onEditFieldValidation(e, "required")}
							/>
							<h4>Required Field</h4>
						</div>
						<p>
							A mandatory field to input, without which an entry can’t be made.
						</p>
					</div>
					<div className="checkbox-field">
						<div className="title-wrapper">
							<Checkbox
								defaultChecked={newField.validation.unique}
								onChange={(e) => onEditFieldValidation(e, "unique")}
							/>
							<h4>Unique field</h4>
						</div>
						<p>
							You won't be able to create an entry if there is an existing entry
							with identical content.
						</p>
					</div>
					<div className="checkbox-field">
						<div className="title-wrapper">
							<Checkbox
								defaultChecked={newField.validation.indexable}
								onChange={(e) => onEditFieldValidation(e, "indexable")}
							/>
							<h4>Indexable</h4>
						</div>
						<p>For efficient execution of DB queries.</p>
					</div>
					<div className="checkbox-field">
						<div className="title-wrapper">
							<Checkbox
								defaultChecked={newField.validation.private}
								onChange={(e) => onEditFieldValidation(e, "private")}
							/>
							<h4>Private field</h4>
						</div>
						<p>A field that will not show up in the API response.</p>
					</div>
					<div className="checkbox-field">
						<div className="title-wrapper">
							<Checkbox
								defaultChecked={numberCheckboxes.maxLength}
								onChange={() => onHandleNumberCheckbox("maxLength")}
							/>
							<h4>Maximum length</h4>
							<div className="number-container">
								<DeveloperInput
									className="number-select"
									type="number"
									name="maxlength"
									value={newField.validation?.maxLength || 0}
									disabled={!numberCheckboxes.maxLength}
									handleChange={(e) => onEditNumberFields(e, "maxLength")}
								/>
							</div>
						</div>
						<p>The maximum length available for input.</p>
					</div>
					<div className="checkbox-field">
						<div className="title-wrapper">
							<Checkbox
								defaultChecked={numberCheckboxes.minLength}
								onChange={() => onHandleNumberCheckbox("minLength")}
							/>
							<h4>Minimum length</h4>
							<div className="number-container">
								<DeveloperInput
									className="number-select"
									type="number"
									name="minLength"
									value={newField.validation?.minLength || 0}
									disabled={!numberCheckboxes.minLength}
									handleChange={(e) => onEditNumberFields(e, "minLength")}
								/>
							</div>
						</div>
						<p>The minimum length to input.</p>
					</div>
				</div>
			</div>
		);
	}

	return (
		<Popup
			show={showPopup}
			closeCallback={() => {
				setShowPopup(false);
				setNewField(emptyField);
				setSelectedField("");
			}}
			className="developer-page popup-warn collection-popup field-types-popup"
		>
			<header>
				<h3>{!selectedField ? `Add ${type} field` : `Update ${type} field`}</h3>
			</header>
			<Tabs
				items={[
					{ name: "Basic Settings", id: "basic" },
					{ name: "Advanced Settings", id: "advanced" },
				]}
				resolveTabComp={(activeTab) => {
					return renderTab(activeTab as string);
				}}
			/>
			<div className="popup-warn-buttons">
				<button
					className={`button primary-button ${loading ? "loading" : ""}`}
					onClick={createField}
				>
					{!selectedField
						? ` ${loading ? "Creating..." : "Add Field"}`
						: `${loading ? "Updating" : "Update Field"}`}
				</button>
			</div>
		</Popup>
	);
};
