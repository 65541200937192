import React from "react";
import { Button } from "@commonninja/commonninja-styleguide-react";

import googleLogo from "../../assets/google-logo.svg";
import githubLogo from "../../assets/github-icon.svg";
import webflowLogo from "../../assets/webflow-icon.png";

export const SocialLogin = ({
  clickCallback,
  successCallback,
  isDeveloper,
}: {
  isDeveloper: boolean;
  successCallback: (isNew?: boolean) => void;
  clickCallback?: () => void;
}) => {
  function openPopup(e: any, platform: string) {
    e.preventDefault();

    if (clickCallback) {
      clickCallback();
    }

    window.open(`/auth/${platform}`, "authWindow");

    window.addEventListener("message", ({ data }) => {
      if (!data) {
        return;
      }

      let eventData: any = {};
      try {
        eventData = typeof data === "string" ? JSON.parse(data) : data;
      } catch (err) {
        // console.log('Unhandled json parsing.');
      }

      switch (eventData.type) {
        case "COMMONNINJA_POST_SOCIAL_LOGIN":
          successCallback(eventData.isNew);
          break;
      }
    });
  }

  return (
    <div className="social-login">
      <Button
        className="auth-provider-btn"
        onClick={(e: any) => openPopup(e, "google")}
        title="Login with Google"
      >
        <span className="auth-provider-icon-wrapper">
          <img
            className="auth-provider-icon"
            alt="Google Logo"
            src={googleLogo}
          />
        </span>
        <span className="auth-provider-btn-text">Sign in with Google</span>
      </Button>
      <div className="row">
        <Button
          className="auth-provider-btn"
          onClick={(e: any) => openPopup(e, "github")}
          title="Login with GitHub"
        >
          <span className="auth-provider-icon-wrapper">
            <img
              className="auth-provider-icon"
              alt="GitHub icon"
              src={githubLogo}
            />
          </span>
          <span className="auth-provider-btn-text">GitHub</span>
        </Button>
        <Button
          className="auth-provider-btn"
          onClick={(e: any) => openPopup(e, "webflow")}
          title="Login with Webflow"
        >
          <span className="auth-provider-icon-wrapper">
            <img
              className="auth-provider-icon"
              alt="Webflow icon"
              src={webflowLogo}
            />
          </span>
          <span className="auth-provider-btn-text">Webflow</span>
        </Button>
      </div>
    </div>
  );
};
