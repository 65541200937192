import React, { ReactElement, useContext, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { DeveloperInput } from "../developer/newDashboardComponents/developerInput.comp";
import defaultImage from "../../assets/placeholder.png";
import { AiFillFire, AiFillStar } from "react-icons/ai";
import { RiLeafFill } from "react-icons/ri";
import { Sidebar } from "../sidebar/sidebar.comp";
import { IPluginListingNew } from "../../services/appList.service";
import logoMask from "../../assets/logo-mask.png";
import { IoMdClose } from "react-icons/io";
import { toast } from "react-toastify";
import { IProject } from "../projects/projects.comp";
import { ProjectPermissionRoleLevel } from "../project/project.helpers";
import { ProjectContext } from "../projectDashboard/projectDashboard.context";

import "./widgetCatalog.scss";

export const WidgetCatalog = ({
  standalone,
  apps,
  additionalFilters,
  additionalAppElement,
  onAppClick,
  appClassGenerator,
}: {
  standalone?: boolean;
  apps?: IPluginListingNew[];
  additionalFilters?: {
    name: string;
    filter: (app: IPluginListingNew) => boolean;
  }[];
  additionalAppElement?: (app: IPluginListingNew) => ReactElement;
  onAppClick?: (app: IPluginListingNew) => void;
  appClassGenerator?: (app: IPluginListingNew) => string;
}) => {
  const { projectId = "" } = useParams() as any;
  const { activeProject, apps: contextApps } = useContext(ProjectContext);
  const pluginList = (apps || contextApps).filter(
    (app) => app.status === "published"
  );
  const [filter, setFilter] = useState<any>({
    page: "home",
    name: "",
    tag: "",
    cat: "",
    customFilter: null,
  });
  const { pathname } = useLocation();
  const showProjectsSidebar = !(pathname || "")?.includes("projects");

  function hasAccess(minRole: IProject["userProjectRole"]) {
    const minLevel: number = (ProjectPermissionRoleLevel as any)[minRole] || 1;
    const userLevel =
      (ProjectPermissionRoleLevel as any)[activeProject.userProjectRole] || 1;
    return userLevel >= minLevel;
  }

  const tagList = pluginList.reduce(
    (acc: string[], plugin: IPluginListingNew) => {
      plugin.tags.forEach((tag) => {
        if (tag && !acc.includes(tag)) {
          acc.push(tag);
        }
      });
      return acc;
    },
    []
  );

  const catList = pluginList.reduce((acc: any, plugin: IPluginListingNew) => {
    plugin.categories.forEach((categorie) => {
      if (categorie && !acc[categorie]) acc[categorie] = 1;
      else acc[categorie] = acc[categorie] + 1;
    });
    return acc;
  }, {});

  const filteredPlugins = pluginList
    .filter(
      (p) =>
        !filter.customFilter || (filter.customFilter && filter.customFilter(p))
    )
    .filter((p: IPluginListingNew) =>
      p.name.toLowerCase().includes(filter.name.toLowerCase())
    )
    .filter((p) => (filter.tag ? p.tags.includes(filter.tag) : p))
    .filter((p) => (filter.cat ? p.categories.includes(filter.cat) : p));

  function renderSidebar() {
    return (
      <>
        <div className="filter-container">
          <form className="search-components" onSubmit={() => {}}>
            <DeveloperInput
              name="searchType"
              title="Search"
              placeholder="Search..."
              value={filter.name}
              handleChange={(e) =>
                setFilter({
                  ...filter,
                  page: "",
                  cat: "",
                  name: e.target.value,
                  customFilter: null,
                })
              }
            />
            {filter.name && (
              <IoMdClose
                onClick={() =>
                  setFilter({ ...filter, customFilter: null, name: "" })
                }
                className="reset"
                fontSize={20}
              />
            )}
          </form>
          <div className="categories">
            <button
              className={`cat ${filter.page === "home" ? "active" : ""}`}
              onClick={() =>
                setFilter({
                  ...filter,
                  cat: "",
                  tag: "",
                  name: "",
                  page: "home",
                  customFilter: null,
                })
              }
            >
              Home
            </button>
            {additionalFilters?.map((fil) => (
              <button
                className={`cat ${filter.page === fil.name ? "active" : ""}`}
                onClick={() =>
                  setFilter({
                    ...filter,
                    cat: "",
                    tag: "",
                    name: "",
                    page: fil.name,
                    customFilter: fil.filter,
                  })
                }
              >
                {fil.name}
              </button>
            ))}
            <div className="divider"></div>
            <p className="cat-title">Categories</p>
            <button
              className={`cat ${
                !filter.page && !filter.tag && !filter.cat ? "active" : ""
              }`}
              onClick={() =>
                setFilter({
                  ...filter,
                  customFilter: null,
                  page: "",
                  cat: "",
                  tag: "",
                  name: "",
                })
              }
            >
              All ({pluginList.length})
            </button>
            {Object.keys(catList).map((cat) => (
              <button
                key={cat}
                className={`cat ${
                  !filter.page && filter.cat === cat ? "active" : ""
                }`}
                onClick={() =>
                  setFilter({
                    ...filter,
                    customFilter: null,
                    page: "",
                    tag: "",
                    name: "",
                    cat,
                  })
                }
              >
                {cat.split("-")[0]} ({catList[cat]})
              </button>
            ))}
          </div>
        </div>
      </>
    );
  }

  function renderApps(appsList: IPluginListingNew[]) {
    if (pluginList.length === 0) {
      return <div className="apps-container">Loading...</div>;
    }

    return (
      <div
        className={`apps-container ${appsList.length === 1 ? "single" : ""}`}
      >
        {appsList.length > 0 &&
          appsList.map((plugin: IPluginListingNew) => (
            <div
              key={plugin.name}
              className={`app ${appClassGenerator?.(plugin)}`}
              onClick={() => onAppClick?.(plugin)}
            >
              <img
                src={plugin.catalogImage || defaultImage}
                alt={plugin.name}
              />
              <div className="details">
                <h4>{plugin.name}</h4>
                <p>{plugin.description || plugin.teaser}</p>
              </div>
              {additionalAppElement?.(plugin)}
              {!standalone && (
                <div className="plugin-listing-actions">
                  <a
                    href={`${
                      hasAccess("admin") || showProjectsSidebar
                        ? `/${plugin.slug}/editor?projectId=${
                            projectId !== "uncategorized" ? projectId : ""
                          }`
                        : "#"
                    }`}
                    onClick={(e) => {
                      if (!hasAccess("admin") && !showProjectsSidebar) {
                        e.preventDefault();
                        toast.warn(
                          `Sorry, you can't create a new widget, please contact the project owner.`
                        );
                      }
                    }}
                    className="action"
                  >
                    <span>+ {plugin.buttonText}</span>
                  </a>
                </div>
              )}
            </div>
          ))}
        {!appsList.length && (
          <div className="no-items">
            <img src={logoMask} alt="common ninja logo" />
            <p>Widget not found</p>
          </div>
        )}
      </div>
    );
  }

  function renderHome() {
    return (
      <div className="widget-catalog-home">
        {tagList.map((tag) => {
          return (
            <div className="home-section" key={tag}>
              <h3>
                {tag.toLowerCase().includes("popular") && (
                  <AiFillStar className="icon" />
                )}
                {tag.toLowerCase().includes("trending") && (
                  <AiFillFire className="icon" />
                )}
                {tag.toLowerCase().includes("new") && (
                  <RiLeafFill className="icon" />
                )}
                {tag.split("-")[0]}
              </h3>
              {renderApps(pluginList.filter((p) => p.tags.includes(tag)))}
            </div>
          );
        })}
      </div>
    );
  }

  return (
    <>
      {!standalone && (
        <Sidebar variant={`${showProjectsSidebar ? "projects" : "widgets"}`} />
      )}
      <section className="widget-catalog main-section">
        <div className="inner">
          {!standalone && (
            <header>
              <hgroup className="page-titles">
                <h2>Widget catalog</h2>
              </hgroup>
            </header>
          )}
          <div className="main-content-wrapper">
            {renderSidebar()}
            {filter.page === 'home' ? renderHome() : renderApps(filteredPlugins)}
          </div>
        </div>
      </section>
    </>
  );
};
