import { IPluginListingNew } from '../services/appList.service';

// declare let gtag: Function;
// declare let ga: Function;

const baseUrl = process.env.REACT_APP_SERVICE_URL || '';

// export function sendConversionEventToGA(
//   value: number = 0,
//   serviceName: string = 'bracketsninja',
// ) {
//   const conversionId = 'NT6MCIzMi-IBEI_Y17wC'; // Purchase conversion
//   const conversionObj: any = {
//     send_to: `AW-664136719/${conversionId}`,
//   };

//   if (value) {
//     conversionObj.currency = 'USD';
//     conversionObj.value = value;
//   }

//   if (
//     window.location.href.includes('localhost:') ||
//     typeof gtag === 'undefined'
//   ) {
//     console.log('gtag event', conversionObj);
//     return;
//   }

//   // Send to GA
//   ga(
//     'send',
//     'event',
//     'Clicks',
//     'purchase',
//     serviceName || '',
//     Math.ceil(value || 0),
//   );

//   // eslint-disable-next-line no-undef
//   // gtag('event', 'conversion', conversionObj);
// }

export function capitalizeFirstLetter(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function getActionUrl(
  actionType: 'edit' | 'view' | 'delete' | 'update' | 'duplicate' | 'analytics',
  component: any,
  apps: IPluginListingNew[],
  projectId?: string,
) {
  const { type, guid, galleryId } = component;
  const projectQuery =
  projectId && projectId !== 'uncategorized' ? `?projectId=${projectId}` : '';
  
  const appDetails = apps.find(({ componentType }) => componentType === type);

  if (!appDetails) {
    return '';
  }

  switch (actionType) {
    case 'edit':
      return `${baseUrl}/${appDetails.slug}/editor/view/${
        guid || ''
      }${projectQuery}`;
    case 'analytics':
      return `${baseUrl}/${appDetails.slug}/editor/analytics/${guid || ''}`;
    case 'view':
      return `https://commoninja.site/${galleryId || ''}`;
    case 'duplicate':
      return `/api/v1/plugin/${guid}/duplicate?serviceName=${appDetails.serviceName}`;
    case 'update':
      return `/api/v1/plugin/${guid}?serviceName=${appDetails.serviceName}`;
    case 'delete':
      return `/api/v1/plugin/${guid}?serviceName=${appDetails.serviceName}`;
    default:
      return '';
  }
}

export function checkIfAuthPage(): boolean {
  const { pathname } = window.location;

  if (pathname.includes('/login')) {
    return true;
  }

  if (pathname.includes('/signup')) {
    return true;
  }

  if (pathname.includes('/join')) {
    return true;
  }

  if (pathname.includes('/reset')) {
    return true;
  }

  if (pathname.includes('/confirm')) {
    return true;
  }

  if (pathname.includes('/forgot')) {
    return true;
  }

  if (pathname.includes('/invitation')) {
    return true;
  }

  if (pathname.includes('/feedback')) {
    return true;
  }

  return false;
}

export function clearRedirectUrl(url: string = '') {
  if (
    url.includes('commoninja.com') ||
    url.includes('localhost:') ||
    url.startsWith('/user/dashboard') ||
    url.startsWith('/dashboard') ||
    url.startsWith('/embed') ||
    url.startsWith('/pricing') ||
    url.startsWith('/invitation') ||
    url.startsWith('/developer') ||
    url.startsWith('/oauth')
  ) {
    return url;
  }
  return null;
}
