import React from 'react';
import { IAppPlan } from '../../../../developer.types';
import { DeveloperInput } from '../../../../newDashboardComponents/developerInput.comp';
import { useHandleChange } from '../../../../newDashboardComponents/hooks/useHandleChange';
import { ActiveButton } from '../../../common/activeButton.comp';

type TPlanGeneralProps = {
  plan: IAppPlan;
  setPlan: (plan: IAppPlan) => void;
  isProviderConnected: boolean;
};
export const PlanGeneral = ({
  plan,
  setPlan,
  isProviderConnected,
}: TPlanGeneralProps) => {
  const toggleActive = (name: keyof IAppPlan) => {
    setPlan({ ...plan, [name]: !plan[name] });
  };

  const [handleChange] = useHandleChange(setPlan);

  return (
    <>
      <DeveloperInput
        className="plan-name"
        name="name"
        label="Plan Name"
        disabled={isProviderConnected}
        value={plan.name}
        handleChange={handleChange}
        required
      />
      <DeveloperInput
        textarea
        name="description"
        label="Plan Description"
        value={plan.description}
        handleChange={handleChange}
      />
      <div className="inputs-horizontal">
        <div className="input-container">
          <label>Free Plan</label>
          <ActiveButton
            size={22.5}
            isDisabled={isProviderConnected}
            isActive={plan.isFree}
            onClick={() => toggleActive('isFree')}
          />
        </div>
        <div className="number-container">
          <DeveloperInput
            className="trial-count small"
            type="number"
            name="trialCount"
            numberMaxLength={3}
            label="Trial Period (days)"
            value={plan.trialCount ? plan.trialCount : 0}
            handleChange={handleChange}
            disabled={isProviderConnected}
          />
        </div>
      </div>
    </>
  );
};
