import { Component } from 'react';

class Embed extends Component<any, any> {
  componentDidMount() {
    this.handleEmbedIfNeeded(true);
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    window.setTimeout(() => {
      this.updateParentOnDimensions();
    }, 50);
  }

  componentWillUnmount() {
    this.handleEmbedIfNeeded(false);
  }

  addEventListeners() {
    window.addEventListener('message', this.handleMessageEvent);
    window.addEventListener('resize', this.updateParentOnDimensions);
  }

  removeEventListeners() {
    window.removeEventListener('message', this.handleMessageEvent);
    window.removeEventListener('resize', this.updateParentOnDimensions);
  }

  updateParentOnDimensions() {
    window.parent.postMessage({
      type: 'COMMONNINJA_DASHBOARD_IFRAME_UPDATE',
      height: (document.querySelector('#main-content') as HTMLBodyElement).clientHeight + 20
    }, '*');
  }

  handleMessageEvent(e: any) {
    if (!e.data) {
      return;
    }

    try {
      const eventData = typeof e.data === 'string' ? JSON.parse(e.data) : e.data;
  
      switch (eventData.type) {
        case 'COMMONNINJA_DASHBOARD_PATH_CHANGE':
          window.location.href = eventData.url;
          break;
        default:
          return;
      }
    } catch (e) {}
  }

  handleEmbedIfNeeded(isMount: boolean = false) {
    if ((this.props as any).embedMode) {
      if (isMount) {
        this.addEventListeners();
      } else {
        this.removeEventListeners();
      }
    }
  }
}

export default Embed;