import React, { useEffect } from 'react';

// import { IAuthProps } from './auth.types';

export const Logout = () => {
  useEffect(() => {
    window.parent.postMessage({
      type: 'COMMONNINJA_AUTH_SUCCESS',
      json: {}
    }, '*');
    // eslint-disable-next-line
  }, []);

  return <></>;
}