import React, { useEffect, useState } from 'react';
import { Light as SyntaxHighlighter } from 'react-syntax-highlighter';
import json from 'react-syntax-highlighter/dist/esm/languages/prism/json';
import { ghcolors } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { toast } from 'react-toastify';

import { developerService } from '../../../../../services/developer.service';
import { DeveloperIcon } from '../../common/developerIcon.comp';

import './messagesViewer.scss';

interface IMessageViewer {
  messageRequest: any;
  getWebhook: () => void;
}

export const MessageViewer = ({ messageRequest, getWebhook }: IMessageViewer) => {
  const [resending, setResending] = useState(false);
  const [message, setMessage] = useState<any>({});

  function renderResponseCode() {
    const resCodeFirstDigit = String(messageRequest.response?.statusCode)[0];
    switch (resCodeFirstDigit) {
      case '2':
        return '#96ec93';
      case '3':
        return '#ffe057';
      case '4':
        return '#ffc04c';
      case '5':
        return '#fba6b3';
      default:
        return '#dedede';
    }
  }

  async function getMessage() {
    try {
      const { success, data } = await developerService.getAppWebhookMessageByRequestId(
        messageRequest.appId,
        messageRequest.id,
      );
      
      if (success) {
        setMessage(data?.body || data || {});
      }
    } catch (e) {
      setMessage({});
    }
  }

  async function resendMessage() {
    setResending(true);

    try {
      const { success } = await toast.promise(
        developerService.resendAppWebhookMessageRequest(
          messageRequest.appId,
          messageRequest.webhookId,
          messageRequest.id,
        ),
        {
          pending: 'Resending message',
          success: 'Resent message successfully!',
          error: 'Error resending message, please try again!',
        },
      );
      if (success) {
        getWebhook();
        setResending(false);
      }
    } catch (e) {
      toast.error((e as Error).message);
      setResending(false);
    }
  }

  useEffect(() => {
    getMessage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messageRequest?.id]);

  return (
    <div className="webhook-message-viewer">
      {messageRequest?.type && (
        <header className="webhook-message-header">
          <div className="webhook-message-type">{messageRequest.type}</div>

          <button
            onClick={resendMessage}
            disabled={resending}
            className="webhook-message-resend"
          >
            <DeveloperIcon
              name={!resending ? 'resend' : 'loading'}
              label="Resend"
              spacing={5}
            />
          </button>
        </header>
      )}
      <section className="webhook-message-content">
        <h4>Response</h4>

        <div className="webhook-message-response">
          {messageRequest?.response ? (
            <>
              <span
                className="webhook-messages-response-code"
                style={{
                  backgroundColor: renderResponseCode(),
                }}
              >
                {messageRequest.response.statusCode}
              </span>
              <span>{messageRequest.response.status}</span>
            </>
          ) : (
            <span>Select to view a message</span>
          )}
        </div>
      </section>
      <section className="webhook-message-content">
        <h4>Request</h4>
        <div className="webhook-message-request">
          <SyntaxHighlighter
            language={json}
            style={ghcolors}
            children={JSON.stringify(message, null, 4)}
            customStyle={{
              background: 'transparent',
              lineHeight: '1.65',
              fontSize: '14px',
              fontFamily: 'Courier',
            }}
            showLineNumbers
            lineNumberStyle={{ minWidth: '20px' }}
            wrapLongLines={true}
          />
        </div>
      </section>
    </div>
  );
};
