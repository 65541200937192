import React from 'react';
import { Card } from '../card/card.comp';
import './statsCard.scss';

interface IStatCardData {
    title: string,
    value: string;
}

interface IStatsCardProps {
    icon?: 'coins' | 'members' | 'exchange';
    title?: string;
    className?: string;
    data: IStatCardData[];
}

export const StatsCard = ({ icon, title, className = '', data }: IStatsCardProps) => {
    function renderVisualization() {
        if (icon === 'coins') return renderCoinsIcon();
        if (icon === 'exchange') return renderExchangeIcon();
        if (icon === 'members') return renderMembersIcon();
    }

    return (
        <Card className={`stats-card ${className}`}>
            {title &&
                <h3>{title}</h3>
            }
            <div className="content">
                {renderVisualization()}
                <div className="stats-container">
                    {(data && data.length) && data.map((data: IStatCardData) => (
                        <div className="stat" key={`stat-${data.title}`}>
                            <p className="stat-title">{data.title}</p>
                            <p>{data.value}</p>
                        </div>
                    ))}
                </div>
            </div>
        </Card>
    );
};

function renderCoinsIcon() {
    return (
        <svg className="stat-icon" width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M35.4166 50C28.3458 50 20.8333 47.8083 20.8333 43.75C20.8333 43.175 21.2999 42.7083 21.8749 42.7083C22.4499 42.7083 22.9166 43.175 22.9166 43.75C22.9166 45.4896 27.6708 47.9167 35.4166 47.9167C43.1624 47.9167 47.9166 45.4896 47.9166 43.75C47.9166 43.175 48.3833 42.7083 48.9583 42.7083C49.5333 42.7083 49.9999 43.175 49.9999 43.75C49.9999 47.8083 42.4874 50 35.4166 50Z" fill="#BABEC1" />
            <path d="M35.4166 43.75C28.3458 43.75 20.8333 41.5583 20.8333 37.5C20.8333 36.925 21.2999 36.4583 21.8749 36.4583C22.4499 36.4583 22.9166 36.925 22.9166 37.5C22.9166 39.2396 27.6708 41.6667 35.4166 41.6667C43.1624 41.6667 47.9166 39.2396 47.9166 37.5C47.9166 36.925 48.3833 36.4583 48.9583 36.4583C49.5333 36.4583 49.9999 36.925 49.9999 37.5C49.9999 41.5583 42.4874 43.75 35.4166 43.75Z" fill="#BABEC1" />
            <path d="M35.4166 37.5C28.3458 37.5 20.8333 35.3083 20.8333 31.25C20.8333 27.1917 28.3458 25 35.4166 25C42.4874 25 49.9999 27.1917 49.9999 31.25C49.9999 35.3083 42.4874 37.5 35.4166 37.5ZM35.4166 27.0833C27.6708 27.0833 22.9166 29.5104 22.9166 31.25C22.9166 32.9896 27.6708 35.4167 35.4166 35.4167C43.1624 35.4167 47.9166 32.9896 47.9166 31.25C47.9166 29.5104 43.1624 27.0833 35.4166 27.0833Z" fill="#BABEC1" />
            <path d="M48.9584 44.7917C48.3834 44.7917 47.9167 44.325 47.9167 43.75V31.25C47.9167 30.675 48.3834 30.2083 48.9584 30.2083C49.5334 30.2083 50.0001 30.675 50.0001 31.25V43.75C50.0001 44.325 49.5334 44.7917 48.9584 44.7917Z" fill="#BABEC1" />
            <path d="M21.8749 44.7917C21.2999 44.7917 20.8333 44.325 20.8333 43.75V31.25C20.8333 30.675 21.2999 30.2083 21.8749 30.2083C22.4499 30.2083 22.9166 30.675 22.9166 31.25V43.75C22.9166 44.325 22.4499 44.7917 21.8749 44.7917Z" fill="#BABEC1" />
            <path d="M14.5833 25C7.5125 25 0 22.8083 0 18.75C0 18.175 0.466667 17.7083 1.04167 17.7083C1.61667 17.7083 2.08333 18.175 2.08333 18.75C2.08333 20.4896 6.8375 22.9167 14.5833 22.9167C22.3292 22.9167 27.0833 20.4896 27.0833 18.75C27.0833 18.175 27.55 17.7083 28.125 17.7083C28.7 17.7083 29.1667 18.175 29.1667 18.75C29.1667 22.8083 21.6542 25 14.5833 25Z" fill="#BABEC1" />
            <path d="M14.5833 18.75C7.5125 18.75 0 16.5583 0 12.5C0 11.925 0.466667 11.4583 1.04167 11.4583C1.61667 11.4583 2.08333 11.925 2.08333 12.5C2.08333 14.2396 6.8375 16.6667 14.5833 16.6667C22.3292 16.6667 27.0833 14.2396 27.0833 12.5C27.0833 11.925 27.55 11.4583 28.125 11.4583C28.7 11.4583 29.1667 11.925 29.1667 12.5C29.1667 16.5583 21.6542 18.75 14.5833 18.75Z" fill="#BABEC1" />
            <path d="M14.5833 37.5C7.5125 37.5 0 35.3083 0 31.25C0 30.675 0.466667 30.2083 1.04167 30.2083C1.61667 30.2083 2.08333 30.675 2.08333 31.25C2.08333 32.9896 6.8375 35.4167 14.5833 35.4167C15.7167 35.4167 16.7042 35.3708 17.5979 35.2771C18.1625 35.2146 18.6833 35.6313 18.7438 36.2021C18.8042 36.7729 18.3896 37.2875 17.8188 37.3479C16.8479 37.4521 15.7896 37.5 14.5833 37.5Z" fill="#BABEC1" />
            <path d="M14.5833 43.75C7.5125 43.75 0 41.5583 0 37.5C0 36.925 0.466667 36.4583 1.04167 36.4583C1.61667 36.4583 2.08333 36.925 2.08333 37.5C2.08333 39.2396 6.8375 41.6667 14.5833 41.6667C15.7167 41.6667 16.7042 41.6208 17.5979 41.5271C18.1625 41.4542 18.6833 41.8813 18.7438 42.4521C18.8042 43.0229 18.3896 43.5375 17.8188 43.5979C16.8479 43.7021 15.7896 43.75 14.5833 43.75Z" fill="#BABEC1" />
            <path d="M14.5833 31.25C7.5125 31.25 0 29.0583 0 25C0 24.425 0.466667 23.9583 1.04167 23.9583C1.61667 23.9583 2.08333 24.425 2.08333 25C2.08333 26.7396 6.8375 29.1667 14.5833 29.1667C15.7167 29.1667 16.7042 29.1208 17.5979 29.0271C18.1625 28.9604 18.6833 29.3792 18.7438 29.9521C18.8042 30.5229 18.3896 31.0375 17.8188 31.0979C16.8479 31.2021 15.7896 31.25 14.5833 31.25Z" fill="#BABEC1" />
            <path d="M14.5833 12.5C7.5125 12.5 0 10.3083 0 6.25C0 2.19167 7.5125 0 14.5833 0C21.6542 0 29.1667 2.19167 29.1667 6.25C29.1667 10.3083 21.6542 12.5 14.5833 12.5ZM14.5833 2.08333C6.8375 2.08333 2.08333 4.51042 2.08333 6.25C2.08333 7.98958 6.8375 10.4167 14.5833 10.4167C22.3292 10.4167 27.0833 7.98958 27.0833 6.25C27.0833 4.51042 22.3292 2.08333 14.5833 2.08333Z" fill="#BABEC1" />
            <path d="M1.04167 38.5417C0.466667 38.5417 0 38.075 0 37.5V6.25001C0 5.67501 0.466667 5.20834 1.04167 5.20834C1.61667 5.20834 2.08333 5.67501 2.08333 6.25001V37.5C2.08333 38.075 1.61667 38.5417 1.04167 38.5417Z" fill="#BABEC1" />
            <path d="M28.1249 19.7917C27.5499 19.7917 27.0833 19.325 27.0833 18.75V6.25001C27.0833 5.67501 27.5499 5.20834 28.1249 5.20834C28.6999 5.20834 29.1666 5.67501 29.1666 6.25001V18.75C29.1666 19.325 28.6999 19.7917 28.1249 19.7917Z" fill="#BABEC1" />
        </svg>
    );
}

function renderMembersIcon() {
    return (
        <svg className="stat-icon" width="51" height="51" viewBox="0 0 51 51" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M36.6134 25.5905C35.6333 25.1247 34.6333 24.7233 33.6158 24.3872C36.9112 21.9137 39.0468 17.975 39.0468 13.5469C39.0468 6.07717 32.9698 0 25.5 0C18.0302 0 11.9531 6.07717 11.9531 13.5469C11.9531 17.9799 14.0935 21.9224 17.395 24.3954C14.3701 25.3913 11.5317 26.941 9.04748 28.9843C4.49215 32.7313 1.32487 37.9577 0.129255 43.7009C-0.245476 45.5005 0.203464 47.3488 1.36073 48.7718C2.51231 50.1879 4.21991 51 6.04555 51H30.58C31.6803 51 32.5722 50.1081 32.5722 49.0078C32.5722 47.9075 31.6803 47.0156 30.58 47.0156H6.04555C5.19509 47.0156 4.682 46.5409 4.4519 46.258C4.05456 45.7694 3.90076 45.1334 4.02996 44.5129C6.09735 34.5824 14.8566 27.3282 24.9718 27.0836C25.147 27.0904 25.3231 27.0938 25.5 27.0938C25.6786 27.0938 25.8564 27.0903 26.0333 27.0834C29.1331 27.1561 32.1144 27.8635 34.9029 29.1891C35.8967 29.6613 37.085 29.2388 37.5574 28.245C38.0297 27.2513 37.6072 26.0629 36.6134 25.5905ZM25.9859 23.0971C25.8242 23.0942 25.6622 23.0927 25.5 23.0927C25.3392 23.0927 25.1784 23.0943 25.0179 23.0973C19.9682 22.8454 15.9375 18.658 15.9375 13.5469C15.9375 8.27405 20.2271 3.98438 25.5 3.98438C30.7728 3.98438 35.0625 8.27405 35.0625 13.5469C35.0625 18.6567 31.0338 22.8433 25.9859 23.0971Z" fill="#BABEC1" />
            <path d="M49.0078 39.5449H43.5293V34.0664C43.5293 32.9661 42.6374 32.0742 41.5371 32.0742C40.4368 32.0742 39.5449 32.9661 39.5449 34.0664V39.5449H34.0664C32.9661 39.5449 32.0742 40.4368 32.0742 41.5371C32.0742 42.6374 32.9661 43.5293 34.0664 43.5293H39.5449V49.0078C39.5449 50.1081 40.4368 51 41.5371 51C42.6374 51 43.5293 50.1081 43.5293 49.0078V43.5293H49.0078C50.1081 43.5293 51 42.6374 51 41.5371C51 40.4368 50.1081 39.5449 49.0078 39.5449Z" fill="#BABEC1" />
        </svg>
    );
}

function renderExchangeIcon() {
    return (
        <svg className="stat-icon" width="43" height="52" viewBox="0 0 43 52" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M21.1186 7.93022H27.1708L24.8786 10.2263C24.285 10.8212 24.2858 11.7845 24.8806 12.3786C25.4742 12.971 26.438 12.9722 27.0325 12.3766L31.914 7.48677C32.5147 6.88481 32.5096 5.89527 31.8791 5.30163L27.0325 0.44667C26.4388 -0.148163 25.4754 -0.148955 24.8806 0.444688C24.2858 1.03873 24.285 2.00211 24.8786 2.59694L27.1644 4.88671C20.2686 4.88671 21.1285 4.88631 21.0532 4.88631C4.96582 4.88631 -5.2458 22.3948 2.82109 36.4679C3.23878 37.1967 4.16808 37.4495 4.89805 37.0314C5.62723 36.6133 5.87927 35.6836 5.46158 34.9545C-1.46954 22.8636 7.38042 7.86127 21.1186 7.93022Z" fill="#BABEC1" />
            <path d="M39.327 15.4739C38.9093 14.7451 37.9792 14.4927 37.25 14.9104C36.5208 15.3285 36.2688 16.2586 36.6869 16.9877C43.5768 29.0072 34.881 44.012 21.0742 44.012H14.9773L17.2694 41.7159C17.8635 41.1211 17.8627 40.1577 17.2679 39.5636C16.673 38.97 15.7092 38.9708 15.1156 39.5656L10.2341 44.4554C9.62499 45.0657 9.64481 46.0549 10.2709 46.6426L15.1156 51.4955C15.7092 52.09 16.6726 52.0916 17.2679 51.4975C17.8627 50.9035 17.8635 49.9401 17.2698 49.3453L14.984 47.0555H21.0932C37.189 47.0551 47.3923 29.5442 39.327 15.4739Z" fill="#BABEC1" />
            <path d="M19.5524 21.4063H25.6395C26.4796 21.4063 27.1612 20.7247 27.1612 19.8845C27.1612 19.044 26.4796 18.3628 25.6395 18.3628H22.596V16.841C22.596 16.0005 21.9143 15.3193 21.0742 15.3193C20.2337 15.3193 19.5524 16.0005 19.5524 16.841V18.3628C17.0348 18.3628 14.9872 20.4108 14.9872 22.9281C14.9872 25.4453 17.0348 27.4933 19.5524 27.4933H22.5956C23.4349 27.4933 24.1177 28.1757 24.1177 29.0151C24.1177 29.8544 23.4389 30.5372 22.5956 30.5372L16.5089 30.5368C15.6684 30.5368 14.9872 31.2181 14.9872 32.0582C14.9868 32.8987 15.668 33.5799 16.5085 33.5803H19.5524V35.1021C19.5524 35.9422 20.2337 36.6239 21.0742 36.6239C21.9143 36.6239 22.596 35.9422 22.596 35.1021V33.5807C25.1187 33.5807 27.1612 31.5394 27.1612 29.0151C27.1612 26.4978 25.1128 24.4498 22.5956 24.4498H19.5524C18.7131 24.4498 18.0307 23.767 18.0307 22.9281C18.0307 22.0887 18.7131 21.4063 19.5524 21.4063Z" fill="#BABEC1" />
        </svg>
    );
}
