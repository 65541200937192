import React, { useContext, useEffect, useState } from 'react';
import { DeveloperInput } from '../../developer/newDashboardComponents/developerInput.comp';
import { SkeletonComponent } from '../../component/component.comp';
import { ImageUpload } from '../../developer/imageUpload/imageUpload.comp';
import { Sidebar } from '../../sidebar/sidebar.comp';
import { toast } from 'react-toastify';
import { IProject } from '../../projects/projects.comp';
import { useHistory, useParams } from 'react-router-dom';
import { DangerZone } from '../../developer/common/dangerZone/dangerZone.comp';
import { WarnPopup } from '../../developer/common/warnPopup/warnPopup.comp';
import { ProjectContext } from '../../projectDashboard/projectDashboard.context';

import './projectSettings.scss';

export const ProjectSettings = () => {
  const { activeProject: projectDetails, setActiveProject } = useContext(ProjectContext);
  const [loading, setLoading] = useState(false);
  const [isDeleteUserPopupOpen, setIsDeleteUserPopupOpen] = useState(false);

  const history = useHistory();
  const { projectId } = useParams<any>();

  const [formState, setFormState] = useState({
    name: projectDetails.name,
    domain: projectDetails.domains?.[0] || '',
    thumbnail: projectDetails.thumbnail || '',
  });

  const renderLoader = () => (
    <div className="components-wrapper loading">
      {Array.from(new Array(9)).map((c, idx) => (
        <SkeletonComponent key={`comp_skel_${idx}`} />
      ))}
    </div>
  );

  const onSave = async () => {
    toast.info('Saving changes...');

    try {
      const req = await fetch(`/api/project/${projectId}`, {
        method: 'PUT',
        body: JSON.stringify({ ...projectDetails, ...formState }),
      });

      const { success, message } = await req.json();
      if (!success) {
        throw new Error(message);
      }

      setActiveProject({
        ...projectDetails,
        name: formState.name,
        thumbnail: formState.thumbnail,
        domains: [formState.domain || ''],
      });

      toast.dismiss();
      toast.success('Changes were successfully saved.');
    } catch (e) {
      toast.dismiss();
      toast.warning((e as Error).message);
    }
  };

  const onDeletePermission = async () => {
    setLoading(true);

    try {
      await fetch(`/api/project/${(projectDetails as IProject).projectId}`, {
        method: 'DELETE',
      });
      history.push('/user/dashboard');
      toast.success(`Project ${projectDetails?.name} deleted.`);
    } catch (e) {
      toast.warning(`Oops, something went wrong`);
    }

    setIsDeleteUserPopupOpen(false);
    setLoading(false);
  };

  useEffect(() => {
    setFormState({
      name: projectDetails.name,
      domain: projectDetails.domains?.[0] || '',
      thumbnail: projectDetails.thumbnail || '',
    });
  }, [projectDetails]);

  return (
    <React.Fragment>
      <Sidebar variant="widgets" />
      <section className="project-settings main-section">
        <div className="inner">
          <header>
            <hgroup className="page-titles">
              <h2>Project Settings</h2>
            </hgroup>
            {(projectDetails.userProjectRole === 'owner' || projectDetails.userProjectRole === 'admin') && (
              <button className="button primary-button" onClick={onSave}>
                Save
              </button>
            )}
          </header>
          {loading ? (
            renderLoader()
          ) : (
            <>
              {projectDetails.userProjectRole === 'owner' || projectDetails.userProjectRole === 'admin' ? (
                <>
                  <div className="project-settings">
                    <DeveloperInput
                      label="Project Name"
                      value={formState.name}
                      handleChange={(e) =>
                        setFormState({ ...formState, name: e.target.value })
                      }
                      placeholder="Enter Text..."
                      name="name"
                      autoFocus
                      required
                    />
                    <DeveloperInput
                      label="Domain"
                      value={formState.domain}
                      handleChange={(e) =>
                        setFormState({ ...formState, domain: e.target.value })
                      }
                      placeholder="Enter Domain..."
                      name="domain"
                    />
                    <div className="input-container">
                      <label htmlFor="icon">Project Thumbnail</label>
                      <ImageUpload
                        value={formState.thumbnail}
                        successCallback={(url: string) =>
                          setFormState({ ...formState, thumbnail: url })
                        }
                      />
                      <small>(Max. file size is 3MB)</small>
                    </div>
                  </div>
                  {projectDetails.userProjectRole === 'owner' && (
                    <DangerZone
                      buttonText="Delete Project"
                      onButtonClick={setIsDeleteUserPopupOpen}
                      title="Danger zone"
                      text="Do you really want to delete the project? Doing so will delete the project and all its contents permanently, including all project’s information and widgets."
                      className="delete-project"
                    />
                  )}
                </>
              ) : (
                <>
                  {!loading &&
                    <div className="no-access-box">
                      {renderShield()}
                      <p>
                        Sorry, you are not permitted here please contact project
                        owner.
                      </p>
                    </div>
                  }
                </>
              )}
            </>
          )}
        </div>
      </section>
      <WarnPopup
        showPopup={isDeleteUserPopupOpen}
        setShowPopup={setIsDeleteUserPopupOpen}
        title="Are you sure you want to remove this project?"
        text={`Removing this project will permanently delete all data associated with this project.`}
        onConfirm={onDeletePermission}
        loading={loading}
      />
    </React.Fragment>
  );
};

function renderShield() {
  return (
    <svg
      width="92"
      height="105"
      viewBox="0 0 92 105"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.3789 53.9356C23.3789 66.3744 33.4987 76.4942 45.9375 76.4942C58.3763 76.4942 68.4961 66.3744 68.4961 53.9356C68.4961 41.4967 58.3763 31.377 45.9375 31.377C33.4987 31.377 23.3789 41.4967 23.3789 53.9356ZM60.293 53.9356C60.293 61.8512 53.8531 68.291 45.9375 68.291C43.4684 68.291 41.1432 67.6641 39.1119 66.5616L58.5636 47.1097C59.6661 49.141 60.293 51.4664 60.293 53.9356ZM33.3115 60.7614C32.209 58.7301 31.582 56.4047 31.582 53.9356C31.582 46.02 38.0219 39.5801 45.9375 39.5801C48.4067 39.5801 50.7318 40.207 52.7631 41.3095L33.3115 60.7614Z"
        fill="#D0D0D0"
      />
      <path
        d="M89.4223 18.8031L47.3813 0.346102C46.3257 -0.117375 45.124 -0.115324 44.0701 0.352049L2.4392 18.8091C0.95628 19.4666 0 20.9364 0 22.5585V54.9609C0 67.2065 4.83923 79.7245 13.277 89.3049C17.5211 94.1237 22.4138 97.9369 27.8195 100.639C33.6097 103.533 39.7054 105 45.9375 105C54.664 105 63.1573 102.176 70.4996 96.8317C72.3311 95.4987 72.7353 92.9336 71.4023 91.102C70.0693 89.2705 67.504 88.8667 65.6724 90.1993C59.742 94.5156 52.9178 96.7969 45.9375 96.7969C26.1887 96.7969 8.20313 76.8565 8.20313 54.9609V25.2268L45.7407 8.58451L83.6719 25.2375V54.9609C83.6719 62.5137 81.5022 70.1572 77.3972 77.0651C76.2399 79.0123 76.8804 81.529 78.8278 82.6863C80.7754 83.8431 83.2919 83.2028 84.4492 81.2554C89.3073 73.0804 91.8751 63.988 91.8751 54.9609V22.5585C91.8751 20.931 90.9126 19.4573 89.4223 18.8031Z"
        fill="#D0D0D0"
      />
    </svg>
  );
}
