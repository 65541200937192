import React, { useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';

import {
  IAppIntegration,
  IIntegrationPlatform,
  IPlatformField,
} from '../developer.types';

import './appIntegration.scss';
import { developerService } from '../../../services/developer.service';
import { toast } from 'react-toastify';
import { DeveloperContext } from '../developer.context';

export const integrationsFieldsCompleted = (
  fields: IPlatformField[] = [],
  state: any = {}
): boolean => {
  const filteredFields = fields.filter((f) => f.type !== 'hidden');

  // eslint-disable-next-line react-hooks/rules-of-hooks
  if (filteredFields.length === 0) {
    return true;
  }

  let completedFields = 0;
  filteredFields.forEach((field) => {
    if (state[field.name] || typeof state[field.name] === 'boolean' || field.type === 'checkbox') {
      completedFields += 1;
    }
  });

  if (completedFields === filteredFields.length) {
    return true;
  }

  return false;
};

export const AppIntegration = ({
  platform,
  integrationData,
  selectIntegration,
}: {
  platform: IIntegrationPlatform;
  integrationData: IAppIntegration;
  selectIntegration: () => void;
}) => {
  const { activeApp, integrations, setIntegrations } =
    useContext(DeveloperContext);
  async function createIntegration() {
    const { data } = await developerService.updateAppIntegration(
      activeApp.appId,
      platform.name,
      {
        isActive: true,
      }
    );

    toast.success('Integration created');
    setIntegrations([...integrations, (data || { ...integrationData, isActive: true })]);
    selectIntegration();
  }

  return (
    <section className="app-section app-integration">
      {integrationData &&
        (integrationData.useSystemIntegration ||
          (integrationData.isActive &&
            integrationsFieldsCompleted(
              platform.fields,
              integrationData.platformAppKeys || {}
            ))) && (
          <span className="status highlight" title="Connected">
            <FontAwesomeIcon icon={faCheckCircle} />
          </span>
        )}
      <img
        src={platform.icon || platform.logo}
        className={platform.name}
        alt={platform.displayName}
      />
      <div className="buttons-wrapper">
        <button
          className={`button ${integrationData?.isActive ? 'secondary' : 'primary'
            }-button`}
          onClick={() =>
            !integrationData?.isActive
              ? createIntegration()
              : selectIntegration()
          }
        >
          {integrationData?.isActive ? 'Edit' : 'Connect'}
        </button>
      </div>
    </section>
  );
};
