import { Popup } from '@commonninja/commonninja-styleguide-react';
import React, { useEffect, useRef } from 'react';

import './upgradePopup.scss';

declare global {
  interface Window {
    CommonninjaAuthSDK: any;
  }
}

interface IUpgradePopupProps {
  serviceName: string;
  show: boolean;
  closeCallback: any;
}

export const UpgradePopup = (props: IUpgradePopupProps) => {
  const { serviceName, closeCallback, show } = props;
  const wrapperRef: any = useRef();
  let popupUrl = `pricing/${serviceName}`;

  useEffect(() => {
    if (show && wrapperRef.current) {
      window?.CommonninjaAuthSDK.embedDashboardView(
        wrapperRef.current,
        popupUrl,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  return (
    <Popup show={show} closeCallback={closeCallback} className="upgrade-popup">
      <section
        id="commonninja-dashboard-iframe-wrapper"
        ref={wrapperRef}
        style={{ padding: 0 }}
      >
        <p className="center">Loading...</p>
      </section>
    </Popup>
  );
};
