import { Popup } from '@commonninja/commonninja-styleguide-react';
import React, { useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { DataTable } from '../../developer/common/dataTable';
import { WarnPopup } from '../../developer/common/warnPopup/warnPopup.comp';
import { DeveloperInput } from '../../developer/newDashboardComponents/developerInput.comp';
import { ProjectContext } from '../../projectDashboard/projectDashboard.context';
import { IProject } from '../../projects/projects.comp';
import { ProjectUpgradePopup } from '../../projectUpgradePopup/projectUpgradePopup.comp';
import { Sidebar } from '../../sidebar/sidebar.comp';
import { ProjectPermissionRoleLevel } from '../project.helpers';

import './projectPermissions.scss';

interface IProjectPermission {
  permissionId: string;
  projectId: string;
  userId: string;
  role: string;
  status: string;
  email: string;
  guid: string;
  fullName: string;
  _id: string;
  userDetails: {
    name: string;
    email: string;
  };
}

export const ProjectPermissions = () => {
  const { activeProject: projectDetails, userPlanFeatures, activeProject } =
    useContext(ProjectContext);
  const [loading, setLoading] = useState(false);
  const [permissions, setPermissions] = useState<{
    total: number;
    pages: number;
    page: number;
    docs: IProjectPermission[];
  }>({ docs: [], page: 1, pages: 1, total: 0 });
  const [search, setSearch] = useState('');
  const [isCreateUserPopupOpen, setIsCreateUserPopupOpen] = useState(false);
  const [isUpdateUserPopupOpen, setIsUpdateUserPopupOpen] = useState(false);
  const [upgradePopupOpen, setUpgradePopupOpen] = useState(false);
  const [isDeleteUserPopupOpen, setIsDeleteUserPopupOpen] = useState(false);
  const [deletePermissionId, setDeletePermissionId] = useState('');
  const [newUser, setNewUser] = useState({
    email: '',
    permission: '',
  });
  const [updatedUser, setUpdatedUser] = useState({
    _id: '',
    email: '',
    name: '',
    newRole: '',
    currentRole: '',
    permissionId: '',
  });

  const { projectId } = useParams<any>();

  const onAddNewUser = async (e: any) => {
    e.preventDefault();

    if (projectDetails.userProjectRole !== 'owner') {
      return toast.info(`Not authorized`);
    }
    if (!newUser.email) return toast.info(`Email required`);
    if (!newUser.permission) return toast.info(`Permission type required`);
    setLoading(true);

    try {
      if ((permissions?.total || 0) >= userPlanFeatures.collaborators) {
        setUpgradePopupOpen(true);
        setNewUser({
          email: '',
          permission: '',
        });
        setIsCreateUserPopupOpen(false);
        setLoading(false);
        return;
      }

      const permissionReq = await fetch(
        `/api/project/${projectId}/permissions`,
        { method: 'POST', body: JSON.stringify(newUser) },
      );
      const permissionRes = await permissionReq.json();

      if (!permissionRes.success) {
        throw new Error(permissionRes.message);
      }

      const inviteData = {
        permissionId: permissionRes.data.permissionId,
        email: newUser.email,
        projectName: projectDetails.name,
        projectThumb: projectDetails.thumbnail,
      };

      const inviteReq = await fetch('/api/project/invite', {
        method: 'post',
        body: JSON.stringify(inviteData),
        headers: {
          'Content-Type': 'application/json',
        },
      });

      const inviteRes = await inviteReq.json();

      if (!inviteRes.success) {
        throw new Error(inviteRes.message);
      }

      getPermissions({ limit: 10, page: 1, query: {}, sort: {} });
      toast.success(`Invitation sent successfully.`);
    } catch (e) {
      toast.warning(`Oops, something went wrong`);
    }

    setNewUser({
      email: '',
      permission: '',
    });
    setIsCreateUserPopupOpen(false);
    setLoading(false);
  };

  const updateUserPermission = async (e: any) => {
    e.preventDefault();
    if (
      !updatedUser.email ||
      !updatedUser.permissionId ||
      updatedUser.newRole === updatedUser.currentRole
    ) {
      return;
    }
    setLoading(true);

    try {
      await fetch(`/api/project/${projectId}/permissions/${updatedUser._id}`, {
        method: 'PUT',
        body: JSON.stringify({ role: updatedUser.newRole }),
      });
      getPermissions({ limit: 10, page: 1, query: {}, sort: {} });
      toast.success(`User ${updatedUser.name} role updated successfully.`);
    } catch (e) {
      toast.warning(`Oops, something went wrong`);
    }

    setLoading(false);
    setIsUpdateUserPopupOpen(false);
  };

  function hasAccess(minRole: IProject['userProjectRole']) {
    const minLevel: number = (ProjectPermissionRoleLevel as any)[minRole] || 1;
    const userLevel =
      (ProjectPermissionRoleLevel as any)[activeProject.userProjectRole] || 1;
    return userLevel >= minLevel;
  }


  const onDeletePermission = async () => {
    setLoading(true);

    try {
      await fetch(
        `/api/project/${projectId}/permissions/${deletePermissionId}`,
        { method: 'DELETE' },
      );
      getPermissions({ limit: 10, page: 1, query: {}, sort: {} });
      toast.success(`User ${updatedUser.name} permission deleted.`);
    } catch (e) {
      toast.warning(`Oops, something went wrong`);
    }

    setIsDeleteUserPopupOpen(false);
    setDeletePermissionId('');
    setLoading(false);
  };

  const onOpenEditUserModal = (email: string) => {
    const selectedUser = permissions.docs.find(
      (permission) => permission.userDetails.email === email,
    );

    setUpdatedUser({
      _id: selectedUser?._id as string,
      email: selectedUser?.userDetails.email || '',
      name: selectedUser?.userDetails?.name || '',
      newRole: '',
      currentRole: selectedUser?.role as string,
      permissionId: selectedUser?.permissionId as string,
    });
    setIsUpdateUserPopupOpen(true);
  };

  const onOpenDeletePermissionModal = (email: string) => {
    const permission = permissions.docs.find(
      (permission) => permission.userDetails.email === email,
    );
    setDeletePermissionId(permission?._id || '');
    setIsDeleteUserPopupOpen(true);
  };

  const getPermissions = async ({
    page,
    limit,
    query,
    sort,
    search,
  }: {
    page: number;
    limit: number;
    query: any;
    sort: any;
    search?: string;
  }) => {
    setLoading(true);
    if (Object.keys(sort)[0] === 'email') {
      sort = { 'userDetails.email': Object.values(sort)[0] };
    }
    if (Object.keys(sort)[0] === 'fullName') {
      sort = { 'userDetails.name': Object.values(sort)[0] };
    }
    const formattedSort = encodeURIComponent(JSON.stringify(sort));

    try {
      const req = await fetch(
        `/api/project/${projectId}/permissions?page=${page}&limit=${limit}&sort=${formattedSort}&search=${search || ''
        }`,
      );
      const { data } = await req.json();

      setPermissions(data);
    } catch (e) { }
    setLoading(false);
  };

  return (
    <>
      <Sidebar variant="widgets" />
      <section className="project-permissions main-section">
        <div className="inner">
          <header>
            <hgroup className="page-titles">
              <h2>Permissions</h2>
            </hgroup>
            {hasAccess('admin') && (
              <div className="filter-wrapper">
                <form
                  className="search-components"
                  onSubmit={(e) => {
                    e.preventDefault();
                    getPermissions({
                      query: {},
                      limit: 10,
                      page: 1,
                      sort: {},
                      search,
                    });
                  }}
                >
                  <DeveloperInput
                    name="searchType"
                    title="Search by name"
                    placeholder="Search by email..."
                    value={search}
                    handleChange={(e) => setSearch(e.target.value)}
                  />
                </form>
                {hasAccess('owner') && (
                  <button
                    onClick={() => setIsCreateUserPopupOpen(true)}
                    className="button primary-button"
                  >
                    + Add User
                  </button>
                )}
              </div>
            )}
          </header>
          {hasAccess('admin') ? (
            <DataTable
              actionIdKey="email"
              className="permissions-data-table"
              currentPage={permissions?.page || 1}
              getItems={getPermissions}
              headers={[
                { title: 'Email', key: 'email', type: 'string' },
                { title: 'Permission', key: 'role', type: 'string' },
                { title: 'Status', key: 'status', type: 'string' },
              ]}
              rows={
                permissions?.docs?.map((per: IProjectPermission) => ({
                  ...per,
                  email: per?.userDetails?.email || '',
                  fullName: per?.userDetails?.name || '',
                })) || []
              }
              totalPages={permissions?.pages || 1}
              disableSearch={true}
              onDelete={onOpenDeletePermissionModal}
              onEdit={onOpenEditUserModal}
              loading={loading}
            />
          ) : (
            <>
              {!loading && (
                <div className="no-access-box">
                  {renderShield()}
                  <p>
                    Sorry, you are not permitted here please contact project
                    owner.
                  </p>
                </div>
              )}
            </>
          )}
        </div>
      </section>
      <Popup
        closeCallback={() => {
          setIsCreateUserPopupOpen(false);
          setNewUser({ email: '', permission: '' });
        }}
        show={isCreateUserPopupOpen}
        className="developer-page popup-warn collection-popup "
      >
        <header>
          <h3>New User</h3>
        </header>
        <div className="content">
          <form onSubmit={onAddNewUser}>
            <div className="input-container">
              <label htmlFor="email">Email:</label>
              <input
                type="email"
                id="email"
                value={newUser.email}
                onChange={(e) =>
                  setNewUser({ ...newUser, email: e.target.value })
                }
                placeholder="Enter email..."
              />
              <label htmlFor="type">Permission Type:</label>
              <select
                name="project"
                value={newUser.permission}
                onChange={(e: any) =>
                  setNewUser({ ...newUser, permission: e.target.value })
                }
              >
                <option value="">-Select permission type-</option>
                <option value="viewer">Viewer</option>
                <option value="editor">Editor</option>
                <option value="admin">Admin</option>
              </select>
            </div>
            <div className="popup-warn-buttons">
              <button className="button primary-button">Add User</button>
            </div>
          </form>
        </div>
      </Popup>
      <Popup
        closeCallback={() => {
          setUpdatedUser({
            currentRole: '',
            email: '',
            name: '',
            newRole: '',
            permissionId: '',
            _id: '',
          });
          setIsUpdateUserPopupOpen(false);
        }}
        show={isUpdateUserPopupOpen}
        className="developer-page popup-warn collection-popup "
        style={{ maxWidth: '400px' }}
      >
        <header>
          <h3>Update Permission</h3>
        </header>
        <div className="content">
          <div className="input-container">
            <label htmlFor="permissionType">Permission Type:</label>
            <select
              name="permissionType"
              value={updatedUser.newRole || updatedUser.currentRole}
              onChange={(e: any) =>
                setUpdatedUser({ ...updatedUser, newRole: e.target.value })
              }
            >
              <option value="viewer">Viewer</option>
              <option value="editor">Editor</option>
              <option value="admin">Admin</option>
            </select>
          </div>
          <div className="popup-warn-buttons">
            <button
              className="button primary-button"
              onClick={updateUserPermission}
            >
              Update User
            </button>
          </div>
        </div>
      </Popup>
      <WarnPopup
        showPopup={isDeleteUserPopupOpen}
        setShowPopup={setIsDeleteUserPopupOpen}
        title="Are you sure you want to remove this permission?"
        text={`Removing this permission will permanently delete the permission's information from the project.`}
        onConfirm={onDeletePermission}
        loading={loading}
      />
      <ProjectUpgradePopup
        title='Upgrade your plan now and get more collaborators!'
        text='By upgrading your current plan you’ll be able to add more collaborators to your projects, aditionally you’ll be getting more benefits such as more projects and websites.'
        buttonText='Upgrade Now!'
        type='collab'
        setShowPopup={setUpgradePopupOpen}
        showPopup={upgradePopupOpen}
      />
    </>
  );
};

function renderShield() {
  return (
    <svg
      width="92"
      height="105"
      viewBox="0 0 92 105"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.3789 53.9356C23.3789 66.3744 33.4987 76.4942 45.9375 76.4942C58.3763 76.4942 68.4961 66.3744 68.4961 53.9356C68.4961 41.4967 58.3763 31.377 45.9375 31.377C33.4987 31.377 23.3789 41.4967 23.3789 53.9356ZM60.293 53.9356C60.293 61.8512 53.8531 68.291 45.9375 68.291C43.4684 68.291 41.1432 67.6641 39.1119 66.5616L58.5636 47.1097C59.6661 49.141 60.293 51.4664 60.293 53.9356ZM33.3115 60.7614C32.209 58.7301 31.582 56.4047 31.582 53.9356C31.582 46.02 38.0219 39.5801 45.9375 39.5801C48.4067 39.5801 50.7318 40.207 52.7631 41.3095L33.3115 60.7614Z"
        fill="#D0D0D0"
      />
      <path
        d="M89.4223 18.8031L47.3813 0.346102C46.3257 -0.117375 45.124 -0.115324 44.0701 0.352049L2.4392 18.8091C0.95628 19.4666 0 20.9364 0 22.5585V54.9609C0 67.2065 4.83923 79.7245 13.277 89.3049C17.5211 94.1237 22.4138 97.9369 27.8195 100.639C33.6097 103.533 39.7054 105 45.9375 105C54.664 105 63.1573 102.176 70.4996 96.8317C72.3311 95.4987 72.7353 92.9336 71.4023 91.102C70.0693 89.2705 67.504 88.8667 65.6724 90.1993C59.742 94.5156 52.9178 96.7969 45.9375 96.7969C26.1887 96.7969 8.20313 76.8565 8.20313 54.9609V25.2268L45.7407 8.58451L83.6719 25.2375V54.9609C83.6719 62.5137 81.5022 70.1572 77.3972 77.0651C76.2399 79.0123 76.8804 81.529 78.8278 82.6863C80.7754 83.8431 83.2919 83.2028 84.4492 81.2554C89.3073 73.0804 91.8751 63.988 91.8751 54.9609V22.5585C91.8751 20.931 90.9126 19.4573 89.4223 18.8031Z"
        fill="#D0D0D0"
      />
    </svg>
  );
}
