import React from "react";
import { IAppPlan } from "../../developer/developer.types";

export const Discount = ({
  plan,
  activeVariantId,
}: {
  plan: IAppPlan;
  activeVariantId: number;
}) => {
  const activeVariant = plan.pricingVariants[activeVariantId] || {};
  const otherVariant = plan.pricingVariants[activeVariantId === 0 ? 1 : 0];

  if (
    !activeVariant?.priceCount ||
    !otherVariant?.priceCount ||
    activeVariant.pricePeriod !== "year"
  ) {
    return <></>;
  }

  if (activeVariant.priceCount < otherVariant.priceCount) {
    return <></>;
  }

  const monthlyCost = activeVariant.priceCount / 12;
  const originalCost = otherVariant.priceCount;
  const saving = (originalCost - monthlyCost) * 12;

  return <span className="after-discount">Save ${saving.toFixed()}</span>;
};
