import {
  HttpService, IHttpResult,
} from "@commonninja/commonninja-styleguide-react";

class FeedbackService extends HttpService {

  public async postFeedback( reason: string, comment: string, token: string): Promise<IHttpResult> {
      const url = `/api/subscription/feedback?token=${token}`;

        return await this.makeRequest(url, {
          method: 'POST',
          body: JSON.stringify({reason, comment}),
          headers: {
            'Content-Type': 'application/json',
          },
        });
      }

}

export default new FeedbackService();
