import React from 'react';
import { GrPrevious, GrNext } from 'react-icons/gr';

import './pagination.scss';

interface IPaginationProps {
    pages?: number;
    className?: string;
    currentPage?: number;
    paginate: (page: number) => void;
}

export const Pagination = ({ pages = 100, className = '', currentPage = 1, paginate }: IPaginationProps) => {

    function renderNumbers() {
        const numbers = [currentPage];
        if (currentPage <= 1 && pages > 1) numbers.push(currentPage + 1);
        else if (pages - 1 >= currentPage) {
            numbers.push(currentPage + 1);
            numbers.unshift(currentPage - 1);
        }
        if (pages === currentPage && currentPage > 1) numbers.unshift(currentPage - 1);

        const activeNumber = numbers.findIndex(page => page === currentPage);

        return (
            <>
                {numbers.map((number: number, idx: number) => (
                    <p key={number} className={`page-number ${activeNumber === idx ? 'active' : ''}`} onClick={() => paginate(number)}>{number}</p>
                ))}
            </>
        );
    }

    return (
        <div className={`pagination ${className}`}>
            {currentPage > 2 &&
                <div onClick={() => paginate(currentPage - 2)} className="double-prev-icon">
                    <GrPrevious className="pagination-icon" />
                    <GrPrevious className="pagination-icon" />
                </div>
            }
            <GrPrevious onClick={() => paginate(currentPage - 1)} className={`pagination-icon ${(currentPage <= 1) ? 'disable' : ''}`} />
            {renderNumbers()}
            <GrNext onClick={() => paginate(currentPage + 1)} className={`pagination-icon ${(currentPage === pages) ? 'disable' : ''}`} />
            {(!pages || pages - 2 >= currentPage) &&
                <div onClick={() => paginate(currentPage + 2)} className="double-next-icon">
                    <GrNext className="pagination-icon" />
                    <GrNext className="pagination-icon" />
                </div>
            }
        </div>
    );
};;
