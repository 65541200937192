import {
  HttpService,
  eventService,
} from "@commonninja/commonninja-styleguide-react";
import React, { useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import { useResizeDetector } from "react-resize-detector";
import { useRouteMatch } from "react-router-dom";

// import { sendConversionEventToGA } from "../../helpers/helpers";
import { IAppPlan } from "../developer/developer.types";

import "./checkoutSuccess.scss";

const nindoApiUrl = process.env.REACT_APP_NINDO_SERVICE_URL || "";

export const CheckoutSuccess = ({ embedMode, websiteMode }: any) => {
  const { user } = useSelector((state: any) => ({ user: state.user }));
  const {
    params: { serviceName },
  } = useRouteMatch() as any;
  const onResize = useCallback(() => {
    const elm = document.querySelector("#main-content");
    if (!elm) {
      return;
    }

    const { height, width } = elm.getBoundingClientRect();

    window.parent.postMessage(
      {
        type: "COMMONNINJA_DASHBOARD_IFRAME_UPDATE",
        height,
        width,
      },
      "*"
    );
  }, []);
  const { ref } = useResizeDetector({
    refreshMode: "debounce",
    onResize,
  });

  async function reportCheckoutEnd() {
    try {
      eventService.reportMixpanelEvent("Subscription creation success", {
        serviceName,
        authenticated: user.isAuthenticated,
        embedMode,
      });

      eventService.reportMixpanelEvent(`Checkout End`, {
        authenticated: user.isAuthenticated,
        serviceName,
      });

      const urlParams = new URLSearchParams(window?.location.search);
      const variantId = urlParams.get("variantId") || "";
      const sessionId = urlParams.get("sessionId") || "";
      const client = new HttpService();
      const result = await client.makeRequest(
        `${nindoApiUrl}/nindo/api/getCheckoutSession/${sessionId}?${client.queryParams}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (!result.success) {
        throw new Error(result.message || "Cannot get checkout url.");
      }

      const { plan, platform } = result.data as {
        plan: IAppPlan;
        platform: string;
      };
      const currentVariant = plan.pricingVariants.find(
        (v) => v.id === variantId
      );

      // @ts-ignore
      window.dataLayer.push({ ecommerce: null });
      // @ts-ignore
      window.dataLayer.push({
        event: "purchase",
        ecommerce: {
          currency: "USD",
          transaction_id: sessionId || "", // Should be subscription ID
          value: currentVariant?.priceCount || 0,
          items: [
            {
              item_id: variantId,
              item_name: `${plan.name}, ${currentVariant?.name || ""}`,
              item_brand: platform,
              item_category: currentVariant?.pricePeriod,
              price: currentVariant?.priceCount || 0,
              quantity: 1, // plan.features?.numberOfInstances || 1,
            },
          ],
        },
      });

      // @ts-ignore
      window.fbq?.("track", "StartTrial", {
        value: `${currentVariant?.priceCount || 0}`,
        currency: "USD",
        predicted_ltv: "0.00",
      });

      // @ts-ignore
      window.lintrk?.('track', { conversion_id: 18793521 });
    } catch (e) {}

    // Redirect to dashboard after reporting all events
    window.setTimeout(() => {
      if (websiteMode) {
        const baseUrl = `${window.location.protocol}//${window.location.host}`;
        window.location.href = `${baseUrl}/user/dashboard`;
      } else {
        window.parent.postMessage(
          {
            type: "COMMONNINJA_DASHBOARD_IFRAME_CHECKOUT_SUCCESS",
          },
          "*"
        );
      }
    }, 3000);
  }

  function renderBody() {
    return (
      <div>
        <img
          className="cn-sword"
          src="https://website-assets.commoninja.com/distribution/1693467250491_sword.png"
          alt="Ninja Sword"
        />
        <img
          className="cn-stars"
          src="https://website-assets.commoninja.com/distribution/1693467293100_stars.png"
          alt="Ninja Stars"
        />
        <img
          src="https://www.commoninja.com/assets/cn-logo-light.svg"
          alt="Common Ninja"
          className="cn-logo"
        />
        <img
          src="https://website-assets.commoninja.com/distribution/1705568842368_cards.png"
          alt="Cards"
          className="cards"
        />
        <div>
          <h2>Your subscription has been successfully created</h2>
          <p>
            Your account is now upgraded. <br />
            Thank you for choosing our widgets and services. <br />
            This window will close automatically.
          </p>
        </div>
      </div>
    );
  }

  useEffect(() => {
    reportCheckoutEnd();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={`checkout-success`} ref={ref}>
      {renderBody()}
    </div>
  );
};
