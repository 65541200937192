import React, { useContext, useEffect, useState } from "react";
import { Popup } from "@commonninja/commonninja-styleguide-react";
import { DeveloperInput } from "../../../newDashboardComponents/developerInput.comp";
import { ICollectionSchemaAttribute } from "../../types";
import JSONEditor from "../../../appPayments/common/jsonEditor.comp";
import { DatePicker } from "../../../common/datePicker/datePicker.comp";
import dayjs from "dayjs";
import { developerService } from "../../../../../services/developer.service";
import { DeveloperContext } from "../../../developer.context";
import { toast } from "react-toastify";

interface IDocumentCreationPopupProps {
  fields: ICollectionSchemaAttribute[];
  showPopup: boolean;
  duplicate: boolean;
  loading: boolean;
  selectedDocumentId?: string;
  collectionName: string;
  setSelectedDocumentId: React.Dispatch<React.SetStateAction<string>>;
  setShowPopup: React.Dispatch<React.SetStateAction<boolean>>;
  setDuplicate: React.Dispatch<React.SetStateAction<boolean>>;
  onCreate: (newField: any) => void;
}

export const DocumentCreationPopup = ({
  showPopup,
  setShowPopup,
  onCreate,
  fields,
  selectedDocumentId,
  setSelectedDocumentId,
  collectionName,
  setDuplicate,
  duplicate,
  loading,
}: IDocumentCreationPopupProps) => {
  const [newDocument, setNewDocument] = useState<any>(
    fields.reduce((acc: any, key) => {
      if (!acc[key.name]) acc[key.name] = key.defaultValue;
      return acc;
    }, {})
  );
  const [loadingDoc, setLoadingDoc] = useState(false);
  const { activeApp } = useContext(DeveloperContext);

  function createDocument() {
    onCreate(newDocument);
  }

  function onEditField(e: any, keyName: string) {
    const value = e.target.value;
    const type = e.target.name;

    if (type === "number") {
      setNewDocument({ ...newDocument, [keyName]: Number(value) });
      return;
    }
    if (type === "boolean") {
      setNewDocument({ ...newDocument, [keyName]: value || false });
      return;
    }
    setNewDocument({ ...newDocument, [keyName]: value });
  }

  function onEditJSONFiled(json: object, keyName: string) {
    setNewDocument({ ...newDocument, [keyName]: json });
  }

  function onEditDateField(e: any, keyName: string) {
    const date = new Date(e).toLocaleDateString();
    const formattedDate = dayjs(date).format("MM/DD/YYYY");
    setNewDocument({ ...newDocument, [keyName]: formattedDate });
  }

  function renderCorrectInputType(field: ICollectionSchemaAttribute) {
    if (loadingDoc) {
        return <></>;
    }
    
    if (
      field.type === "text" ||
      field.type === "password" ||
      field.type === "email" ||
      field.type === "uid" ||
      field.type === "url"
    ) {
      return (
        <input
          type="text"
          name={"text"}
          minLength={field.validation.minLength || undefined}
          maxLength={field.validation.maxLength || undefined}
          title={`${field.displayName}`}
          placeholder={`Enter ${field.displayName}...`}
          value={newDocument[field.name]}
          onChange={(e) => onEditField(e, field.name)}
        />
      );
    }

    if (field.type === "number") {
      return (
        <DeveloperInput
          className="number-select"
          type="number"
          name="number"
          value={newDocument[field.name]}
          handleChange={(e) => onEditField(e, field.name)}
          // numberMaxLength={field.validation?.maxLength || undefined}
          // numberMinLength={field.validation?.minLength || undefined}
        />
      );
    }

    if (field.type === "boolean") {
      return (
        <DeveloperInput
          type="checkbox"
          name="boolean"
          value={newDocument[field.name] || false}
          handleChange={(e) => onEditField(e, field.name)}
        />
      );
    }

    if (field.type === "json" || field.type === "array") {
      return (
        <JSONEditor
          value={newDocument[field.name] || {}}
          onChange={(j) => onEditJSONFiled(j, field.name)}
        />
      );
    }

    if (field.type === "date") {
      return (
        <DatePicker
          type="single"
          value={newDocument[field.name]}
          onChange={(e) => onEditDateField(e, field.name)}
          format="MM/DD/YYYY"
        />
      );
    }

    if (field.type === "enumeration") {
      return (
        <JSONEditor
          value={newDocument[field.name] || []}
          onChange={(j) => onEditJSONFiled(j, field.name)}
        />
      );
    }

    if (field.type === "rich-text") {
      return (
        <textarea
          minLength={field.validation.minLength || undefined}
          maxLength={field.validation.maxLength || undefined}
          value={newDocument[field.name]}
          cols={30}
          rows={10}
          onChange={(e) => onEditField(e, field.name)}
        />
      );
    }

    return (
      <input
        type="text"
        name={"text"}
        minLength={field.validation.minLength || undefined}
        maxLength={field.validation.maxLength || undefined}
        title={`${field.displayName}`}
        placeholder={`Enter ${field.displayName}...`}
        value={newDocument[field.name]}
        onChange={(e) => onEditField(e, field.name)}
      />
    );
    // if (field.type === 'relation') { }
  }

  async function getSelectedDocument(selectedDocumentId: string) {
    try {
      setLoadingDoc(true);

      const { data } = await developerService.getDocument(
        activeApp.appId,
        collectionName,
        selectedDocumentId
      );
      delete data._id;
      delete data._created;
      delete data._updated;
      setNewDocument(data);
    } catch (e) {
      toast.error("Oops, something went wrong");
    }

    setLoadingDoc(false);
  }

  useEffect(() => {
    if (selectedDocumentId) {
      getSelectedDocument(selectedDocumentId);
    } else {
      setNewDocument(
        fields.reduce((acc: any, key) => {
          if (!acc[key.name]) acc[key.name] = key.defaultValue;
          return acc;
        }, {})
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fields, selectedDocumentId]);

  return (
    <Popup
      show={showPopup}
      closeCallback={() => {
        setShowPopup(false);
        setNewDocument(
          fields.reduce((acc: any, key) => {
            if (!acc[key.name]) acc[key.name] = key.defaultValue;
            return acc;
          }, {})
        );
        setDuplicate(false);
        setSelectedDocumentId("");
      }}
      className="developer-page popup-warn collection-popup field-types-popup"
    >
      <header>
        <h3>New Document</h3>
      </header>
      <div className="input-container">
        {fields.map((field) => (
          <React.Fragment key={field.name}>
            <label htmlFor={`${field.name}`}>
              {field.displayName}
              {field.validation.required && " *"}
            </label>
            {renderCorrectInputType(field)}
          </React.Fragment>
        ))}
      </div>
      <div className="popup-warn-buttons">
        <div
          className={`button primary-button ${loading ? "loading" : ""}`}
          onClick={createDocument}
        >
          {duplicate &&
            selectedDocumentId &&
            `${loading ? "Duplicating..." : "Duplicate Document"}`}
          {!duplicate &&
            selectedDocumentId &&
            `${loading ? "Updating..." : "Update Document"}`}
          {!selectedDocumentId &&
            `${loading ? "Creating..." : "Create Document"}`}
        </div>
      </div>
    </Popup>
  );
};
