import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
} from "react-router-dom";
import { connect, useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import Notifications from "react-notification-system-redux";
import {
  loggedIn,
  NinjaSkeletonTheme,
} from "@commonninja/commonninja-styleguide-react";

import { User } from "@commonninja/commonninja-styleguide-react/lib/esm/components/internal";
import { DeveloperDashboard } from "./components/developer/developer.comp";
import { Pricing } from "./components/pricing/pricing.comp";
import { Analytics } from "./components/analytics/analytics.comp";
import { Login } from "./components/auth/login.comp";
import { PostLogin } from "./components/auth/postLogin.comp";
import { Logout } from "./components/auth/logout.comp";
import { Signup } from "./components/auth/signup.comp";
import { ForgotPassword } from "./components/auth/forgot.comp";
import { ResetPassword } from "./components/auth/reset.comp";
import { FeedbackPage } from "./components/auth/feedbackPage.comp";
import { InvitationConfirmation } from "./components/auth/invitation.comp";
import { Confirmation } from "./components/auth/confirm.comp";
import { DeveloperSignup } from "./components/developer/developerSignup/developerSignup.comp";
import { CheckoutSuccess } from "./components/checkoutSuccess/checkoutSuccess.comp";
import { checkIfAuthPage } from "./helpers/helpers";
import { ToastContainer } from "react-toastify";
import { ProjectDashboard } from "./components/projectDashboard/projectDashboard.comp";
import { initializePaddle } from "@paddle/paddle-js";

import "./app.scss";

const token = process.env.REACT_APP_PADDLE_PUBLIC_KEY || "";

function AppWrapper(props: any) {
  const history = useHistory();
  const isEmbed = window.location.pathname.includes("/embed");
  const isDeveloper = window.location.pathname.includes("/developer");
  const isAuth = checkIfAuthPage();
  const [, setIsPricing] = useState<boolean>(
    window.location.pathname.includes("/pricing")
  );
  const { user } = useSelector((state: any) => ({
    user: state.user,
  }));
  const dispatch = useDispatch();

  function renderHeader() {
    if ((!isEmbed && !isAuth) || isDeveloper) {
      return <></>;
    }
    return (
      <User
        user={user}
        componentType=""
        serviceName="commonninja"
        noUI={true}
        postLoginCallback={(user: any) => {
          dispatch(loggedIn(user));
        }}
      />
    );
  }

  useEffect(() => {
    return history.listen((location) => {
      setIsPricing(location.pathname.includes("/pricing"));
    });
  }, [history]);

  // useEffect(() => {
  //   if (user?.email) {
  //     // Profitwell Retain
  //     (window as any).profitwell?.("start", {
  //       user_email: user.email,
  //     });
  //   }
  // }, [user]);

  useEffect(() => {
    initializePaddle({
      token,
      pwCustomer: {},
    });
  }, []);

  return (
    <>
      {renderHeader()}
      <NinjaSkeletonTheme leadColor="#f7f7f9">
        <main id="main-content">
          <Switch>
            <Route path="/feedback" component={FeedbackPage} />
            <Route path="/login" component={Login} />
            <Route path="/signup" component={Signup} />
            <Route
              path="/developer/signup"
              render={(routeProps) => (
                <Signup isDeveloper={true} {...routeProps} />
              )}
            />
            <Route
              path="/developer/login"
              render={(routeProps) => (
                <Login isDeveloper={true} {...routeProps} />
              )}
            />
            <Route path="/forgot" component={ForgotPassword} />
            <Route
              path="/developer/forgot"
              render={(routeProps) => (
                <ForgotPassword isDeveloper={true} {...routeProps} />
              )}
            />
            <Route path="/confirm/:token" component={Confirmation} />
            <Route path="/reset/:token" component={ResetPassword} />
            <Route
              path="/invitation/:token"
              component={InvitationConfirmation}
            />
            <Route exact path="/project/invitation/:token">
              <InvitationConfirmation invitationType="project" />
            </Route>
            <Route path="/developer/join" exact component={DeveloperSignup} />
            <Route path="/developer" component={DeveloperDashboard} />

            {/* SINGLE PROJECT */}
            <Route path="/user/dashboard" component={ProjectDashboard} />

            {/* Pricing */}
            <Route path="/pricing/:serviceName" component={Pricing} />
            {/* <Route path='/pricing' exact render={(routeProps) => <Pricing {...routeProps} />} /> */}
            <Route path="/auth/post-login" component={PostLogin} />
            <Route
              path="/embed/website/pricing/:serviceName"
              render={(routeProps) => (
                <Pricing {...routeProps} embedMode={true} websiteMode={true} />
              )}
            />
            <Route
              path="/embed/website/checkout-success/:serviceName"
              render={(routeProps) => (
                <CheckoutSuccess
                  {...routeProps}
                  embedMode={true}
                  websiteMode={true}
                />
              )}
            />
            <Route
              path="/embed/checkout-success/:serviceName"
              render={(routeProps) => (
                <CheckoutSuccess {...routeProps} embedMode={true} />
              )}
            />
            <Route
              path="/embed/pricing/:serviceName"
              render={(routeProps) => (
                <Pricing {...routeProps} embedMode={true} />
              )}
            />
            {/* <Route path='/embed/pricing' exact render={(routeProps) => <Pricing {...routeProps} embedMode={true} />} /> */}
            <Route
              path="/embed/v/:vendor/pricing/:serviceName"
              render={(routeProps) => (
                <Pricing {...routeProps} embedMode={true} />
              )}
            />
            <Route
              path="/embed/login"
              render={(routeProps) => (
                <Login {...routeProps} embedMode={true} />
              )}
            />
            <Route
              path="/embed/signup"
              render={(routeProps) => (
                <Signup {...routeProps} embedMode={true} />
              )}
            />
            <Route
              path="/embed/developer/signup"
              render={(routeProps) => (
                <Signup isDeveloper={true} {...routeProps} embedMode={true} />
              )}
            />
            <Route
              path="/embed/developer/login"
              render={(routeProps) => (
                <Login isDeveloper={true} {...routeProps} embedMode={true} />
              )}
            />
            <Route path="/embed/logout" component={Logout} />
            <Route
              path="/embed/forgot"
              render={(routeProps) => (
                <ForgotPassword {...routeProps} embedMode={true} />
              )}
            />
            <Route
              path="/embed/developer/forgot"
              render={(routeProps) => (
                <ForgotPassword
                  isDeveloper={true}
                  {...routeProps}
                  embedMode={true}
                />
              )}
            />
            <Route
              path="/embed/analytics/:componentId"
              render={(routeProps) => (
                <Analytics {...routeProps} embedMode={true} />
              )}
            />
            <Route
              path="/embed/analytics"
              render={(routeProps) => (
                <Analytics {...routeProps} embedMode={true} />
              )}
            />
            <Redirect from="/" to="/user/dashboard" />
          </Switch>
        </main>
      </NinjaSkeletonTheme>
    </>
  );
}

function App(props: any) {
  const isDeveloper = window.location.pathname.includes("/developer");

  return (
    <div className={`app ${isDeveloper ? "developer-page" : ""}`}>
      <Router>
        <AppWrapper />
        <Notifications
          notifications={props.notifications}
          style={{
            Containers: {
              DefaultStyle: {
                width: 520,
                left: "50%",
              },
            },
            NotificationItem: {
              DefaultStyle: {
                padding: "10px 20px",
              },
            },
            Title: {
              DefaultStyle: {
                margin: "0",
              },
            },
          }}
        />
        <ToastContainer position={"top-center"} autoClose={2500} />
      </Router>
    </div>
  );
}

export default connect((state: any) => ({
  notifications: state.notifications,
}))(App);
