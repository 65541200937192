import React from 'react';
type changeFn = (e: any) => void;

export function useHandleChange(
  setState: React.SetStateAction<any>
): [changeFn] {
  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    setState((prevState: any) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  }
  return [handleChange];
}
