import {
	HttpService,
	IHttpResult,
} from '@commonninja/commonninja-styleguide-react';
import {
	IApp,
	IAppIntegration,
	IAppPlanFeature,
	IDeveloper,
	IAppPlan,
	IAppWebhook,
	IAppPlanCoupon,
} from '../components/developer/developer.types';
import {
	ICollectionSchema,
	ICollectionSchemaAttribute,
} from '../components/developer/appDatabase/types';
import { Dayjs } from 'dayjs';

const integrationsUrl = process.env.REACT_APP_INTEGRATIONS_SERVICE_URL || '';
const baseUrl = `${integrationsUrl}/integrations/developer`;

class DeveloperService extends HttpService {
	public developerSignup(captchaToken: string) {
		return this.makeRequest(`${baseUrl}/signup`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({ captchaToken }),
		});
	}

	public async getDeveloper(): Promise<IHttpResult> {
		const url = `${baseUrl}/details`;
		return await this.makeRequest(url);
	}

	public async updateDeveloper(
		data: Partial<IDeveloper>
	): Promise<IHttpResult> {
		const url = `${baseUrl}/details`;
		return await this.makeRequest(url, {
			method: 'POST',
			body: JSON.stringify(data),
			headers: {
				'Content-Type': 'application/json',
			},
		});
	}

	public async signup(): Promise<IHttpResult> {
		const url = `${baseUrl}/signup`;
		return await this.makeRequest(url);
	}

	public async getDeveloperApps(): Promise<IHttpResult> {
		const url = `${baseUrl}/apps`;
		return await this.makeRequest(url);
	}

	public async getDeveloperApp(appId: string): Promise<IHttpResult> {
		const url = `${baseUrl}/apps/${appId}`;
		return await this.makeRequest(url);
	}

	public async createDeveloperApp(data: Partial<IApp>): Promise<IHttpResult> {
		const url = `${baseUrl}/apps`;
		return await this.makeRequest(url, {
			method: 'POST',
			body: JSON.stringify(data),
			headers: {
				'Content-Type': 'application/json',
			},
		});
	}

	public async updateDeveloperApp(
		appId: string,
		data: Partial<IApp>
	): Promise<IHttpResult> {
		const url = `${baseUrl}/apps/${appId}`;
		return await this.makeRequest(url, {
			method: 'PUT',
			body: JSON.stringify(data),
			headers: {
				'Content-Type': 'application/json',
			},
		});
	}

	public async deleteDeveloperApp(appId: string): Promise<IHttpResult> {
		const url = `${baseUrl}/apps/${appId}`;
		return await this.makeRequest(url, {
			method: 'DELETE',
		});
	}

	public async getDashboardData(
		appId: string,
		slugUrl: string
	): Promise<IHttpResult> {
		const url = `${baseUrl}/apps/${appId}/analytics/${slugUrl}`;
		return await this.makeRequest(url);
	}

	public async getChartData(
		appId: string,
		extractUrls: string[],
		viewOptions: {
			groupBy: 'month' | 'day';
			from: Dayjs;
			to: Dayjs;
		}
	): Promise<any> {
		let to = viewOptions.to;
		if (extractUrls?.[0]?.includes('stats')) {
			//changing "to" to tommorow for subscriptions and installations endpoint
			to = viewOptions.to.add(1, 'day');
		}

		return await Promise.all(
			extractUrls.map(async (slugUrl) => {
				const url = `${baseUrl}/apps/${appId}/analytics/${slugUrl}`;
				const urlWithParams = `
        ${url}${url.includes('?') ? '&' : '?'}groupBy=${
					viewOptions.groupBy
				}&from=${viewOptions.from.format('YYYY-MM-DD')}&to=${to.format(
					'YYYY-MM-DD'
				)}`;
				const { data } = await this.makeRequest(urlWithParams);
				return {
					name: slugUrl,
					data,
				};
			})
		);
	}

	public async getAppIntegrations(appId: string): Promise<IHttpResult> {
		const url = `${baseUrl}/apps/${appId}/integrations`;
		return await this.makeRequest(url);
	}

	public async getAppIntegrationPlatforms(): Promise<IHttpResult> {
		const url = `${baseUrl}/integrationPlatforms`;
		return await this.makeRequest(url);
	}

	public async updateAppIntegration(
		appId: string,
		platform: string,
		data: Partial<IAppIntegration>
	): Promise<IHttpResult> {
		const url = `${baseUrl}/apps/${appId}/integrations/${platform}`;
		return await this.makeRequest(url, {
			method: 'PUT',
			body: JSON.stringify(data),
			headers: {
				'Content-Type': 'application/json',
			},
		});
	}

	public async getAppPlans(appId: string): Promise<IHttpResult> {
		const url = `${baseUrl}/apps/${appId}/payments/plans`;
		return await this.makeRequest(url);
	}

	public async getAppPlan(appId: string, planId: string): Promise<IHttpResult> {
		const url = `${baseUrl}/apps/${appId}/payments/plans/${planId}`;
		return await this.makeRequest(url);
	}

	public async createAppPlan(
		appId: string,
		plan: Partial<IAppPlan>
	): Promise<IHttpResult> {
		const url = `${baseUrl}/apps/${appId}/payments/plans`;
		return await this.makeRequest(url, {
			method: 'POST',
			body: JSON.stringify(plan),
			headers: {
				'Content-Type': 'application/json',
			},
		});
	}

	public async updateAppPlan(
		appId: string,
		planId: string,
		data: Partial<IAppPlan>
	): Promise<IHttpResult> {
		const url = `${baseUrl}/apps/${appId}/payments/plans/${planId}`;
		return await this.makeRequest(url, {
			method: 'PUT',
			body: JSON.stringify(data),
			headers: {
				'Content-Type': 'application/json',
			},
		});
	}

	public async deleteAppPlan(
		appId: string,
		planId: string
	): Promise<IHttpResult> {
		const url = `${baseUrl}/apps/${appId}/payments/plans/${planId}`;
		return await this.makeRequest(url, {
			method: 'DELETE',
		});
	}

	public async getAppFeatures(appId: string): Promise<IHttpResult> {
		const url = `${baseUrl}/apps/${appId}/payments/features`;
		return await this.makeRequest(url);
	}

	public async updateAppFeatures(
		appId: string,
		features: IAppPlanFeature[]
	): Promise<IHttpResult> {
		const url = `${baseUrl}/apps/${appId}/payments/features`;
		return await this.makeRequest(url, {
			method: 'PUT',
			body: JSON.stringify({ features }),
			headers: {
				'Content-Type': 'application/json',
			},
		});
	}

	public async getAppCoupons(appId: string): Promise<IHttpResult> {
		const url = `${baseUrl}/apps/${appId}/payments/coupons`;
		return await this.makeRequest(url);
	}

	public async createAppCoupon(
		appId: string,
		coupon: IAppPlanCoupon
	): Promise<IHttpResult> {
		const url = `${baseUrl}/apps/${appId}/payments/coupons`;
		const res = await this.makeRequest(
			url,
			{
				method: 'POST',
				body: JSON.stringify(coupon),
				headers: {
					'Content-Type': 'application/json',
				},
			},
			true,
			true
		);
		return await this.getFinalResponse(res);
	}

	public async updateAppCoupon(
		appId: string,
		coupon: IAppPlanCoupon
	): Promise<IHttpResult> {
		const url = `${baseUrl}/apps/${appId}/payments/coupons/${coupon.couponId}`;
		const res = await this.makeRequest(
			url,
			{
				method: 'PUT',
				body: JSON.stringify(coupon),
				headers: {
					'Content-Type': 'application/json',
				},
			},
			true,
			true
		);
		return await this.getFinalResponse(res);
	}

	public async deleteAppCoupon(
		appId: string,
		couponId: string,
		platform: string
	): Promise<IHttpResult> {
		const url = `${baseUrl}/apps/${appId}/payments/coupons/${couponId}?platform=${platform}`;
		const res = await this.makeRequest(
			url,
			{
				method: 'DELETE',
			},
			true,
			true
		);
		return await this.getFinalResponse(res);
	}

	public async getAppConnections(
		appId: string,
		platform: string
	): Promise<IHttpResult> {
		const url = `${baseUrl}/apps/${appId}/payments/connections?platform=${platform}`;
		return await this.makeRequest(url);
	}

	public async getAppPlanConnections(
		appId: string,
		planId: string
	): Promise<IHttpResult> {
		const url = `${baseUrl}/apps/${appId}/payments/plans/${planId}/connections`;
		return await this.makeRequest(url);
	}

	public async createAppPlanConnection(
		appId: string,
		planId: string,
		providerName: string,
		pricingToPlanMap: { [key: string]: string } = {}
	): Promise<IHttpResult> {
		const url = `${baseUrl}/apps/${appId}/payments/plans/${planId}/connect`;
		return await this.makeRequest(url, {
			method: 'POST',
			body: JSON.stringify({ platform: providerName, pricingToPlanMap }),
			headers: {
				'Content-Type': 'application/json',
			},
		});
	}

	public async disableAppPlanConnection(
		appId: string,
		planId: string,
		providerName: string
	): Promise<IHttpResult> {
		const url = `${baseUrl}/apps/${appId}/payments/plans/${planId}/disconnect`;
		return await this.makeRequest(url, {
			method: 'POST',
			body: JSON.stringify({ platform: providerName }),
			headers: {
				'Content-Type': 'application/json',
			},
		});
	}

	public async getAppWebhooks(appId: string) {
		const url = `${baseUrl}/apps/${appId}/webhooks`;
		return await this.makeRequest(url);
	}

	public async getAppWebhook(appId: string, webhookId: string) {
		const url = `${baseUrl}/apps/${appId}/webhooks/${webhookId}`;
		return await this.makeRequest(url);
	}

	public async createAppWebhook(
		appId: string,
		data: Partial<IAppWebhook>
	): Promise<IHttpResult> {
		const url = `${baseUrl}/apps/${appId}/webhooks`;
		return await this.makeRequest(url, {
			method: 'POST',
			body: JSON.stringify(data),
			headers: {
				'Content-Type': 'application/json',
			},
		});
	}

	public async updateAppWebhook(
		appId: string,
		webhookId: string,
		data: Partial<IAppWebhook>
	): Promise<IHttpResult> {
		const url = `${baseUrl}/apps/${appId}/webhooks/${webhookId}`;
		return await this.makeRequest(url, {
			method: 'PUT',
			body: JSON.stringify(data),
			headers: {
				'Content-Type': 'application/json',
			},
		});
	}

	public async deleteAppWebhook(
		appId: string,
		webhookId: string
	): Promise<IHttpResult> {
		const url = `${baseUrl}/apps/${appId}/webhooks/${webhookId}`;
		return await this.makeRequest(url, {
			method: 'DELETE',
		});
	}

	public async getAppWebhookMessageRequests(
		appId: string,
		webhookId: string,
		page: number,
		limit: number,
		status?: string
	) {
		const url = `${baseUrl}/apps/${appId}/webhooks/${webhookId}/requests?page=${page}&limit=${limit}${
			status ? `&status=${status}` : ''
		}`;
		return await this.makeRequest(url);
	}

	public async getAppWebhookMessageByRequestId(appId: string, id: string) {
		const url = `${baseUrl}/apps/${appId}/webhooks/requests/${id}/message`;
		return await this.makeRequest(url);
	}

	public async resendAppWebhookMessageRequest(
		appId: string,
		webhookId: string,
		requestId: string
	) {
		const url = `${baseUrl}/apps/${appId}/webhooks/${webhookId}/requests/${requestId}/resend`;
		return await this.makeRequest(url, {
			method: 'POST',
		});
	}

	public async getDB(appId: string) {
		const url = `${baseUrl}/apps/${appId}/db`;
		return await this.makeRequest(url);
	}

	public async createDB(appId: string) {
		const url = `${baseUrl}/apps/${appId}/db`;
		const res = await this.makeRequest(
			url,
			{
				method: 'POST',
			},
			true,
			true
		);
		return await this.getFinalResponse(res);
	}

	public async deleteDB(appId: string) {
		const url = `${baseUrl}/apps/${appId}/db`;
		return await this.makeRequest(url, {
			method: 'DELETE',
		});
	}

	public async getCollections(appId: string) {
		const url = `${baseUrl}/apps/${appId}/db/collection`;
		return await this.makeRequest(url);
	}

	public async getCollection(appId: string, collectionName: string) {
		const url = `${baseUrl}/apps/${appId}/db/collection/${collectionName}`;
		return await this.makeRequest(url);
	}

	public async createCollection(appId: string, collectionName: string) {
		const url = `${baseUrl}/apps/${appId}/db/collection/${collectionName}`;
		const res = await this.makeRequest(
			url,
			{
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
			},
			true,
			true
		);
		return await this.getFinalResponse(res);
	}

	public async updateCollection(
		appId: string,
		collectionName: string,
		newCollectionName: string
	) {
		const url = `${baseUrl}/apps/${appId}/db/collection/${collectionName}`;
		const res = await this.makeRequest(
			url,
			{
				method: 'PUT',
				body: JSON.stringify({
					name: newCollectionName,
				}),
				headers: {
					'Content-Type': 'application/json',
				},
			},
			true,
			true
		);
		return await this.getFinalResponse(res);
	}

	public async deleteCollection(appId: string, collectionName: string) {
		const url = `${baseUrl}/apps/${appId}/db/collection/${collectionName}`;
		const res = await this.makeRequest(
			url,
			{
				method: 'DELETE',
			},
			true,
			true
		);
		return await this.getFinalResponse(res);
	}

	public async getDocuments(
		appId: string,
		collectionName: string,
		params: {
			page?: number;
			limit?: number;
			query?: string;
			sort?: string;
		}
	) {
		const { page, query, sort, limit } = params;
		const url = `${baseUrl}/apps/${appId}/db/collection/${collectionName}/document?page=${
			page || 1
		}&limit=${limit || 10}${query ? `&query=${query}` : ''}${
			sort ? `&sort=${sort}` : ''
		}`;
		return await this.makeRequest(url);
	}

	public async getDocument(
		appId: string,
		collectionName: string,
		documentId: string
	) {
		const url = `${baseUrl}/apps/${appId}/db/collection/${collectionName}/document/${documentId}`;
		return await this.makeRequest(url);
	}

	private async getFinalResponse(res: Response): Promise<any> {
		if (res.status !== 200) {
			throw new Error((await res.text()) || res.statusText);
		}

		const json = await res.json();

		return json;
	}

	public async createDocument(
		appId: string,
		collectionName: string,
		document: any //TODO: add types
	) {
		const url = `${baseUrl}/apps/${appId}/db/collection/${collectionName}/document`;
		const res = await this.makeRequest(
			url,
			{
				method: 'POST',
				body: JSON.stringify(document),
				headers: {
					'Content-Type': 'application/json',
				},
			},
			true,
			true
		);

		return await this.getFinalResponse(res);
	}

	public async updateDocument(
		appId: string,
		collectionName: string,
		documentId: string,
		document: any //TODO: add types
	) {
		const url = `${baseUrl}/apps/${appId}/db/collection/${collectionName}/document/${documentId}`;
		const res = await this.makeRequest(
			url,
			{
				method: 'PUT',
				body: JSON.stringify(document),
				headers: {
					'Content-Type': 'application/json',
				},
			},
			true,
			true
		);
		return await this.getFinalResponse(res);
	}

	public async deleteDocument(
		appId: string,
		collectionName: string,
		documentId: string
	) {
		const url = `${baseUrl}/apps/${appId}/db/collection/${collectionName}/document/${documentId}`;
		const res = await this.makeRequest(
			url,
			{
				method: 'DELETE',
			},
			true,
			true
		);
		return await this.getFinalResponse(res);
	}

	public async countDocuments(appId: string, collectionName: string) {
		const url = `${baseUrl}/apps/${appId}/db/collection/${collectionName}/document/count`;
		return await this.makeRequest(url);
	}

	public async getCollectionSchema(appId: string, collectionName: string) {
		const url = `${baseUrl}/apps/${appId}/db/collection/${collectionName}/schema`;
		return await this.makeRequest(url);
	}

	public async createCollectionSchema(appId: string, collectionName: string) {
		const url = `${baseUrl}/apps/${appId}/db/collection/${collectionName}/schema`;
		const res = await this.makeRequest(
			url,
			{
				method: 'POST',
				body: JSON.stringify({
					displayName: collectionName,
					appId,
					attributes: [],
				}),
				headers: {
					'Content-Type': 'application/json',
				},
			},
			true,
			true
		);
		return await this.getFinalResponse(res);
	}

	public async updateCollectionSchema(
		appId: string,
		collectionName: string,
		schema: Partial<ICollectionSchema>
	) {
		const url = `${baseUrl}/apps/${appId}/db/collection/${collectionName}/schema`;
		const { data: prevSchema } = await this.getCollectionSchema(
			appId,
			collectionName
		);
		const updatedSchema = { ...prevSchema, ...schema };
		const res = await this.makeRequest(
			url,
			{
				method: 'POST',
				body: JSON.stringify(updatedSchema),
				headers: {
					'Content-Type': 'application/json',
				},
			},
			true,
			true
		);
		return await this.getFinalResponse(res);
	}

	public async deleteCollectionSchema(appId: string, collectionName: string) {
		const url = `${baseUrl}/apps/${appId}/db/collection/${collectionName}/schema`;
		const res = await this.makeRequest(
			url,
			{
				method: 'DELETE',
			},
			true,
			true
		);
		return await this.getFinalResponse(res);
	}

	public async deleteCollectionField(
		appId: string,
		collectionName: string,
		fieldName: string
	) {
		const url = `${baseUrl}/apps/${appId}/db/collection/${collectionName}/schema`;
		const { data: prevSchema } = await this.getCollectionSchema(
			appId,
			collectionName
		);
		const newSchema = JSON.parse(JSON.stringify(prevSchema)).attributes.filter(
			(field: ICollectionSchemaAttribute) => field.name !== fieldName
		);

		const res = await this.makeRequest(
			url,
			{
				method: 'POST',
				body: JSON.stringify({ attributes: newSchema }),
				headers: {
					'Content-Type': 'application/json',
				},
			},
			true,
			true
		);
		return await this.getFinalResponse(res);
	}

	public async updateCollectionField(
		appId: string,
		collectionName: string,
		newField: ICollectionSchemaAttribute
	) {
		const url = `${baseUrl}/apps/${appId}/db/collection/${collectionName}/schema`;
		const { data: prevSchema } = await this.getCollectionSchema(
			appId,
			collectionName
		);
		const schemaCopy = JSON.parse(JSON.stringify(prevSchema));

		const oldFieldIdx = schemaCopy.attributes.findIndex(
			(field: ICollectionSchemaAttribute) => field.id === newField.id
		);
		schemaCopy.attributes.splice(oldFieldIdx, 1, newField);

		const res = await this.makeRequest(
			url,
			{
				method: 'POST',
				body: JSON.stringify(schemaCopy),
				headers: {
					'Content-Type': 'application/json',
				},
			},
			true,
			true
		);
		return await this.getFinalResponse(res);
	}

	public async getAppUsers(
		appId: string,
		params: {
			page?: number;
			limit?: number;
			query?: string;
			sort?: string;
		}
	) {
		const { page, query, sort, limit } = params;
		const url = `${baseUrl}/apps/${appId}/users?page=${page || 1}&limit=${
			limit || 20
		}${query ? `&query=${query}` : ''}${sort ? `&sort=${sort}` : ''}`;
		return await this.makeRequest(url);
	}

	public async getAppUser(appId: string, userId: string) {
		const url = `${baseUrl}/apps/${appId}/users/${userId}`;
		return await this.makeRequest(url);
	}

	public async getAppUserSubscription(
		appId: string,
		userId: string,
		params: {
			page?: number;
			limit?: number;
			query?: string;
			sort?: string;
		}
	) {
		const { page, query, sort, limit } = params;
		const url = `${baseUrl}/apps/${appId}/users/${userId}/subscription?page=${
			page || 1
		}&limit=${limit || 5}${query ? `&query=${query}` : ''}${
			sort ? `&sort=${sort}` : ''
		}`;
		return await this.makeRequest(url);
	}

	public async updateAppUserSubscription(
		appId: string,
		userId: string,
		subscriptionId: string,
		subscription: {
			planPricingVariantId: string;
			planId: string;
			platform: string;
		}
	) {
		const url = `${baseUrl}/apps/${appId}/users/${userId}/subscription/${subscriptionId}`;
		const res = await this.makeRequest(
			url,
			{
				method: 'PUT',
				body: JSON.stringify(subscription),
				headers: {
					'Content-Type': 'application/json',
				},
			},
			true,
			true
		);
		return await this.getFinalResponse(res);
	}

	public async cancelAppUserSubscription(
		appId: string,
		userId: string,
		subscriptionId: string,
		platform: string
	) {
		const url = `${baseUrl}/apps/${appId}/users/${userId}/subscription/${subscriptionId}/cancel`;
		const res = await this.makeRequest(
			url,
			{
				method: 'POST',
				body: JSON.stringify({ platform }),
				headers: {
					'Content-Type': 'application/json',
				},
			},
			true,
			true
		);
		return await this.getFinalResponse(res);
	}

	public async getAppUserCharges(
		appId: string,
		userId: string,
		params: {
			page?: number;
			limit?: number;
			query?: string;
			sort?: string;
		}
	) {
		const { page, query, sort, limit } = params;
		const url = `${baseUrl}/apps/${appId}/users/${userId}/charges?page=${
			page || 1
		}&limit=${limit || 5}${query ? `&query=${query}` : ''}${
			sort ? `&sort=${sort}` : ''
		}`;
		return await this.makeRequest(url);
	}

	public async refundAppUser(
		appId: string,
		userId: string,
		chargeId: string,
		refundDetails: {
			platform: string;
			cancelUserSubscription: boolean;
			refundAmount?: number;
		}
	) {
		const url = `${baseUrl}/apps/${appId}/users/${userId}/charges/${chargeId}/refund`;
		const res = await this.makeRequest(
			url,
			{
				method: 'PUT',
				body: JSON.stringify(refundDetails),
				headers: {
					'Content-Type': 'application/json',
				},
			},
			true,
			true
		);
		return await this.getFinalResponse(res);
	}

	public async getAppUserStats(appId: string, userId: string) {
		const url = `${baseUrl}/apps/${appId}/users/${userId}/stats`;
		return await this.makeRequest(url);
	}

	public async updateAppUserDetails(
		appId: string,
		userId: string,
		user: { accountName: string; accountEmail: string }
	) {
		const url = `${baseUrl}/apps/${appId}/users/${userId}`;
		const res = await this.makeRequest(
			url,
			{
				method: 'PUT',
				body: JSON.stringify(user),
				headers: {
					'Content-Type': 'application/json',
				},
			},
			true,
			true
		);
		return await this.getFinalResponse(res);
	}

	public async deleteAppUserDetails(appId: string, userId: string) {
		const url = `${baseUrl}/apps/${appId}/users/${userId}`;
		const res = await this.makeRequest(
			url,
			{
				method: 'DELETE',
				headers: {
					'Content-Type': 'application/json',
				},
			},
			true,
			true
		);
		return await this.getFinalResponse(res);
	}

	public async createAppUserNote(
		appId: string,
		userId: string,
		note: { title: string; description: string }
	) {
		const url = `${baseUrl}/apps/${appId}/users/${userId}/notes`;
		const res = await this.makeRequest(
			url,
			{
				method: 'POST',
				body: JSON.stringify(note),
				headers: {
					'Content-Type': 'application/json',
				},
			},
			true,
			true
		);
		return await this.getFinalResponse(res);
	}

	public async updateAppUserNote(
		appId: string,
		userId: string,
		note: { title: string; description: string; id: string }
	) {
		const url = `${baseUrl}/apps/${appId}/users/${userId}/notes/${note.id}`;
		const res = await this.makeRequest(
			url,
			{
				method: 'PUT',
				body: JSON.stringify(note),
				headers: {
					'Content-Type': 'application/json',
				},
			},
			true,
			true
		);
		return await this.getFinalResponse(res);
	}

	public async deleteAppUserNote(
		appId: string,
		userId: string,
		noteId: string
	) {
		const url = `${baseUrl}/apps/${appId}/users/${userId}/notes/${noteId}`;
		const res = await this.makeRequest(
			url,
			{
				method: 'DELETE',
				headers: {
					'Content-Type': 'application/json',
				},
			},
			true,
			true
		);
		return await this.getFinalResponse(res);
	}
}

export const developerService = new DeveloperService();
