import React, { useState, useContext } from 'react';
import { DeveloperContext } from '../../developer.context';
import { developerService } from '../../../../services/developer.service';
import { toast } from 'react-toastify';
import { DeveloperInput } from '../../newDashboardComponents/developerInput.comp';

import './settings.scss';

export const Settings = () => {
  const { activeApp, setActiveApp } = useContext(DeveloperContext);
  const [localCheckoutSuccess, setLocalCheckoutSuccess] = useState(
    activeApp.endpoints.checkoutSuccess || ''
  );
  const [localCheckoutFailure, setLocalCheckoutFailure] = useState(
    activeApp.endpoints.checkoutFailure || ''
  );
  const [saving, setSaving] = useState(false);

  async function handleSubmit(e: any) {
    e.preventDefault();
    if (localCheckoutSuccess && !localCheckoutSuccess.startsWith('http')) {
      toast.error('Checkout success URL must be a valid URL');
      return;
    }
    if (localCheckoutFailure && !localCheckoutFailure.startsWith('http')) {
      toast.error('Checkout failure URL must be a valid URL');
      return;
    }
    setSaving(true);

    try {
      const data = {
        endpoints: {
          ...activeApp.endpoints,
          checkoutSuccess: localCheckoutSuccess.trim(),
          checkoutFailure: localCheckoutFailure.trim(),
        },
      };
      const { success } = await toast.promise(
        developerService.updateDeveloperApp(activeApp.appId, data),
        {
          pending: 'Saving changes...',
          success: 'Great success! 👌',
          error: 'Could not save changes 😕',
        }
      );

      if (success) {
        setSaving(false);
        setActiveApp({
          ...activeApp,
          ...data,
        });
      }
    } catch (e) {
      toast.error((e as Error).message);
      setSaving(false);
    }
  }

  return (
    <div>
      <form className="app-section app-section-body payment-settings" onSubmit={handleSubmit}>
        <DeveloperInput
          name="checkoutSuccess"
          value={localCheckoutSuccess}
          handleChange={(e: any) => setLocalCheckoutSuccess(e.target.value)}
          label="Checkout Success URL"
          placeholder="https://example.com/checkout-success"
          autoFocus
        />
        <DeveloperInput
          name="checkoutFailure"
          value={localCheckoutFailure}
          handleChange={(e: any) => setLocalCheckoutFailure(e.target.value)}
          label="Checkout Failure URL"
          placeholder="https://example.com/checkout-failure"
        />
        <div className="buttons-wrapper">
          <button
            type="submit"
            disabled={saving}
            className="primary-button button"
          >
            {saving ? 'Saving...' : 'Save'}
          </button>
        </div>
      </form>
    </div>
  );
};
