import React, { useContext, useEffect } from "react";
import { Route, Switch, useParams, useRouteMatch } from "react-router-dom";
import { Widgets } from "../project/widgets/widgets.comp";
import { ProjectPermissions } from "../project/projectPermissions/projectPermissions.comp";
import { ProjectSettings } from "../project/projectSettings/projectSettings.comp";
import { WidgetCatalog } from "../widgetCatalog/widgetCatalog.comp";
import { ProjectContext } from "../projectDashboard/projectDashboard.context";
import { toast } from "react-toastify";
import { MediaManager } from './mediaManager/mediaManager.comp';

export const ProjectWrapper = () => {
  const { setActiveProject } = useContext(ProjectContext);
  const { projectId = "" } = useParams() as any;
  const { path } = useRouteMatch();

  async function getProjectDetails() {
    try {
      const req = await fetch(`/api/project/${projectId}`);
      const { data } = await req.json();
      setActiveProject(data);
    } catch (e) {
      toast.warning(`Could not load project settings`);
    }
  }

  useEffect(() => {
    getProjectDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId]);

  return (
    <>
      <Switch>
        <Route exact path={`${path}`}>
          <Widgets />
        </Route>
        <Route exact path={`${path}/widgets`}>
          <Widgets />
        </Route>
        <Route exact path={`${path}/catalog`}>
          <WidgetCatalog />
        </Route>
        <Route exact path={`${path}/media-manager`}>
          <MediaManager />
        </Route>
        <Route exact path={`${path}/settings`}>
          <ProjectSettings />
        </Route>
        <Route exact path={`${path}/permissions`}>
          <ProjectPermissions />
        </Route>
      </Switch>
    </>
  );
};
