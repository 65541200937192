import React, { ReactElement } from "react";

import "./dangerZone.scss";
interface IDangerZoneProp {
  title?: string;
  text?: ReactElement | string;
  buttonText?: string;
  onButtonClick?: React.Dispatch<React.SetStateAction<boolean>>;
  className?: string;
}

export const DangerZone = ({
  title = "Danger Zone",
  text,
  onButtonClick = () => {},
  buttonText = "Delete",
  className = "",
}: IDangerZoneProp) => {
  return (
    <div className={`danger-zone ${className}`}>
      <h4>{title}</h4>
      <p>{text}</p>
      <button
        className="button "
        onClick={(e) => {
          e.preventDefault();
          onButtonClick(true);
        }}
      >
        {buttonText}
      </button>
    </div>
  );
};
