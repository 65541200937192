import React, { useContext, useState } from 'react';
import { Table } from '@commonninja/commonninja-styleguide-react';
import { DeveloperContext } from '../developer.context';
import { developerService } from '../../../services/developer.service';
import { toast } from 'react-toastify';
import { ActiveButton } from '../appPayments/common/activeButton.comp';

const scopesTypes = [
  {
    name: 'Products',
    description: 'View and modify products information.',
    scopes: ['read_products', 'write_products'],
  },
  {
    name: 'Content',
    description: 'View and modify store content. ',
    scopes: ['read_content', 'write_content'],
  },
  {
    name: 'Customers',
    description: 'View and modify customer information.',
    scopes: ['read_customers', 'write_customers'],
  },
  {
    name: 'Orders',
    description: 'View and modify orders.',
    scopes: ['read_orders', 'write_orders'],
  },
  {
    name: 'Marketing Events',
    description: 'View and modify marketing information.',
    scopes: ['read_marketing_events', 'write_marketing_events'],
  },
  {
    name: 'Themes',
    description: 'View and modify themes.',
    scopes: ['read_themes', 'write_themes'],
  },
  {
    name: 'Store Info',
    description: 'View and modify general store information.',
    scopes: ['read_store_info', 'write_store_info'],
  },
  {
    name: 'Shipping',
    description: 'View and modify shipping information.',
    scopes: ['read_shipping', 'write_shipping'],
  },
  {
    name: 'Discounts',
    description: 'View and modify discount information.',
    scopes: ['read_discounts', 'write_discounts'],
  },
  {
    name: 'Scripts',
    description: 'View and modify script information.',
    scopes: ['read_scripts', 'write_scripts'],
  },
  {
    name: 'Storefront',
    description: 'View and modify storefront information.',
    scopes: ['read_storefront', 'write_storefront'],
  },
];

export const AppPermissions = () => {
  const { activeApp, setActiveApp } = useContext(DeveloperContext);
  const [permissions, setPermissions] = useState<string[]>(
    activeApp.scopes || []
  );

  function handleScopeChange(scopeName: string) {
    const permissionsCopy = [...permissions];
    if (permissions.includes(scopeName)) {
      permissionsCopy.splice(permissions.indexOf(scopeName), 1);
    } else {
      permissionsCopy.push(scopeName);
    }
    setPermissions(permissionsCopy);
  }

  async function updateApp(e: any) {
    e.preventDefault();
    try {
      toast.dismiss();
      toast.loading('Saving Changes...');
      const data = await developerService.updateDeveloperApp(activeApp.appId, {
        scopes: permissions,
      });
      toast.dismiss();

      if (data.success) {
        setActiveApp({
          ...activeApp,
          scopes: permissions,
        });
        toast.success('Great success! 👌');
      } else {
        throw new Error(data.message);
      }
    } catch (e) {
      toast.dismiss();
      toast.error((e as Error).message);
    }
  }

  function renderPermissionToggler(scopeName: string): JSX.Element {
    return (
      <ActiveButton
        size={22.5}
        isActive={permissions.includes(scopeName)}
        onClick={() => handleScopeChange(scopeName)}
      />
    );
  }

  function permissionsScopeTable(): JSX.Element {
    return (
      <div className="app-section app-section-body permissions-table">
        <Table
          headers={[
            'Scope Name',
            'Description',
            'Permission to Read',
            'Permission to Write',
          ]}
          rows={scopesTypes.map((item: any) => {
            return {
              cells: [
                {
                  content: item.name,
                },
                {
                  content: item.description,
                },
                {
                  content: renderPermissionToggler(item.scopes[0]),
                  className: 'toggle',
                },
                {
                  content: renderPermissionToggler(item.scopes[1]),
                  className: 'toggle',
                },
              ],
            };
          })}
        />
      </div>
    );
  }

  return (
    <>
      <header className="flex-wrapper align-end">
        <hgroup>
          <h2>Permissions</h2>
          <p>Specify the scopes and areas to which your app needs access.</p>
        </hgroup>
        <button className="button primary-button" onClick={updateApp}>
          Save
        </button>
      </header>
      {permissionsScopeTable()}
    </>
  );
};
