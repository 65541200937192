import React from 'react';
import { Link } from 'react-router-dom';
import reactStringReplace from 'react-string-replace';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheck,
  faCircleNotch,
  faLink,
} from '@fortawesome/free-solid-svg-icons';

interface IDashboardStepProps {
  title: string;
  description: string;
  url: string;
  linkText: string;
  openInAnotherTab?: boolean;
  completed?: boolean;
}
export function DashboardStep({
  title,
  description,
  url,
  linkText,
  completed = false,
  openInAnotherTab = false,
}: IDashboardStepProps) {
  return (
    <li className="flex-wrapper dashboard-step">
      <div className="dashboard-step-content">
        <h5>
          <div className="status">
            <FontAwesomeIcon icon={completed ? faCheck : faCircleNotch} />
          </div>
          {title}
          <span>
            {reactStringReplace(description, 'e-commerce', (match, index) => (
              <i key={index} className="no-wrap">
                {match}
              </i>
            ))}
          </span>
        </h5>
      </div>
      {openInAnotherTab ? (
        <a
          className="button dashboard-button"
          href={url}
          rel="noopener noreferrer"
          target="_blank"
        >
          {linkText}
          <FontAwesomeIcon className="external-link-icon" icon={faLink} />
        </a>
      ) : (
        <Link className="button dashboard-button" to={url}>
          {linkText}
        </Link>
      )}
    </li>
  );
}
