import React, { useContext, useEffect } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { Widgets } from '../project/widgets/widgets.comp';
import { Projects } from '../projects/projects.comp';
import {
  ProjectContext,
  ProjectDashboardContextProvider,
} from './projectDashboard.context';
import { ProjectWrapper } from '../project/projectWrapper.comp';
import { NewApps } from '../newApps/newApps.comp';
import { Account } from '../account/account.comp';
import { Billing } from '../billing/billing.comp';
import { WidgetCatalog } from '../widgetCatalog/widgetCatalog.comp';

export const ProjectDashboard = () => {
  useEffect(() => {
    document.title = `Projects | Common Ninja`;
  }, []);

  return (
    <ProjectDashboardContextProvider>
      <ProjectDashboardWrapper />
    </ProjectDashboardContextProvider>
  );
};

const ProjectDashboardWrapper = () => {
  const { getUserPlanFeatures, getApps } = useContext(ProjectContext);
  const { path } = useRouteMatch();

  useEffect(() => {
    getApps();
    getUserPlanFeatures();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Switch>
      <Route exact path={`${path}`}>
        <Projects type="personal" />
      </Route>
      <Route exact path={`${path}/projects`}>
        <Projects type="personal" />
      </Route>
      <Route exact path={`${path}/shared-projects`}>
        <Projects type="shared" />
      </Route>
      <Route exact path={`${path}/shared-widgets`}>
        <Widgets type="shared-widgets" />
      </Route>
      <Route exact path={`${path}/new-apps`}>
        <NewApps />
      </Route>
      <Route path={`${path}/account`}>
        <Account />
      </Route>
      <Route path={`${path}/billing`}>
        <Billing />
      </Route>
      <Route exact path={`${path}/catalog`}>
        <WidgetCatalog />
      </Route>
      <Route path={`${path}/projects/:projectId`}>
        <ProjectWrapper />
      </Route>
    </Switch>
  );
};
