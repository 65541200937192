import React, { useContext, useEffect, useState } from 'react';
import { developerService } from '../../../services/developer.service';
import { toast } from 'react-toastify';

import { DeveloperContext } from '../developer.context';
import { IDeveloper } from '../developer.types';
import { ImageUpload } from '../imageUpload/imageUpload.comp';
import logo from '../../../assets/logo.png';
import './developerAccount.scss';
import { AppDeveloperMenu } from '../appMenu/appDeveloperMenu/appDeveloperMenu.comp';
import { Link } from 'react-router-dom';
import { useHandleChange } from '../newDashboardComponents/hooks/useHandleChange';
import { DeveloperInput } from '../newDashboardComponents/developerInput.comp';
import {
  faBook,
  faCommentDots,
  faThLarge,
  faUserEdit,
} from '@fortawesome/free-solid-svg-icons';

const developerLinks: { name: string; type: string }[] = [
  {
    type: 'website',
    name: 'Website',
  },
  {
    type: 'github',
    name: 'GitHub',
  },
  {
    type: 'linkedin',
    name: 'LinkedIn',
  },
  {
    type: 'twitter',
    name: 'Twitter',
  },
  {
    type: 'facebook',
    name: 'Facebook',
  },
];

export const DeveloperAccount = () => {
  const { developer, setDeveloper } = useContext(DeveloperContext);
  const [formState, setFormState] = useState<IDeveloper>(developer);
  const [handleChange] = useHandleChange(setFormState);

  async function updateDeveloper(e?: any) {
    e?.preventDefault();
    try {
      const data: Partial<IDeveloper> = {
        name: formState.name,
        email: formState.email,
        emergencyEmail: formState.emergencyEmail,
        supportEmail: formState.supportEmail,
        thumbnail: formState.thumbnail,
        coverImage: formState.coverImage,
        caption: formState.caption,
        description: formState.description,
        links: formState.links,
        fullAddress: formState.fullAddress,
        privacyLink: formState.privacyLink,
        termsLink: formState.termsLink,
      };

      const { success } = await toast.promise(
        developerService.updateDeveloper(data),
        {
          pending: 'Updating developer...',
          success: 'Great success! 👌',
          error: 'Could not update developer 😕',
        }
      );

      if (success) {
        setDeveloper(formState);
      }
    } catch (e) {
      toast.error((e as Error).message);
    }
  }

  function handleDevLinkChange(e: any, linkType: string) {
    const links = [...formState.links];
    const foundIndex = links.findIndex(
      (link: { type: string }) => link.type === linkType
    );
    foundIndex === -1
      ? links.push({ type: linkType, url: e.target.value })
      : (links[foundIndex].url = e.target.value);

    setFormState((prevState: IDeveloper) => ({
      ...prevState,
      links,
    }));
  }

  useEffect(() => {
    setFormState(developer);
  }, [developer]);
  // }, [developer]);

  return (
    <>
      <header className="top-bar">
        <figure className="logo">
          <Link to={'/developer/apps'} className="flex-wrapper">
            <img src={logo} alt="Common Ninja" />
            {/* <p>Developer Platform</p> */}
          </Link>
        </figure>
      </header>
      <form
        className="developer-account app-dashboard wrapper"
        onSubmit={updateDeveloper}
      >
        <AppDeveloperMenu
          menuLinks={[
            {
              name: 'My Apps',
              url: `/developer/apps`,
              icon: faThLarge,
            },
            {
              name: 'Developer Info',
              url: `/developer/account`,
              icon: faUserEdit,
            },
            {
              name: 'Documentation',
              url: `https://docs.commoninja.com/`,
              target: '_blank',
              icon: faBook,
            },
            {
              name: 'Support',
              url: 'https://help.commoninja.com/',
              target: '_blank',
              icon: faCommentDots,
            },
          ]}
        />
        <header className="flex-wrapper align-center">
          <hgroup>
            <h2>Developer Info</h2>
          </hgroup>
          <button className="button primary-button">Save</button>
        </header>

        <section className="app-section">
          <div className="app-section-header separator-light flex-wrapper">
            <h4>General Details</h4>
          </div>
          <div className="app-section-body">
            <DeveloperInput
              name="name"
              value={formState.name}
              handleChange={handleChange}
              label="Name"
              autoFocus
              required
              placeholder="Enter name..."
            />
            <DeveloperInput
              name="email"
              value={formState.email}
              handleChange={handleChange}
              label="Email"
              required
              placeholder="Enter email..."
            />
            <DeveloperInput
              name="caption"
              value={formState.caption}
              handleChange={handleChange}
              label="Caption"
              placeholder="Enter caption..."
            />
            <DeveloperInput
              name="description"
              value={formState.description}
              handleChange={handleChange}
              label="Description"
              placeholder="Enter description..."
            />
            <DeveloperInput
              name="fullAddress"
              value={formState.fullAddress}
              handleChange={handleChange}
              label="Full Address"
              placeholder="Enter full address..."
            />
            <div className="input-container">
              <label htmlFor="thumbnail">Logo</label>
              <ImageUpload
                value={formState.thumbnail}
                successCallback={(url: string) =>
                  setFormState((prev) => ({ ...prev, thumbnail: url }))
                }
              />
              <small>(Max. file size is 3MB)</small>
            </div>
          </div>
        </section>

        <section className="app-section">
          <div className="app-section-header separator-light flex-wrapper">
            <h4>Contact Details</h4>
          </div>
          <div className="app-section-body">
            <DeveloperInput
              name="supportEmail"
              value={formState.supportEmail}
              handleChange={handleChange}
              label="Support Email"
              placeholder="Enter support email..."
            />
            <DeveloperInput
              name="emergencyEmail"
              value={formState.emergencyEmail}
              handleChange={handleChange}
              label="Emergency Email"
              placeholder="Enter emergency email..."
            />
          </div>
        </section>

        <section className="app-section">
          <div className="app-section-header separator-light flex-wrapper">
            <h4>Links</h4>
          </div>
          <div className="app-section-body">
            {developerLinks.map((devLink) => {
              const index = formState.links.findIndex(
                (link: { type: string }) => link.type === devLink.type
              );
              return (
                <DeveloperInput
                  name={devLink.type}
                  value={
                    formState.links[index] ? formState.links[index].url : ''
                  }
                  label={devLink.name}
                  placeholder={`Enter your ${devLink.name} URL...`}
                  key={devLink.type}
                  handleChange={(e) => handleDevLinkChange(e, devLink.type)}
                />
              );
            })}
          </div>
        </section>
      </form>
    </>
  );
};

export default DeveloperAccount;
