import React, { ReactNode } from 'react';
import { NavLink } from 'react-router-dom';

interface IAppMenuSectionProps {
  title?: string;
  items: IAppMenuSectionItem[];
  setMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
}
interface IAppMenuSectionItem {
  url: string;
  label: string;
  icon?: ReactNode;
  target?: '_blank' | '_self' | '_parent' | '_top';
}
export function AppMenuSection({
  title,
  items,
  setMenuOpen,
}: IAppMenuSectionProps) {
  return (
    <section>
      {title && <h5>{title}</h5>}
      {items.map((item) => (
        <NavLink
          key={item.url}
          onClick={() => setMenuOpen(false)}
          to={item.url}
          target={item.target}
          activeClassName={'active'}
        >
          {item.icon && item.icon}
          {item.label}
        </NavLink>
      ))}
    </section>
  );
}


