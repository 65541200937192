import React from 'react';
import { NinjaSkeleton } from '@commonninja/commonninja-styleguide-react';

export const SkeletonTable = (props: { rows: number, cols: number }) => {
  const { cols, rows } = props;

  return (
    <div className="content-wrapper">
      <div className="data-table-wrapper">
        <table className="data-table">
          <thead>
            <tr>
            {
              Array.from(new Array(cols)).map((col, idx) => (
                <th key={`th_skel_${idx}`}><NinjaSkeleton count={1} /></th>
              ))
            }
            </tr>
          </thead>
          <tbody>
            {
              Array.from(new Array(rows)).map((row, rIdx) => (
                <tr key={`row_skel_${rIdx}`}>
                {
                  Array.from(new Array(cols)).map((col, cIdx) => (
                    <td key={`td_skel_${rIdx}_${cIdx}`}><NinjaSkeleton count={1} /></td>
                  ))
                }
                </tr>
              ))
            }
          </tbody>
        </table>
      </div>
    </div>
  );
};