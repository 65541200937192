import React from "react";

export const FieldIcons = ({ type }: { type: string }) => {
	function renderFieldType() {
		if (type === "email") return renderEmailType();
		if (type === "password") return renderPasswordType();
		if (type === "rich-text") return renderRichTextType();
		if (type === "number") return renderNumberType();
		if (type === "enumeration") return renderEnumType();
		if (type === "date") return renderDateType();
		if (type === "media") return renderMediaType();
		if (type === "boolean") return renderBooleanType();
		if (type === "json") return renderJSONType();
		if (type === "uid") return renderUIDType();
		if (type === "text") return renderTextType();
		if (type === "array") return renderArrayType();
		if (type === "url") return renderURLType();
		if (type === "relation") return renderRelationType();
		return <></>;
	}

	return renderFieldType();
};

function renderArrayType() {
	return (
		<svg
			width="35"
			height="25"
			viewBox="0 0 35 25"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<rect
				x="0.25"
				y="0.25"
				width="34.5"
				height="24.5"
				rx="4.75"
				fill="#FDFCE5"
				stroke="#CC9730"
				strokeWidth="0.5"
			/>
			<circle cx="15" cy="12.5" r="1" fill="#CC9730" />
			<circle cx="17.9961" cy="12.5" r="1" fill="#CC9730" />
			<circle cx="20.9922" cy="12.5" r="1" fill="#CC9730" />
			<path d="M23 18V17H24.7717V8H23V7H26V18H23Z" fill="#CC9730" />
			<path d="M13 18V17H11.2283V8H13V7H10V18H13Z" fill="#CC9730" />
		</svg>
	);
}

function renderURLType() {
	return (
		<svg
			width="35"
			height="25"
			viewBox="0 0 35 25"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<rect
				x="0.25"
				y="0.25"
				width="34.5"
				height="24.5"
				rx="4.75"
				fill="#D6E1FF"
				stroke="#7285C9"
				strokeWidth="0.5"
			/>
			<path
				d="M18.931 11.067C18.777 10.9121 18.6062 10.7748 18.4219 10.6576C17.9337 10.345 17.3661 10.1788 16.7864 10.1788C15.9817 10.1773 15.2097 10.4971 14.6417 11.067L11.8862 13.8247C11.3192 14.3928 11.0005 15.1625 11 15.9652C10.9989 17.6402 12.3559 18.9989 14.0308 18.9999C14.8343 19.0027 15.6055 18.6845 16.1733 18.1161L18.4479 15.8415C18.4892 15.8005 18.5123 15.7446 18.512 15.6864C18.5113 15.5668 18.4137 15.4704 18.2941 15.4711H18.2074C17.7322 15.4727 17.2612 15.3815 16.821 15.2024C16.7399 15.1691 16.6467 15.1879 16.5849 15.2501L14.9494 16.8878C14.4415 17.3957 13.6181 17.3957 13.1102 16.8878C12.6023 16.3799 12.6023 15.5565 13.1102 15.0486L15.8765 12.2845C16.384 11.7776 17.2061 11.7776 17.7136 12.2845C18.0556 12.6063 18.589 12.6063 18.931 12.2845C19.0781 12.1372 19.1673 11.9419 19.1823 11.7342C19.1981 11.4861 19.1066 11.243 18.931 11.067Z"
				fill="#7285C9"
			/>
			<path
				d="M23.1096 6.88833C21.9251 5.70389 20.0048 5.70389 18.8203 6.88833L16.5479 9.15858C16.4855 9.22128 16.4675 9.31559 16.5024 9.39688C16.5369 9.4784 16.6175 9.53072 16.706 9.52902H16.7862C17.2609 9.52815 17.7311 9.62013 18.1704 9.79981C18.2515 9.83319 18.3448 9.81436 18.4066 9.75216L20.0378 8.12311C20.5456 7.61523 21.3691 7.61523 21.8769 8.12311C22.3848 8.63098 22.3848 9.45441 21.8769 9.96228L19.845 11.9921L19.8276 12.0116L19.1171 12.7178C18.6096 13.2246 17.7875 13.2246 17.2801 12.7178C16.9381 12.3959 16.4046 12.3959 16.0626 12.7178C15.9146 12.8662 15.8253 13.0632 15.8113 13.2723C15.7955 13.5205 15.887 13.7635 16.0626 13.9396C16.3134 14.1914 16.6067 14.3967 16.9291 14.5461C16.9746 14.5678 17.0201 14.5851 17.0656 14.6046C17.1111 14.6241 17.1588 14.6393 17.2042 14.6566C17.2497 14.6739 17.2974 14.6891 17.3429 14.7021L17.4707 14.7367C17.5573 14.7584 17.644 14.7757 17.7328 14.7909C17.8398 14.8068 17.9475 14.8169 18.0556 14.8212H18.2072H18.2202L18.3502 14.8061C18.3978 14.8039 18.4477 14.7931 18.504 14.7931H18.5777L18.7271 14.7714L18.7965 14.7584L18.9221 14.7324H18.9459C19.478 14.5988 19.9639 14.3233 20.3518 13.9352L23.1095 11.1776C24.294 9.99312 24.294 8.07277 23.1096 6.88833Z"
				fill="#7285C9"
			/>
		</svg>
	);
}

function renderRelationType() {
	return (
		<svg
			width="35"
			height="25"
			viewBox="0 0 35 25"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<rect
				x="0.25"
				y="0.25"
				width="34.5"
				height="24.5"
				rx="4.75"
				fill="#E5FEF2"
				stroke="#52C45E"
				strokeWidth="0.5"
			/>
			<circle cx="23.5" cy="7.5" r="1.5" fill="#52C35E" />
			<circle cx="11.5" cy="7.5" r="1.5" fill="#52C35E" />
			<circle cx="23.5" cy="12" r="1.5" fill="#52C35E" />
			<circle cx="11.5" cy="12" r="1.5" fill="#52C35E" />
			<circle cx="23.5" cy="16.5" r="1.5" fill="#52C35E" />
			<circle cx="11.5" cy="16.5" r="1.5" fill="#52C35E" />
			<path
				d="M22 7.5H20.7727C18.3182 7.5 16.2727 16.5 14.4318 16.5C13.45 16.5 13.0682 16.5 13 16.5"
				stroke="#52C35E"
				strokeLinecap="round"
			/>
			<path
				d="M22 12H20.7727C18.3182 12 16.2727 7.5 14.4318 7.5C13.45 7.5 13.0682 7.5 13 7.5"
				stroke="#52C35E"
				strokeLinecap="round"
			/>
			<path
				d="M22 16.5H20.7727C18.3182 16.5 16.2727 12 14.4318 12C13.45 12 13.0682 12 13 12"
				stroke="#52C35E"
				strokeLinecap="round"
			/>
		</svg>
	);
}

function renderTextType() {
	return (
		<svg
			width="35"
			height="25"
			viewBox="0 0 35 25"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<rect
				x="0.25"
				y="0.25"
				width="34.5"
				height="24.5"
				rx="4.75"
				fill="#FDF2D9"
				stroke="#ED9051"
				strokeWidth="0.5"
			/>
			<path
				d="M22.9737 6H12.0263C11.4595 6 11 6.45952 11 7.02632V9.69405C11 10.2609 11.4595 10.7204 12.0263 10.7204C12.5931 10.7204 13.0526 10.2609 13.0526 9.69405V8.05263H16.4737V16.9474H14.8888C14.322 16.9474 13.8625 17.4069 13.8625 17.9737C13.8625 18.5405 14.322 19 14.8888 19H20.1112C20.678 19 21.1375 18.5405 21.1375 17.9737C21.1375 17.4069 20.678 16.9474 20.1112 16.9474H18.5263V8.05263H21.9474V9.69405C21.9474 10.2609 22.4069 10.7204 22.9737 10.7204C23.5405 10.7204 24 10.2609 24 9.69405V7.02632C24 6.45952 23.5405 6 22.9737 6Z"
				fill="#ED9051"
			/>
		</svg>
	);
}

function renderEmailType() {
	return (
		<svg
			width="35"
			height="25"
			viewBox="0 0 35 25"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<rect
				x="0.25"
				y="0.25"
				width="34.5"
				height="24.5"
				rx="4.75"
				fill="#FDE8E7"
				stroke="#D32725"
				strokeWidth="0.5"
			/>
			<path
				d="M22.3429 7.63892C21.2389 6.54631 19.8658 6 18.226 6C16.5022 6 15.0327 6.62056 13.8201 7.86434C12.6075 9.10812 12 10.6542 12 12.5053C12 14.2795 12.5779 15.8044 13.7312 17.0826C14.8895 18.3609 16.4454 19 18.4038 19C19.5843 19 20.7425 18.7401 21.8786 18.2177C22.249 18.0479 22.4293 17.5918 22.2787 17.1913C22.1231 16.7776 21.6835 16.5867 21.3007 16.7617C20.3128 17.2179 19.3447 17.4459 18.4013 17.4459C16.8998 17.4459 15.7366 16.9553 14.9117 15.9714C14.0893 14.9902 13.6769 13.8366 13.6769 12.5133C13.6769 11.0759 14.119 9.87719 15.0006 8.91452C15.8798 7.95451 16.964 7.47185 18.2482 7.47185C19.4312 7.47185 20.4314 7.86699 21.2463 8.65728C22.0613 9.44757 22.4688 10.4421 22.4688 11.6408C22.4688 12.4602 22.2811 13.1444 21.9082 13.6881C21.5353 14.2344 21.1476 14.5049 20.745 14.5049C20.5277 14.5049 20.419 14.3803 20.419 14.1283C20.419 13.9241 20.4338 13.6854 20.461 13.4096L20.9204 9.37597H19.3398L19.2385 9.77111C18.836 9.4184 18.3939 9.24072 17.9148 9.24072C17.1541 9.24072 16.5022 9.56691 15.9613 10.2166C15.418 10.8664 15.1488 11.7044 15.1488 12.7281C15.1488 13.7279 15.3884 14.5367 15.8699 15.1493C16.3515 15.7646 16.9294 16.0696 17.6061 16.0696C18.2112 16.0696 18.7273 15.7964 19.157 15.2528C19.4806 15.7752 19.9572 16.0351 20.587 16.0351C21.5131 16.0351 22.3132 15.6028 22.9874 14.7356C23.6617 13.8711 24 12.8262 24 11.6036C24 10.0549 23.4493 8.73154 22.3429 7.63892ZM18.7026 13.7411C18.4235 14.1442 18.0901 14.3484 17.7024 14.3484C17.4382 14.3484 17.2258 14.1999 17.0652 13.9029C16.9022 13.6059 16.8207 13.2373 16.8207 12.7944C16.8207 12.2481 16.9343 11.8078 17.1616 11.4737C17.3888 11.1395 17.6703 10.9698 18.0062 10.9698C18.2976 10.9698 18.5569 11.0945 18.7841 11.3464C19.0113 11.5983 19.1249 11.9325 19.1249 12.3515C19.1225 12.8739 18.9817 13.3354 18.7026 13.7411Z"
				fill="#D32725"
			/>
		</svg>
	);
}

function renderRichTextType() {
	return (
		<svg
			width="35"
			height="25"
			viewBox="0 0 35 25"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<rect
				x="0.25"
				y="0.25"
				width="34.5"
				height="24.5"
				rx="4.75"
				fill="#FFE1CB"
				stroke="#DA9A5E"
				strokeWidth="0.5"
			/>
			<rect x="8" y="6" width="19" height="2" rx="1" fill="#E28F43" />
			<rect x="8" y="11" width="11" height="2" rx="1" fill="#E28F43" />
			<rect x="8" y="16" width="16" height="2" rx="1" fill="#E28F43" />
		</svg>
	);
}

function renderPasswordType() {
	return (
		<svg
			width="35"
			height="25"
			viewBox="0 0 35 25"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<rect
				x="0.25"
				y="0.25"
				width="34.5"
				height="24.5"
				rx="4.75"
				fill="#E5FAE4"
				stroke="#7FCC73"
				strokeWidth="0.5"
			/>
			<path
				d="M22.7569 12.7369C22.5949 12.5789 22.3982 12.5 22.1666 12.5H15.7777V9.79172C15.7777 9.19353 15.9948 8.68296 16.4288 8.25976C16.8629 7.83665 17.3866 7.62504 18.0001 7.62504C18.6135 7.62504 19.1373 7.83662 19.5712 8.25976C20.0053 8.68296 20.2223 9.19356 20.2223 9.79172C20.2223 9.93837 20.2773 10.0653 20.3871 10.1725C20.4972 10.2797 20.6275 10.3334 20.7777 10.3334H21.3336C21.4839 10.3334 21.6141 10.2797 21.7242 10.1725C21.8338 10.0653 21.889 9.93837 21.889 9.79172C21.889 8.74777 21.5085 7.85503 20.7476 7.113C19.9865 6.37094 19.0707 6 18.0001 6C16.9294 6 16.0136 6.37094 15.2525 7.11297C14.4916 7.85491 14.1111 8.74774 14.1111 9.79169V12.5H13.8333C13.6019 12.5 13.4051 12.5791 13.2431 12.7369C13.081 12.8948 13 13.0867 13 13.3124V18.1875C13 18.4133 13.081 18.6052 13.2431 18.7631C13.4051 18.921 13.6019 19 13.8333 19H22.1666C22.3982 19 22.595 18.921 22.7569 18.7631C22.9188 18.6052 23 18.4133 23 18.1875V13.3125C23.0001 13.0868 22.9191 12.895 22.7569 12.7369Z"
				fill="#7FCC73"
			/>
		</svg>
	);
}

function renderNumberType() {
	return (
		<svg
			width="35"
			height="25"
			viewBox="0 0 35 25"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<rect
				x="0.25"
				y="0.25"
				width="34.5"
				height="24.5"
				rx="4.75"
				fill="#FDE7F0"
				stroke="#DC3183"
				strokeWidth="0.5"
			/>
			<path d="M8.23 16.41V9.75H7V8.16H9.85V16.41H8.23Z" fill="#DC3183" />
			<path
				d="M13.7599 16.41V15.58C14.4099 15.0833 14.9783 14.6333 15.4649 14.23C15.9516 13.8233 16.3666 13.455 16.7099 13.125C17.0533 12.795 17.3316 12.495 17.5449 12.225C17.7583 11.9517 17.9133 11.7017 18.0099 11.475C18.1099 11.245 18.1599 11.0283 18.1599 10.825C18.1599 10.5717 18.0999 10.3417 17.9799 10.135C17.8599 9.925 17.6966 9.75833 17.4899 9.635C17.2866 9.51167 17.0566 9.45 16.7999 9.45C16.5533 9.45 16.3249 9.51333 16.1149 9.64C15.9083 9.76333 15.7266 9.94667 15.5699 10.19C15.4133 10.4333 15.2833 10.7317 15.1799 11.085L13.8199 10.45C13.9199 10.0733 14.0633 9.735 14.2499 9.435C14.4399 9.13167 14.6666 8.87333 14.9299 8.66C15.1966 8.44667 15.4949 8.28333 15.8249 8.17C16.1549 8.05667 16.5116 8 16.8949 8C17.4783 8 17.9966 8.12167 18.4499 8.365C18.9033 8.605 19.2583 8.93333 19.5149 9.35C19.7749 9.76667 19.9049 10.2417 19.9049 10.775C19.9049 11.0417 19.8683 11.3033 19.7949 11.56C19.7216 11.8167 19.6066 12.0767 19.4499 12.34C19.2933 12.6 19.0899 12.8683 18.8399 13.145C18.5899 13.4183 18.2883 13.7067 17.9349 14.01C17.5849 14.31 17.1766 14.63 16.7099 14.97H20.0549V16.41H13.7599Z"
				fill="#DC3183"
			/>
			<path
				d="M26.376 16.57C25.8827 16.57 25.431 16.485 25.021 16.315C24.611 16.145 24.2643 15.9067 23.981 15.6C23.6977 15.2933 23.4993 14.9317 23.386 14.515L24.886 13.805C24.9093 14.0717 24.9827 14.3083 25.106 14.515C25.2327 14.7183 25.4043 14.8783 25.621 14.995C25.841 15.1083 26.0943 15.165 26.381 15.165C26.781 15.165 27.0993 15.055 27.336 14.835C27.576 14.615 27.696 14.3233 27.696 13.96C27.696 13.7 27.6377 13.4683 27.521 13.265C27.4043 13.0583 27.246 12.8967 27.046 12.78C26.8493 12.66 26.6294 12.6 26.386 12.6C26.246 12.6 26.126 12.62 26.026 12.66V11.58H26.401C26.6177 11.58 26.8127 11.5267 26.986 11.42C27.1627 11.31 27.3027 11.165 27.406 10.985C27.5127 10.805 27.566 10.6117 27.566 10.405C27.566 10.2017 27.5127 10.0183 27.406 9.855C27.3027 9.69167 27.1627 9.56167 26.986 9.465C26.8093 9.36833 26.611 9.32 26.391 9.32C26.171 9.32 25.966 9.37 25.776 9.47C25.5893 9.57 25.4343 9.70667 25.311 9.88C25.1877 10.0533 25.1093 10.2533 25.076 10.48L23.791 9.88C23.8843 9.50667 24.056 9.18 24.306 8.9C24.556 8.61667 24.8627 8.39833 25.226 8.245C25.5927 8.08833 25.991 8.01 26.421 8.01C26.9244 8.01 27.3727 8.11333 27.766 8.32C28.1627 8.52667 28.4743 8.80833 28.701 9.165C28.9277 9.51833 29.041 9.92167 29.041 10.375C29.041 10.6083 29.006 10.8267 28.936 11.03C28.8694 11.2333 28.7727 11.4183 28.646 11.585C28.5194 11.7517 28.366 11.895 28.186 12.015C28.4193 12.1383 28.621 12.295 28.791 12.485C28.961 12.675 29.091 12.8933 29.181 13.14C29.271 13.3833 29.316 13.655 29.316 13.955C29.316 14.455 29.1927 14.9033 28.946 15.3C28.6994 15.6933 28.3543 16.0033 27.911 16.23C27.471 16.4567 26.9594 16.57 26.376 16.57Z"
				fill="#DC3183"
			/>
		</svg>
	);
}

function renderDateType() {
	return (
		<svg
			width="35"
			height="25"
			viewBox="0 0 35 25"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<rect
				x="0.25"
				y="0.25"
				width="34.5"
				height="24.5"
				rx="4.75"
				fill="#DFF7FF"
				stroke="#43AAE3"
				strokeWidth="0.5"
			/>
			<rect
				x="11.5"
				y="8.35718"
				width="12"
				height="10.1429"
				rx="1.5"
				stroke="#43AAE3"
			/>
			<path
				d="M11 9.85718C11 8.75261 11.8954 7.85718 13 7.85718H22C23.1046 7.85718 24 8.75261 24 9.85718V10.6429H11V9.85718Z"
				fill="#43AAE3"
			/>
			<rect
				x="13.7861"
				y="6"
				width="1.85714"
				height="2.78571"
				rx="0.928571"
				fill="#43AAE3"
			/>
			<rect
				x="19.3574"
				y="6"
				width="1.85714"
				height="2.78571"
				rx="0.928571"
				fill="#43AAE3"
			/>
		</svg>
	);
}

function renderEnumType() {
	return (
		<svg
			width="35"
			height="25"
			viewBox="0 0 35 25"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<rect
				x="0.25"
				y="0.25"
				width="34.5"
				height="24.5"
				rx="4.75"
				fill="#EEDDFF"
				stroke="#9A5AEC"
				strokeWidth="0.5"
			/>
			<rect x="12" y="6" width="15" height="2" rx="1" fill="#9A5AEC" />
			<rect x="12" y="11" width="15" height="2" rx="1" fill="#9A5AEC" />
			<rect x="12" y="16" width="15" height="2" rx="1" fill="#9A5AEC" />
			<rect x="8" y="16" width="2" height="2" rx="1" fill="#9A5AEC" />
			<rect x="8" y="11" width="2" height="2" rx="1" fill="#9A5AEC" />
			<rect x="8" y="6" width="2" height="2" rx="1" fill="#9A5AEC" />
		</svg>
	);
}

function renderMediaType() {
	return (
		<svg
			width="35"
			height="25"
			viewBox="0 0 35 25"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<rect
				x="0.25"
				y="0.25"
				width="34.5"
				height="24.5"
				rx="4.75"
				fill="#F5E9FB"
				stroke="#CD5CCF"
				strokeWidth="0.5"
			/>
			<path
				d="M13.751 11.6875C14.5782 11.6875 15.251 10.9587 15.251 10.0625C15.251 9.16631 14.5782 8.4375 13.751 8.4375C12.9237 8.4375 12.251 9.16631 12.251 10.0625C12.251 10.9587 12.9237 11.6875 13.751 11.6875Z"
				fill="#CD5CCF"
			/>
			<path
				d="M23.125 6H11.875C10.8415 6 10 6.91162 10 8.03125V16.9688C10 18.0884 10.8415 19 11.875 19H23.125C24.1585 19 25 18.0884 25 16.9688V8.03125C25 6.91162 24.1585 6 23.125 6ZM11.875 7.625H23.125C23.332 7.625 23.5 7.807 23.5 8.03125V13.7992L21.1307 10.8043C20.8795 10.485 20.5157 10.3144 20.125 10.3046C19.7365 10.3071 19.372 10.4939 19.123 10.8173L16.3375 14.4394L15.43 13.4588C14.917 12.903 14.0823 12.903 13.57 13.4588L11.5 15.7004V8.03125C11.5 7.807 11.668 7.625 11.875 7.625Z"
				fill="#CD5CCF"
			/>
		</svg>
	);
}

function renderJSONType() {
	return (
		<svg
			width="35"
			height="25"
			viewBox="0 0 35 25"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<rect
				x="0.25"
				y="0.25"
				width="34.5"
				height="24.5"
				rx="4.75"
				fill="#E5FDFE"
				stroke="#72C9C4"
				strokeWidth="0.5"
			/>
			<path
				d="M11.6915 18C11.3783 18 11.1205 17.946 10.9183 17.8379C10.716 17.7332 10.5577 17.5924 10.4435 17.4156C10.3294 17.2421 10.2494 17.0506 10.2037 16.8411C10.1581 16.6315 10.1352 16.4253 10.1352 16.2223V13.5067C10.1352 13.2906 10.0781 13.1368 9.96394 13.0451C9.85301 12.9501 9.69966 12.9027 9.50391 12.9027V12.0973C9.69966 12.0973 9.85301 12.0499 9.96394 11.9549C10.0781 11.86 10.1352 11.7061 10.1352 11.4933V8.77768C10.1352 8.5747 10.1581 8.36845 10.2037 8.15893C10.2494 7.9494 10.3294 7.75789 10.4435 7.58438C10.5577 7.40759 10.716 7.26682 10.9183 7.16205C11.1205 7.05402 11.3783 7 11.6915 7H12.5039V8.09509H12.1515C11.9362 8.09509 11.7617 8.16875 11.6279 8.31607C11.4974 8.46339 11.4321 8.65818 11.4321 8.90045V11.5571C11.4321 11.8092 11.3816 12.0106 11.2804 12.1612C11.1793 12.3085 11.0553 12.4214 10.9085 12.5C11.0553 12.5786 11.1793 12.6948 11.2804 12.8487C11.3816 12.9993 11.4321 13.1973 11.4321 13.4429V16.0996C11.4321 16.3418 11.4974 16.5366 11.6279 16.6839C11.7617 16.8313 11.9362 16.9049 12.1515 16.9049H12.5039V18H11.6915Z"
				fill="#72C9C4"
			/>
			<path
				d="M22.4961 18V16.9049H22.8479C23.0629 16.9049 23.2355 16.8313 23.3658 16.6839C23.4994 16.5366 23.5661 16.3418 23.5661 16.0996V13.4429C23.5661 13.1973 23.6166 12.9993 23.7176 12.8487C23.8218 12.6948 23.9472 12.5786 24.0938 12.5C23.9472 12.4214 23.8218 12.3085 23.7176 12.1612C23.6166 12.0106 23.5661 11.8092 23.5661 11.5571V8.90045C23.5661 8.65818 23.4994 8.46339 23.3658 8.31607C23.2355 8.16875 23.0629 8.09509 22.8479 8.09509H22.4961V7H23.3121C23.6248 7 23.8821 7.05402 24.084 7.16205C24.286 7.26682 24.444 7.40759 24.558 7.58438C24.672 7.75789 24.7518 7.9494 24.7974 8.15893C24.843 8.36845 24.8658 8.5747 24.8658 8.77768V11.4933C24.8658 11.7061 24.9212 11.86 25.0319 11.9549C25.1459 12.0499 25.3007 12.0973 25.4961 12.0973V12.9027C25.3007 12.9027 25.1459 12.9501 25.0319 13.0451C24.9212 13.1368 24.8658 13.2906 24.8658 13.5067V16.2223C24.8658 16.4253 24.843 16.6315 24.7974 16.8411C24.7518 17.0506 24.672 17.2421 24.558 17.4156C24.444 17.5924 24.286 17.7332 24.084 17.8379C23.8821 17.946 23.6248 18 23.3121 18H22.4961Z"
				fill="#72C9C4"
			/>
			<circle cx="14.5039" cy="12.5" r="1" fill="#72C9C4" />
			<circle cx="17.5" cy="12.5" r="1" fill="#72C9C4" />
			<circle cx="20.4961" cy="12.5" r="1" fill="#72C9C4" />
		</svg>
	);
}

function renderBooleanType() {
	return (
		<svg
			width="35"
			height="25"
			viewBox="0 0 35 25"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<rect
				x="0.25"
				y="0.25"
				width="34.5"
				height="24.5"
				rx="4.75"
				fill="#E5F9E4"
				stroke="#83C67F"
				strokeWidth="0.5"
			/>
			<rect x="9.625" y="8" width="15.75" height="9" rx="4.5" fill="#83C67F" />
			<circle cx="20.8754" cy="12.5001" r="3.6" fill="#E5F9E4" />
		</svg>
	);
}

function renderUIDType() {
	return (
		<svg
			width="35"
			height="25"
			viewBox="0 0 35 25"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<rect
				x="0.25"
				y="0.25"
				width="34.5"
				height="24.5"
				rx="4.75"
				fill="#E5F3FD"
				stroke="#3095CC"
				strokeWidth="0.5"
			/>
			<path
				d="M11.1607 16.7423C11.167 16.6614 11.2035 16.5837 11.2635 16.5257L16.4457 11.4059C16.2418 10.9314 16.1342 10.4175 16.1342 9.88643C16.1342 8.84853 16.544 7.87199 17.2856 7.13747C18.0283 6.40441 19.016 6 20.0672 6C21.1188 6 22.1049 6.40455 22.8487 7.13806C24.3836 8.65358 24.3839 11.1186 22.849 12.634C22.1051 13.369 21.1188 13.7739 20.0677 13.7739C19.53 13.7739 19.0111 13.6667 18.5297 13.465L16.8272 15.1482C16.7564 15.2173 16.663 15.254 16.5622 15.2507L15.6344 15.2186L15.6647 16.1375C15.6676 16.2344 15.6322 16.3287 15.5611 16.3977C15.4926 16.4667 15.3963 16.5042 15.2986 16.5003L14.3674 16.4688L14.4008 17.3867C14.4037 17.4838 14.366 17.5783 14.2975 17.6473C14.2267 17.7162 14.1442 17.7533 14.0325 17.75L13.1036 17.7188L13.1347 18.6368C13.1402 18.7386 13.0968 18.8365 13.0231 18.906C12.9551 18.9671 12.8703 19 12.7809 19C12.7681 19 12.7549 18.9995 12.7418 18.9983L11.3174 18.842C11.125 18.8213 10.9848 18.6516 11.0013 18.461L11.1607 16.7423ZM20.5423 9.41715C20.9798 9.84893 21.6871 9.84893 22.1237 9.41715C22.5609 8.98524 22.5609 8.28657 22.1237 7.85506C21.6871 7.42356 20.98 7.42315 20.5423 7.85493C20.1057 8.28639 20.1059 8.98551 20.5423 9.41715ZM12.3766 16.8595C12.4286 16.9108 12.4982 16.9365 12.5659 16.9365C12.6336 16.9365 12.7033 16.9108 12.7553 16.8595L17.1645 12.5021C17.0417 12.3713 16.9301 12.2336 16.8287 12.0889L12.3766 16.4865C12.2726 16.5892 12.2726 16.7568 12.3766 16.8595Z"
				fill="#3095CC"
			/>
		</svg>
	);
}
