import React, { useContext, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';
import { useHandleChange } from '../../../newDashboardComponents/hooks/useHandleChange';
import {
  IAppPlan,
  IAppPlanConnection,
  IAppPlanCoupon,
} from '../../../developer.types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { DeveloperInput } from '../../../newDashboardComponents/developerInput.comp';
import { capitalizeFirstLetter } from '../../../../../helpers/helpers';
import { DeveloperContext } from '../../../developer.context';
import { DatePicker } from '../../../common/datePicker/datePicker.comp';
import { developerService } from '../../../../../services/developer.service';
import { Checkbox } from '../../../common/checkbox/checkbox.comp';

import './couponEditor.scss';

interface IProps {
  coupon: IAppPlanCoupon;
  onChange: (coupon: IAppPlanCoupon) => void;
  onRemove: () => void;
  nameReadonly: boolean;
  plans: IAppPlan[];
}

export const CouponEditor: React.FC<IProps> = ({
  coupon,
  onChange,
  onRemove,
  nameReadonly,
  plans,
}) => {
  const { integrationPlatforms, integrations, activeApp } =
    useContext(DeveloperContext);
  const [connectedPlans, setConnectedPlans] = useState<IAppPlanConnection[]>(
    [],
  );
  const [isSaving, setIsSaving] = useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');
  const [localCoupon, setLocalCoupon] = useState<IAppPlanCoupon>({
    ...coupon,
  });
  const {
    platformCouponCode,
    couponName,
    discountAmount,
    discountType,
    maxRedemptions,
    expires,
    platform,
    appliesTo,
  } = localCoupon;
  const [handleChange] = useHandleChange(setLocalCoupon);

  function handleError(message: string) {
    setErrorMessage(message);
    setIsSaving(false);
  }

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsSaving(true);
    try {
      if (couponName.trim().length === 0) {
        throw new Error('Coupon name is required');
      }
      if (platformCouponCode.trim().length === 0) {
        throw new Error('Coupon code is required');
      }
      if (!discountAmount) {
        throw new Error('Discount amount is required');
      }
      if (!platform) {
        throw new Error('Platform is required');
      }
      if (!expires) {
        throw new Error('Expiration date is required');
      }
      if (appliesTo.length < 1) {
        throw new Error('Please connect at least one plan to the coupon');
      }
      onChange({ ...localCoupon });
      setIsSaving(false);
      // setLocalCoupon({} as IAppPlanCoupon);
      // setErrorMessage('');
    } catch (error) {
      handleError((error as any).message);
    }
  };

  useEffect(() => {
    async function getConnectedPlans() {
      try {
        const { data: connectedProviders } =
          await developerService.getAppConnections(
            activeApp.appId,
            localCoupon.platform,
          );
        setConnectedPlans(connectedProviders);
      } catch (error) {
        toast.error(
          'There was a problem getting the connected plans: ' +
            (error as Error).message,
        );
      }
    }

    getConnectedPlans();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localCoupon.platform]);

  return (
    <form onSubmit={handleSubmit} className="app-section-body coupon-editor">
      <div className="inputs-horizontal">
        <DeveloperInput
          label="Coupon Name"
          name="couponName"
          placeholder="Add text..."
          value={couponName}
          handleChange={handleChange}
          required
        />
        <DeveloperInput
          label="Coupon Code"
          name="platformCouponCode"
          placeholder="Add text..."
          value={platformCouponCode}
          handleChange={handleChange}
          required
          disabled={nameReadonly}
          numberMaxLength={20}
        />
        <DeveloperInput
          label="Platform"
          name="platform"
          required
          value={platform}
          title="Platform"
          disabled={nameReadonly}
          select={[
            {
              name: '',
              displayName: 'Choose a Platform',
            },
            ...integrationPlatforms
              .filter((p) => {
                if (!p.tags.includes('payments')) {
                  return false;
                }

                if (
                  integrations.find((i) => i.platform === p.name && i.isActive) &&
                  // Supported platforms for now
                  (
                    p.name === 'paddle' ||
                    p.name === 'stripe'
                  )
                ) {
                  return true;
                }

                return false;
              })
              .map((p) => ({
                name: p.name,
                displayName: capitalizeFirstLetter(p.name),
              })),
          ]}
          handleChange={(e) => {
            setLocalCoupon({
              ...localCoupon,
              platform: e.target.value as any,
            });
          }}
        />
      </div>
      <div className="inputs-horizontal">
        <DeveloperInput
          label="Discount Type"
          name="discountType"
          required
          value={discountType}
          title="Discount Type"
          select={['percentage', 'flat'].map((name) => ({
            name,
            displayName: capitalizeFirstLetter(name),
          }))}
          handleChange={(e) => {
            setLocalCoupon({
              ...localCoupon,
              discountType: e.target.value as any,
            });
          }}
        />
        <DeveloperInput
          label="Discount"
          name="discountAmount"
          placeholder="0"
          numberAllowNegative={false}
          type="number"
          value={discountAmount}
          handleChange={handleChange}
          required
        />
      </div>
      <div className="inputs-horizontal">
        <DeveloperInput
          label="Maximum Uses"
          name="maxRedemptions"
          placeholder="0"
          numberAllowNegative={false}
          type="number"
          value={maxRedemptions}
          handleChange={handleChange}
          required
        />
        <div className={`input-container`}>
          <label>Exp. Date</label>
          <DatePicker
            type="single"
            format="YYYY-MM-DD"
            value={dayjs(expires).format('YYYY-MM-DD')}
            onChange={(selectedDate) => {
              const expires = new Date(selectedDate.toString()).toString();
              setLocalCoupon({
                ...localCoupon,
                expires,
              });
            }}
          />
        </div>
      </div>
      {localCoupon.platform && (
        <div className={`input-container`}>
          <label>Apply for Plans</label>
          {connectedPlans.map((connection) => {
            const plan = plans.find((p) => p.planId === connection.planId);
            const variant = plan?.pricingVariants.find(
              (v) => v.id === connection.planPricingVariantId,
            );

            return (
              <Checkbox
                key={`connection_${connection._id}`}
                defaultChecked={coupon.appliesTo.includes(
                  connection.platformPlanId,
                )}
                onChange={() => {
                  let nextAppliesTo = [...localCoupon.appliesTo];
                  const planIdx = nextAppliesTo.indexOf(
                    connection.platformPlanId,
                  );
                  if (planIdx === -1) {
                    nextAppliesTo.push(connection.platformPlanId);
                  } else {
                    nextAppliesTo = nextAppliesTo.splice(planIdx, 1);
                  }
                  setLocalCoupon({
                    ...localCoupon,
                    appliesTo: nextAppliesTo,
                  });
                }}
                label={`${plan?.name} (${variant?.name})`}
              />
            );
          })}
        </div>
      )}
      <div className="buttons-wrapper center">
        {nameReadonly && (
          <button className="button link-button" onClick={onRemove}>
            Permanently Remove
          </button>
        )}
        <button
          className="button primary-button"
          type="submit"
          disabled={isSaving}
        >
          {isSaving
            ? 'Saving...'
            : nameReadonly
            ? 'Update Coupon'
            : 'Add Coupon'}
        </button>
      </div>
      {errorMessage && (
        <span className="editor-error">
          <FontAwesomeIcon icon={faExclamationTriangle} />
          {errorMessage}
        </span>
      )}
    </form>
  );
};
