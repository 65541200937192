import React, { useContext, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Tabs } from '@commonninja/commonninja-styleguide-react';
import { Coupons, Features, Plans, Settings } from './';
import { PlanEditor } from './plans/planEditor/planEditor.comp';
import { DeveloperContext } from '../developer.context';

import './appPayments.scss';

export const AppPayments = () => {
  const history = useHistory();
  const { activeApp, } = useContext(DeveloperContext);
  const { planId, subPage } = useParams<any>();

  useEffect(() => {
    if (!activeApp.appId) {
      return;
    }

  }, [activeApp.appId]);

  if (!activeApp.appId) {
    return <></>;
  }

  if (planId) {
    return <PlanEditor />;
  }

  function paymentsTabResolver(tab: string | number): JSX.Element {

    switch (subPage) {
      case 'plans':
        return <Plans />;
      case 'features':
        return <Features />;
      case 'coupons':
        return <Coupons />;
      case 'settings':
        return <Settings />;
      default:
        return <Plans />;
      // return <Help />;
    }
  }

  return (
    <div className="payments-page">
      <header className="flex-wrapper">
        <hgroup>
          <h2>Payments</h2>
          <p>Create and manage payment plans and features for your app.</p>
        </hgroup>
      </header>
      <Tabs
        items={[
          { name: 'Plans', id: 'plans' },
          { name: 'Features', id: 'features' },
          { name: 'Coupons', id: 'coupons' },
          { name: 'Settings', id: 'settings' },
          // { name: '( ? )', id: 'help' },
        ]}
        onChange={(tab) => history.push(`/developer/apps/${activeApp.appId}/payments/${tab.id}`)} 
        activeTabId={subPage}
        resolveTabComp={(tab) => paymentsTabResolver(tab)}
      />
    </div>
  );
};


// function Help(): JSX.Element {
//   return (
//     <div className="payments-help">
//       <div className="app-payments-header" style={{ padding: '0 20px' }}>
//         <h3>
//           <strong>Need help setting up your payments?</strong>
//         </h3>
//       </div>
//       <div className="app-payments-body" style={{ padding: '0 20px' }}>
//         <p>
//           Our developer platform saves you time integrating your app with
//           payment services.
//         </p>
//         <p>
//           The platform currently supports{' '}
//           <Link to="https://docs.commoninja.com/" target="_blank">
//             Stripe
//           </Link>{' '}
//           and <u>PayPal</u> and connects with e-commerce sites.
//         </p>
//         <p>
//           Further details on how to set up your payments can be found at the{' '}
//           <Link target="_blank" to="https://docs.commoninja.com">
//             Developer Platform documentation.
//           </Link>
//         </p>
//         <p>
//           If you feel like you need our help with the features, please do not
//           hesitate to contact our team.
//         </p>
//       </div>
//     </div>
//   );
// }
