import React from "react";
import {
  Loader,
  detailsChanged,
} from "@commonninja/commonninja-styleguide-react";
import { error, success } from "react-notification-system-redux";
import { connect } from "react-redux";

import Embed from "../embed/embed.comp";

import "./profile.scss";
import { DeveloperInput } from "../developer/newDashboardComponents/developerInput.comp";
import { ImageUpload } from "../developer/imageUpload/imageUpload.comp";
import { DangerZone } from "../developer/common/dangerZone/dangerZone.comp";
import { WarnPopup } from "../developer/common/warnPopup/warnPopup.comp";

class Profile extends Embed {
  public firstInput: HTMLInputElement | null = null;

  constructor(props: any) {
    super(props);

    this.state = {
      loading: false,
      deleteAccountPopupIsOpened: false,
      form: {
        thumbnail: props.user.thumbnail || "",
        fullName: props.user.fullName || "",
        isSubscribe: props.user.isSubscribe || false,
      },
    };
  }

  componentWillReceiveProps(nextProps: any) {
    this.setState({
      form: {
        thumbnail: nextProps.user.thumbnail || "",
        fullName: nextProps.user.fullName || "",
        isSubscribe: nextProps.user.isSubscribe || false,
      },
    });
  }

  handleChange = (e: any) => {
    const { form } = this.state;
    const { name, value } = e.target;
    this.setState({
      form: {
        ...form,
        [name]: value,
      },
    });
  };

  submit = async (e: any) => {
    e.preventDefault();

    this.setState({ loading: true });

    let localError = null;

    try {
      const body = JSON.stringify(this.state.form);
      const update = await fetch("/api/user", {
        body,
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
      });

      const updateRes = await update.json();

      if (updateRes.success) {
        this.props.dispatch(
          success({
            message: "Your account has been updated",
            autoDismiss: 3.5,
            position: "tc",
          })
        );

        this.props.dispatch(detailsChanged(JSON.parse(body)));
      } else {
        localError = updateRes.message || "Could not update account details";
      }
    } catch (e) {
      localError = "Could not update account details";
    }

    if (localError) {
      this.props.dispatch(
        error({
          message: localError,
          autoDismiss: 5,
          position: "tc",
        })
      );
    }

    this.setState({ loading: false });
  };

  onThumbnailChangeHandler = async (url: string) => {
    this.setState({
      form: {
        ...this.state.form,
        thumbnail: url,
      },
    });
  };

  onDeleteAccount = async () => {
    this.setState({ loading: true, deleteAccountPopupIsOpened: false });

    let localError = null;

    try {
      const deleteRes = await fetch("/api/user", {
        method: "delete",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const res = await deleteRes.json();
      if (res.success) {
        window.location.href = "/";
      } else {
        localError = res.message || "Could not delete account";
      }
    } catch (e) {
      localError = "Could not delete account.";
    }

    if (localError) {
      this.props.dispatch(
        error({
          message: localError,
          autoDismiss: 5,
          position: "tc",
        })
      );
    }

    this.setState({ loading: false });
  };

  render() {
    const { form, loading } = this.state as any;
    const { user } = this.props as any;

    return loading ? (
      <Loader>
        <p>Loading...</p>
      </Loader>
    ) : (
      <>
        <div className="content-wrapper">
          <form onSubmit={this.submit}>
            <div className="inner-wrapper">
              <div className="input-container">
                <label>Thumbnail</label>
                <div>
                  <ImageUpload
                    value={this.state.form.thumbnail}
                    successCallback={this.onThumbnailChangeHandler}
                  />
                  <small>(Max. file size is 3MB)</small>
                </div>
              </div>
              <div>
                <DeveloperInput
                  name="fullName"
                  type="text"
                  label="Full Name"
                  title="Full Name"
                  placeholder="Enter text"
                  value={form.fullName}
                  handleChange={this.handleChange}
                />

                <div className="input-container">
                  <label htmlFor="email">Email Address</label>
                  <div>
                    <input
                      disabled
                      type="text"
                      name="email"
                      title="Email Address"
                      defaultValue={user.email}
                    />
                    <small
                      className={`user-email-status ${
                        user.isConfirmed ? "" : "unconfirmed"
                      }`}
                    >
                      ({user.isConfirmed ? "confirmed" : "not confirmed"})
                    </small>
                  </div>
                </div>

                <DeveloperInput
                  className="email-updates"
                  type="checkbox"
                  label="Email Updates"
                  name="isSubscribe"
                  title="Email Updates"
                  value={form.isSubscribe}
                  handleChange={this.handleChange}
                />
              </div>
            </div>
            <div className="buttons-wrapper">
              <button className="button primary-button" type="submit">
                Save Changes
              </button>
            </div>
          </form>
        </div>
        <DangerZone
          onButtonClick={() => {
            this.setState({ deleteAccountPopupIsOpened: true });
          }}
          title="Account Deletion"
          text={
            <>
              Please be aware that deleting your account will result in
              permanent data loss, loss of access to our services, and
              forfeiture of any remaining purchases or subscriptions. If you
              need assistance, feel free to contact our support team.
            </>
          }
          buttonText="Delete My Account"
        ></DangerZone>
        <WarnPopup
          showPopup={this.state.deleteAccountPopupIsOpened}
          setShowPopup={(opened) =>
            this.setState({ deleteAccountPopupIsOpened: opened })
          }
          title="Are you sure you want to delete your account?"
          text={
            <>
              <p>Before you delete your account, please note:</p>
              <ul style={{ listStyle: "disc", margin: "0 20px 20px" }}>
                <li>Data loss is permanent and irreversible.</li>
                <li>You'll lose access to all services.</li>
                <li>No refunds for purchases or subscriptions.</li>
              </ul>
              <p>To proceed, click the button below. If you need help, feel free to contact our support team.</p>
            </>
          }
          onConfirm={this.onDeleteAccount}
          loading={loading}
          buttonText="Delete My Account"
        />
      </>
    );
  }
}

const mapStateToProps = (state: any) => ({
  user: state.user,
});

export default connect(mapStateToProps)(Profile as any);
