import React, { useState } from 'react';

import {
  IApp,
  IAppIntegration,
  IDeveloper,
  IIntegrationPlatform,
} from './developer.types';

const emptyApp = (): IApp => ({
  appId: '',
  name: '',
  developerIds: [''],
  apiKey: '',
  secretKey: '',
  cnToken: '',
  scopes: [],
  endpoints: {
    authCallback: '',
    authErrorCallback: '',
    checkoutFailure: '',
    checkoutSuccess: '',
  },
  meta: {},
  status: 'draft',
});

const emptyDeveloper = (): IDeveloper => ({
  caption: '',
  cnUserId: '',
  coverImage: '',
  description: '',
  email: '',
  emergencyEmail: '',
  fullAddress: '',
  guid: '',
  isActive: false,
  links: [],
  name: '',
  privacyLink: '',
  supportEmail: '',
  termsLink: '',
  thumbnail: '',
});

interface IDeveloperContext {
  activeApp: IApp;
  setActiveApp: (app: null | IApp) => void;
  developer: IDeveloper;
  setDeveloper: (developer: null | IDeveloper) => void;
  integrations: IAppIntegration[];
  setIntegrations: (integrations: IAppIntegration[]) => void;
  integrationPlatforms: IIntegrationPlatform[];
  setIntegrationPlatforms: (
    integrationPlatforms: IIntegrationPlatform[]
  ) => void;
  loading: boolean;
  setLoading: (loading: boolean) => void;
  apps: IApp[];
  setApps: (apps: IApp[]) => void;
}

export const DeveloperContext = React.createContext<IDeveloperContext>({
  activeApp: emptyApp(),
  setActiveApp: () => {},
  developer: emptyDeveloper(),
  setDeveloper: () => {},
  integrations: [],
  setIntegrations: () => {},
  integrationPlatforms: [],
  setIntegrationPlatforms: () => {},
  loading: false,
  setLoading: () => {},
  apps: [],
  setApps: () => {},
});

export const DeveloperContextProvider = (props: any) => {
  const setActiveApp = (app: null | IApp) => {
    setState((prevState) => ({ ...prevState, activeApp: app || emptyApp() }));
  };

  const setDeveloper = (developer: null | IDeveloper) => {
    setState((prevState) => ({
      ...prevState,
      developer: developer || emptyDeveloper(),
    }));
  };

  const setIntegrations = (integrations: IAppIntegration[] = []) => {
    setState((prevState) => ({ ...prevState, integrations }));
  };

  const setIntegrationPlatforms = (
    integrationPlatforms: IIntegrationPlatform[] = []
  ) => {
    setState((prevState) => ({ ...prevState, integrationPlatforms }));
  };

  const setLoading = (loading: boolean) => {
    setState((prevState) => ({ ...prevState, loading }));
  };

  const setApps = (apps: null | IApp[]) => {
    setState((prevState) => ({ ...prevState, apps: apps || [] }));
  };

  const initState: IDeveloperContext = {
    activeApp: emptyApp(),
    setActiveApp,
    developer: emptyDeveloper(),
    setDeveloper,
    integrations: [],
    setIntegrations,
    integrationPlatforms: [],
    setIntegrationPlatforms,
    loading: false,
    setLoading,
    apps: [],
    setApps,
  };

  const [state, setState] = useState(initState);

  return (
    <DeveloperContext.Provider value={state}>
      {props.children}
    </DeveloperContext.Provider>
  );
};
