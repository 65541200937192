import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBook,
  faCommentDots,
  faPlus,
  faSearch,
  faThLarge,
  faUserEdit,
} from '@fortawesome/free-solid-svg-icons';
import { IApp } from '../developer.types';
import { DeveloperContext } from '../developer.context';
import { DLoader } from '../loader/loader.comp';
import defaultIcon from '../../../assets/default-icon.png';
import logo from '../../../assets/logo.png';
import { Popup } from '@commonninja/commonninja-styleguide-react';
import { NewAppPopup } from '../appMenu/appSubmenu/newAppPopup.comp';
import { AppDeveloperMenu } from '../appMenu/appDeveloperMenu/appDeveloperMenu.comp';

import './developerApps.scss';

export const DeveloperApps = () => {
  const [filterName, setFilterName] = useState('');
  const [opened, setOpened] = useState<boolean>(false);
  const { setActiveApp, loading, apps } = useContext(DeveloperContext);

  const filteredApps = apps.filter((app: IApp) =>
    app.name.toLocaleLowerCase().includes(filterName.trim())
  );

  return (
    <>
      <header className="top-bar">
        <figure className="logo">
          <Link to={'/developer/apps'} className="flex-wrapper">
            <img src={logo} alt="Common Ninja" />
            {/* <p>Developer Platform</p> */}
          </Link>
        </figure>
      </header>
      <div className="developer-apps wrapper">
        <AppDeveloperMenu
          menuLinks={[
            {
              name: 'My Apps',
              url: `/developer/apps`,
              icon: faThLarge,
            },
            {
              name: 'Developer Info',
              url: `/developer/account`,
              icon: faUserEdit,
            },
            {
              name: 'Documentation',
              url: `https://docs.commoninja.com/`,
              target: '_blank',
              icon: faBook,
            },
            {
              name: 'Support',
              url: 'https://help.commoninja.com/',
              target: '_blank',
              icon: faCommentDots,
            },
          ]}
        />
        <hgroup>
          <div className="inner-nav align-end">
            <div className="title">
              <h2>My Apps</h2>
              <p>Create, view and edit your apps.</p>
            </div>
            <div className="input-container search-input">
              <input
                value={filterName}
                onChange={(e) => setFilterName(e.target.value.toLowerCase())}
                type="text"
                placeholder="Search..."
              />
              <div className="search-icon">
                <FontAwesomeIcon icon={faSearch} />
              </div>
            </div>
          </div>
        </hgroup>

        {loading ? (
          <DLoader />
        ) : (
          <div className={`app-cards apps-wrapper`}>
            {filteredApps.length > 0 &&
              filteredApps.map((app) => (
                <div key={app.appId} className="app app-card flex-wrapper">
                  <section className="flex-wrapper">
                    <img
                      src={app.meta.thumbnail || app.meta.icon || defaultIcon}
                      alt={app.name}
                    />
                    <div className="app-info">
                      <h4>{app.name}</h4>
                      <p>App ID: {app.appId}</p>
                    </div>
                  </section>
                  <footer className="flex-wrapper align-end">
                    <Link
                      to={`/developer/apps/${app.appId}`}
                      onClick={() => setActiveApp(app)}
                      className="button secondary-button"
                    >
                      Edit
                    </Link>
                    <span
                      className={`app-status ${
                        app.status === 'published' ? ' highlight' : ''
                      }`}
                    >
                      {app.status}
                    </span>
                    {/* <span className="highlight">Listed</span> */}
                  </footer>
                </div>
              ))}

            {filteredApps.length === 0 && apps.length !== 0 && (
              <div className="no-results">
                <p>
                  No results found :( <br />{' '}
                </p>
                <div
                  className="button primary-button"
                  onClick={() => setFilterName('')}
                >
                  Clear search
                </div>
              </div>
            )}

            {(filteredApps.length > 0 || apps.length === 0) && (
              <Link
                to={`/developer/apps/new`}
                className="app-card app-card-add"
                onClick={(e) => {
                  e.preventDefault();
                  setOpened(true);
                }}
              >
                <FontAwesomeIcon icon={faPlus} />
                <span>Create a New App</span>
              </Link>
            )}
          </div>
        )}
      </div>
      <Popup
        closeCallback={() => setOpened(false)}
        show={opened}
        className="developer-page app-name-popup"
      >
        <NewAppPopup />
      </Popup>
    </>
  );
};