import React from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { TChildren } from '@commonninja/commonninja-styleguide-react';
import { IAuthProps } from './auth.types';
import loginImage from '../../assets/login-img.png';
import logoLight from '../../assets/logo-light.png';

import './auth.scss';

interface IAuthLayoutProps extends IAuthProps {
  children: TChildren;
  embedMode?: boolean;
  isDeveloper?: boolean;
  teaser?: string;
  feedbackMode?: boolean;
}

const { REACT_APP_RECAPTCHA_SITE_KEY } = process.env;

export const AuthLayout = ({ children, embedMode, isDeveloper, teaser, feedbackMode }: IAuthLayoutProps) => {

  return (
    <GoogleReCaptchaProvider reCaptchaKey={REACT_APP_RECAPTCHA_SITE_KEY}>
      <div className={`auth-page ${isDeveloper ? 'developers' : ''} ${embedMode ? 'embedded' : ''} ${feedbackMode ? 'feedback' : ''}`}>
        <section>
          {children}
        </section>
        <aside>
          <div className="content">
            <div className="reviews-wrapper">
              <div className="review-item">
                {renderStars()}
                <p className="title">"Their widgets are so user-friendly and help make our landing pages more interesting and interactive."</p>
                <p className="name">- Jennifer, Sep 2024</p>
              </div>
              <div className="review-item">
                {renderStars()}
                <p className="title">"Best Option to Enhance Your Website! and the customer service was amazing."</p>
                <p className="name">- Christopher Breedlove, June 2024</p>
              </div>
              <div className="review-item">
                {renderStars()}
                <p className="title">"I can confidently say they are among the best available. They’re not only innovative but also easy to embed."</p>
                <p className="name">- YDA Security Systems NYC</p>
              </div>

            </div>
            <img className='login-bg' src={loginImage} alt={`Common Ninja's Dashboard`} />
            <img className='logo' src={logoLight} alt={`Common Ninja's Logo`} />
            <div className="overlay"></div>
          </div>
        </aside>
      </div>
    </GoogleReCaptchaProvider>
  );
};

function renderStars() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="125" height="20" viewBox="0 0 125 20" fill="none">
      <path d="M20.9452 7.55776C20.8077 7.13517 20.4306 6.83502 19.9844 6.79506L13.9232 6.24811L11.5265 0.673057C11.3497 0.264478 10.9473 0 10.5001 0C10.0529 0 9.65043 0.264478 9.47371 0.674012L7.07696 6.24811L1.01484 6.79506C0.569423 6.83598 0.193224 7.13517 0.0549532 7.55776C-0.0833176 7.98035 0.0443786 8.44386 0.381323 8.73605L4.96284 12.7292L3.61186 18.6434C3.513 19.0782 3.68284 19.5277 4.0459 19.7885C4.24105 19.9287 4.46936 20 4.6996 20C4.89812 20 5.09503 19.9468 5.27175 19.8417L10.5001 16.7363L15.7265 19.8417C16.1089 20.0704 16.591 20.0495 16.9533 19.7885C17.3165 19.5269 17.4862 19.0773 17.3873 18.6434L16.0364 12.7292L20.6179 8.73684C20.9548 8.44386 21.0835 7.98115 20.9452 7.55776Z" fill="#FFAF2F" />
      <path d="M46.9452 7.55776C46.8077 7.13517 46.4306 6.83502 45.9844 6.79506L39.9232 6.24811L37.5265 0.673057C37.3497 0.264478 36.9473 0 36.5001 0C36.0529 0 35.6504 0.264478 35.4737 0.674012L33.077 6.24811L27.0148 6.79506C26.5694 6.83598 26.1932 7.13517 26.055 7.55776C25.9167 7.98035 26.0444 8.44386 26.3813 8.73605L30.9628 12.7292L29.6119 18.6434C29.513 19.0782 29.6828 19.5277 30.0459 19.7885C30.241 19.9287 30.4694 20 30.6996 20C30.8981 20 31.095 19.9468 31.2718 19.8417L36.5001 16.7363L41.7265 19.8417C42.1089 20.0704 42.591 20.0495 42.9533 19.7885C43.3165 19.5269 43.4862 19.0773 43.3873 18.6434L42.0364 12.7292L46.6179 8.73684C46.9548 8.44386 47.0835 7.98115 46.9452 7.55776Z" fill="#FFAF2F" />
      <path d="M72.9452 7.55776C72.8077 7.13517 72.4306 6.83502 71.9844 6.79506L65.9232 6.24811L63.5265 0.673057C63.3497 0.264478 62.9473 0 62.5001 0C62.0529 0 61.6504 0.264478 61.4737 0.674012L59.077 6.24811L53.0148 6.79506C52.5694 6.83598 52.1932 7.13517 52.055 7.55776C51.9167 7.98035 52.0444 8.44386 52.3813 8.73605L56.9628 12.7292L55.6119 18.6434C55.513 19.0782 55.6828 19.5277 56.0459 19.7885C56.241 19.9287 56.4694 20 56.6996 20C56.8981 20 57.095 19.9468 57.2718 19.8417L62.5001 16.7363L67.7265 19.8417C68.1089 20.0704 68.591 20.0495 68.9533 19.7885C69.3165 19.5269 69.4862 19.0773 69.3873 18.6434L68.0364 12.7292L72.6179 8.73684C72.9548 8.44386 73.0835 7.98115 72.9452 7.55776Z" fill="#FFAF2F" />
      <path d="M98.9452 7.55776C98.8077 7.13517 98.4306 6.83502 97.9844 6.79506L91.9232 6.24811L89.5265 0.673057C89.3497 0.264478 88.9473 0 88.5001 0C88.0529 0 87.6504 0.264478 87.4737 0.674012L85.077 6.24811L79.0148 6.79506C78.5694 6.83598 78.1932 7.13517 78.055 7.55776C77.9167 7.98035 78.0444 8.44386 78.3813 8.73605L82.9628 12.7292L81.6119 18.6434C81.513 19.0782 81.6828 19.5277 82.0459 19.7885C82.241 19.9287 82.4694 20 82.6996 20C82.8981 20 83.095 19.9468 83.2718 19.8417L88.5001 16.7363L93.7265 19.8417C94.1089 20.0704 94.591 20.0495 94.9533 19.7885C95.3165 19.5269 95.4862 19.0773 95.3873 18.6434L94.0364 12.7292L98.6179 8.73684C98.9548 8.44386 99.0835 7.98115 98.9452 7.55776Z" fill="#FFAF2F" />
      <path d="M124.945 7.55776C124.808 7.13517 124.431 6.83502 123.984 6.79506L117.923 6.24811L115.526 0.673057C115.35 0.264478 114.947 0 114.5 0C114.053 0 113.65 0.264478 113.474 0.674012L111.077 6.24811L105.015 6.79506C104.569 6.83598 104.193 7.13517 104.055 7.55776C103.917 7.98035 104.044 8.44386 104.381 8.73605L108.963 12.7292L107.612 18.6434C107.513 19.0782 107.683 19.5277 108.046 19.7885C108.241 19.9287 108.469 20 108.7 20C108.898 20 109.095 19.9468 109.272 19.8417L114.5 16.7363L119.726 19.8417C120.109 20.0704 120.591 20.0495 120.953 19.7885C121.317 19.5269 121.486 19.0773 121.387 18.6434L120.036 12.7292L124.618 8.73684C124.955 8.44386 125.083 7.98115 124.945 7.55776Z" fill="#FFAF2F" />
    </svg>
  );
}