import dayjs from 'dayjs';
import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { developerService } from '../../../../services/developer.service';
import { DataTable } from '../../common/dataTable';
import { DeveloperContext } from '../../developer.context';
import { IAppUser, IUsersPaginationResponse } from '../types';

import './userList.scss';

const tableHeaders: any[] = [
    { title: 'Name', key: 'accountName', type: 'string' },
    { title: 'Email', key: 'accountEmail', type: 'string' },
    { title: 'Platform', key: 'platform', type: 'string' },
    { title: 'Platform User ID', key: 'platformUserId', type: 'string' },
    { title: 'Joined', key: 'created', type: 'string' },
];

export const UserList = () => {
    const [loading, setLoading] = useState(true);
    const [users, setUsers] = useState<IUsersPaginationResponse<IAppUser>>();
    const { activeApp } = useContext(DeveloperContext);
    const history = useHistory();

    function onSelectUser(id: string) {
        history.push(`/developer/apps/${activeApp.appId}/users/user/${id}`);
    }

    async function getUsers({
        page,
        limit,
        query,
        sort,
    }: {
        page: number;
        limit: number;
        query: any;
        sort: any;
    }) {
        if (!activeApp?.appId) return;
        setLoading(true);
        try {
            const formattedQuery = encodeURIComponent(JSON.stringify(query));
            const formattedSort = encodeURIComponent(JSON.stringify(sort));
            const { data } = await developerService.getAppUsers(activeApp.appId, {
                page,
                limit,
                query: formattedQuery,
                sort: formattedSort,
            });
            setUsers(data);
        } catch (e) {
            toast.error((e as Error)?.message || 'Oops, something went wrong');
        }

        setTimeout(() => {
            setLoading(false);
        }, 600);
    }

    return (
        <div className="users-table-page">
            <hgroup>
                <h2>Users</h2>
                <p>Manage your app's users and view their statistics</p>
            </hgroup>
            <DataTable
                defaultSearchKey='accountEmail'
                currentPage={users?.page || 1}
                totalPages={users?.pages || 1}
                title="User Information"
                subTitle={`${users?.total || 0} Users found`}
                getItems={getUsers}
                onEdit={(id: string) => onSelectUser(id)}
                headers={tableHeaders}
                rows={(users?.docs || []).map((d) => ({
                    ...d,
                    created: dayjs(d.created).format('MM/DD/YYYY'),
                }))}
                loading={loading}
                showPageSizeSelect={true}
                rowBehavior="edit"
                paginationParams={{ sort: { created: -1 }, limit: 20 }}
            />
        </div>
    );
};
