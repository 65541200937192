import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { developerService } from '../../../../services/developer.service';
import { DeveloperContext } from '../../developer.context';
import { DeveloperInput } from '../../newDashboardComponents/developerInput.comp';
import { WarnPopup } from '../../common/warnPopup/warnPopup.comp';

import './settings.scss';

interface ISettingsProps {
  collectionName: string;
  setCollectionName: React.Dispatch<React.SetStateAction<string>>;
}

export const Settings = ({
  collectionName,
  setCollectionName,
}: ISettingsProps) => {
  const [saving, setSaving] = useState(false);
  const [newCollectionName, setNewCollectionName] = useState(
    collectionName || '',
  );
  const [showDeletePopup, setShowDeletePopup] = useState<boolean>(false);
  const { activeApp } = useContext(DeveloperContext);
  const history = useHistory();

  async function onDelete() {
    try {
      await developerService.deleteCollectionSchema(
        activeApp.appId,
        collectionName,
      );
      await developerService.deleteCollection(activeApp.appId, collectionName);
      setShowDeletePopup(false);
      history.push(`/developer/apps/${activeApp.appId}/database`);
      toast.success(`Collection ${collectionName} has been deleted`);
    } catch (e) {
      toast.error((e as Error).message || 'Oops, something went wrong');
    }
  }

  async function editCollectionName(e: any) {
    e.preventDefault();
    if (newCollectionName === collectionName) return;
    const regex = /^[0-9A-Za-z\s-]+$/;
    const isValid = regex.test(newCollectionName);
    if (!isValid) return toast.warning(`Please remove special characters`);
    try {
      setSaving(true);
      const { data } = await developerService.getCollections(activeApp.appId);
      if (data.includes(newCollectionName))
        return toast.warning(`Collection ${newCollectionName} already exists.`);
      await Promise.all([
        await developerService.updateCollectionSchema(
          activeApp.appId,
          collectionName,
          { collectionName: newCollectionName, displayName: newCollectionName },
        ),
        await developerService.updateCollection(
          activeApp.appId,
          collectionName,
          newCollectionName,
        ),
      ]);
      setTimeout(() => {
        toast.success('Collection name updated successfully');
        setSaving(false);
        setCollectionName(newCollectionName);
        window.history.replaceState(
          null,
          `collection ${newCollectionName}`,
          `/developer/apps/${activeApp.appId}/database/${newCollectionName}`,
        );
      }, 1300);
    } catch (e) {
      setSaving(false);
      toast.error((e as Error).message || 'Oops, something went wrong');
    }
  }

  return (
    <>
      <div className="app-content-wrapper collection-settings">
        <form
          className="app-section app-section-body"
          onSubmit={editCollectionName}
        >
          <DeveloperInput
            name="newCollectionName"
            value={newCollectionName}
            handleChange={(e: any) =>
              setNewCollectionName(
                e.target.value.toLocaleLowerCase().replaceAll(' ', '-'),
              )
            }
            label="Collection Name"
            placeholder="Collection Name"
            autoFocus
          />
          <div className="buttons-wrapper">
            <button
              type="submit"
              disabled={saving}
              className="primary-button button"
            >
              {saving ? 'Saving...' : 'Save'}
            </button>
          </div>
        </form>
        <div className="buttons-wrapper delete-app">
          <h4>Danger zone</h4>
          <p>
            Caution! This will delete the collection and all its data will be
            lost. This action cannot be undone.
          </p>
          <button
            className="button primary-button"
            onClick={(e) => {
              e.preventDefault();
              setShowDeletePopup(true);
            }}
          >
            Delete Collection
          </button>
        </div>
      </div>
      <WarnPopup
        showPopup={showDeletePopup}
        setShowPopup={setShowDeletePopup}
        title="Are you sure you want to delete this collection?"
        text={`Warning! Deleting this collection will permanently remove all associated data.`}
        onConfirm={onDelete}
      />
    </>
  );
};
