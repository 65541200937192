import React, { useRef, useState, useEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { H3 } from '@commonninja/commonninja-styleguide-react/lib/esm/components/internal';
import { FormRow } from '@commonninja/commonninja-styleguide-react';

import { IAuthProps } from './auth.types';
import { AuthLayout } from './authLayout.comp';
import { authService } from './auth.service';
import { AuthForm } from './authForm.comp';

export const ForgotPassword = ({ embedMode, isDeveloper = false }: IAuthProps) => {
  const firstInput: any = useRef();
  const { search } = useLocation();
  const [postSubmission, setPostSubmission] = useState<boolean>(false);
  const [formState, setFormState] = useState<any>({
    email: '',
  });

  function formFieldChanged(value: string = '') {
    setFormState({
      email: value.toLowerCase()
    });
  }

  function renderPostSubmissionMessage() {
    return (
      <>
        <H3>Check your inbox</H3>
        <p className="body-1 post-message">
          An email has been sent to <strong>{formState.email}</strong> with further information and instructions.
        </p>
        <div className="buttons-wrapper center">
          <NavLink className="primary" to={`${embedMode ? '/embed' : ''}/login${search ? search : ''}`}>Back to Login Page</NavLink>
        </div>
      </>
    );
  }

  function renderForm() {
    return (
      <>
        <H3>Lost password?</H3>
        <p className="body-1">Worry not. Type the email address you signed up with for recovery.</p>
        <AuthForm
          name="forgot"
          mainButtonText="Reset Password"
          embedMode={embedMode}
          authRequest={(captchaToken) => authService.forgotPassword(formState, captchaToken)}
          firstInput={firstInput}
          successCallback={() => setPostSubmission(true)}
          footerComp={(
            <div className="center">
              <NavLink to={`${embedMode ? '/embed' : ''}${isDeveloper ? '/developer' : ''}/login${search ? search : ''}`} className="primary">Back to login</NavLink>
            </div>
          )}
        >
          <FormRow flow="column">
            <input
              type="email"
              ref={firstInput}
              value={formState.email}
              onChange={(e) => formFieldChanged(e.target.value)}
              minLength={4}
              required
              placeholder='Enter email address'
            />
          </FormRow>
        </AuthForm>
      </>
    );
  }

  useEffect(() => {
    document.title = `Forgot Password | Common Ninja`;
  }, []);

  return (
    <AuthLayout isDeveloper={isDeveloper} embedMode={embedMode}>
      {
        postSubmission ?
          renderPostSubmissionMessage() :
          renderForm()
      }
    </AuthLayout>
  );
};