import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CommonNinjaWidget } from "commonninja-react";
import { removeAll, info, success } from "react-notification-system-redux";
import {
  Button,
  HttpService,
  Popup,
} from "@commonninja/commonninja-styleguide-react";
import { error } from "react-notification-system-redux";

import { SkeletonTable } from "../skeletonTable/skeletonTable.comp";
import { appList, IPluginListingNew } from "../../services/appList.service";
import { MiniBundlesPopup } from "../pricing/miniBundlesPopup/miniBundlesPopup.comp";

import "./subscriptions.scss";

const nindoApiUrl = process.env.REACT_APP_NINDO_SERVICE_URL || "";

export interface IUserSubscription {
  created: string;
  isActive: boolean;
  plan: {
    name: string;
    period: "month" | "year";
    amount: number;
    services: string[];
    features: any;
  };
  source: string;
  status: string;
  subscriptionId: string;
  version: number;
  miniBundleDetails?: {
    id: string;
    selectedApps: string[];
  };
}

export const Subscriptions = () => {
  const { user } = useSelector((state: any) => ({ user: state.user }));
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState<IUserSubscription[]>([]);
  const [apps, setApps] = useState<IPluginListingNew[]>([]);
  const [bundlesPopupOpened, setBundlesPopupOpened] = useState<boolean>(false);
  const [
    activeSubscriptionForCancellation,
    setActiveSubscriptionForCancellation,
  ] = useState<null | IUserSubscription>(null);
  const dispatch = useDispatch();

  async function loadApps() {
    setApps(await appList.get());
  }

  async function loadSubscriptions() {
    setLoading(true);
    setItems([]);

    try {
      const platformReq = await fetch(
        `${nindoApiUrl}/nindo/api/getUserSubscriptions`,
        { credentials: "include" }
      );
      const platformRes = await platformReq.json();
      const paddleSubscriptions = platformRes?.data || [];

      const promises = paddleSubscriptions.map(
        async (paddleSubscription: IUserSubscription) => {
          const localServiceName = paddleSubscription.plan.services[0];
          if (localServiceName === "minibundles") {
            const res = await fetch(`${nindoApiUrl}/nindo/api/getMiniBundle`, {
              credentials: "include",
            });
            const json = await res.json();

            if (json.success && json.data) {
              paddleSubscription.miniBundleDetails = json.data;
            }
          }

          return paddleSubscription;
        }
      );

      await Promise.all(promises);

      const unifiedSubscriptions: IUserSubscription[] = [
        ...paddleSubscriptions,
      ];

      setItems(unifiedSubscriptions);
    } catch (e) {
      dispatch(removeAll());
      dispatch(
        error({
          message: (e as Error).message,
          autoDismiss: 5,
          position: "tc",
        })
      );
    }

    setLoading(false);
  }

  async function cancelSubscription(e: any) {
    e.preventDefault();
    const sure = window.confirm(
      "Are you sure you want to cancel this subscription?"
    );
    if (!sure) {
      return;
    }
    const url = e.target.href;

    dispatch(
      info({
        message: "Cancelling the subscription.",
        autoDismiss: 0,
        position: "tc",
      })
    );

    try {
      const req = await fetch(url, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const result = await req.json();

      dispatch(removeAll());

      if (!result.success) {
        throw new Error(
          result.data?.[0]?.msg ||
            "Could not cancel the subscription. Please contact our support team."
        );
      }

      dispatch(
        success({
          message: "Subscription has been successfully cancelled.",
          autoDismiss: 3,
          position: "tc",
        })
      );

      loadSubscriptions();
    } catch (e) {
      dispatch(
        error({
          message: (e as Error).message,
          autoDismiss: 5,
          position: "tc",
        })
      );
    }

    setLoading(false);
  }

  async function cancelPaddleSubscription(
    localServiceName: string,
    subscriptionId: string
  ) {
    try {
      setLoading(true);
      dispatch(removeAll());

      let componentType = apps.filter(
        (a) => a.serviceName === localServiceName
      )?.[0]?.componentType;

      if (localServiceName === "bundle" || localServiceName === "bundles") {
        componentType = "bundle";
      } else if (
        localServiceName === "mini-bundles" ||
        localServiceName === "minibundles"
      ) {
        componentType = "mini_bundles";
      } else if (
        localServiceName === "widgetsbundle" ||
        localServiceName === "widgets-bundle"
      ) {
        componentType = "widgets_bundle";
      }

      const client = new HttpService();
      const result = await client.makeRequest(
        `${nindoApiUrl}/nindo/api/cancelSubscription/${componentType}?${client.queryParams}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ subscriptionId }),
        }
      );

      if (!result.success) {
        throw new Error(result.message || "Cannot cancel subscription.");
      }

      dispatch(
        success({
          message: "Subscription has been successfully cancelled.",
          autoDismiss: 3,
          position: "tc",
        })
      );

      loadSubscriptions();
      setLoading(false);
      return true;
    } catch (e) {
      dispatch(
        error({
          message: (e as Error).message,
          autoDismiss: 5,
          position: "tc",
        })
      );
      setLoading(false);
      return false;
    }
  }

  async function updateMiniBundle(id: string, selectedApps: string[]) {
    try {
      const client = new HttpService();
      const result = await client.makeRequest(
        `${nindoApiUrl}/nindo/api/updateMiniBundle/${id}?${client.queryParams}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ selectedApps }),
        }
      );

      if (!result.success) {
        throw new Error(result.message || "Cannot update bundle.");
      }
    } catch (e) {
      console.error("Cannot update bundle", e);
    }
  }

  function renderMiniBundlesSelection(item: IUserSubscription) {
    const localServiceName = item.plan.services[0];

    if (localServiceName !== "minibundles") {
      return <></>;
    }

    return (
      <>
        <Button
          color="transparent"
          onClick={() => {
            setBundlesPopupOpened(true);
          }}
        >
          Select Apps
        </Button>
        <MiniBundlesPopup
          popupOpened={bundlesPopupOpened}
          setPopupOpened={setBundlesPopupOpened}
          initialSelectedApps={item.miniBundleDetails?.selectedApps || []}
          limit={item.plan?.features?.numberOfApps || 3}
          apps={apps.filter((a) => a.componentType !== "bracket")}
          onSelect={async (apps: string[]) => {
            await updateMiniBundle(item.miniBundleDetails?.id || "", apps);
            loadSubscriptions();
          }}
          allowLimitPass={false}
        />
      </>
    );
  }

  function renderCancellationPopup(
    localServiceName: string,
    subscriptionId: string
  ) {
    const activeApp = apps.find(
      (p: IPluginListingNew) => p.serviceName === localServiceName
    );
    return (
      <Popup
        show={
          activeSubscriptionForCancellation?.subscriptionId === subscriptionId
        }
        closeCallback={() => {
          setActiveSubscriptionForCancellation(null);
        }}
        className="cancellation-popup"
        style={{
          width: 630,
        }}
      >
        <CommonNinjaWidget
          widgetId="9c8349fe-04e2-42d9-918d-e869e708761c"
          widgetProps={encodeURIComponent(
            JSON.stringify({
              // App name field
              "7e4c74d6-6fee-447b-bc47-51ab40d75287":
                activeApp?.name || "Bundle",
              // Email field
              "75e6a1e0-e8e4-4098-aa9b-983cf1d112e4": user?.email || "",
            })
          )}
        />
      </Popup>
    );
  }

  function renderActions(item: IUserSubscription) {
    const noActions = <span>⁄</span>;

    if (item.source === "paddle") {
      if (!item?.plan?.services?.[0]) {
        return noActions;
      }

      if (item.status !== "active" && item.status !== "trial") {
        return (
          <a
            href={`/embed/pricing/${item.plan.services[0]}`}
            target="_blank"
            rel="noreferrer"
          >
            Renew
          </a>
        );
      }

      return (
        <>
          {item.plan.services[0] !== "minibundles" && (
            <a
              href={`/embed/pricing/${item.plan.services[0]}`}
              target="_blank"
              rel="noreferrer"
            >
              Change
            </a>
          )}
          {renderMiniBundlesSelection(item)}
          <Button
            color="transparent"
            className="delete"
            onClick={() => {
              setActiveSubscriptionForCancellation(item);
            }}
          >
            Cancel
          </Button>
          {renderCancellationPopup(item.plan.services[0], item.subscriptionId)}
        </>
      );
    }

    if (item.status !== "active") {
      const localServiceName = item?.plan?.services?.[0];
      if (localServiceName) {
        const pluginDetails = apps.find(
          (p: IPluginListingNew) => p.serviceName === localServiceName
        );
        return <a href={`/widgets/${pluginDetails?.slug}/pricing`}>Renew</a>;
      }
      return noActions;
    }

    if (item.source === "paypal") {
      if (item.version <= 1) {
        return (
          <a
            href="https://www.paypal.com/cgi-bin/webscr?cmd=_subscr-find&alias=U9NBBXTNVKAQW"
            target="_blank"
            rel="noopener noreferrer"
          >
            Cancel
          </a>
        );
      }

      return (
        <>
          {item?.plan?.services?.[0] && (
            <a href={`/embed/pricing/${item.plan.services[0]}`}>Change</a>
          )}
          <a
            href={`/api/paypal/cancelSubscription/${item.subscriptionId}`}
            onClick={cancelSubscription}
            rel="noopener noreferrer"
          >
            Cancel
          </a>
        </>
      );
    }

    if (item.source === "bluesnap") {
      return (
        <>
          {item?.plan?.services?.[0] && (
            <a href={`/embed/pricing/${item.plan.services[0]}`}>Change</a>
          )}
          <a
            href={`/api/bluesnap/cancelSubscription/${item.subscriptionId}`}
            onClick={cancelSubscription}
            rel="noopener noreferrer"
          >
            Cancel
          </a>
        </>
      );
    }

    return noActions;
  }

  useEffect(() => {
    if (!activeSubscriptionForCancellation) {
      return;
    }

    const messageHandler = async (event: MessageEvent) => {
      if (event.data && event.data.type === "cn-form-submitted") {
        // Handle the payload here if needed
        // const payload = event.data.payload || {};

        const success = await cancelPaddleSubscription(
          activeSubscriptionForCancellation?.plan.services[0],
          activeSubscriptionForCancellation?.subscriptionId
        );

        if (success) {
          setActiveSubscriptionForCancellation(null);
        }
      }
    };

    window.addEventListener("message", messageHandler);

    return () => {
      window.removeEventListener("message", messageHandler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeSubscriptionForCancellation]);

  useEffect(() => {
    loadApps();
    loadSubscriptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) {
    return <SkeletonTable rows={10} cols={8} />;
  }

  if (!items.length) {
    return <p className="center">No active subscriptions.</p>;
  }

  return (
    <div className="content-wrapper">
      <div className="data-table-wrapper">
        <table className="data-table">
          <thead>
            <tr>
              <th style={{ width: "90px" }} className="center">
                Status
              </th>
              {/* <th>Source</th> */}
              <th>Plan Name</th>
              <th>Payment Cycle</th>
              <th>Payment Amount</th>
              <th>Created Date</th>
              <th className="center">Actions</th>
            </tr>
          </thead>
          <tbody>
            {items.map((item: IUserSubscription) => (
              <tr
                className="subscription"
                key={`subs_${item.status}_${item.subscriptionId || Date.now()}`}
              >
                <td className="center status">
                  <span
                    className={`is-active ${item.isActive ? "yes" : "no"}`}
                  ></span>
                  {item.status}
                </td>
                {/* <td>{item.source === 'paypal' ? 'PayPal' : 'Credit Card'}</td> */}
                <td>{item.plan.name}</td>
                <td>{item.plan.period}</td>
                <td>${item.plan.amount}</td>
                <td>{item.created}</td>
                <td className="center actions">{renderActions(item)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
