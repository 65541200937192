import React, { useContext, useState } from 'react';
import { toast } from 'react-toastify';
import { developerService } from '../../../../services/developer.service';
import { DeveloperContext } from '../../developer.context';
import { DataTable } from '../../common/dataTable';
import { WarnPopup } from '../../common/warnPopup/warnPopup.comp';
import { ICollectionSchemaAttribute } from '../types';
import { DocumentCreationPopup } from './documentCreationPopup/documentCreationPopup.comp';

import './documents.scss';

interface IDocumentProps {
  fields: ICollectionSchemaAttribute[];
  collectionName: string;
}

export const Documents = ({ fields, collectionName }: IDocumentProps) => {
  const [showDeletePopup, setShowDeletePopup] = useState<boolean>(false);
  const [showCreationPopup, setShowCreationPopup] = useState<boolean>(false);
  const [duplicate, setDuplicate] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [documents, setDocuments] = useState<{
    limit?: number;
    page?: number;
    pages?: number;
    total?: number;
    items?: any[];
  }>({});
  const [selectedDocumentId, setSelectedDocumentId] = useState<string>('');
  const { activeApp } = useContext(DeveloperContext);

  async function onCreate(newDocument: any) {
    const newDocumentkeys = Object.keys(newDocument);
    let isValid = true;
    newDocumentkeys.forEach((key: string) => {
      const wantedField = fields.find((field) => field.name === key);
      if (wantedField?.validation.required && !newDocument[key])
        isValid = false;
    });
    if (!isValid) return toast.warning('Some required fields are missing');

    try {
      setLoading(true);
      if (!selectedDocumentId || duplicate) {
        await developerService.createDocument(
          activeApp.appId,
          collectionName,
          newDocument,
        );
        toast.success('Document created successfully');
      } else {
        await developerService.updateDocument(
          activeApp.appId,
          collectionName,
          selectedDocumentId,
          newDocument,
        );
        toast.success('Document updated successfully');
      }
      await getDocuments({
        page: 1,
        limit: 10,
        query: {},
        sort: { _created: -1 },
      });
    } catch (e) {
      toast.error((e as Error).message || 'Oops, something went wrong');
    }

    setTimeout(() => {
      setShowCreationPopup(false);
      setLoading(false);
    }, 600);
  }

  async function onDelete() {
    try {
      setLoading(true);
      await developerService.deleteDocument(
        activeApp.appId,
        collectionName,
        selectedDocumentId,
      );
      toast.success('Document deleted successfully');
      await getDocuments({
        page: 1,
        limit: 10,
        query: {},
        sort: { _created: -1 },
      });
      setSelectedDocumentId('');
    } catch (e) {
      toast.error((e as Error).message || 'Oops, something went wrong');
    }
    
    setTimeout(() => {
      setShowDeletePopup(false);
      setLoading(false);
    }, 600);
  }

  async function onDuplicate(id: string) {
    setSelectedDocumentId(id);
    setDuplicate(true);
    setShowCreationPopup(true);
  }

  async function onEdit(id: string) {
    setSelectedDocumentId(id);
    setShowCreationPopup(true);
  }

  function onOpenConfirmPopup(id: string) {
    setShowDeletePopup(true);
    setSelectedDocumentId(id);
  }

  async function getDocuments({
    page,
    limit,
    query,
    sort,
  }: {
    page: number;
    limit: number;
    query: any;
    sort: any;
  }) {
    try {
      setLoading(true);
      const formattedQuery = encodeURIComponent(JSON.stringify(query));
      const formattedSort = encodeURIComponent(JSON.stringify(sort));
      const { data } = await developerService.getDocuments(
        activeApp.appId,
        collectionName,
        { page, limit, query: formattedQuery, sort: formattedSort },
      );
      setDocuments(data);
    } catch (e) { }

    setTimeout(() => {
      setLoading(false);
    }, 600);
  }

  function onShowCreationPopup() {
    setSelectedDocumentId('');
    setShowCreationPopup(true);
  }

  const documentActionsProps = {
    onDelete: (id: string) => onOpenConfirmPopup(id),
    onDuplicate,
    onEdit,
    onCreate: () => {
      fields.length === 0
        ? toast.info('Please create a field first')
        : onShowCreationPopup();
    },
  };
  const fieldNames = fields.map((field) => field.name);

  return (
    <>
      <div className="app-content-wrapper collection-documents">
        {fields.length ? (
          <DataTable
            getItems={getDocuments}
            totalPages={documents?.pages || 1}
            currentPage={documents?.page || 1}
            {...documentActionsProps}
            actionIdKey="_id"
            title={`Documents Editor`}
            subTitle={`${documents?.total || 0} items found`}
            showPageSizeSelect={true}
            headers={[
              {
                title: 'ID',
                key: '_id',
                type: 'string',
              },
              ...fields
                .filter((f) => fieldNames.includes(f.name))
                .map(
                  (field) =>
                  ({
                    title: field.displayName,
                    key: field.name,
                    type: convertType(field.type),
                  } as any),
                ),
              {
                title: 'Created',
                key: '_created',
                type: 'string',
              },
              {
                title: 'Updated',
                key: '_updated',
                type: 'string',
              },
            ]}
            rows={documents?.items || []}
            buttonText="Create new document"
            noItemsIcon={renderDocumentIcon}
            noItemsText="Sorry, no documents found"
            paginationParams={{ limit: 10, sort: { _created: -1 } }}
            loading={loading}
          />
        ) : (
          <div className="add-field-box">
            {renderDocumentIcon()}
            <p>
              The documents view will be enabled once you add collection fields
            </p>
            <button
              className="button primary-button"
              onClick={() =>
                (
                  document.querySelector('.tab-trigger:nth-child(2)') as any
                )?.click()
              }
            >
              Add Fields
            </button>
          </div>
        )}
      </div>
      <DocumentCreationPopup
        duplicate={duplicate}
        setDuplicate={setDuplicate}
        collectionName={collectionName}
        selectedDocumentId={selectedDocumentId}
        setSelectedDocumentId={setSelectedDocumentId}
        fields={fields}
        onCreate={onCreate}
        setShowPopup={setShowCreationPopup}
        showPopup={showCreationPopup}
        loading={loading}
      />
      <WarnPopup
        showPopup={showDeletePopup}
        setShowPopup={setShowDeletePopup}
        title="Are you sure you want to remove this document?"
        text={`Removing this document will permanently delete the document’s information from the collection.`}
        onConfirm={onDelete}
        loading={loading}
      />
    </>
  );
};

function convertType(type: string) {
  if (type === 'date') return 'date';
  if (type === 'number') return 'number';
  return 'string';
}

function renderDocumentIcon() {
  return (
    <svg
      width="81"
      height="105"
      viewBox="0 0 81 105"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M77.4206 21.7932L57.6415 3.08561C55.5377 1.09573 52.7841 0 49.8881 0H12.2313C6.01035 0 0.949219 5.05989 0.949219 11.2793V93.7207C0.949219 99.9401 6.01035 105 12.2313 105H69.6672C75.8881 105 80.9492 99.9401 80.9492 93.7207V29.9869C80.9492 26.9007 79.6631 23.9142 77.4206 21.7932ZM71.4438 24.6094H56.1287C55.5632 24.6094 55.1031 24.1494 55.1031 23.584V9.15387L71.4438 24.6094ZM69.6672 98.8477H12.2313C9.40358 98.8477 7.10307 96.5477 7.10307 93.7207V11.2793C7.10307 8.4523 9.40358 6.15234 12.2313 6.15234H48.9492V23.584C48.9492 27.5418 52.1699 30.7617 56.1287 30.7617H74.7954V93.7207C74.7954 96.5477 72.4949 98.8477 69.6672 98.8477Z"
        fill="#D0D0D0"
      />
      <path
        d="M62.8975 41.0156H17.7693C16.07 41.0156 14.6924 42.3929 14.6924 44.0918C14.6924 45.7907 16.07 47.168 17.7693 47.168H62.8975C64.5968 47.168 65.9744 45.7907 65.9744 44.0918C65.9744 42.3929 64.5968 41.0156 62.8975 41.0156Z"
        fill="#D0D0D0"
      />
      <path
        d="M62.8975 57.422H17.7693C16.07 57.422 14.6924 58.7993 14.6924 60.4982C14.6924 62.197 16.07 63.5743 17.7693 63.5743H62.8975C64.5968 63.5743 65.9744 62.197 65.9744 60.4982C65.9744 58.7993 64.5968 57.422 62.8975 57.422Z"
        fill="#D0D0D0"
      />
      <path
        d="M32.6862 73.8281H17.7693C16.07 73.8281 14.6924 75.2054 14.6924 76.9043C14.6924 78.6032 16.07 79.9805 17.7693 79.9805H32.6862C34.3855 79.9805 35.7632 78.6032 35.7632 76.9043C35.7632 75.2054 34.3855 73.8281 32.6862 73.8281Z"
        fill="#D0D0D0"
      />
    </svg>
  );
}
