import { Popup } from '@commonninja/commonninja-styleguide-react';
import React, { useEffect, useState, useContext } from 'react';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { v4 } from 'uuid';

import { IAppPlan, IAppPlanCoupon } from '../../developer.types';
import { DeveloperContext } from '../../developer.context';
import { developerService } from '../../../../services/developer.service';
// import { CouponEditor } from './couponEditor/couponEditor.comp';
import { SortableAppCards } from '../common/sortableAppCards.comp';
import { DLoader } from '../../loader/loader.comp';
import { CouponEditor } from './couponEditor/couponEditor.comp';

import './coupons.scss';

function generateCouponCode(length = 8) {
  var result = '';
  var characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export const Coupons = () => {
  const emptyCoupon: () => IAppPlanCoupon = () => ({
    couponId: v4(),
    couponName: '',
    platform: '',
    platformCouponCode: generateCouponCode(),
    discountType: 'percentage',
    discountAmount: 20,
    discountCurrency: 'usd',
    expires: '',
    durationType: 'forever',
    durationAmountInMonths: 0,
    maxRedemptions: 5,
    timesRedeemed: 0,
    appliesTo: [],
  });
  const {
    integrationPlatforms,
    integrations,
    activeApp: { appId },
  } = useContext(DeveloperContext);
  const [coupons, setCoupons] = useState<IAppPlanCoupon[]>([]);
  const [editorOpened, setEditorOpened] = useState(false);
  const [loading, setLoading] = useState(true);
  const [editorCoupon, setEditorCoupon] = useState(emptyCoupon());
  const [editorCouponIndex, setEditorCouponIndex] = useState(-1);
  const [removeCouponWarning, setRemoveCouponWarning] = useState(false);
  const [plans, setPlans] = useState<IAppPlan[]>([]);
  const editMode = editorCouponIndex !== coupons.length;

  const handleEditorClose = () => {
    setEditorOpened(false);
    setEditorCouponIndex(-1);
    setEditorCoupon(emptyCoupon());
  };

  const handleEditorOpen = (coupon: IAppPlanCoupon, index: number) => {
    setEditorOpened(true);
    setEditorCoupon({ ...coupon });
    setEditorCouponIndex(index);
  };

  const handleNewCoupon = async (coupon: IAppPlanCoupon) => {
    try {
      await developerService.createAppCoupon(appId, coupon);
      handleEditorClose();
      const newCoupons = [...coupons, { ...coupon }];
      setCoupons(newCoupons);
    } catch (error) {
      toast.error(`Could not create coupon: ` + (error as Error).message);
    }
    setLoading(false);
  };

  const handleCouponChange = async (coupon: IAppPlanCoupon) => {
    try {
      await developerService.updateAppCoupon(appId, coupon);
      handleEditorClose();
      const newCoupons = [...coupons];
      newCoupons[editorCouponIndex] = { ...coupon };
      setCoupons(newCoupons);
    } catch (error) {
      toast.error(`Could not create coupon: ` + (error as Error).message);
    }
  };

  const handleRemoveCoupon = async (
    couponId: string,
    { confirmed = false },
  ) => {
    const idx = coupons.findIndex((f) => f.couponId === couponId);
    if (!confirmed) {
      setRemoveCouponWarning(true);
      setEditorCoupon(coupons[idx]);
      return;
    }

    try {
      await developerService.deleteAppCoupon(
        appId,
        couponId,
        coupons[idx].platform,
      );
      const newCoupons = [...coupons];
      newCoupons.splice(idx, 1);
      setCoupons(newCoupons);
      setRemoveCouponWarning(false);
    } catch (error) {
      toast.error(`Could not create coupon: ` + (error as Error).message);
    }
  };

  const renderBody = () => {
    if (loading) {
      return <DLoader />;
    }

    if (!plans.length) {
      return (
        <div className="app-section app-section-body">
          <p className="center">
            Coupons will be available once you add your first payment plan.
          </p>
        </div>
      );
    }

    const hasIntegration = integrationPlatforms.filter((p) => {
      if (!p.tags.includes('payments')) {
        return false;
      }

      if (integrations.find((i) => i.platform === p.name && i.isActive)) {
        return true;
      }

      return false;
    }).length > 0;

    if (!hasIntegration) {
      return (
        <div className="app-section app-section-body">
          <p className="center">
            Coupons will be available once you connect a payment integration.
          </p>
        </div>
      );
    }

    return (
      <>
        <div className="coupons-container app-content-wrapper">
          <SortableAppCards
            onRemove={(index) => {
              handleRemoveCoupon(coupons[index]?.couponId, {
                confirmed: false,
              });
            }}
            buttonText="Add a Coupon"
            onAdd={() => handleEditorOpen(emptyCoupon(), coupons.length)}
            items={coupons.map((coupon, index) => {
              return {
                onClick: () => handleEditorOpen(coupon, index),
                header: coupon.couponName,
                content: (
                  <>
                    <div style={{ marginBottom: '5px' }}>
                      Usage: <strong>{coupon.timesRedeemed}</strong>/
                      {coupon.maxRedemptions}
                    </div>
                    <div>
                      {`Discount: ${coupon.discountType === 'flat' ? '$' : ''}${
                        coupon.discountAmount
                      }${coupon.discountType === 'percentage' ? '%' : ''}`}
                    </div>
                  </>
                ),
                footer: (
                  <>
                    Code:{' '}
                    <i style={{ marginLeft: '5px' }}>{coupon.platformCouponCode}</i>
                  </>
                ),
                idx: index,
              };
            })}
          />
        </div>
        <Popup
          show={editorOpened}
          closeCallback={handleEditorClose}
          className="developer-page app-section"
        >
          <h2 className="separator-light">
            {editMode ? 'Edit' : 'Add'} Coupon
          </h2>
          <CouponEditor
            coupon={editorCoupon}
            onChange={editMode ? handleCouponChange : handleNewCoupon}
            nameReadonly={editMode}
            plans={plans}
            onRemove={() =>
              handleRemoveCoupon(editorCoupon.couponId, { confirmed: false })
            }
          />
        </Popup>
        <Popup
          show={removeCouponWarning}
          closeCallback={() => setRemoveCouponWarning(false)}
          className="developer-page popup-warn"
        >
          <header>
            <FontAwesomeIcon
              icon={faExclamationCircle}
              color="#fabf5e"
              size="2x"
            />
            <h3>Are you sure you want to remove this coupon?</h3>
          </header>
          <p className="popup-warn-text">
            This will remove the coupon from all of the app plans. This is
            irreversible.
          </p>
          <div className="popup-warn-buttons">
            <div
              className="button secondary-button"
              onClick={() => setRemoveCouponWarning(false)}
            >
              Cancel
            </div>
            <div
              className="button primary-button"
              onClick={() =>
                handleRemoveCoupon(editorCoupon.couponId, { confirmed: true })
              }
            >
              Remove
            </div>
          </div>
        </Popup>
      </>
    );
  };

  useEffect(() => {
    async function fetchPlans() {
      if (appId) {
        try {
          const { data } = await developerService.getAppPlans(appId);
          setPlans(data);
        } catch (e) {
          toast.error((e as Error).message);
        }
      }
    }
    fetchPlans();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const fetchCoupons = async () => {
      try {
        const { data: coupons } = await developerService.getAppCoupons(appId);
        setCoupons(coupons);
        setLoading(false);
      } catch (error) {
        toast.error(
          `There was a problem getting the app's coupons: ` +
            (error as Error).message,
        );
        setLoading(false);
      }
    };
    fetchCoupons();
  }, [appId]);

  return <div className="coupons">{renderBody()}</div>;
};
