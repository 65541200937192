import React, { useState, useRef, useEffect } from "react";
import "./mFApopup.scss";
import { authService } from "./auth.service";
import { Popup } from "@commonninja/commonninja-styleguide-react";

interface IMFAPopupProps {
  successCallback: () => void;
  onClose: () => void;
  email: string;
}

export const MFAPopup = ({
  successCallback,
  onClose,
  email,
}: IMFAPopupProps) => {
  const [token, setToken] = useState<string[]>(Array(6).fill(""));
  const [error, setError] = useState<string>("");
  const firstInputRef = useRef<HTMLInputElement | null>(null);
  const inputsRef = useRef<HTMLInputElement[]>([]);

  useEffect(() => {
    // Focus on the first input box when the popup opens
    const focusTimeout = setTimeout(() => {
      firstInputRef.current?.focus();
    }, 300); // Adjust the delay if needed

    return () => clearTimeout(focusTimeout); // Clean up the timeout on component unmount
  }, []);

  const handleChange = (value: string, index: number) => {
    if (!/^[0-9]?$/.test(value)) {
      setError("Only numbers are allowed");
      return;
    } // Allow only numbers or empty input
    setError("");
    const newToken = [...token];
    newToken[index] = value;
    setToken(newToken);

    // Auto-focus to the next input box
    if (value && index < 5) {
      inputsRef.current[index + 1]?.focus();
    }

    // Trigger submit if all boxes are filled
    if (newToken.every((char) => char !== "")) {
      handleSubmit(newToken.join(""));
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent, index: number) => {
    if (e.key === "Backspace" && token[index] === "" && index > 0) {
      inputsRef.current[index - 1]?.focus();
    }
  };

  const handlePaste = (e: React.ClipboardEvent) => {
    const pasteData = e.clipboardData.getData("text");
    if (!/^[0-9]{6}$/.test(pasteData)) {
      setError("Only numbers are allowed");
      return; // Allow only a 6-digit numeric string
    }
    setError("");
    const newToken = pasteData.split("").slice(0, 6); // Distribute characters across inputs
    setToken(newToken);

    // Auto-focus the last input box
    if (inputsRef.current[5]) {
      inputsRef.current[5].focus();
    }

    // Trigger submit if all boxes are filled
    handleSubmit(newToken.join(""));
  };

  const handleSubmit = async (fullToken: string) => {
    setError("");
    try {
      const result = await authService.verifyMFA({
        email,
        token: fullToken,
      });
      if (result?.success) {
        successCallback();
      } else {
        throw new Error(result.message);
      }
    } catch (e) {
      setError("Could not verify token");
    }
  };

  return (
    <Popup
      show={true}
      closeCallback={onClose}
      className="popup-warn collection-popup mfa-popup"
    >
      <header>
        <h3>Two Factor Authentication Log In</h3>
      </header>
      <form onSubmit={(e) => e.preventDefault()}>
        <div
          className="input-container"
          onPaste={handlePaste} // Add onPaste handler here
        >
          {token.map((value, index) => (
            <input
              key={index}
              type="text"
              value={value}
              onChange={(e) => handleChange(e.target.value, index)}
              onKeyDown={(e) => handleKeyDown(e, index)}
              maxLength={1}
              ref={(el) => {
                inputsRef.current[index] = el!;
                if (index === 0) firstInputRef.current = el;
              }}
              className="mfa-input"
            />
          ))}
        </div>
        <p className="modal-description">
          Please enter the MFA token generated by your authenticator app
        </p>
        {error && <p className="error-message">{error}</p>}
        <div className="popup-warn-buttons">
          <button
            type="button"
            className="button secondary-button"
            onClick={onClose}
          >
            Cancel
          </button>
          <button
            type="button"
            className="button primary-button"
            onClick={() => handleSubmit(token.join(""))}
          >
            Submit
          </button>
        </div>
      </form>
    </Popup>
  );
};