
import React from 'react';
import useCopyToClipboard from '../../newDashboardComponents/hooks/useCopyToClipboard';
import { MdContentCopy } from 'react-icons/md';

import './copyText.scss';

interface ICopyTextProps {
    text: string;
    className?: string;
    showIcon?: boolean;
}

export const CopyText = ({ text, showIcon = true, className = '' }: ICopyTextProps) => {
    const [copy] = useCopyToClipboard();

    return (
        <div onClick={copy} className={`copy-wrapper ${className}`}>
            <p>{text}
                <span className="copied-txt"></span>
                {showIcon &&
                    <MdContentCopy className='copy-icon' />
                }
            </p>
        </div>
    );
};
