import React from "react";
import { useParams } from "react-router-dom";
// import { toast } from "react-toastify";
import { Sidebar } from "../../sidebar/sidebar.comp";

import "./mediaManager.scss";
import { MediaManagerFiles } from "./mediaManagerFiles/mediaManagerFiles.comp";
import { AssetService } from "./asset.service";
import { ToastContainer } from 'react-toastify';

export const MediaManager = () => {
  const { projectId } = useParams<any>();
  const assetService = new AssetService();

  function renderBody() {
    return (
      <MediaManagerFiles
        showActions
        toolbarProps={{
          onChange: () => {
            // Reload list
            const reloader: HTMLDivElement | null = document.querySelector(
              "#media-manager-filer-reload"
            );

            if (reloader) {
              reloader.click();
            }
          },
          showSearch: true,
          showActions: true,
          uploadIsAvailable: true,
          sizeLimitInMB: 50,
          acceptType: "image",
          uploadDisabledCallback: () => {},
        }}
        getFilesPromise={async (q, page, limit) => {
          const result = await assetService.get({
            q,
            page,
            limit,
            assetType: "image",
            projectId,
          });

          result.data.items = (result.data?.docs || []).map((doc: any) => ({
            guid: doc.guid,
            assetType: "image",
            name: doc.name,
            url: doc.url,
            downloadUrl: doc.downloadUrl,
            userId: "",
            serviceName: "",
            thumbnail: doc.url,
            author: doc.author,
          }));

          return result;
        }}
      />
    );
  }

  return (
    <>
      <Sidebar variant="widgets" />
      <section className="media-manager main-section">
        <div className="inner">
          <header>
            <hgroup className="page-titles">
              <h2>Media Manager</h2>
            </hgroup>
          </header>
          <div className="styled-media-manager">
            <main>{renderBody()}</main>
          </div>
        </div>
      </section>
      <ToastContainer position={'top-center'} autoClose={2500} />
    </>
  );
};
