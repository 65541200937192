import { Dayjs } from 'dayjs';

interface IDateRangeViewOptions {
  from: Dayjs;
  to: Dayjs;
  groupBy: 'month' | 'day';
}
export function getDateRange(viewOptions: IDateRangeViewOptions): Dayjs[] {
  let dates = [];
  let { from, to, groupBy } = viewOptions;
  to = to.add(1, groupBy);
  
  while (from < to) {
    dates.push(from);
    from = from.add(1, groupBy);
  }
  return dates;
}

export function formatDates(
  dates: Dayjs[],
  viewOptions: IDateRangeViewOptions
): string[] {
  return dates.map((date: Dayjs) =>
    date.format(viewOptions.groupBy === 'month' ? 'MMM-YY' : 'DD-MM-YY')
  );
}

export function capitalize(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}
