import { IUser } from "@commonninja/types";
import React from "react";

export const CurrentSubscription = ({
  userDetails,
}: {
  userDetails: IUser;
}) => {
  const status = userDetails.plan?.subscription?.status;
  
  if (status !== "active" && status !== 'trial') {
    return <></>;
  }

  return (
    <div className="active-subscription message center">
      <p>
        Your account is currently associated with a subscription plan:{" "}
        <strong>{userDetails.plan?.name}</strong>{" "}
      </p>
    </div>
  );
};
