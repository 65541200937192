import { Popup } from '@commonninja/commonninja-styleguide-react';
import React, { useEffect, useState } from 'react';
import { IAppNote } from '../../types';

interface ICreateNotePopupProps {
    openPopup: boolean;
    setOpenPopup: React.Dispatch<React.SetStateAction<boolean>>;
    setSelectedNote: React.Dispatch<React.SetStateAction<any>>;
    selectedNote?: IAppNote;
    onAddNote: (note: IAppNote) => void;
    onEditNote: (note: IAppNote) => void;
}

export const CreateNotePopup = ({ openPopup, setOpenPopup, onAddNote, onEditNote, setSelectedNote, selectedNote }: ICreateNotePopupProps) => {
    const [newNote, setNewNote] = useState({
        title: '',
        description: '',
        id: ''
    });

    function onSubmit(e: any) {
        e.preventDefault();

        if (selectedNote) {
            onEditNote(newNote);
        } else {
            onAddNote(newNote);
        }
        setNewNote({ title: '', description: '', id: '' });
        setSelectedNote(undefined);
    }

    useEffect(() => {
        if (selectedNote) {
            setNewNote(selectedNote);
        }
    }, [selectedNote]);

    return (
        <Popup
            show={openPopup}
            closeCallback={() => { setOpenPopup(false); setNewNote({ title: '', description: '', id: '' }); setSelectedNote(undefined); }}
            className="developer-page popup-warn collection-popup"
        >
            <header>
                <h3>{selectedNote ? 'Update' : 'New'} note</h3>
            </header>
            <form onSubmit={onSubmit}>
                <div className="input-container">
                    <label htmlFor="name">Note Title</label>
                    <div>
                        <input
                            type="text"
                            name="name"
                            title="Note Name"
                            placeholder='Note Title...'
                            value={newNote.title}
                            onChange={(e) => setNewNote({ ...newNote, title: e.target.value })}
                        />
                    </div>
                    <label htmlFor="name">Note Description</label>
                    <div>
                        <textarea
                            name="name"
                            title="Note Description"
                            placeholder='Enter Description...'
                            value={newNote.description}
                            onChange={(e) => setNewNote({ ...newNote, description: e.target.value })}
                        />
                    </div>
                </div>
                <div className="popup-warn-buttons">
                    <button className="button primary-button" >
                        {selectedNote ? 'Edit' : 'Create'} note
                    </button>
                </div>
            </form>
        </Popup>
    );
};
