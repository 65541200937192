import React from 'react';
import { Card } from '../card/card.comp';

import './infoCard.scss';

interface IAppInfoProps {
  title?: string;
  mail?: string;
  leftSide?: { title: string; text: string }[];
  rightSide?: { title: string; text: string }[];
  openPopup?: React.Dispatch<React.SetStateAction<boolean>>;
}

export const InfoCard = ({
  title,
  leftSide = [],
  rightSide = [],
  mail,
  openPopup,
}: IAppInfoProps) => {
  return (
    <Card className="app-info">
      <div className="top">
        <h3>{title}</h3>
        <div className="buttons-container">
          {mail && (
            <a
              className="button secondary-button"
              target="_blank"
              href={`mailto:${mail}`}
              rel="noreferrer"
            >
              Send email
            </a>
          )}
          <button
            onClick={() => {
              openPopup && openPopup(true);
            }}
            className="button primary-button"
          >
            Edit info
          </button>
        </div>
      </div>
      <div className="content">
        <div className="box">
          {leftSide.length > 0 &&
            leftSide.map((detail) => {
              if (!detail.text) return '';
              return (
                <div key={`left-box-${detail.title}`} className="info-wrapper">
                  <p className="info-title">{detail.title}</p>
                  <p>{detail.text}</p>
                </div>
              );
            })}
        </div>
        <div className="box">
          {rightSide.length > 0 &&
            rightSide.map((detail) => (
              <div key={`right-box-${detail.title}`} className="info-wrapper">
                <p className="info-title">{detail.title}</p>
                <p>{detail.text}</p>
              </div>
            ))}
        </div>
      </div>
    </Card>
  );
};
