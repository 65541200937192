import { HttpService } from '@commonninja/commonninja-styleguide-react';

class AnalyticsService extends HttpService {
  async getClientEvents(
    componentId: string,
    startDate: string,
    endDate: string,
  ) {
    return await this.makeRequest(
      `/api/v1/plugin/${componentId}/analytics/client?fromDate=${startDate}&toDate=${endDate}`,
    );
  }

  async getEngagementEvents(
    componentId: string,
    startDate: string,
    endDate: string,
  ) {
    return await this.makeRequest(
      `/api/v1/plugin/${componentId}/analytics/engagement?fromDate=${startDate}&toDate=${endDate}`,
    );
  }
}

export default new AnalyticsService();
