import { Popup } from '@commonninja/commonninja-styleguide-react';
import React, { useEffect, useState } from 'react';
import { getPlanName } from '../../helpers';
import { IAppUserSubscription, IPaymentPlan, IPlanVariants } from '../../types';

import './updateSubscriptionPopup.scss';

interface IUpdateSubscriptionPopupProps {
    openPopup: boolean;
    setOpenPopup: React.Dispatch<React.SetStateAction<boolean>>;
    subscription?: IAppUserSubscription;
    setSubscription: React.Dispatch<React.SetStateAction<any>>;
    onEditSubscription: (subscription: { planId: string, planPricingVariantId: string; }) => void;
    plans: IPaymentPlan[];
}

export const UpdateSubscriptionPopup = ({ openPopup, setOpenPopup, onEditSubscription, subscription, setSubscription, plans = [] }: IUpdateSubscriptionPopupProps) => {
    const [subscriptionState, setSubscriptionState] = useState({ planId: '', planPricingVariantId: '' });
    const [planVariants, setPlanVariants] = useState<IPlanVariants[]>([]);

    function onSubmit(e: any) {
        e.preventDefault();
        onEditSubscription(subscriptionState);
    }

    useEffect(() => {
        const miniSubscription = { planId: subscription?.planId || '', planPricingVariantId: subscription?.planPricingVariantId || '' };
        setSubscriptionState(miniSubscription);
    }, [subscription]);

    useEffect(() => {
        const variants = (plans.find((plan) => plan.planId === subscriptionState?.planId)?.pricingVariants || []) as IPlanVariants[];
        setPlanVariants(variants);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [subscriptionState]);

    return (
        <Popup
            show={openPopup}
            closeCallback={() => { setOpenPopup(false); setSubscriptionState({ planId: '', planPricingVariantId: '' }); setSubscription(undefined); }}
            className="developer-page popup-warn collection-popup update-subscription"
        >
            <header>
                <h3>Edit subscription</h3>
            </header>
            <form onSubmit={onSubmit}>
                <div className="input-container">
                    <label htmlFor="plan">Plan</label>
                    <select name="plan" value={subscriptionState?.planId || ''} onChange={(e: any) => setSubscriptionState({ ...subscriptionState, planId: e.target.value })}>
                        {plans.map((plan) => (
                            <option key={plan.planId} value={plan.planId}>{getPlanName(plans, plan?.planId || '')}</option>
                        ))}
                    </select>
                    <label htmlFor="billing">Billing Period</label>
                    <select name="billing" value={subscriptionState?.planPricingVariantId} onChange={(e: any) => setSubscriptionState({ ...subscriptionState, planPricingVariantId: e.target.value })}>
                        {planVariants?.map((variant: IPlanVariants) => (
                            <option key={variant.id} value={variant.id}>{variant.pricePeriod}</option>
                        ))}
                    </select>
                </div>
                <div className="popup-warn-buttons">
                    <button type="button" onClick={(e: any) => { e.preventDefault(); setOpenPopup(false); }} className="button secondary-button" >
                        Cancel
                    </button>
                    <button type='submit' className="button primary-button" >
                        Change
                    </button>
                </div>
            </form>
        </Popup>
    );
};

