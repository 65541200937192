import React, { useEffect, useState, useContext, Suspense } from 'react';
import { IAppPlan } from '../../../developer.types';
import { useParams, Link } from 'react-router-dom';
import { DeveloperContext } from '../../../developer.context';
import { IAppPlanConnection } from '../../../developer.types';
import { developerService } from '../../../../../services/developer.service';
import { toast } from 'react-toastify';
import { DeveloperInput } from '../../../newDashboardComponents/developerInput.comp';
import { PlanFeatures, PlanPrices, PlanGeneral, PlanProviders } from './';

import './planEditor.scss';

const JSONEditor = React.lazy(() => import('../../common/jsonEditor.comp'));

export const PlanEditor = () => {
  const [plan, setPlan] = useState<IAppPlan>({} as IAppPlan);
  const [madeChanges, setMadeChanges] = useState(false);
  const [validationError, setValidationError] = useState('');
  const { activeApp, integrations } = useContext(DeveloperContext);
  const { planId } = useParams<any>();
  const [connectedProviders, setConnectedProviders] = useState<
    IAppPlanConnection[]
  >([]);

  useEffect(() => {
    async function fetchPlan() {
      try {
        const { data: plan } = await developerService.getAppPlan(
          activeApp.appId,
          planId,
        );
        setPlan(plan);
      } catch (error) {
        toast.error(
          'There was a problem getting the plan info: ' +
            (error as Error).message,
        );
      }
    }
    if (planId && activeApp?.appId) {
      fetchPlan();
    }
  }, [activeApp.appId, planId]);

  useEffect(() => {
    renderProviderError();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [plan, madeChanges]);

  async function savePlan() {
    try {
      if (!plan.name) throw new Error('Plan name is required');
      let planToSave = JSON.parse(JSON.stringify(plan));
      if (planToSave.isFree) {
        planToSave = { ...planToSave, pricingVariants: [], trialCount: 0 };
        setPlan(planToSave);
      }

      await developerService.updateAppPlan(activeApp.appId, planId, planToSave);
      setMadeChanges(false);
      toast.success('Plan saved');
    } catch (e) {
      toast.error((e as Error).message);
    }
  }

  function renderProviderError() {
    const validation = [
      // {
      //   rule: !plan.isActive,
      //   message:
      //     'Your plan must be active before connecting/disconnecting a provider.',
      // },
      {
        rule: !integrations.length,
        message:
          'You need to add a payment integration before you connect a provider.',
      },
      {
        rule: !plan.pricingVariants?.some((p) => !!p.priceCount),
        message: 'You need to add at least one price to your plan.',
      },
      {
        rule: madeChanges,
        message:
          'You made changes to the plan. Please save the plan before connecting a provider.',
      },
    ];
    if (validation.some((v) => v.rule)) {
      setValidationError(validation.find((v) => v.rule)?.message || '');
    } else {
      setValidationError('');
    }
  }

  return (
    <div className="plan-editor app-editor-page app-content-wrapper">
      <Link
        to={`/developer/apps/${activeApp.appId}/payments`}
        className="app-editor-page-back"
      >
        <i className="chevron-left"></i>
        <span>Back to Payments</span>
      </Link>
      {plan?.planId && (
        <>
          <header className="flex-wrapper align-end">
            <hgroup>
              <h2>Plan Editor</h2>
              <p>
                Modify your plan's details, and connect it to payment providers.
              </p>
            </hgroup>
            <button className="button primary-button" onClick={savePlan}>
              Save Plan
            </button>
          </header>
          <div className="app-section-spread">
            <div className="app-section">
              <div className="app-section-header plan-editor-header">
                <h4>General</h4>
                <DeveloperInput
                  name="isActive"
                  label="Active"
                  value={plan.isActive as any}
                  type="checkbox"
                  handleChange={() =>
                    setPlan({ ...plan, isActive: !plan.isActive })
                  }
                />
              </div>
              <div className="app-section-body">
                <PlanGeneral
                  plan={plan}
                  setPlan={setPlan}
                  isProviderConnected={!!connectedProviders.length}
                />
              </div>
            </div>
            <div className="app-section">
              <div className="app-section-header">
                <h4>Connect Providers</h4>
              </div>
              <div className="app-section-body">
                <PlanProviders
                  connectedProviders={connectedProviders}
                  setConnectedProviders={setConnectedProviders}
                  validationError={validationError}
                  plan={plan}
                  disabled={plan.isFree}
                />
              </div>
            </div>
          </div>

          <div className="app-section-spread">
            <PlanPrices
              isFree={plan.isFree}
              prices={plan.pricingVariants}
              currency={plan.currency}
              onChange={(prices) => {
                setPlan({ ...plan, pricingVariants: prices });
                setMadeChanges(true);
              }}
              isProviderConnected={!!connectedProviders.length}
            />
            <div className="app-section">
              <div className="app-section-header">
                <h4>Metadata</h4>
              </div>
              <div className="app-section-body">
                <Suspense fallback={<div>Loading...</div>}>
                  <JSONEditor
                    value={plan.meta}
                    onChange={(j) => setPlan({ ...plan, meta: j })}
                  />
                </Suspense>
              </div>
            </div>
          </div>

          <div className="app-section plan-editor-body app-content-table">
            <div className="app-section-header">
              <h4>Features</h4>
            </div>
            <PlanFeatures
              planFeatures={plan.features}
              setPlanFeatures={(features) => {
                setPlan({ ...plan, features });
              }}
            />
          </div>
        </>
      )}
    </div>
  );
};
