import React from 'react';

import 'jsoneditor-react/es/editor.min.css';

const { JsonEditor } = require('jsoneditor-react');

export const JSONEditor = ({ value = {}, onChange }: { value: any, onChange: (value: any) => void }) => {
  return (
    <div className="json-editor-wrapper">
      <JsonEditor 
        value={value} 
        onChange={onChange}
        search={false}
        mainMenuBar={false}
        navigationBar={false}
        statusBar={false}
      />
    </div>
  );
};

export default JSONEditor;
