import React, { useContext, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faExclamationCircle,
  faQuestionCircle,
} from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import { Popup } from '@commonninja/commonninja-styleguide-react';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';

import { developerService } from '../../../services/developer.service';
import { IAppIntegration, IIntegrationPlatform } from '../developer.types';
import { DeveloperContext } from '../developer.context';
import { ActiveButton } from '../appPayments/common/activeButton.comp';
import { DeveloperInput } from '../newDashboardComponents/developerInput.comp';
import { DeveloperCopier } from '../newDashboardComponents/developerCopier.comp';
import { addCommasAndAnd } from '../newDashboardComponents/helpers';
import { useHandleChange } from '../newDashboardComponents/hooks/useHandleChange';

import commonNinjaLogo from '../../../assets/commonninja-ribbon.svg';

interface Props {
  integrationData: IAppIntegration;
  platform: IIntegrationPlatform;
  backPageCallback: () => void;
}
interface IFormState {
  [key: string]: string;
}

export const AppIntegrationForm = ({
  integrationData,
  platform,
  backPageCallback,
}: Props) => {
  const [openDisconnectWarning, setOpenDisconnectWarning] = useState(false);
  const { integrations, setIntegrations } = useContext(DeveloperContext);
  const { activeApp } = useContext(DeveloperContext);
  const [formState, setFormState] = useState<IFormState>({
    ...(integrationData?.platformAppKeys || {}),
  });
  const [useSystemIntegration, setUseSystemIntegration] = useState<boolean>(
    platform.hasSystemIntegration && integrationData?.useSystemIntegration,
  );
  const [handleChange] = useHandleChange(setFormState);
  const [disconnecting, setDisconnecting] = useState(false);
  function clearPlatformKeys(prevState: IFormState): IFormState {
    const newState: IFormState = {};
    for (const key in prevState) {
      newState[key] = '';
    }
    return newState;
  }

  const disconnect = async (e: React.MouseEvent | React.TouchEvent) => {
    e.preventDefault();
    setOpenDisconnectWarning(false);
    setDisconnecting(true);
    updateIntegration(e, false, false);
    setFormState((prevState: IFormState) => {
      return clearPlatformKeys(prevState);
    });
    window.setTimeout(backPageCallback, 750);
  };

  async function updateIntegration(
    e: any,
    creationMode: boolean = false,
    isActive: boolean = true,
  ) {
    e.preventDefault();
    let platformAppKeys = { ...formState };
    if (!isActive) {
      platformAppKeys = clearPlatformKeys(platformAppKeys);
    }
    try {
      const { success } = await toast.promise(
        developerService.updateAppIntegration(activeApp.appId, platform.name, {
          platformAppKeys,
          isActive,
          useSystemIntegration,
        }),
        {
          pending: creationMode
            ? 'Creating Integration...'
            : 'Saving changes...',
          success: 'Great success! 👌',
          error: creationMode
            ? 'Could not create integration 😕'
            : 'Could not save changes 😕',
        },
      );

      if (success) {
        if (creationMode) {
          setIntegrations([
            ...integrations,
            {
              platform: platform.name,
              platformAppKeys,
              isActive,
              useSystemIntegration,
            },
          ]);
        } else {
          setIntegrations([
            ...integrations.map((integration) => {
              if (integration.platform === platform.name) {
                return {
                  platform: platform.name,
                  platformAppKeys,
                  isActive,
                  useSystemIntegration,
                };
              }
              return integration;
            }),
          ]);
        }
      }
    } catch (e) {
      toast.error((e as Error).message);
    }
  }

  useEffect(() => {
    if (useSystemIntegration) {
      setFormState(clearPlatformKeys(integrationData?.platformAppKeys));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [useSystemIntegration]);

  return (
    <div className="app-editor-page">
      <div className="app-editor-page-back" onClick={backPageCallback}>
        <i className="chevron-left"></i>
        <span>Back to Integrations</span>
      </div>
      <header className="flex-wrapper">
        <h2>Connect to {platform.displayName}</h2>
        <div className="button primary-button" onClick={updateIntegration}>
          Save
        </div>
      </header>
      <div className="app-section">
        <div className="app-section-body">
          <div className="flex-wrapper align-end space-between mb-20">
            <img
              src={platform.icon || platform.logo}
              alt={platform.displayName}
              height="35px"
            />
            <ActiveButton
              isActive={disconnecting ? false : true}
              size={22.5}
              onClick={() => setOpenDisconnectWarning(true)}
            />
          </div>
          <div>Connect {platform.displayName} to your app.</div>
        </div>
      </div>
      {platform.fields.length > 0 ? (
        <div className="app-section">
          <div className="app-section-body">
            <hgroup>
              <h3>{platform.displayName} Credentials</h3>
              <div>Enter your exact online client credentials.</div>
            </hgroup>

            {platform.hasSystemIntegration && (
              <div className="use-credentials-wrapper">
                <div
                  className={`credential-box ${
                    useSystemIntegration ? 'active' : ''
                  }`}
                  onClick={() => setUseSystemIntegration(true)}
                >
                  <h6>Use Common Ninja credentials</h6>
                  <p>
                    Get the integration up and running with zero effort and less
                    configuration.
                  </p>
                  {useSystemIntegration && (
                    <FontAwesomeIcon icon={faCheckCircle} />
                  )}
                </div>
                <div
                  className={`credential-box ${
                    !useSystemIntegration ? 'active' : ''
                  }`}
                  onClick={() => setUseSystemIntegration(false)}
                >
                  <h6>Use custom credentials</h6>
                  <p>
                    Configure your own custom {platform.displayName}'s
                    credentials and connect it to your app.
                  </p>
                  {!useSystemIntegration && (
                    <FontAwesomeIcon icon={faCheckCircle} />
                  )}
                </div>
              </div>
            )}
            {platform.fields
              .filter((f) => f.type !== 'hidden') // Hide hidden fields
              .map((field) => (
                <DeveloperInput
                  key={`${platform.name}-${field.name}`}
                  name={field.name}
                  label={field.label}
                  type={field.type}
                  value={formState[field.name] ? formState[field.name] : ''}
                  handleChange={handleChange}
                  placeholder={'Enter your ' + field.label + '...'}
                  disabled={useSystemIntegration}
                />
              ))}

            {!!platform.endpoints?.length && (
              <>
                <hr className="separator" />
                <hgroup>
                  <h3>Copy to {platform.displayName}</h3>
                  <div>
                    Copy the following URLs to the equivalent fields on{' '}
                    {platform.displayName}.
                  </div>
                </hgroup>
                {platform.endpoints.map((endpoint) => (
                  <DeveloperCopier
                    key={`${platform.name}-endpoint-${endpoint.value}`}
                    label={endpoint.label}
                    value={endpoint.value.replace(
                      /\{CN_APP_ID\}/g,
                      activeApp.appId,
                    )}
                    light
                  />
                ))}
              </>
            )}

            {platform.docsUrl && (
              <div className="info-box flex-wrapper">
                <FontAwesomeIcon icon={faQuestionCircle} />
                <a href={platform.docsUrl} target={'_blank'} rel="noreferrer">
                  Learn more in the docs
                </a>
              </div>
            )}
          </div>
        </div>
      ) : (
        <>
          <div className="app-section app-section-body center">
            <img src={commonNinjaLogo} alt="Common Ninja" className="mb-20" />
            <p>No need to enter any keys. We got you covered.</p>
            <button
              className="button primary-button"
              onClick={backPageCallback}
            >
              Go Back
            </button>
          </div>
        </>
      )}
      <Popup
        show={openDisconnectWarning}
        closeCallback={() => setOpenDisconnectWarning(false)}
        className="developer-page popup-warn"
      >
        <header>
          <FontAwesomeIcon
            icon={faExclamationCircle}
            color="#fabf5e"
            size="2x"
          />
          <h3>Are you sure you want to disconnect {platform.displayName}?</h3>
        </header>
        <p className="popup-warn-text">
          Your {addCommasAndAnd(platform.fields.map((field) => field.label))}{' '}
          will be deleted and you will have to re-enter them should you desire
          to reconnect {platform.displayName}.
        </p>
        <div className="popup-warn-buttons">
          <div
            className="button secondary-button"
            onClick={() => setOpenDisconnectWarning(false)}
          >
            Cancel
          </div>
          <div className="button primary-button" onClick={disconnect}>
            Disconnect
          </div>
        </div>
      </Popup>
    </div>
  );
};
