import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { developerService } from '../../../services/developer.service';
import { CollectionDetails } from '../appDatabase/collectionDetails/collectionDetails.comp';
import { Collections } from '../appDatabase/collections/collections.comp';
import { DatabaseCreation } from '../appDatabase/databaseCreation/databaseCreation.comp';
import { DeveloperContext } from '../developer.context';
import { DLoader } from '../loader/loader.comp';

export const AppDatabase = () => {
  const { collectionId } = useParams<any>();
  const { activeApp, } = useContext(DeveloperContext);
  const [databaseExist, setDatabaseExist] = useState(false);
  const [loading, setLoading] = useState(true);

  async function getDB() {
    try {
      const { data } = await developerService.getDB(activeApp.appId);
      if (data) setDatabaseExist(true);
    } catch (e) {
      setDatabaseExist(false);
    }
    setLoading(false);
  }

  useEffect(() => {
    if (!activeApp.appId) {
      return;
    }

    getDB();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeApp.appId]);

  if (loading) return <><DLoader /></>;

  return (
    <>
      {(databaseExist) &&
        <>
          {collectionId ?
            <CollectionDetails collectionName={collectionId} />
            :
            <Collections />
          }
        </>
      }
      {!databaseExist &&
        <DatabaseCreation />
      }
    </>
  );
};