import React from "react";
import useCopyToClipboard from "../../newDashboardComponents/hooks/useCopyToClipboard";

interface IDashboardCopierProps {
  value: string;
  title?: string;
  hidden?: boolean;
}

export const DashboardCopier = ({
  title,
  value,
  hidden = false,
}: IDashboardCopierProps) => {
  const [copy] = useCopyToClipboard();

  return (
    <div className="topbar-details-copier copier">
      {title && <span className="topbar-details-copier-title">{title}</span>}
      <span
        className={`topbar-details-copier-value ${hidden ? "hidden" : ""}`}
        title="Copy to clipboard"
        onClick={copy}
      >
        <span>{value}</span>
      </span>
    </div>
  );
};
