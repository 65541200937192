import React from 'react';
import useCopyToClipboard from './hooks/useCopyToClipboard';

export const DeveloperCopier = ({
  label = '',
  value = '',
  light = false,
  hidden = false,
  type = 'input',
}) => {
  const [copy] = useCopyToClipboard();

  return (
    <div className="input-container">
      <label>{label}</label>
      <div
        className={`copier ${light ? 'light' : ''}`}
        title="Copy to clipboard"
        onClick={copy}
      >
        {value}
        {type === 'input' && (
          <input
            defaultValue={value}
            readOnly
            type={hidden ? 'password' : 'text'}
          />
        )}
        {type === 'textarea' && <textarea defaultValue={value} readOnly />}
      </div>
    </div>
  );
};
