import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEllipsisH,
  faCode,
  faCopy,
  faPen,
  faChartBar,
  faEye,
  faUsers,
  faTrashAlt,
} from '@fortawesome/free-solid-svg-icons';
import { NinjaSkeleton } from '@commonninja/commonninja-styleguide-react';

import { getActionUrl } from '../../helpers/helpers';

// import './component.scss';

const getBgStyles = (previewImage?: string) => {
  if (previewImage) {
    return {
      backgroundImage: `url(${previewImage})`,
    };
  }
  return {};
};

export const SkeletonComponent = () => {
  return (
    <div className="component">
      <figure>
        <div className="bg">
          <NinjaSkeleton count={1} height="100%" />
        </div>
      </figure>
      <div className="details">
        <h4>
          <NinjaSkeleton count={1} />
        </h4>
        <p>
          <NinjaSkeleton width="75%" count={1} />
        </p>
      </div>
    </div>
  );
};

export const CNComponent = ({
  data,
  userRole,
  codeClick,
  deleteClick,
  duplicateClick,
  permissionsClick,
}: any) => {
  const [opened, setOpened] = useState(false);

  function linkAsButtonClick(e: any, callback: Function) {
    e.preventDefault();

    callback(data);
  }

  function onActionsClick(e: any) {
    e.preventDefault();

    setOpened(!opened);
  }

  function handleOutsideClick(e: any) {
    if (opened && !e.target.closest('.actions-menu')) {
      setOpened(false);
    }
  }

  useEffect(() => {
    window.addEventListener('mousedown', handleOutsideClick);

    return () => {
      window.removeEventListener('mousedown', handleOutsideClick);
    };
  });

  return (
    <div className={`component ${data.type}`}>
      <figure>
        <div className="bg" style={getBgStyles(data.previewImage)} />
        <div className="hover-wrapper">
          {userRole === 'owner' || userRole === 'editor' ? (
            <a
              title="Edit"
              className="btn"
              target="_parent"
              rel="noopener noreferrer"
              href={getActionUrl('edit', data, [])}
            >
              Edit Plugin
            </a>
          ) : (
            <a
              title="Edit"
              className="btn"
              target="_parent"
              rel="noopener noreferrer"
              href={getActionUrl('view', data, [])}
            >
              Live Preview
            </a>
          )}
          {userRole !== 'viewer' && (
            <div className="actions-menu">
              <button
                className="btn open-actions"
                title="More actions"
                onClick={onActionsClick}
              >
                <FontAwesomeIcon icon={faEllipsisH} />
              </button>
              <div className={`actions ${opened ? 'opened' : ''}`}>
                {userRole === 'owner' && (
                  <React.Fragment>
                    <a
                      href="/code"
                      title="Add to Website"
                      onClick={(e) => linkAsButtonClick(e, codeClick)}
                    >
                      <FontAwesomeIcon icon={faCode} />
                      <span>Add to Website</span>
                    </a>
                    <a
                      href="/duplicate"
                      title="Duplicate"
                      onClick={(e) => linkAsButtonClick(e, duplicateClick)}
                    >
                      <FontAwesomeIcon icon={faCopy} />
                      <span>Duplicate</span>
                    </a>
                  </React.Fragment>
                )}
                {(userRole === 'owner' || userRole === 'editor') && (
                  <a
                    title="Edit"
                    target="_parent"
                    rel="noopener noreferrer"
                    href={getActionUrl('edit', data, [])}
                  >
                    <FontAwesomeIcon icon={faPen} />
                    <span>Edit</span>
                  </a>
                )}
                <a
                  title="Analytics"
                  target="_parent"
                  rel="noopener noreferrer"
                  href={`/analytics/${data.guid}`}
                >
                  <FontAwesomeIcon icon={faChartBar} />
                  <span>Analytics</span>
                </a>
                <a
                  title="View"
                  target="_blank"
                  rel="noopener noreferrer"
                  href={getActionUrl('view', data, [])}
                >
                  <FontAwesomeIcon icon={faEye} />
                  <span>Live Preview</span>
                </a>
                {userRole === 'owner' && (
                  <React.Fragment>
                    <a
                      title="Permissions"
                      target="_parent"
                      rel="noopener noreferrer"
                      href="/api/permissions"
                      onClick={(e) => linkAsButtonClick(e, permissionsClick)}
                    >
                      <FontAwesomeIcon icon={faUsers} />
                      <span>Permissions</span>
                    </a>
                    <a
                      href="/delete"
                      title="Delete"
                      onClick={(e) => linkAsButtonClick(e, deleteClick)}
                      className="delete"
                    >
                      <FontAwesomeIcon icon={faTrashAlt} />
                      <span>Delete</span>
                    </a>
                  </React.Fragment>
                )}
              </div>
            </div>
          )}
        </div>
      </figure>
      <div className="details">
        <h4>
          <a
            href={getActionUrl('view', data, [])}
            target="_parent"
            rel="noopener noreferrer"
          >
            {data.name}
          </a>
        </h4>
        <p>Last Update: {data.updated}</p>
        {userRole !== 'owner' && (
          <p>
            Access Level: <strong className="role">{userRole}</strong>
          </p>
        )}
      </div>
    </div>
  );
};
