import React, { useState, useContext, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import { Popup } from '@commonninja/commonninja-styleguide-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faExclamationTriangle,
  faArrowsAltH,
} from '@fortawesome/free-solid-svg-icons';

import { DeveloperContext } from '../../../../developer.context';
import { developerService } from '../../../../../../services/developer.service';
import {
  IAppPlan,
  IAppPlanConnection,
  IIntegrationPlatform,
} from '../../../../developer.types';

import logoImage from '../../../../../../assets/commonninja-logo.svg';
import './planProviders.scss';
import { ActiveButton } from '../../../common/activeButton.comp';

interface IPlanProvidersProps {
  plan: IAppPlan;
  connectedProviders: IAppPlanConnection[];
  setConnectedProviders: (plans: IAppPlanConnection[]) => void;
  validationError?: string;
  disabled?: boolean;
}

export const PlanProviders = ({
  plan,
  connectedProviders,
  setConnectedProviders,
  validationError,
  disabled = false,
}: IPlanProvidersProps) => {
  const initialPricingToPlanMap: any = {};
  plan.pricingVariants.forEach((p) => {
    initialPricingToPlanMap[p.id] = '';
  });

  const [providers, setProviders] = useState<[]>([]);
  const [selected, setSelected] = useState(-1);
  const [pricingToPlanMap, setPricingToPlanMap] = useState<{
    [key: string]: string;
  }>({ ...initialPricingToPlanMap });
  const { activeApp, integrations } = useContext(DeveloperContext);
  const { planId } = useParams<any>();

  async function fetchConnectedProviders() {
    try {
      const { data: connectedProviders } =
        await developerService.getAppPlanConnections(activeApp.appId, planId);
      setConnectedProviders(connectedProviders);
    } catch (error) {
      toast.error(
        'There was a problem getting the connected providers: ' +
          (error as Error).message,
      );
    }
  }

  async function handleConnect() {
    const provider = providers[selected] as IIntegrationPlatform;
    try {
      if (!provider) {
        throw new Error(`Provider doesn't exists`);
      }

      let fieldsArePopulated = true;

      if (
        provider.name === 'paddle' ||
        provider.name === 'duda' ||
        provider.name === 'wix'
      ) {
        Object.keys(pricingToPlanMap).forEach((p) => {
          if (!pricingToPlanMap[p]) {
            fieldsArePopulated = false;
          }
        });
      }

      if (!fieldsArePopulated) {
        throw new Error(
          'Please add a plan ID for each pricing plan you defined.',
        );
      }

      await developerService
        .createAppPlanConnection(
          activeApp.appId,
          planId,
          provider.name,
          pricingToPlanMap,
        )
        .catch((e) => {
          throw new Error(
            `There was a problem connecting to this provider, did you set up the integration to ${provider.displayName} correctly?`,
          );
        });
      toast.success('Provider connected successfully');
      handleClose();
    } catch (e) {
      toast.error((e as Error).message);
    }
  }

  async function handleDisconnect() {
    try {
      const provider = providers[selected] as IIntegrationPlatform;
      await developerService.disableAppPlanConnection(
        activeApp.appId,
        planId,
        provider.name,
      );
      toast.success('Provider disconnected successfully');
      handleClose();
    } catch (e) {
      toast.error((e as Error).message);
    }
  }

  function handleClose() {
    setSelected(-1);
    fetchConnectedProviders();
  }

  function getConnectedProvider(provider: IIntegrationPlatform) {
    return connectedProviders.find(
      (p: IAppPlanConnection) => p.platform === provider.name,
    );
  }

  function handleClick(index: number) {
    if (validationError) {
      toast.error(validationError);
      return;
    }
    setSelected(index);
  }

  useEffect(() => {
    async function fetchProviders() {
      try {
        const { data: providers } =
          await developerService.getAppIntegrationPlatforms();
        setProviders(
          providers.filter((p: IIntegrationPlatform) => {
            if (!p.tags.includes('payments')) {
              return false;
            }

            if (integrations.find((i) => i.platform === p.name && i.isActive)) {
              return true;
            }

            return false;
          }),
        );
      } catch (error) {
        toast.error(
          'There was a problem loading the providers: ' +
            (error as Error).message,
        );
      }
    }
    if (activeApp?.appId) {
      fetchProviders();
    }
    // eslint-disable-next-line
  }, [activeApp.appId, integrations]);

  useEffect(() => {
    if (planId && activeApp?.appId) {
      fetchConnectedProviders();
    }
    // eslint-disable-next-line
  }, [activeApp.appId, planId]);

  useEffect(() => {
    if (selected === -1) {
      setPricingToPlanMap({ ...initialPricingToPlanMap });
    }
    // eslint-disable-next-line
  }, [selected]);

  function renderPlanFields() {
    const provider = providers[selected] as IIntegrationPlatform;

    return (
      <div className="plan-fields form">
        <p className="center">
          Enter your {provider.displayName} subscription plan or product IDs
        </p>
        {plan.pricingVariants.map((pricing) => (
          <div className="form-row" key={`pricing-${pricing.id}`}>
            <label>{pricing.name}</label>
            <input
              type={'text'}
              autoComplete="off"
              value={pricingToPlanMap[pricing.id] || ''}
              onChange={(e) =>
                setPricingToPlanMap({
                  ...pricingToPlanMap,
                  [pricing.id]: e.target.value,
                })
              }
              placeholder={`Enter ${
                pricing.pricePeriod === 'one-time' ? 'product' : 'plan'
              } ID...`}
            />
          </div>
        ))}
      </div>
    );
  }

  function renderPopupBody(isConnected: boolean) {
    const provider = providers[selected] as IIntegrationPlatform;

    return (
      <>
        <h2>{isConnected ? 'Disconnect provider' : 'Connect provider'}</h2>
        <section className="plan-provider-details">
          <div className="plan-provider-details-header">
            <img src={provider.logo} alt={provider.name} />
            <FontAwesomeIcon icon={faArrowsAltH} size={'2x'} />
            <img src={logoImage} alt="Common Ninja Logo" />
          </div>
          {isConnected && (
            <div className="plan-provider-details-body center">
              <p>
                Are you sure you want to disconnect{' '}
                <strong>{provider.displayName} </strong>
                from your plan?
              </p>
            </div>
          )}
          {!isConnected &&
            (provider.name === 'paddle' ||
              provider.name === 'duda' ||
              provider.name === 'wix') &&
            renderPlanFields()}
          <div className="plan-provider-warning">
            <FontAwesomeIcon icon={faExclamationTriangle} />
            <strong>Please note:</strong>{' '}
            {isConnected
              ? `This will disable the integration
              and remove it from your plan. You will have to set it up again
              in order for your users to pay using this integration.`
              : `After connecting to the payment
              provider, you will the name of the plan or the prices will be
              set to read-only. You will not be able to change the plan name
              or the prices.`}
          </div>
          <div className="buttons-wrapper center">
            <button
              className="button link-button"
              onClick={() => setSelected(-1)}
            >
              Go back
            </button>
            <button
              className="button primary-button"
              onClick={isConnected ? handleDisconnect : handleConnect}
            >
              {isConnected ? 'Disconnect' : 'Connect'}
            </button>
          </div>
        </section>
      </>
    );
  }

  return (
    <div className="plan-providers">
      {providers.map((provider: IIntegrationPlatform, index) => (
        <div
          className={`plan-provider-item ${disabled ? 'disabled' : ''}`}
          key={provider.name}
        >
          <img
            src={provider.logo}
            alt={provider.name}
            className={provider.name}
          />
          <ActiveButton
            size={22.5}
            isActive={!!connectedProviders && !!getConnectedProvider(provider)}
            onClick={() => handleClick(index)}
            isDisabled={disabled}
          />
        </div>
      ))}

      {selected !== -1 && (
        <Popup
          show={selected !== -1}
          closeCallback={handleClose}
          className="plan-providers developer-page"
        >
          {renderPopupBody(!!getConnectedProvider(providers[selected]))}
        </Popup>
      )}
    </div>
  );
};
