import React, { useEffect, useState, useRef, useCallback } from "react";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import {
  Button,
  IHttpResult,
  TChildren,
  Loader,
} from "@commonninja/commonninja-styleguide-react";
import { useResizeDetector } from "react-resize-detector";

interface IAuthFormProps {
  name: string;
  authRequest: (token: string) => Promise<IHttpResult>;
  mainButtonText: string;
  successCallback: (isMFAEnabled?: boolean) => void;
  children?: TChildren;
  footerComp?: TChildren;
  secondaryButtonComp?: TChildren;
  firstInput?: any;
  embedMode?: boolean;
  invalidMessage?: string;
  className?: string;
  buttonDisabled?: boolean;
  validateCaptcha?: boolean;
}

export const AuthForm = ({
  name,
  className,
  children,
  footerComp,
  mainButtonText,
  secondaryButtonComp,
  firstInput,
  successCallback,
  embedMode,
  authRequest,
  invalidMessage,
  validateCaptcha,
  buttonDisabled = false,
}: IAuthFormProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const queryParams = new URLSearchParams(window.location.search);
  const [error, setError] = useState<string>(
    queryParams.get("error")
      ? (queryParams.get("error") as string).replaceAll("_", " ")
      : ""
  );
  const form = useRef<HTMLFormElement>(null);
  const { executeRecaptcha } = useGoogleReCaptcha();
  const onResize = useCallback(handleResize, []);
  const { ref } = useResizeDetector({
    refreshMode: "debounce",
    refreshRate: 1,
    onResize,
  });

  function handleResize() {
    window.parent.postMessage(
      {
        type: "COMMONNINJA_AUTH_IFRAME_UPDATE",
        height:
          (document.querySelector("#main-content")?.clientHeight || 0) + 30,
        // width: window.innerWidth
      },
      "*"
    );
  }

  async function getRecaptchaToken(action: string) {
    if (!validateCaptcha) {
      return "";
    }

    if (!executeRecaptcha) {
      return "";
    }

    try {
      return await executeRecaptcha(action);
    } catch (e) {
      return "";
    }
  }

  async function submit(e: any) {
    e.preventDefault();

    if (loading) {
      return;
    }

    if (form.current) {
      const isValid = form.current.checkValidity();
      form.current.reportValidity();

      if (!isValid) {
        return;
      }
    }

    setError("");
    setLoading(true);

    try {
      if (invalidMessage) {
        throw new Error(invalidMessage);
      }

      const token = await getRecaptchaToken(name);
      if (validateCaptcha && !token) {
        throw new Error("Token is empty");
      }
      const result: IHttpResult = await authRequest(token);

      // Throw error if needed
      if (!result.success) {
        throw new Error(result.message);
      }

      const isMFA = result.data?.is2FaRequired || false;

      successCallback(isMFA);
    } catch (e) {
      setError((e as Error).message);
    }

    setLoading(false);
  }

  useEffect(() => {
    if (embedMode) {
      handleResize();
    }
    // eslint-disable-next-line
  }, [error]);

  useEffect(() => {
    if (firstInput?.current) {
      firstInput.current.focus();
    }

    if (embedMode) {
      handleResize();
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div className={className || ""} ref={ref}>
      <form onSubmit={submit} ref={form}>
        {children && children}
        <div className="buttons-wrapper">
          {secondaryButtonComp && secondaryButtonComp}
          <Button mode="primary" disabled={buttonDisabled} onClick={submit}>
            {loading ? (
              <Loader innerColor={"#fff"} outerColor={"#fff"} size="big" />
            ) : (
              mainButtonText
            )}
          </Button>
        </div>
        {error && <p className="body-1 error">{error}</p>}
        {footerComp && (
          <>
            <hr />
            {footerComp}
          </>
        )}
      </form>
    </div>
  );
};
