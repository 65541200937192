import React, { useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import { DeveloperContext } from '../developer.context';
import { developerService } from '../../../services/developer.service';
import { IApp } from '../developer.types';
import { ImageUpload } from '../imageUpload/imageUpload.comp';
import { DeveloperInput } from '../newDashboardComponents/developerInput.comp';
import { useHandleChange } from '../newDashboardComponents/hooks/useHandleChange';
import { DeveloperCopier } from '../newDashboardComponents/developerCopier.comp';
import { Popup } from '@commonninja/commonninja-styleguide-react';
import { DeveloperWarning } from '../newDashboardComponents/developerWarning.comp';
import { capitalizeFirstLetter } from '../../../helpers/helpers';

export const AppInfo = () => {
  const { activeApp, setActiveApp } = useContext(DeveloperContext);
  const [formState, setFormState] = useState<any>({
    name: activeApp.name,
    status: activeApp.status,
    icon: activeApp.meta.icon || '',
  });
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const history = useHistory();

  const [handleChange] = useHandleChange(setFormState);

  async function deleteApp() {
    try {
      const { success } = await toast.promise(
        developerService.deleteDeveloperApp(activeApp.appId),
        {
          pending: 'Deleting app...',
          success: 'Great success! 👌',
          error: 'Could not delete app 😕',
        },
      );

      if (success) {
        setActiveApp(null);
        history.push('/developer/apps');
      }
    } catch (e) {
      toast.error((e as Error).message);
    }
  }

  async function updateApp(e?: any) {
    e?.preventDefault();
    try {
      const data: Partial<IApp> = {
        name: formState.name,
        status: formState.status,
        meta: {
          ...activeApp.meta,
          icon: formState.icon || '',
        },
      };
      const { success } = await toast.promise(
        developerService.updateDeveloperApp(activeApp.appId, data),
        {
          pending: 'Saving changes...',
          success: 'Great success! 👌',
          error: 'Could not save changes 😕',
        },
      );

      if (success) {
        setActiveApp({
          ...activeApp,
          ...data,
        });
      }
    } catch (e) {
      toast.error((e as Error).message);
    }
  }

  return (
    <>
      <form onSubmit={updateApp} className="app-content-wrapper">
        <header className="flex-wrapper align-end">
          <hgroup>
            <h2>App Info</h2>
          </hgroup>
          <button className="button primary-button">Save</button>
        </header>
        <section className="app-section">
          <div className="app-section-body">
            <DeveloperInput
              label="App Name"
              value={formState.name}
              handleChange={handleChange}
              placeholder="Enter app name..."
              name="name"
              autoFocus
              required
            />
            <DeveloperInput
              select={['published', 'draft'].map((name) => ({
                name,
                displayName: capitalizeFirstLetter(name),
              }))}
              label="Status"
              value={formState.status}
              handleChange={handleChange}
              name="status"
            />
            <div className="input-container">
              <label htmlFor="icon">App Icon</label>
              <ImageUpload
                value={formState.icon}
                successCallback={(url: string) => {
                  setFormState((prevState: any) => ({
                    ...prevState,
                    icon: url,
                  }));
                  window.setTimeout(updateApp, 10);
                }}
              />
              <small>(Max. file size is 3MB)</small>
            </div>
            <DeveloperCopier label="App ID" value={activeApp.appId} />
            <DeveloperCopier
              label="App Secret"
              value={activeApp.secretKey}
              hidden
            />
          </div>
        </section>
        <div className="buttons-wrapper delete-app">
          <h4>App Deletion</h4>
          <p>
            Caution! This will delete the app and all its data will be lost.
            This action cannot be undone.
          </p>
          <button
            className="button primary-button"
            onClick={(e) => {
              e.preventDefault();
              setShowConfirmDelete(true);
            }}
          >
            Delete App
          </button>
        </div>
      </form>
      <Popup
        show={showConfirmDelete}
        closeCallback={() => setShowConfirmDelete(false)}
        className="developer-page popup-warn"
      >
        <DeveloperWarning
          title="Are you sure you want to delete this app?"
          message="This action cannot be undone. You will lose all reports and data associated with this app."
          confirm={{ onClick: deleteApp, label: 'Delete App' }}
          cancel={{
            onClick: () => setShowConfirmDelete(false),
            label: 'I changed my mind, take me back!',
          }}
        />
      </Popup>
    </>
  );
};
