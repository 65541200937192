import React, { useState, useContext, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { IAppPlan } from "../../developer.types";
import { DeveloperContext } from "../../developer.context";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { developerService } from "../../../../services/developer.service";
import { Popup } from "@commonninja/commonninja-styleguide-react";
import { toast } from "react-toastify";
import "./plans.scss";
import { DLoader } from "../../loader/loader.comp";
import { DeveloperInput } from "../../newDashboardComponents/developerInput.comp";
import { CopyInput } from "../../copyInput/copyInput.comp";
import { MdContentCopy } from 'react-icons/md';

export const Plans = () => {
  const {
    activeApp: { appId },
  } = useContext(DeveloperContext);
  const [plans, setPlans] = useState<IAppPlan[]>([]);
  const [loading, setLoading] = useState(true);
  const [localName, setLocalName] = useState("");
  const [popupOpened, setPopupOpened] = useState(false);
  // const [planToDelete, setPlanToDelete] = useState<IAppPlan>();
  const history = useHistory();
  const addCardRef = React.createRef<HTMLDivElement>();

  // async function deletePlan(plan: IAppPlan) {
  //   try {
  //     const { success } = await toast.promise(
  //       developerService.deleteAppPlan(appId, plan.planId),
  //       {
  //         pending: 'Deleting plan...',
  //         success: 'Plan deleted!',
  //         error: 'Could not delete plan 😕',
  //       }
  //     );
  //     if (success) {
  //       setPlans(plans.filter((p) => p.planId !== plan.planId));
  //       setPlanToDelete(undefined);
  //     }
  //   } catch (e) {
  //     toast.error((e as Error).message);
  //   }
  // }

  async function createPlan(plan: Partial<IAppPlan>, redirect = false) {
    try {
      toast.loading("Creating plan...");
      const { data } = await developerService.createAppPlan(appId, {
        ...plan,
        isActive: false,
      });
      toast.dismiss();
      if (redirect) {
        history.push(`/developer/apps/${appId}/payments/plan/${data.planId}`);
      } else {
        if (addCardRef.current) {
          addCardRef.current.scrollIntoView({
            behavior: "smooth",
            block: "center",
          });
        }
      }
      setPlans([...plans, data]);
    } catch (e) {
      toast.error((e as Error).message);
    }
  }

  useEffect(() => {
    async function fetchPlans() {
      if (appId) {
        try {
          const { data } = await developerService.getAppPlans(appId);
          setPlans(data);
          setLoading(false);
        } catch (e) {
          toast.error((e as Error).message);
          setLoading(false);
        }
      }
    }
    fetchPlans();
  }, [appId]);

  return (
    <div className="plans app-content-wrapper">
      {loading ? (
        <DLoader />
      ) : (
        <>
          <div className="app-cards">
            {plans
              .sort((a, b) => (a.order || 0) - (b.order || 0))
              .map((plan) => (
                <div className="app-card app-card-item" key={plan.planId}>
                  <div className="app-card-header">
                    <div className="title">
                      <h5 title={plan.name}>{plan.name}</h5>
                      <span
                        className={`label ${
                          plan.isActive ? "active" : "inactive"
                        }`}
                      >
                        {plan.isActive ? "Active" : "Inactive"}
                      </span>
                    </div>
                    <div className="app-card-buttons">
                      <CopyInput inputType="hidden" value={plan.planId} buttonStyles={{
                        padding: 0,
                        marginRight: 7,
                        marginTop: 1,
                      }}>
                        <MdContentCopy className="copy-icon" />
                      </CopyInput>
                      <i
                        className="app-card-button copy-icon"
                        title="Duplicate"
                        onClick={() =>
                          createPlan(
                            {
                              ...plan,
                              _id: undefined,
                              name: `${plan.name} (copy)`,
                              planId: undefined,
                              pricingVariants: [],
                            },
                            false
                          )
                        }
                      ></i>
                      {/* <i
                      className="app-card-button"
                      onClick={() => setPlanToDelete(plan)}
                    >
                      <FontAwesomeIcon icon={faTrash} />
                    </i> */}
                    </div>
                  </div>
                  <div className="app-card-content">
                    {plan.description ? plan.description : "No description."}
                  </div>
                  <div className="app-card-footer buttons-wrapper">
                    <Link
                      to={`/developer/apps/${appId}/payments/plan/${plan.planId}`}
                      className="button secondary-button"
                    >
                      Edit
                    </Link>
                  </div>
                </div>
              ))}
            {!false && (
              <div
                className="app-card app-card-add"
                onClick={() => setPopupOpened(true)}
                ref={addCardRef}
              >
                <FontAwesomeIcon icon={faPlus} />
                <span>{"Add a Plan"}</span>
              </div>
            )}
          </div>
        </>
      )}
      <Popup
        show={popupOpened}
        closeCallback={() => {
          setPopupOpened(false);
          setLocalName("");
        }}
        className="developer-page app-name-popup"
      >
        <h2>Plan Name</h2>
        <div className="app-section-body">
          <p>What would be the name of your plan?</p>
          <DeveloperInput
            handleChange={(e: any) => setLocalName(e.target.value)}
            name="localName"
            title="Plan Name"
            value={localName}
            required
            autoFocus
            placeholder="Plan Name"
          />
          <div
            className="button primary-button"
            onClick={() => createPlan({ name: localName }, true)}
          >
            Create Plan
          </div>
        </div>
      </Popup>
      {/* <Popup
        show={planToDelete !== undefined}
        closeCallback={() => setPlanToDelete(undefined)}
        className="developer-page popup-warn"
      >
        <header>
          <FontAwesomeIcon
            icon={faExclamationCircle}
            color="rgb(250,	191, 94)" // #FABF56
            size="2x"
          />
          <h3>Are you sure you want to delete this plan?</h3>
        </header>
        <p className="popup-warn-text">
          This action cannot be undone. This will delete all the pricing
          variants associated with this plan.
        </p>
        <div className="popup-warn-buttons">
          <div
            className="button secondary-button"
            onClick={() => setPlanToDelete(undefined)}
          >
            Cancel
          </div>
          <div
            className="button primary-button"
            onClick={() => {
              if (planToDelete) {
                deletePlan(planToDelete);
              }
            }}
          >
            Delete
          </div>
        </div>
      </Popup> */}
    </div>
  );
};
