import React from 'react';

import './card.scss';

interface ICardProps {
    children: React.ReactNode;
    className?: string;
}

export const Card = ({ children, className = '' }: ICardProps) => {
    return (
        <div className={`card ${className}`}>
            {children}
        </div>
    );
};

