import React, { useEffect, useState, useContext } from 'react';
import { useParams, Link, Redirect } from 'react-router-dom';
import relativeTime from 'dayjs/plugin/relativeTime';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';
import {
  IPaginationResponse,
  Table,
  Tabs,
} from '@commonninja/commonninja-styleguide-react';

import { IAppWebhook, IAppWebhookMessageRequest } from '../../developer.types';
import { DeveloperContext } from '../../developer.context';
import { developerService } from '../../../../services/developer.service';
import { DeveloperIcon } from '../common/developerIcon.comp';
import { DLoader } from '../../loader/loader.comp';
import { MessageViewer } from './messageViewer/messageViewer.comp';
import './webhookMessages.scss';
dayjs.extend(relativeTime);

interface IWebhookMessages {
  webhook?: IAppWebhook;
  getWebhooks: () => void;
}

function getDeveloperIconName(message: IAppWebhookMessageRequest) {
  if (message.response?.statusCode === 200) {
    return 'sent';
  } else if (message.response?.statusCode >= 400) {
    return 'failed';
  }

  if (message.status) {
    return message.status;
  }

  return '';
}

const WebhookMessagesComp = ({
  tabId,
  appId,
  webhookId,
}: {
  tabId: string;
  appId: string;
  webhookId: string;
}) => {
  const [messages, setMessages] = useState<IPaginationResponse>({
    docs: [],
    limit: 10,
    page: 1,
    pages: 0,
    total: 0,
  });
  const [loading, setLoading] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const [viewedMessage, setViewedMessage] = useState<{
    [tabId: string]: IAppWebhookMessageRequest;
  }>({});

  async function getWebhookData(loadMore = false) {
    if (loadMore) {
      setLoadingMore(true);
    } else {
      setLoading(true);
    }

    try {
      const { data } = await developerService.getAppWebhookMessageRequests(
        appId,
        webhookId,
        messages.page,
        loadMore ? messages.limit + 10 : messages.limit,
        tabId !== 'all' ? tabId : ''
      );
      setMessages(data);
      if (loadMore) {
        setLoadingMore(false);
      }
      const updatedMessage = data.docs.find(
        (message: IAppWebhookMessageRequest) =>
          viewedMessage[tabId]?.id === message.id
      );
      setViewedMessage({
        ...viewedMessage,
        [tabId]: updatedMessage || data?.docs[0] || {},
      });
    } catch (e) {
      toast.error((e as Error).message);
    }
    setLoading(false);
  }

  useEffect(() => {
    getWebhookData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabId]);

  if (loading) {
    return <DLoader />;
  }

  return messages?.docs?.length ? (
    <div className="flex-wrapper">
      <div className="app-content-table">
        <Table
          headers={['Message', 'Time Sent']}
          rows={messages.docs.map((message) => ({
            cells: [
              {
                content: (
                  <DeveloperIcon
                    name={getDeveloperIconName(message)}
                    label={message.type}
                  />
                ),
              },
              {
                content: dayjs(message.created).format(
                  `YYYY-MM-DD[\xa0\xa0]HH:mm:ss`
                ),
              },
            ],
            className:
              viewedMessage[tabId]?.id === message.id
                ? 'now-viewing'
                : 'clickable',
            onClick: () =>
              setViewedMessage({ ...viewedMessage, [tabId]: message }),
          }))}
        />
        {messages.page < messages.pages && (
          <div className="load-more" onClick={() => getWebhookData(true)}>
            <span>
              <DeveloperIcon
                name={loadingMore ? 'loading' : 'loadmore'}
                label="Load more"
                size="sm"
              />
            </span>
          </div>
        )}
      </div>
      <MessageViewer
        messageRequest={viewedMessage[tabId]}
        getWebhook={getWebhookData}
      />
    </div>
  ) : (
    <div className="center">No messages</div>
  );
};

export const WebhookMessages = ({ webhook }: IWebhookMessages) => {
  const { activeApp } = useContext(DeveloperContext);
  const { webhookId } = useParams<any>();

  if (!webhook) {
    return <Redirect to={`/developer/apps/${activeApp.appId}/webhooks`} />;
  }

  return (
    <div className="app-editor-page webhook-messages">
      <Link
        to={`/developer/apps/${activeApp.appId}/webhooks`}
        className="app-editor-page-back"
      >
        <i className="chevron-left"></i>
        <span>Back to Webhooks</span>
      </Link>
      <header>
        <hgroup>
          <h2>Webhook Messages</h2>
          {webhook.url && (
            <p className="trim" title={webhook.url}>
              {webhook.url}
            </p>
          )}
        </hgroup>
      </header>

      <Tabs
        items={[
          { name: 'All', id: 'all' },
          { name: 'Succeeded', id: 'sent' },
          { name: 'Failed', id: 'failed' },
        ]}
        resolveTabComp={(tabId: string | number) => {
          return (
            <WebhookMessagesComp
              appId={activeApp?.appId}
              webhookId={webhookId}
              tabId={String(tabId)}
            />
          );
        }}
      />
    </div>
  );
};
